import React from 'react';
import DeliveryCode from '../../components/deliveryCode/DeliveryCode';

const Delivery = () => {
    return (
        <div className="main">
            <div className="body_container">
                <div className="main-padding1">
                    <DeliveryCode></DeliveryCode>
                </div>
            </div>
        </div>
    )
}

export default Delivery;