import React from "react"
import './OrderReport.scss';

const OrderReport = () => {
    return (
        <>
            <div className="main-report">
                <div className="body-container">
                    <iframe className="iframe-report"
                        title="CRM.orders"
                        src="https://app.fabric.microsoft.com/view?r=eyJrIjoiMjk5ZTc4ZWUtNTk1Mi00YTk1LWFmNDgtOTg5ZDZhYTM1YTAxIiwidCI6ImJhMGUwMjI4LTdkOWYtNGQwNy1hNWYyLWIzMTM0NjRkZmNkNCJ9&pageName=ReportSection"
                        frameborder="0" allowfullscreen="true">
                    </iframe>
                </div>
            </div>

        </>
    )
}
export default OrderReport