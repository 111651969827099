import React from "react";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { useCallback } from "react";
import { ToastContainer } from "react-toastify";
import { useLanguage } from "../../LanguageContext";
import '../../pages/order-page/Orders.scss'
import API from "../../services/Api";
import { Link } from "react-router-dom";
import NewOrderSidebar from "../../components/order-sidebar/NewOrderSidebar";
import Order from "../../components/order-sidebar/OrderSidebar";
import OrdersSkeletonLoder from "../../components/skeleton-loader/OrdersSkeletonLoder";
import OrderDelete from "./OrderDelete";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CardSkeletonLoader from "../../components/skeleton-loader/CardSkeletonLoader";
import STORAGE_KEYS from "../../utils/storageKey";
import Permissions from "../../components/jwttoken-decode-service/Permission";

const Orders = () => {
  const { t } = useLanguage();
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const [openSidebar, setOpenSidebar] = useState();
  const [openNewSidebar, setOpenNewSidebar] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus,] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [pageCount, setPageCount] = useState();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [noRecordsMessage] = useState();
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deleteServiceRequestId, setDeleteServiceRequestId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orderStatCount, setOrderStatCount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const openDeleteConfirmation = (orderId) => {
    setDeleteServiceRequestId(orderId);
    setIsDeleteConfirmationVisible(true);
  };

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = data.map((_, index) => index);
      setCheckedItems(checkedItems);
    } else {
      setCheckedItems([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleSingleCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked && !checkedItems.includes(index)) {
      setCheckedItems([...checkedItems, index]);
    } else if (!isChecked && checkedItems.includes(index)) {
      setCheckedItems(checkedItems.filter(item => item !== index));
    }
  };

  const handleOpenNewSidebar = () => {
    setOpenNewSidebar(!openNewSidebar);
  };

  const handleOpenSidebar = (request) => {
    setSelectedOrders(request);
    setOpenSidebar(true);
  };

  const handleClose = () => {
    setOpenSidebar(!openSidebar);
  };

  const headers = {
    Authorization: `${refreshToken}`,
    "Content-Type": "application/json",
  };

  const fetchData = useCallback(
    (searchQuery, page = currentPage + 1, selectedStatus,
      selectedMonth,
      selectedYear,
      selectedEmployee,
      selectedDepartment, selectedPaymentStatus) => {
      setLoading(true);
      let fetchUrl = "";

      if (!startDate || !endDate) {
        alert('Please select both start and end dates, and provide a groupId.');
        setLoading(false);
        return;
      }
      setIsLoading(true);

      const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      fetchUrl = `${API.commerceAPI}/commerce/order/order/group/${groupId}`;

      const queryParams = [];

      if (formattedStartDate) {
        fetchUrl += `?start_date=${formattedStartDate}`;
      }

      if (formattedEndDate) {
        fetchUrl += `&end_date=${formattedEndDate}`;
      }

      if (searchQuery && typeof searchQuery === 'string' && searchQuery.trim() !== "") {
        if (/^\d+$/.test(searchQuery)) {
          if (searchQuery.length === 10) {
            queryParams.push(`phoneNumber=${encodeURIComponent(searchQuery)}`);
          } else {
            queryParams.push(`orderId=${encodeURIComponent(searchQuery)}`);
          }
        } else {
          queryParams.push(`name=${encodeURIComponent(searchQuery)}`);
        }

      }

      if (selectedStatus) {
        queryParams.push(`status=${selectedStatus}`);
      }

      if (selectedMonth) {
        queryParams.push(`month=${selectedMonth}`);
      }

      if (selectedYear) {
        queryParams.push(`year=${selectedYear}`);
      }

      if (selectedEmployee) {
        queryParams.push(`assignedTo=${selectedEmployee}`);
      }

      if (selectedDepartment) {
        queryParams.push(`department=${selectedDepartment}`);
      }

      if (selectedPaymentStatus) {
        queryParams.push(`paymentStatus=${selectedPaymentStatus}`);
      }

      if (queryParams?.length > 0) {
        fetchUrl += `&${queryParams.join("&")}`;
      }

      fetchUrl += `&page=${page}&limit=${perPage}`;

      fetch(fetchUrl, { headers })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 404) {
            setData([]);
          } else {
            throw new Error("Network response was not ok.");
          }
        })
        .then((data) => {
          setData(data?.data?.items || []);
          setOrderStatCount(data)
          const pageCount = data?.totalPages || 1;
          setPageCount(pageCount);
          setCheckedItems([]);
          setAllChecked(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [currentPage, groupId, refreshToken, selectedPaymentStatus, startDate, endDate, perPage]
  );

  const onPageChange = ({ selected }) => {
    const newPage = selected + 1;
    fetchData(
      searchQuery,
      newPage,
      selectedStatus,
      selectedMonth,
      selectedYear,
      selectedEmployee,
      selectedDepartment,
      selectedPaymentStatus,
      startDate,
      endDate
    );
  };

  useEffect(() => {
    fetchData(
      searchQuery,
      1,
      selectedStatus,
      selectedMonth,
      selectedYear,
      selectedEmployee,
      selectedDepartment,
      selectedPaymentStatus,
      startDate,
      endDate,
    );
  }, [searchQuery, selectedStatus, selectedMonth, selectedYear, selectedEmployee, selectedDepartment, selectedPaymentStatus, startDate, endDate, perPage]);

  const addSpaceToStatus = (status) => {
    if (status === "Inprogress" || status === "Inprocess") {
      return status
        .replace("Inprogress", "In Progress")
        .replace("Inprocess", "In Process");
    } else if (status === "intransit") {
      return "In Transit";
    } else {
      return status;
    }
  };

  const handleOpenDropdown = (item) => {
    setShowDropdown(!showDropdown);
    setSelectedItem(item);
  };

  const handleOptionClick = (option, e) => {
    e.stopPropagation();
    if (option === "WhatsApp" && selectedItem?.phoneNumber) {
      const whatsappUrl = `https://web.whatsapp.com/send?phone=${selectedItem?.phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else if (option === "Email" && selectedItem?.email) {
      const emailUrl = `mailto:${selectedItem.email}`;
      window.location.href = emailUrl;
    } else {
      console.log(`Selected option "${option}" for item:`, selectedItem);
    }
    setShowDropdown(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "#000080";
      case "package":
        return '#FFA500';
      case "shipment":
        return "#A020F0";
      case "cancelled":
        return "#FF0000";
      case "delivered":
        return "#008000";
      case "dispatched":
        return "#A020F0 ";
      case "In Transit":
        return "#d1ba06";
      default:
        return "black";
    }
  };

  const capitalizeEachWord = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word?.slice(1))
      .join(" ");
  };

  const TruncateText = ({ text, maxLength }) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    // fetch(`${API.peopleManagentService}/peopleManagement/deparment/groupId/${groupId}`)
    fetch(`${API.departmentAPI}/peopleManagement/deparment/groupId/${groupId}`)

      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        debugger
        if (Array.isArray(data?.data?.items)) {
          const departments = data?.data?.items.map((item) => ({
            deparment_id: item.deparment_id,
            name: item.name,
          }));
          console.log(departments, "departments");
          setDepartmentOptions(departments);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const employees = data?.data?.items?.map((employee) => ({
            value: employee,
          }));
          setEmployeeOptions(employees);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  };
  const truncateText = (text, maxLength) => {
    if (typeof text !== 'string') {
      return text ? String(text).substring(0, maxLength) : "-";
    }
    return text.length > maxLength ? text.substring(0, maxLength) : text;
  };


  const keyColors = (key) => {
    switch (key) {
      case "Operators":
        return " #FFA500";
      case "Negative":
        return "#FF0000";
      case "Success":
        return "#008000";
      case "Current":
        return "#964B00";
      case "leads":
        return " #A020F0";
      default:
        return "black";
    }
  };

  const getPaymentStatusColor = (paymentStatus) => {
    switch (paymentStatus) {
      case "paid":
        return "#1bcb4a";
      case "partially paid":
        return "#FE6F5E";
      case "credit":
        return "#964B00";
      default:
        return "red";
    }
  };

  const formatKeyName = (key) => {
    switch (key) {
      case 'Orders':
        return t('sidebar.orders');
      case 'TotalQuantity':
        return 'Total Quantity';
      case 'TotalSale':
        return t('dashboard.Total Sale');
      case 'Profit':
        return t('dashboard.Profit');
      case 'PaidCount':
        return 'Paid Count';
      case 'Credit':
        return t('table.Credit');
      case 'Taxes':
        return t('order.Taxes');
      case 'PartiallyPaid':
        return t('order.Partially Paid');
      case 'PendingAmount':
        return t('dashboard.Pending Amt');
      case 'ReceivedAmount':
        return t('dashboard.Received Amt');
      case 'Products':
        return t('sidebar.products');
      case 'Paid':
        return t('order.Paid');
      case 'cancelled':
        return t('order.cancelled')
    }
  };

  const getKeyColor = (key) => {
    switch (key) {
      case 'Orders':
        return '#000080';
      case 'Paid':
        return '#008000';
      case 'Credit':
        return '#FF0000';
      case 'Taxes':
        return '#FFA500';
      case 'Profit':
        return '#A020F0';
      case 'TotalSale':
        return '#FE6F5E';
      case 'ReceivedAmount':
        return '#1ABC9C';
      case 'PendingAmount':
        return '#6495ED';
      case 'PartiallyPaid':
        return '#F407FC';
      case 'Products':
        return '#1414F1';
      default:
        return 'black';
    }
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />

      <div className="">
        <div className="main-padding">
          <div className="heading_flex">
            <div className="">
              <div className="line-height">
                <p className="service-request-title"><b>{t("sidebar.orders")}</b></p>
                <p className="sub-title">{t("order.subtitle")}</p>
              </div>
            </div>

            <div className="date_flex">
              <div className="">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    fetchData(date, startDate);
                  }}
                  placeholderText="Start Date"
                  className="start-input font14 mt-1 mr-1 pl-2"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div className="">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date, () => {
                      fetchData(startDate, date);
                    });
                  }}
                  placeholderText="End Date"
                  className="start-input font14 mt-1 pl-2"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div className="col-4 text-right">
                <div className=" font14 lineHeight0 cursor-pointer mt-1 ">
                  <i className="fa-solid fa-magnifying-glass search-icon-order "></i>

                  <input
                    type="text"
                    value={searchQuery}
                    placeholder={t("order.search")}
                    onChange={(e) => {
                      const newSearchQuery = e.target.value;
                      setSearchQuery(newSearchQuery);
                      fetchData(newSearchQuery);
                    }}
                    className="search-input_service-box  font14">
                  </input>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {loading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="col-2 card cardstyle mr-2">
                  <CardSkeletonLoader />
                </div>
              ))
            ) : (
              orderStatCount && orderStatCount.data ? (
                Object.entries(orderStatCount.data).map(([key, value], index) => (
                  key !== 'items' && (
                    <div key={index} className="col-2 card cardstyle mr-3">
                      {typeof value !== 'object' && (
                        <p className="cardname text-center mt-1">
                          <span style={{ color: getKeyColor(key) }}>
                            <strong>{formatKeyName(key)}</strong>
                          </span>
                          <br />
                          <span style={{ color: getKeyColor(key) }}>{value}</span>
                        </p>
                      )}
                    </div>
                  )
                ))
              ) : (
                <div>No orders data available</div>
              )
            )}
          </div>

          <div className="d_flex pt-2">
            <div className="flex_dropdown">
              <div className="mx-1 font14 lineHeight0 cursor-pointer">
                <select
                  className="select-type dropdown-list select-year  cursor-pointer"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  value={selectedStatus}>
                  <option value="">{t('order.status')}</option>
                  <option value="new">New</option>
                  <option value="shipment">Shipment</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="completed">Delivered</option>
                  <option value="dispatched">Dispatched</option>
                  <option value="In Transit">In Transit</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>

              <div className="mx-1 font14 cursor-pointer lineHeight0">
                <select
                  className="select-type dropdown-list select-year cursor-pointer"
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                  value={selectedEmployee}>
                  <option>{t('order.select-employee')}</option>
                  {employeeOptions.map((employee, index) => {
                    return (
                      <option key={index} value={employee.value.name}>
                        <TruncateText text={employee.value.name} maxLength={15} />
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="mx-1 font14 lineHeight0 select-dep-cust cursor-pointer">
                <select
                  className="cursor-pointer dropdown-list select-year payment-status-order font14"
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}>
                  <option value="" className="fontw-500 dropdown_head">
                    {t("sidebarcustomers.Select Department")}
                  </option>
                  {departmentOptions ? (
                    departmentOptions.map((option, index) => (
                      <option key={index} value={option.departmentId}>
                        {option.name}
                      </option>
                    ))
                  ) : null}
                </select>
              </div>

              <div className="mx-2 font14 lineHeight0 cursor-pointer">
                <select
                  className="select-type cursor-pointer select-year "
                  onChange={(e) => setSelectedPaymentStatus(e.target.value)}
                  value={selectedPaymentStatus}>
                  <option value="">{t('order.paymentstatus')}</option>
                  <option value="paid">Paid</option>
                  <option value="credit">Credit</option>
                  <option value="partially paid">Partially Paid</option>
                </select>
              </div>
            </div>

            <div className="delete-addnew-btn1">
              {tokenPermissions && tokenPermissions.includes(Permissions.MOR4) && (
                <button
                  type="button"
                  className={`btn_disable cursor-pointer dropdown-list ${checkedItems?.length === 0 ? "light-button" : ""}`}
                  onClick={() => openDeleteConfirmation(checkedItems._id)}
                  disabled={checkedItems?.length === 0}
                  style={{ width: "85px", height: "37px" }}>
                  {t("button.delete")}
                </button>
              )}

              <button
                type="button"
                className="dark-button dropdown-list cursor-pointer btn_primary_1 font14 ml-1"
                onClick={handleOpenNewSidebar}>
                {t("button.new order")}
              </button>
            </div>
          </div>

          <div className="table-scroll-sr-order mt-1">
            <table className="table table-hover table-style  set_table">
              <thead className="font14 theadStyle">
                <tr className="dark-text-theme table-head">
                  <th className="checkbox-input"
                    style={{ width: "20px" }}>
                    <input
                      type="checkbox"
                      className="cursor-pointer "
                      name="check"
                      checked={allChecked}
                      onChange={handleAllChecked}></input>
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "100px" }}>
                    {t("order.title")}
                  </th>

                  <th
                    scope="col"
                    className="table-head "
                    style={{ width: "125px" }}>
                    {t("table.customerName")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "100px" }}>
                    {t("table.phoneNo")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "125px" }}>
                    {t("order.date")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "95px" }}>
                    {t("table.location")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "110px" }}>
                    {t("table.Payment Status")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "75px" }}>
                    {t("table.Amount")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "75px" }}>
                    {t("table.status")}
                  </th>

                  <th
                    scope="col"
                    className="table-head"
                    style={{ width: "60px" }}>
                    {t("table.action")}
                  </th>
                </tr>
              </thead>

              {loading ? (
                <OrdersSkeletonLoder />
              ) : (
                <tbody>
                  {(Array.isArray(data) && data?.length > 0) || data.e ? (
                    data.map((item, index) => (
                      <tr className="font14" key={index}>
                        <td className="t-data">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            name="check"
                            checked={checkedItems.includes(index)}
                            onChange={(e) =>
                              handleSingleCheckboxChange(e, index)
                            }
                          />
                        </td>

                        <td
                          className="t-data font13 cursor-pointer"
                          onClick={() => handleOpenSidebar(item)}>
                          {typeof item?.orderId === "object"
                            ? "-"
                            : item?.orderId}
                        </td>

                        <td className="t-data font13">
                          <Link
                            to={`/customer-details/${item?.userId?.userId}`}
                            className="text-decoration-none locate-color">
                            {item?.name ? capitalizeEachWord(item.name) : "N/A"}
                          </Link>
                        </td>

                        <td
                          className="t-data font13">
                          {typeof item?.userId?.phoneNumber === "object"
                            ? "-"
                            : item?.userId?.phoneNumber || "-"}
                        </td>

                        <td
                          className="t-data font13 locate-color">
                          {typeof item?.DateTime === "object" ? (
                            "--"
                          ) : (
                            <span>
                              {new Date(item?.orderDate).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}{" "}
                              {new Date(item?.orderDate).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )}
                            </span>
                          )}
                        </td>

                        <td
                          className="t-data font13" >
                          {typeof item?.userId?.location === "object"
                            ? "-"
                            : item?.userId?.location || "-"}
                        </td>

                        <td className="t-data font14 cursor-pointer"
                          onClick={() => handleOpenSidebar(item)}>
                          <span style={{
                            color: getPaymentStatusColor(item?.paymentInfo?.paymentStatus),
                            fontWeight: "900"
                          }}>
                            {typeof item?.paymentInfo?.paymentStatus === "object"
                              ? "-"
                              : capitalizeFirstLetter(item?.paymentInfo?.paymentStatus) || "-"}
                          </span>
                        </td>

                        <td className="t-data font13">
                          <i className="fa-solid fa-indian-rupee-sign"></i>
                          <span>{Math.round(item?.totalCartPrice)}</span>
                        </td>
                        <td className="t-data font13">
                          {item?.status ? (
                            <span
                              style={{
                                color: getStatusColor(item?.status),
                                fontWeight: "900",
                              }}
                            >
                              {addSpaceToStatus(
                                item.status.charAt(0).toUpperCase() + item.status?.slice(1)
                              )}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>


                        <td className="t-data font13 cursor-pointer">
                          <div className="dropdown">
                            <i
                              className="fas fa-ellipsis-h dot-icon"
                              id={`dropdownMenuButton${index}`}
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={() => handleOpenDropdown(item)} >
                            </i>

                            <div
                              className="dropdown-menu"
                              aria-labelledby={`dropdownMenuButton${index}`}>
                              <button
                                onClick={(e) => handleOptionClick("WhatsApp", e)}
                                className={`dropdown-item font14 ${!selectedItem?.phoneNumber ? "disabled" : ""}`}>
                                WhatsApp
                              </button>

                              <button
                                onClick={(e) => handleOptionClick("Email", e)}
                                className={`dropdown-item font14 ${!selectedItem?.email ? "disabled" : ""}`}>
                                Email
                              </button>

                              <Link
                                to={`/customer-details/${item?.userId?.userId}`}
                                className="dropdown-item font14">
                                View Customer
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))

                  ) : (
                    <>
                      {noRecordsMessage && <div>{noRecordsMessage}</div>}
                      <td>
                        <img
                          src="./../no-data-image.png"
                          className="data-image"
                        />
                      </td>
                    </>
                  )}
                </tbody>
              )}
            </table>
            {openSidebar && (
              <Order
                handleclose={handleClose}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                setOpenSidebar={setOpenSidebar}
                fetchData={fetchData}
              />
            )}

            <div>
              {" "}
              {openNewSidebar && (
                <NewOrderSidebar
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  fetchData={fetchData}
                  setOpenNewSidebar={setOpenNewSidebar}
                />
              )}
            </div>

            {isDeleteConfirmationVisible && (
              <OrderDelete
                groupId={groupId}
                orderId={deleteServiceRequestId}
                onClose={() => setIsDeleteConfirmationVisible(false)}
                fetchData={fetchData}
                Order={Order}
                setIsDeleteConfirmationVisible={setIsDeleteConfirmationVisible}
                checkedItems={checkedItems}
                data={data}
              />
            )}
          </div>

          {Array.isArray(data) && data?.length > 0 && (
            <div className="d-inline-flex pagination-container">
              <div className="col-12 p-0 mt-4">
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={onPageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'Pagination-products'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;