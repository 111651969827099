import React from "react";
import "./AddressSidebar.scss";
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import AddaddressSideBar from "./AddaddressSideBar";
import API from "../../services/Api";
import STORAGE_KEYS from "../../utils/storageKey";

function AddressSidebar({ handleClose, data, selectedNewOrder, fetchSearchCust, setOpenNewAddressSidebar }) {
  const [dataOpen, setDataOpen] = useState(true);
  const [addressData, setAddressData] = useState([]);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [openAddressSidebar, setOpenAddressSidebar] = useState(false);
  const [ setSelectedAddressId] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState("billing");

  const handleAddressSelection = (addressType, addressId) => {
    setSelectedAddressType(addressType);
    setSelectedAddressId(addressId);
  };

  const handleChooseClick = async (groupId, userId, address) => {
    try {
      const addressTypeToUpdate = selectedAddressType;
      const payload = {
        addressId: address?.addressId,
        address: {
          street: address?.address?.street,
          locality: address?.address?.locality,
          city: address?.address?.city,
          state: address?.address?.state,
          zip: address?.address?.zip,

        },
      };
      if (addressTypeToUpdate === 'billing') {
        payload.address.billingAddress = true;
      } else if (addressTypeToUpdate === 'shipping') {
        payload.address.shippingAddress = true;
      }
      const response = await fetch(`${API.commerceAPI}/commerce/customer/updateAddress/${groupId}/${userId}/${address?.addressId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setOpenNewAddressSidebar(false);
        fetchSearchCust()
      } else {
        throw new Error(`Failed to update ${addressTypeToUpdate} address: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error updating address: ${error.message}`);
    }
  };

  const handleCancelClick = () => {
    setDataOpen(false);
  };

  const updateAddressData = (newData) => {
    setAddressData(newData);
  };

  const fetchAddressData = async () => {
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/customer/address/${groupId}/${data?.userId}`
      );
      if (response.ok) {
        const data = await response.json();
        setAddressData(data?.data);
      } else {
        throw new Error(
          `Failed to fetch address data: ${response.statusText}`
        );
      }
    } catch (error) {
      console.error(`Error fetching address data: ${error.message}`);
    }
  };

  useEffect((groupId) => {
    fetchAddressData()
  }, [groupId])

  return (
    <>
      {dataOpen ? (
        <div className="address-sidebar">
          <div className="container-fluid row">
            <div className="col-12">
              <div className="pt-3 col-9">
                <h6 className="text-left"><b>Select Address</b></h6>
                <p className="new-order-name">
                  {selectedNewOrder?.name}
                </p>
              </div>
              <i
                className="fa-solid fa-circle-xmark cancle-addres"
                onClick={handleClose}
              ></i>

              <hr />

              <div className="row">
                <div className=" col-11 d-flex mt-2">
                  <div className="BillingShiping">
                    <input
                      type="radio"
                      name="addressType"
                      onChange={() => handleAddressSelection("billing")}
                      checked={selectedAddressType === "billing"}
                      className="radio mr-2"
                    />
                    <label htmlFor="Billing">
                      Billing
                    </label>
                    <input
                      type="radio"
                      name="addressType"
                      onChange={() => handleAddressSelection("shipping")}
                      checked={selectedAddressType === "shipping"}
                      className="radio ml-3 mr-2"
                    />
                    <label htmlFor="Shipping">
                      Shipping
                    </label>
                  </div>
                </div>

                <div className="col-1 pt-1">
                  <span
                    className=" font14 cls"
                    onClick={() => setOpenAddressSidebar(!openAddressSidebar)}>
                    <i className="fa-sharp fa-solid fa-plus"></i>
                  </span>
                </div>
              </div>
              <div>

                {openAddressSidebar && (
                  <AddaddressSideBar
                    handleClose={handleClose}
                    data={data}
                    updateAddressData={updateAddressData}
                    setOpenAddressSidebar={setOpenAddressSidebar}
                    fetchAddressData={fetchAddressData}
                  />
                )}
              </div>

              <div className="scroll">
                {addressData &&
                  addressData?.length > 0 &&
                  addressData.map((address, index) => (
                    <div key={address?.addressId}>
                      <div className="row container-fluid mt-3">
                        <div className="col-9">
                          <p className="classfont_size mb-0 mt-1 color cls-lcs ">
                            Address Type: {address?.address?.tag || ""}
                          </p>
                          <p className=" mb-0 cls-lcs font13">
                            {address?.address?.street || ""}
                          </p>
                          <p className="mb-0 cls-lcs font13">
                            {address?.address?.locality || ""}
                          </p>
                          <p className="mb-0 cls-lcs font13">
                            {address?.address?.city || ""},{" "}
                            {address?.address?.state || ""}
                          </p>
                          <p className="mb-0 cls-lcs font13">
                            {address.address.zip || ""}
                          </p>
                        </div>

                        <div className="col-1">
                          <button
                            type="button"
                            className="choose_btn"
                            onClick={() => handleChooseClick(groupId, data?.userId, address)}>
                            Choose
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <button className=" CloseBtn mt-3" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default AddressSidebar;
