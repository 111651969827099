import React, { useState, useEffect } from 'react';
import './Warehouse.scss';
import { WarehouseHeader } from '../../components/header/Header';
import WareHouseUpdateSidebar from './WareHouseUpdateSidebar';
import WarehousePopup from './WarehousePopup';
import { Tooltip as ReactTooltip } from "react-tooltip";
import API from '../../services/Api';
import ReactPaginate from 'react-paginate';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import { ToastContainer } from 'react-toastify';

const Warehouse = () => {
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const defaultImageUrl = '../images/wares.jpg';
  const [wareHouse, setWareHouse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredWareHouses, setFilteredWareHouses] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const { t } = useLanguage();
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deleteWarehouseId, setDeleteWarehouseId] = useState(null);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredWareHouses.slice(offset, offset + itemsPerPage);

  const fetchWareHouseData = (query) => {
    fetch(`${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}?search=${query}`)
      .then((result) => result.json())
      .then((resp) => {
        const items = resp?.data?.items || [];
        setWareHouse(items);
        setLoading(false);
        const filtered = items.filter((wareHouse) => {
          const lowerSearchQuery = searchQuery?.toLowerCase() || "";
          const nameMatches = wareHouse.WareHouseName && wareHouse.WareHouseName.toLowerCase().includes(lowerSearchQuery);
          const locationMatches = wareHouse.locationName && wareHouse.locationName.toLowerCase().includes(lowerSearchQuery);
          return nameMatches || locationMatches;
        });
        setFilteredWareHouses(filtered.reverse());
      })
      .catch(error => console.error("Error fetching warehouse data:", error));
  };

  useEffect(() => {
    fetchWareHouseData(searchQuery);
  }, [searchQuery]);

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const getZoomLevel = () => {
      return Math.round((window.outerWidth / window.innerWidth) * 100);
    };
    const updateItemsPerPage = () => {
      const zoomLevel = getZoomLevel();
      if (zoomLevel === 100) {
        setItemsPerPage(10);
      } else if (zoomLevel <= 90) {
        setItemsPerPage(12);
      }
    };
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const openSidebar = (warehouseId) => {
    const selected = wareHouse.find((item) => item._id === warehouseId);
    setSelectedWarehouse(selected);
  };

  const handleClose = () => {
    setSelectedWarehouse(null);
  };

  const openDeleteConfirmation = (warehouseId) => {
    setDeleteWarehouseId(warehouseId);
    setIsDeleteConfirmationVisible(true);
  };

  function handleImageError(e, placeholderImagePath) {
    e.target.src = placeholderImagePath;
  }

  return (
    <div className="main">
      <ToastContainer position="top-right" autoClose={2000} />
      <div className="body_container">
        <div className='warehouse-horizental'>
          {selectedWarehouse && (
            <div>
              <WareHouseUpdateSidebar handleClose={handleClose} selectedWarehouse={selectedWarehouse} _id={selectedWarehouse._id} fetchWareHouse={fetchWareHouseData} />
            </div>
          )}

          <div className="main-padding1">
            <WarehouseHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} fetchWareHouse={fetchWareHouseData} />
            <hr className='hr-line-warehouse' />
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-lg-12'>
                  {loading && (
                    <div>
                      <div className='spinner-border LODER'></div>
                    </div>
                  )}

                  <div className='scrollbar-ware'>
                    <div className='grid-container warehouse-list-scroll '>
                      {currentItems.map((item) => (
                        <div key={item._id} className='grid-item mt-4' >
                          <img
                            src={item.pictures || defaultImageUrl}
                            alt={`Warehouse ${item._id}`}
                            className='emp-logo'
                            onError={(e) => handleImageError(e, 'https://icon-library.com/images/category-icon-png/category-icon-png-14.jpg')}
                          />

                          <h6 className='warehouse-name ' data-tooltip-id={`tooltip-${item._id}`}>{truncateText(item.WareHouseName, 13)}</h6>
                          <ReactTooltip id={`tooltip-${item._id}`} place="top" effect="solid">
                            {item.WareHouseName}
                          </ReactTooltip>

                          <h6 className='warehouse-capacity'>{item.capacity}</h6>
                          <h6 className='warehouse-location'>
                            {truncateText(item?.locationName ? item.locationName : 'Address here', 20)}
                          </h6>


                          <div className='d-inline-flex'>
                            <label className='pin-code'> <h6>{item.pinCode}</h6> </label>
                            <div>
                              <i className="fa-solid fa-pencil ware-trash edit-warehouse " onClick={() => openSidebar(item._id)} />
                            </div>
                            <div>
                              <i className="fa-solid fa-trash ware-trash delete-warehouse ml-5" onClick={() => openDeleteConfirmation(item._id)} />
                            </div>
                            {isDeleteConfirmationVisible && (
                              <WarehousePopup warehouseId={deleteWarehouseId} onClose={() => setIsDeleteConfirmationVisible(false)} />
                            )}
                            <ToastContainer autoClose={2000} className="custom-toast-container" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="d-inline-flex pagination-container">
                    <div className="col-12 p-0 mt-2">
                      <ReactPaginate
                        className="pagination-bar"
                        previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                        nextLabel={<i className="fa-solid fa-angle-right"></i>}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredWareHouses?.length / itemsPerPage)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={0}
                        onPageChange={onPageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        pageClassName={'Pagination-products'}
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehouse;