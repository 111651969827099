import React, { useState } from 'react';
import "./DeliveryCode.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DeliveryCode.scss';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';

const DeliveryUpdateSidebar = ({ handleClose, selectedDelivery, _id, getCall}) => {
    const [code, setCode] = useState(selectedDelivery ? selectedDelivery.code : '');
    const [villages, setVillages] = useState(selectedDelivery ? selectedDelivery.locality : []);
    const [village, setVillage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [apiCallMade, setApiCallMade] = useState(false);
    const [codeError, setCodeError] = useState('');
    const {  t } = useLanguage();

    const handleCodeChange = (e) => {
        setCode(e.target.value);
        setCodeError('')
    };

    const clearInputs = () => {
        setCode(selectedDelivery ? selectedDelivery.code : '');
        setVillages(selectedDelivery ? selectedDelivery.locality : []);
        setVillage('');
    };

    const handleVillageChange = (e) => {
        setVillage(e.target.value);
    };

    const handleAddVillage = () => {
        if (village.trim() !== '') {
            setVillages([...villages, { location: village }]);
            setVillage('');
        }
    };

    const handleRemoveVillage = (index) => {
        const updatedVillages = [...villages];
        updatedVillages.splice(index, 1);
        setVillages(updatedVillages);
    };

    const handleSave = async () => {
        if (apiCallMade) {
            return;
        }
        let isValid=true;

        if (!code) {
            setCodeError("PIN code must be a 6-digit number.");
            isValid = false
          }
      
          if (!isValid) {
            return;
          }

        setIsSaving(true);
        const updatedDelivery = {
            _id: _id,
            code: code,
            locality: villages,
        };

        try {
            const response = await fetch(`${API.commerceAPI}/commerce/deliverycode/${_id}`, {
                method: 'PUT',
                body: JSON.stringify(updatedDelivery),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                getCall('')
                toast.success('Successfully Updated', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
                setTimeout(() => {
                    handleClose();
                }, 3000);
                setTimeout(() => {
                    getCall('')
                }, 3000);
                setApiCallMade(true);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            console.error(error);
            toast.error('Update Failed');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        clearInputs();
        handleClose(); 
    };
    
    return (
        <div className='setting-sidebar pt-3'>
            <ToastContainer position="top-right" autoClose={5000} />
            <div className='row'>
                <div className='col-lg-12 5'>
                    <div className='ml-4'>
                        <h4 className='mt-3'>{t("Deliverycode.Delivery")}
                            <i className="fa-solid fa-circle-xmark float-right mr-4" onClick={handleClose}></i>
                        </h4>
                        <small>{t("Deliverycode.delivery codes")}</small>
                    </div>
                    <hr className='hr-set-1 ' />
                </div>
            </div>

            <div className=' mt-2 scrollbar-delivery'>
                <div>
                    <input className='code-input'
                        type='text'
                        placeholder={t("Deliverycode.PIN code")}
                        value={code}
                        onChange={handleCodeChange} />
                    {codeError && <p className="delivery-code">{codeError}</p>}

                    <p className='ml-4 mt-3'>{t("Deliverycode.Village")}</p>
                </div>

                {villages.map((locationObj, index) => (
                    <>
                        <div className='d-inline-flex  ml-4' key={index}>
                            <i className="fa-solid fa-circle-arrow-right circle-arrow1"></i>
                            <p className='ml-4'><small className='village-name'>{locationObj.location}</small></p>
                            <i className='fa-solid fa-circle-xmark cross-mark' onClick={() => handleRemoveVillage(index)}></i>
                        </div>
                        <br />
                    </>
                ))}

                <div>
                    <input className='village-input'
                        type='text'
                        placeholder={t("Deliverycode.Village")}
                        value={village}
                        onChange={handleVillageChange} />
                    <button className='code-add-btn' onClick={handleAddVillage}>{t("Deliverycode.Add")}</button>
                </div>
            </div>

            <div className='button-savedelivery'>
                <button
                    className="save-delivery"
                    type="submit"
                    onClick={handleSave}
                    disabled={isSaving} >
                    {isSaving ? 'Updating...' : t("Deliverycode.Update")}
                </button>
               
                <button type='button' onClick={handleCancel} className='cancel-delivery ml-2'>{t("Deliverycode.Cancel")}</button>
               
            </div>
        </div>
    );
}

export default DeliveryUpdateSidebar;