const Permissions = {
    // Customer
    MC2: "MC2",  // read customer
    MC3: "MC3",  //update customer
    MC4: "MC4",  //delete customer
    MCS: "MCS", //search customer
    MCAC1: "MCAC1", //create new customer
    MCACS: "MCACS",  //save new customer
    MCACC: "MCACC",  //cancel new customer

    //employee
    ME2: "ME2",  //read employee
    ME3: "ME3",  //update employee
    ME4: "ME4",  //delete employee
    MES: "MES", //search employee
    MEAE1: "MEAE1", //create new employee
    MEAES: "MEAES",  //save new employee
    MEAEC: "MEAEC",  //cancel new employee

    //Product
    MP2: "MP2",  //read product
    MP3: "MP3",  //update product
    MP4: "MP4",  //delete product
    MPS: "MPS", //search product
    MPAP1: "MPAP1", //create new product
    MPAPS: "MPAPS",  //save new product
    MPAPC: "MPAPC",  //cancel new product


    //Inventory
    MI2: "MI2",  //read Inventory
    MI3: "MI3",  //update Inventory
    MI4: "MI4",  //delete Inventory
    MIS: "MIS", //search Inventory
    MIAI1: "MIAI1", //create new Inventory
    MIAIS: "MIAIS",  //save new Inventory
    MIAIC: "MIAIC",  //cancel new Inventory

    //Service Request
    MR2: "MR2",  //read Service Request
    MR3: "MR3",  //update Service Request
    MR4: "MR4",  //delete Service Request
    MRAR1: "MRAR1", //create new Service Request
    MRA5: "MRA5",  //save new Service Request
    MRA3: "MRA3",  //cancel new Service Request

    //WareHouse
    MW2: "MW2",  //read WareHouse
    MW3: "MW3",  //update WareHouse
    MW4: "MW4",  //delete WareHouse
    MWS: "MWS",  //search WareHouse
    MWA1: "MWA1", //create new WareHouse
    MWAS: "MWAS",  //save new WareHouse
    MWAC: "MWAC",  //cancel new WareHouse

    //Category
    MC2: "MC2",  //read Category
    MC3: "MC3",  //update Category
    MC4: "MC4",  //delete Category
    MCS: "MCS", //search Category
    MCAC1: "MCAC1", //create new Category
    MCACS: "MCACS",  //save new Category
    MCACC: "MCACC",  //cancel new Category

    //Supplier
    MS2: "MS2",  //read Supplier
    MS3: "MS3",  //update Supplier
    MS4: "MS4",  //delete Supplier
    MSASS: "MSASS", //search Supplier
    MSAS1: "MSAS1", //create new Supplier
    MSASS: "MSASS",  //save new Supplier
    MSASC: "MSASC",  //cancel new Supplier

    //Delivery Code
    MD2: "MD2",  //read Delivery Code
    MD3: "MD3",  //update Delivery Code
    MD4: "MD4",  //delete Delivery Code
    MD1: "MD1", //create new Delivery Code
    MDACS: "MDACS",  //save new Delivery Code
    MDACC: "MDACC",  //cancel new Delivery Code

    // Orders 	
    MORM2: "MORM2",	// Menu-Read
    MOR2: "MOR2",	//Order -read 
    MOR3: "MOR3",	//Order-update 	
    MOR4: "MOR4", //Order-delete  
    MORF3: "MORF3",	//Order filter-update  
    MORS3: "MORS3",	//Order-search box-update  
    MORNO1: "MORNO1",//Order-New Order-Create  
    MORC1: "MORC1",	//Order-New customer-create 
    MORD1: "MORD1",	//Order-Details-create  
    MORD2: "MORD2",	//Order-Details-read  
    MORD3: "MORD3",	//Order-Details-update  
    MORD4: "MORD4",	//Order-Details-delete  
    MORA3: "MORA3",	//Order-action-update  
    MORS: "MORS", //Order-new-save
    MORC: "MORC", //Order-cancel

    // Language
    ML3: "ML3",  //change Language

    //Setting
    MS3: "MS3",  //change Setting

    //Theme
    MT3: "MT3",  //change Theme

    //Exit
    MX: "MX",  // Exit

    DUMMY: "DUMMY",


    // sidemenu
    MORM2: "MORM2", //order
    MIM2: "MIM2", //inventory
    MSTO2: "MSTO2", //stock transfer
    MSTM2:"MSTM2", //stock menu
    MPM2: "MPM2", //product
    MIT2: "MIT2",//intent
    MITM2:"MITM2",//intent menu
    MPO2: "MPO2",//purchase order
    MEM2: "MEM2", //employee
    MRM2: "MRM2", //service Request
    MMDM2:"MMDM2" ,//masterdata
    MRM2:"MRM2" ,//report
    MWM2:"MWM2", //warehouse
    MCM2:"MCM2", //category
    MSM2:"MSM2", //supplier
    MDM2:"MDM2", //delivery code
    MPOM2:"MPOM2"














};


Object.freeze(Permissions);

export default Permissions; 