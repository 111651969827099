import React, { useState } from 'react'
import { useEffect } from 'react';
import BankDetails from './BankDetails';
import SuppliersBankDetailsSidebar from './SupplierBankDetailsSidebar';
import './SuplierBankDetails.scss';
import { useLanguage } from '../../LanguageContext';

const SuppliersBankDetails = ({ supplier, accountDetails, updateBankDetails, cancelBankDetails }) => {
    const [supBankDetails, setSupBankDetails] = useState()
    const [click, setClick] = useState(false)
    const [bankDetailsInfo, setBankDetailsInfo] = useState({});
    const {  t } = useLanguage();

    const handleClick = () => {
        setClick(!click);
    }

    const updateBankDetailsInfo = (event) => {
        const updateBankDetailsInfo ={ ...bankDetailsInfo, [event.target.name]: event.target.value };
        setBankDetailsInfo(updateBankDetailsInfo);
    };

    useEffect(() => {
        setBankDetailsInfo(accountDetails);
    }, [accountDetails]);

    const handleSave = () => {
        updateBankDetails({ target: { 'name': 'bankDetailsInfo', 'value': bankDetailsInfo } })
    }

    const handleCancel = () => {
        setClick(false);
        setSupBankDetails(false)
    }

    return (
        <>
            <div className=''>
                <p className='BankDeteails '>{t("Supplier.BANK DETAILS")}
                    {supplier && supplier.supplierId && !(supplier.accountDetails && supplier.accountDetails?.length > 0) && (     
                        <i className="fa-solid fa-pencil  edit-pencil-icon ml-5 mt-2" onClick={() => setSupBankDetails(!supBankDetails)} />
                    )}
                </p>
            
                {
                    supBankDetails && (
                        <SuppliersBankDetailsSidebar
                            supplier={supplier}
                            accountDetails={accountDetails}
                            updateBankDetails={updateBankDetailsInfo}
                            onSave={handleSave}
                            handleCancel={handleCancel}
                            cancel={cancelBankDetails}
                        />
                    )}
                    
                <BankDetails supplier={supplier}/>
            </div>
        </>
    )
}

export default SuppliersBankDetails;