import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import EmployeeInfo from '../employee-info/EmployeeInfo';
import { useNavigate } from "react-router-dom";
import Trips from '../trips/Trips';
import Leads from '../leads/Leads';
import AllOrders from '../all-order/AllOrder';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import './EmployeeDetails.css';
import STORAGE_KEYS from '../../utils/storageKey';

const EmployeeDetails = () => {
    const defaultImageUrl = "/images/default images.jpg";
    const [employee, setEmployee] = useState("");
    const [activeComponent, setActiveComponent] = useState("component1");
    const { userId } = useParams();
    const [member, setMember] = useState();
    const { t } = useLanguage();
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const empId = localStorage.getItem(STORAGE_KEYS.EMPID);

    const [user, setUser] = useState({
        groupId: groupId,
        name: '',
        role: " ",
        location: '',
        gender: '',
        phoneNumber: '',
        email: '',
    });

    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1);
    };

    const fetchUser = () => {
        const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
        fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        })
            .then((result) => {
                if (!result.ok) {
                    throw new Error(`HTTP error! Status: ${result.status}`);
                }
                return result.json();
            })
            .then((resp) => {
                const userData = resp?.data?.items[0];
                setEmployee(userData);
                setMember(userData);
                setUser({
                    groupId: userData?.groupId || groupId,
                    name: userData?.name || '',
                    role: userData?.role || '',
                    phoneNumber: userData?.phoneNumber || '',
                    email: userData?.email || '',
                    userId: userData?.userId || '',
                    imageUrl: userData?.imageUrl || '',
                });
            })
            .catch((error) => {
                console.error('Fetch Error:', error);
            });
    };

    useEffect(() => {
        fetchUser();
    }, [userId, groupId]);

    const renderComponent = () => {
        switch (activeComponent) {
            case "component1":
                return (
                    <EmployeeInfo
                        userId={userId}
                        employee={employee}
                        setEmployee={setEmployee}
                    />
                );
            case "component2":
                return <Trips userId={userId} />;
            case "component3":
                return <Leads empId={empId} employee={employee}/>;
            case "component4":
                return <AllOrders userId={userId} />;
            default:
                return null;
        }
    };

    return (
        <div className="main">
            <div className="body_container">
                <div className="main-padding-customer">
                    <div className="profile-section">
                        <img
                            className="profile"
                            src={user?.imageUrl || defaultImageUrl}
                            alt="User Profile Image"
                        />

                        <div className="info  ">
                            <p className="name">
                                {user?.name}
                            </p>

                            <i
                                className="fa-solid fa-circle-xmark customer_xmark cursor-pointer"
                                onClick={handleClose}>
                            </i>

                            <p className="customer_address color-text">
                                {user?.location}
                            </p>

                            <div className="color-text">
                                <i className="fa-solid fa-phone"></i>
                                <span className="pl-2">{user?.phoneNumber}</span>
                            </div>

                            <div className="customer-email">
                                <i className="fa-solid fa-envelope customer_email pt-2">
                                    <span className="pl-2">{user?.email}</span>
                                </i>
                            </div>
                        </div>
                    </div>

                    <hr className='trip_hr' />
                    <div className="buttons-section">
                        <button
                            onClick={() => setActiveComponent("component1")}
                            className={`cust-info-btn p-1 ${activeComponent === "component1" ? "selected" : ""}`}>
                            {t("Empdetails.Employee Info")}
                        </button>

                        <button
                            className={`cust-info-btn p-1 ml-2 ${activeComponent === "component2" ? "selected" : ""}`}
                            onClick={() => setActiveComponent("component2")}>
                            {t("Empdetails.Trips")}
                        </button>

                        <button
                            className={`cust-info-btn p-1 ml-2 ${activeComponent === "component3" ? "selected" : ""}`}
                            onClick={() => setActiveComponent("component3")}>
                            {t("Empdetails.Leads")}
                        </button>

                        <button
                            className={`cust-info-btn p-1 ml-2 ${activeComponent === "component4" ? "selected" : ""}`}
                            onClick={() => setActiveComponent("component4")}>
                            {t("Empdetails.Order")}
                        </button>

                    </div>
                    <div>{renderComponent()}</div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeDetails;