import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';

const ServiceDeletePopup = ({ _id, onClose, fetchService }) => {
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const { t } = useLanguage();

  const handleDelete = async () => {
    if (_id && !deleteInitiated) {
      try {
        setDeleteInitiated(true);

        const response = await fetch(
          `${API.commerceAPI}/commerce/services/${_id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          toast.success('Service Deleted Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                  className="fa-solid fa-trash float-right cis pr-2"
                  style={{ color: "white" }}
              ></i>
          ),
            progressClassName: "custom-progress-leads",
          });
          onClose();
          setTimeout(() => {
            window.location.reload()
          }, 2000);
          setTimeout(() => {
            fetchService()
          }, 2000);
        } else {
          console.error('Failed to delete service');
          toast.error('Failed to delete service');
        }
      } catch (error) {
        console.error('Error deleting service:', error);
        toast.error('Error deleting service: ' + error.message);
      } finally {
        setDeleteInitiated(false);
      }
    } else if (deleteInitiated) {
      toast.warn('Delete operation already initiated');
    } else {
      console.error('Invalid service ID');
      toast.error('Invalid service ID');
    }
  };

  return (
    <div>
      <ToastContainer className="custom-toast-container" />
      <div className="popup-box-service">
        <div className="service-box">
          <p className="text-delete">{t("productdetails.Are you sure you want to delete this service?")}</p>
          <button onClick={handleDelete} className="ser-delete" disabled={deleteInitiated}>
            {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}
          </button>
          <button onClick={onClose} className="ser-nodelete">{t("productdetails.No")}</button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDeletePopup;