import React from 'react';
import './ConfirmationPopup.scss';
import { useLanguage } from '../../../LanguageContext';

const ConfirmationPopup = ({ onConfirm, onCancel }) => {
  const { t } = useLanguage()
  return (
    <div className="confirmation-overlay">
      <div className="confirmation-popup">
        <p>{t("order.Are")}</p>
        <div className="button-container">
          <div className="confirmation-buttons">
            <button onClick={onConfirm}>{t("categorySidebar.yes")}</button>
            <button onClick={onCancel}>{t("categorySidebar.No")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
