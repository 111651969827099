import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import CreateStock from '../../components/create-stock/CreateStock';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import './StockTransfer.scss';
import StockSkeletonLoader from '../../components/skeleton-loader/StockSkeletonLoader';
import UpdateStock from '../../components/update-stock/UpdateStock';
import DeleteStock from '../../pages/stock-transfer/DeleteStock';
import STORAGE_KEYS from '../../utils/storageKey';
import API from '../../services/Api';

const StockTransfer = () => {
    const { t } = useLanguage();
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const [isOpenStoSidebar, setIsOpenStoSidebar] = useState(false);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [currentStock, setCurrentStock] = useState([]);
    const [currentStoId, setCurrentStoId] = useState([]);
    const [isLoding, setIsLoding] = useState(true);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();
    const [allChecked, setAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const offset = currentPage * itemsPerPage;
    const currentData = Array.isArray(filteredStocks) ? filteredStocks.slice(offset, offset + itemsPerPage) : [];
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

    const fetchStockData = async () => {
        setIsLoding(true);
        try {
            const encodedQuery = encodeURIComponent(searchQuery || "");
            const response = await fetch(`${API.commerceAPI}/commerce/sto/all/getByGroupId/${groupId}?search=${encodedQuery}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            if (result.status === "Success") {
                let filteredByStatus = result.data.items;
                if (searchQuery) {
                    filteredByStatus = filteredByStatus.filter(stock =>
                        Object.values(stock).some(value =>
                            String(value).toLowerCase().includes(searchQuery.toLowerCase())
                        )
                    );
                }
                setFilteredStocks(filteredByStatus.reverse());
                setTotalItemsCount(filteredByStatus.length);
                setAllChecked(false);
                setCheckedItems([]);
                setIsLoding(false);
            }
        } catch (error) {
            console.error('Error fetching stock data:', error);
        } finally {
            setIsLoding(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date);
    };

    useEffect(() => {
        fetchStockData();
    }, [searchQuery]);

    const onPageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleCloseSidebar = () => {
        setIsOpenSidebar(false);
        setIsOpenStoSidebar(false);
    };

    const handleOpenSidebar = () => {
        setIsOpenSidebar(true);
    };

    const handleOpenStoSidebar = (stoId, stock) => {
        setIsOpenStoSidebar(true);
        setCurrentStoId(stoId);
        setCurrentStock(stock);

        if (stock && stock.STOdate) {
            const STOdate = new Date(stock.STOdate);
            STOdate.setDate(STOdate.getDate() - 1);
            setCurrentStock(prevIntent => ({
                ...prevIntent,
                expecteDeliveryDate: STOdate.toISOString(),
            }));
        }
    };

    const openDeleteConfirmation = () => {
        setIsDeleteConfirmationVisible(true);
    };

    const handleAllChecked = (event) => {
        if (event.target.checked) {
            let checkedItems = filteredStocks.map((item, index) => index);
            setCheckedItems(checkedItems);
        } else {
            setCheckedItems([]);
        }
        setAllChecked(event.target.checked);
    };

    const handleSingleCheckboxChange = (event, index) => {
        if (event.target.checked) {
            setCheckedItems([...checkedItems, index]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== index));
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "new":
                return "#000080";
            case "received":
                return "#277640";
            case "dispatched":
                return "#763d9a";
            case "In Transit":
                return "#aaa737";
            default:
                return "black";
        }
    };

    return (
        <div className="main">
            <ToastContainer autoClose={2000} />

            <div className="body_container">
                <div className="main-padding1">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
                        <div>
                            <h5 className='intent-title'><strong>{t('STO.STO')}</strong></h5>
                            <p className='intent-title'>{t('STO.List of all STO')}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="search-member-stock">
                                <i className="fa-solid fa-magnifying-glass search-icon-stock"></i>
                                <input
                                    className='search'
                                    type="text"
                                    placeholder={t("sto.Search Type, Status")}
                                    aria-label="Search"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                        fetchStockData();
                                    }}
                                />
                            </div>

                            <div className='mt-2'>
                                <button
                                    type="button"
                                    className={`btn_disable cursor-pointer ${checkedItems.length === 0 ? "light-button" : ""}`}
                                    onClick={openDeleteConfirmation}
                                    disabled={checkedItems.length === 0}
                                    style={{ width: "80px", height: "37px" }}>
                                    {t("button.delete")}
                                </button>
                            </div>

                            <div className=''>
                                <button className='STO-button' onClick={handleOpenSidebar}>
                                    {t('STO.New')}
                                </button>
                            </div>
                        </div>
                    </div>

                    {isOpenSidebar && (
                        <CreateStock
                            handleClose={handleCloseSidebar}
                            fetchStockData={fetchStockData}
                        />
                    )}

                    {isOpenStoSidebar && (
                        <UpdateStock
                            handleClose={handleCloseSidebar}
                            fetchStockData={fetchStockData}
                            currentStock={currentStock}
                        />
                    )}

                    <div className='row'>
                        <div className="table-scroll-sto">
                            <table className="table table-hover">
                                <thead className="font14">
                                    <tr className="dark-text-theme table-head">
                                        <th className="checkbox-input">
                                            <input
                                                type="checkbox"
                                                className="cursor-pointer"
                                                name="check"
                                                checked={allChecked}
                                                onChange={handleAllChecked}
                                            ></input>
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.STOId")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.Date")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.From")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.To")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.Qty")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.Price")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.Type")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head">
                                            {t("STO.Status")}
                                        </th>
                                    </tr>
                                </thead>

                                {isLoding ? (
                                    <StockSkeletonLoader />
                                ) : (
                                    <>
                                        {currentData.length === 0 ? (
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    <img src="/no-data-image.png" alt="No data found" />
                                                </td>
                                            </tr>
                                        ) : (
                                            <tbody>
                                                {currentData.map((stock, index) => (
                                                    <tr key={index} className="font14 t-data">
                                                        <td className="t-data">
                                                            <input
                                                                type="checkbox"
                                                                className="cursor-pointer"
                                                                name="check"
                                                                checked={checkedItems.includes(index)}
                                                                onChange={(e) =>
                                                                    handleSingleCheckboxChange(e, index)
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-colors-id'
                                                            onClick={() => handleOpenStoSidebar(stock.stoId, stock)}>
                                                            {stock?.stoId}</td>
                                                        <td>{formatDate(stock.STOdate)}</td>
                                                        <td>{stock?.from || "--"}</td>
                                                        <td>{stock.destination}</td>
                                                        <td>{stock.Qty}</td>
                                                        <td>{stock.price}</td>
                                                        <td>{stock.type}</td>
                                                        <td>
                                                            {stock?.status ? (
                                                                <span style={{ color: getStatusColor(stock.status), fontWeight: "900" }}>
                                                                    {stock.status}
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </>
                                )}
                            </table>
                        </div>

                        <div className="d-inline-flex pagination-container">
                            <div className="col-12 p-0">
                                <ReactPaginate
                                    className="pagination-bar"
                                    previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                                    nextLabel={<i className="fa-solid fa-angle-right"></i>}
                                    breakLabel={'...'}
                                    pageCount={Math.ceil(filteredStocks.length / itemsPerPage)}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={0}
                                    onPageChange={onPageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'Pagination-products'}
                                />
                            </div>
                        </div>

                        {isDeleteConfirmationVisible && (
                            <DeleteStock
                                onClose={() => setIsDeleteConfirmationVisible(false)}
                                fetchStockData={fetchStockData}
                                checkedItems={checkedItems}
                                filteredStocks={filteredStocks}
                                setCheckedItems={setCheckedItems}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockTransfer;
