import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLanguage } from "../../LanguageContext";
import ApexCharts from 'react-apexcharts';
import './Dashboard.css';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';

const Dashboard = () => {
    const { t } = useLanguage();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [orderStatCount, setOrderStatCount] = useState(null);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [chartSeries, setChartSeries] = useState([]);
    const [chartColors, setChartColors] = useState([]);
    const [data, setData] = useState([]);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

    const getKeyColor = (key) => {
        switch (key) {
            case 'Profit': return '#A020F0';
            case 'TotalSale': return '#FE6F5E';
            case 'ReceivedAmount': return '#1ABC9C';
            case 'PendingAmount': return '#6495ED';
            default: return 'black';
        }
    };

    const formatKeyName = (key) => {
        switch (key) {
            case 'TotalQuantity': return 'Total Quantity';
            case 'TotalSale': return 'Total Sales';
            case 'Profit': return 'Profits';
            case 'PaidCount': return 'Paid Count';
            case 'Credit': return 'Credit';
            case 'Taxes': return 'Taxes';
            case 'PartiallyPaid': return 'Partially Paid';
            case 'PendingAmount': return 'Pending Amt';
            case 'ReceivedAmount': return 'Received Amt';
            case 'Products': return 'Products';
            default: return key;
        }
    };

    const fetchData = async () => {
        try {
            const formatDate = (date) => {
                const formattedDate = new Date(date);
                const year = formattedDate.getFullYear();
                const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
                const day = String(formattedDate.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            let fetchUrl = `${API.commerceAPI}/commerce/order/order/group/${groupId}`;

            if (formattedStartDate) {
                fetchUrl += `?start_date=${formattedStartDate}`;
            }
            if (formattedEndDate) {
                fetchUrl += `&end_date=${formattedEndDate}`;
            }

            const response = await fetch(fetchUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            if (responseData && responseData.data) {
                setOrderStatCount(responseData);
                setTotalItemsCount(responseData.totalItemsCount || 0);

                const keys = ['Profit', 'TotalSale', 'ReceivedAmount', 'PendingAmount'];
                const seriesData = keys.map(key => responseData.data[key] ?? 0);
                const colors = keys.map(key => getKeyColor(key));

                setChartSeries(seriesData);
                setChartColors(colors);
            } else {
                console.error('Unexpected API response:', responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const isEmptySeries = chartSeries.length === 0 || chartSeries.every(count => count === 0);
    const sanitizedSeries = chartSeries.map(value => value ?? 0);

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: [t('dashboard.Profit'), t('dashboard.Total Sale'), t('dashboard.Received Amt'), t('dashboard.Pending Amt')],
        colors: isEmptySeries ? ['#E0E0E0'] : chartColors,
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        legend: {
            show: !isEmptySeries,
        },
        tooltip: {
            enabled: !isEmptySeries,
        },
    };

    const fetchCustomerData = async () => {
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/customer/active/customers/${groupId}`, {
                method: "GET"
            })
            if (response.ok) {
                const data = await response.json()
                setData(data.customers)
            }
        } catch (error) {
            console.log("Error fetching data", error)
        }
    }
    useEffect(() => {
        fetchCustomerData()
    }, [groupId])

    const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="main-padding">
            <div className="dashboard-header">
                <div className="line-height">
                    <p className="customer-request-title">{t("sidebar.Dashboard")}</p>
                    <p className="sub-title">{t("dashboard.Key")}</p>
                </div>
                <div className="line-height">
                    <div className="row">
                        <div className="col-10 p-0">
                            <p className="customer-request-title date-title-dash mr-1 pt-1">{t("table.Date")}</p>
                            <div className="row date-dash">
                                <div className="date_flex date-set-line">
                                    <div className="">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Start Date"
                                            className="start-date-dash font14 mt-2 pl-2"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            placeholderText="End Date"
                                            className="end-date-dash font14 mt-2 ml-2 pl-2"
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='dash-hr mt-4' />

            <div className="scrollable-content">
                <div className="pl-2 order-dash-cardstyle">
                    <div className="">
                        <div className="row">
                            <div className="ser-request font18 ">{t("sidebar.orders")}</div>
                            <div id="chart" className=" col-6 set-graph">
                                <div className="mt-4">
                                    <ApexCharts
                                        options={chartOptions}
                                        series={isEmptySeries ? [0] : sanitizedSeries}
                                        type="donut"
                                        height={340}
                                        width={430}
                                    />
                                    <p className='font14 mx-5 font16'><b>{t("dashboard.Total Orders")} - {totalItemsCount}</b></p>

                                </div>
                            </div>
                            <div className='dash-scroll col-6'>
                                <table border={1} className='table'>
                                    <thead className='table-head '>
                                        <th>{t("dashboard.Customer")}</th>
                                        <th>{t("dashboard.Total Order")}</th>
                                        <th>{t("dashboard.Order Date")}</th>
                                    </thead>
                                    <tbody className=''>
                                        {
                                            data.map((items, index) => (
                                                <tr key={index}>
                                                    <td>{items?.name}</td>
                                                    <td>{items?.orderCount}</td>
                                                    <td>{formatDate(items?.orderData?.[0])}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
