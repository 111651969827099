import React, { useState, useEffect } from 'react'
import SupplierAddressSidebar from './SupplierAddressSidebar';
import Addresses from './Addresses';
import "./SupplierAddress.scss";
import { useLanguage } from '../../LanguageContext';

const SupplierAddress = ({ supplier }) => {
    const [click, setClick] = useState(false);
    const { t } = useLanguage();
    useEffect(() => {
    }, []);

    const handleCancel = () => {
        setClick(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setClick(false);
    }

    const handleClick = () => {
        setClick(!click)
    }
    const handleCloseSidebar = () => {
        setClick(!click)
    }


    return (
        <>
            <div className='d-inline-flex'>
                <div className={click ? "to-right" : ""}>
                    <p className='Address'> {t("Supplier.ADDRESS")} {" "}
                        {supplier.supplierId && !(supplier.addresses && supplier.addresses?.length > 0) && (
                            <i className='fa-solid fa-pencil edit-profile ml-5 mb-3 mt-2' onClick={handleClick} />
                        )}
                    </p>
                </div>

                {click && (
                    <SupplierAddressSidebar
                        handleCloseSide={handleCloseSidebar}
                        onSave={handleSubmit}
                        cancel={handleCancel}
                        supplier={supplier} />
                )}
            </div>

            <div className=''>
                {
                    supplier?.addresses ?
                        supplier.addresses.map((address, index) => {
                            return (<Addresses key={index} value={address.addressId}
                                address={address}
                                supplier={supplier}></Addresses>
                            )
                        })
                        : <>----</>}
            </div>
        </>
    )
}

export default SupplierAddress