import React, { useState, useEffect } from "react";
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";
import STORAGE_KEYS from "../../utils/storageKey";

function EmployeeAddress({ handleCloseAddress, fetchEmployeeData, userId, userData }) {
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [locality, setLocality] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zip, setZip] = useState('');
    const [addressType, setAddressType] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useLanguage();

    useEffect(() => {
        if (userData.addresses && userData.addresses?.length > 0) {
            const address = userData.addresses[0].address;
            setLocality(address?.locality || '');
            setCity(address?.city || '');
            setState(address?.state || '');
            setCountry(address?.country || '');
            setZip(address?.zip || '');
            setAddressType(address?.type || '');
            setSelectedTag(address?.tag || '');
        }
    }, [userData]);

    const handleAddressTypeChange = (e) => {
        setAddressType(e.target.value);
    };

    const handleLine1Change = (e) => {
        setLocality(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };
    const handleZipChange = (e) => {
        setZip(e.target.value);
    };

    const handleTagChange = (e) => {
        setSelectedTag(e.target.value);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const errors = {};
        if (!locality.trim()) {
            errors.locality = "locality is required.";
        }

        if (!city.trim()) {
            errors.city = "City is required.";
        }

        if (!state.trim()) {
            errors.state = "State is required.";
        }

        if (!country.trim()) {
            errors.country = "Country is required.";
        }

        if (!zip.trim()) {
            errors.zip = "Zip is required.";
        } else if (zip.trim()?.length !== 6) {
            errors.zip = "Zip must be 6 digits.";
        }

        if (!selectedTag) {
            errors.tag = "Please select an address tag.";
        }

        if (Object.keys(errors)?.length > 0) {
            setErrors(errors);
            setIsLoading(false);
            return;
        }

        try {
            const addressId = userData?.addresses[0]?.addressId;
            if (!addressId) {
                throw new Error("Address ID is not available.");
            }

            const updatedAddress = {
                locality,
                city,
                state,
                country,
                zip,
                type: addressType,
                tag: selectedTag,
                billingAddress: addressType === "Billing",
                shippingAddress: addressType === "Shipping",
                default: true
            };

            const payload = {
                userId: parseInt(userId),
                groupId: parseInt(groupId),
                addressId: parseInt(addressId),
                address: updatedAddress
            };

            const url = `${API.peopleManagentService}/peopleManagement/members/updateAddress/${groupId}/${userId}/${addressId}`;
            const method = "PUT";
            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error("Failed to update/create customer address");
            }
            setIsLoading(false);
            handleCloseAddress();
            fetchEmployeeData();
        } catch (error) {
            console.error("Error updating/creating customer address:", error);
            setIsLoading(false);
        }
    };

    return (
        <div className="add-customerinfo-sidebar">
            <div className="side-padding">
                <div className="container-fluid">
                    <div className="d-flex">
                        <div className="me-5">
                            <h6><b className="me-5">{t("Employeeaddress.Update Employee Address")}</b></h6>
                            <p className="font14">{t("Employeeaddress.Enter Employee Address")}</p>
                        </div>
                        <div className="close-icon">

                            <i className="fa-solid fa-circle-xmark" onClick={handleCloseAddress}
                            ></i>

                        </div>
                    </div>

                    <div className="radi">
                        <input
                            type="radio"
                            id="Billing"
                            name="addressType"
                            value="Billing"
                            className="radio mr-2 cursor-pointer"
                            checked={addressType === "Billing"}
                            onChange={handleAddressTypeChange}
                        />
                        <label htmlFor="Billing">{t("sidebarcustomers.Billing Address ")}</label>
                        <input
                            type="radio"
                            id="Shipping"
                            name="addressType"
                            value="Shipping"
                            className="radio ml-2 mr-2 cursor-pointer"
                            checked={addressType === "Shipping"}
                            onChange={handleAddressTypeChange}
                        />
                        <label htmlFor="Shipping">{t("sidebarcustomers.Shipping Address ")}</label>
                    </div>
                    <hr className="mt-4" />

                    <div className="sidebar-wrapper">
                        <div className="font14">
                            <label htmlFor="cars">{t("sidebarcustomers.Tag :")}</label>
                            <select
                                name="cars"
                                id="cars"
                                style={{ cursor: "pointer" }}
                                value={selectedTag}
                                onChange={handleTagChange}>
                                <option value="">{t("sidebarcustomers.Select Address")}</option>
                                <option value="इतर">इतर</option>
                                <option value="घर">घर</option>
                                <option value="ऑफिस">ऑफिस</option>
                            </select>
                            {errors.tag && <div className="text-danger mt-2 font14">{errors.tag}</div>}
                        </div>

                        <form className="">
                            <div>
                                <div className="mt-2">
                                    <div className="">
                                        <label className="font14 mt-1">
                                            {t("Employeeaddress.Locality")}<span className="text-danger">*</span>
                                        </label>{" "}
                                        <br />
                                        <input
                                            className={`add-Address-inputs pl-2 ${errors.locality ? 'error-border' : ''}`}
                                            placeholder={t("Employeeaddress.Locality")}
                                            name="locality"
                                            value={locality}
                                            onChange={handleLine1Change}
                                        />
                                        {errors.locality && <div className="text-danger mt-2 font14">{errors.locality}</div>}
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <div className="">
                                        <label className="font14 mt-2">
                                            {t("sidebarcustomers.City")}<span className="text-danger">*</span>
                                        </label>
                                        <br />
                                        <input
                                            className={`add-Address-inputs pl-2 ${errors.city ? 'error-border' : ''}`}
                                            placeholder={t("sidebarcustomers.City")}
                                            name="city"
                                            value={city}
                                            onChange={handleCityChange}
                                        />
                                        {errors.city && <div className="text-danger mt-2 font14">{errors.city}</div>}
                                    </div>

                                    <div>
                                        <label className="font14 mt-2">
                                            {t("sidebarcustomers.State")}<span className="text-danger">*</span>
                                        </label>{" "}
                                        <br />
                                        <input
                                            className={`add-Address-inputs pl-2 ${errors.state ? 'error-border' : ''}`}
                                            placeholder={t("sidebarcustomers.State")}
                                            name="state"
                                            value={state}
                                            onChange={handleStateChange}
                                        />
                                        {errors.state && <div className="text-danger mt-2 font14">{errors.state}</div>}
                                    </div>
                                </div>

                                <div className="">
                                    <div className="">
                                        <label className="font14 mt-2">
                                            {t("sidebarcustomers.Country")}<span className="text-danger">*</span>
                                        </label>{" "}
                                        <br />
                                        <input
                                            className={`add-Address-inputs pl-2 ${errors.country ? 'error-border' : ''}`}
                                            placeholder={t("sidebarcustomers.Country")}
                                            name="country"
                                            value={country}
                                            onChange={handleCountryChange}
                                        />
                                        {errors.country && <div className="text-danger mt-2 font14">{errors.country}</div>}
                                    </div>

                                    <div className="pt-2">
                                        <label className="font14 mt-2">
                                            {t("sidebarcustomers.Zip")}<span className="text-danger">*</span>{" "}
                                        </label>
                                        <br />
                                        <input
                                            type="text"
                                            className={`add-Address-inputs pl-2 ${errors.zip ? 'error-border' : ''}`}
                                            placeholder={t("sidebarcustomers.Zip")}
                                            name="zip"
                                            value={zip}
                                            maxLength="6"
                                            onChange={handleZipChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                                            }}
                                        />
                                        {errors.zip && <div className="text-danger mt-2 font14">{errors.zip}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="set-button">
                                <div className="d-flex">
                                    <button className="saveDraft-btn mt-4"
                                        onClick={handleUpdate}
                                        style={{ width: "94px", height: "30px" }}>
                                        {isLoading ? (
                                            <i className="spinner-border spinner-border-sm spin"></i>
                                        ) : (
                                            t("Customer.update")
                                        )}
                                    </button>

                                    <button type="button"
                                        className="CloseBtn mt-4 ml-2"
                                        onClick={handleCloseAddress}>
                                        {t("sidebarcustomersbtn.Cancel")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeAddress;