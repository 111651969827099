import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const StockSkeletonLoader = () => {
    return (
        <SkeletonTheme color="#007bff" highlightColor="#C0C2DE">
            <tbody>
                {[...Array(9)].map((_, index) => (
                    <tr className="font14" key={index}>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>

                        <td className="t-data">
                            <Skeleton />
                        </td>

                        <td className="t-data">
                            <Skeleton />
                        </td>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>

                        <td
                            className="t-data">
                            <Skeleton />
                        </td>
                        
                        <td
                            className="t-data">
                            <Skeleton />
                        </td>
                    </tr>
                ))}
            </tbody>
        </SkeletonTheme>
    )
}
export default StockSkeletonLoader