import { useState, useEffect } from "react"
import UpdatePurchaseSidebar from "../../components/update-purchase-order/UpdatePurchaseSidebar"
import API from "../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from "../../LanguageContext";
import "./PurchaseOrder.scss";
import ReactPaginate from 'react-paginate';
import PurchaseOrderSkeletonLoader from "../../components/skeleton-loader/PurchaseOrderSkeletonLoader";
import STORAGE_KEYS from "../../utils/storageKey";

const PurchaseOrder = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState()
    const [pageNumber, setPageNumber] = useState(0);
    const { t } = useLanguage();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const offset = pageNumber * itemsPerPage;
    const currentPO = purchaseOrders?.slice(offset, offset + itemsPerPage);

    const handleOptionClick = (option, purchaseOrderId) => {
        setSelectedOption({ option, purchaseOrderId });
        setSelectedPurchaseOrder({ option, purchaseOrderId })
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const fetchPurchaseOrders = async (reverse = false) => {
        try {
            setLoading(true)
            const response = await fetch(`${API?.commerceAPI}/commerce/purchaseorder/group/${groupId}?reverse=${reverse}`);
            if (!response.ok) {
                throw new Error('Failed to fetch purchase orders');
            }
            const data = await response.json();
            const processedData = data?.data?.items.map((item) => ({
                purchaseOrderId: item.purchaseOrderId,
                supplierName: item.quotation?.supplier?.name,
                products: item.quotation?.products,
                quotation: item?.quotation?.intent,
                value: item,
                grnDate: data.grnDate ? new Date(data.grnDate) : null,
                expiryDate: data.expiryDate ? new Date(data.expiryDate) : null,
                paymentDate: data.paymentDate ? new Date(data.paymentDate) : null,
                deliveryDate: data.deliveryDate ? new Date(data.deliveryDate) : null,
            }));
            setPurchaseOrders(processedData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPurchaseOrders();
    }, []);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ background: "#FF0000", color: "white" }}
            />

            <div className="main">
                <div className="body_container">
                    <div className="main-padding1">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
                            <div>
                                <h5><strong className="text-color1">{t("po.title")}</strong></h5>
                                <p className="text-color1">{t("po.subTitle")}</p>
                            </div>
                        </div>

                        <div className="table-container1">
                            <table className="table table-hover">
                                <thead className="font14 theadstyle">
                                    <tr className="dark-text-theme table-head">
                                        <th style={{ width: "100px" }}>
                                            {t("supplier.PO Id")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="table-head"
                                            style={{ width: "230px" }}>
                                            {t("supplier.Supplier Name")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head "
                                            style={{ width: "120px" }}>
                                            {t("po.HSN")}
                                        </th>

                                        <th scope="col"
                                            className="table-head"
                                            style={{ width: "110px" }}>
                                            {t("inventory.quantity")}
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-head"
                                            style={{ width: "100px" }}>
                                            {t("po.rate")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="table-head text-center">
                                            {t("neworder.total")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="table-head "
                                            style={{ width: "80px" }}>
                                            {t("po.totalTax")}
                                        </th>
                                        
                                        <th
                                            scope="col"
                                            className="table-head text-center"
                                            style={{ width: "150px" }}>
                                            {t("po.TaxableAmount")}
                                        </th>

                                        
                                    </tr>
                                </thead>

                                {loading ? (
                                    <PurchaseOrderSkeletonLoader columns={9} />
                                ) :
                                    currentPO?.length === 0 ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan="12" className="text-center">
                                                    <img src="/no-data-image.png" alt="No data found" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {Array.isArray(currentPO) && currentPO.map((order, index) => {
                                                const totalQuantity = order.products.reduce((sum, item) => sum + item.actualQty, '');
                                                const totalUnitRate = order.products.reduce((sum, item) => sum + item.perUnit, 0);
                                                const totalTaxAmount = order.products.reduce((sum, item) => sum + item.taxAmount, 0);
                                                const totalAmount = order.products.reduce((sum, item) => sum + item.totalAmount, 0);
                                                const totalPriceExcludingTax = order.products.reduce((sum, item) => sum + item.totalPriceExcludingTax, 0);

                                                return (
                                                    <tr key={index} className="font14">
                                                        <td className="supp-bold cursor-pointer" onClick={() => handleOptionClick(order)}>
                                                            {order?.purchaseOrderId}
                                                        </td>

                                                        <td className="table-cell  " >
                                                            {order?.supplierName || "--"}
                                                        </td>

                                                        <td className="table-cell">
                                                            {order.products[0]?.product?.hsnNo || "--"}
                                                        </td>

                                                        <td className="table-cell">{totalQuantity}</td>
                                                        <td className="table-cell">{Math.round(totalUnitRate.toFixed(2))}</td>
                                                        <td className="table-cell text-center">{totalAmount}</td>

                                                        <td className="table-cell">{totalTaxAmount}</td>

                                                        <td className="table-cell text-center">{totalPriceExcludingTax}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                    )}
                            </table>
                        </div>

                        {selectedOption && (
                            <UpdatePurchaseSidebar selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption} purchaseOrder={selectedPurchaseOrder} fetchPurchaseOrders={fetchPurchaseOrders} />
                        )}

                        <div className="d-inline-flex pagination-container">
                            <div className="col-12 p-0">
                                <ReactPaginate
                                    className="pagination-bar"
                                    previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                                    nextLabel={<i className="fa-solid fa-angle-right"></i>}
                                    breakLabel={'...'}
                                    pageCount={Math.ceil(purchaseOrders?.length / itemsPerPage)}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={0}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'Pagination-products'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PurchaseOrder;