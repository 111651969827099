import React, { useState } from 'react';
import API from '../../services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const DeleteConfigurationPopup = ({ currentConfigId, onClose, fetchConfigOnSetting }) => {
    const [deleteInitiated, setDeleteInitiated] = useState(false);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();

    const deleteConfiguration = async () => {
        setDeleteInitiated(true);
        try {
            const response = await fetch(
                `${API.commerceAPI}/commerce/configuration/delete/configuration`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer YOUR_ACCESS_TOKEN_HERE",
                    },
                    body: JSON.stringify({
                        configurationIds: [currentConfigId],
                        groupId: parseInt(groupId),

                    }),
                }
            );

            const data = await response.json();
            if (response.ok) {
                fetchConfigOnSetting();
                toast.success("Configuration Deleted Successfully!", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                    progressClassName: "custom-progress-leads",
                });
                onClose();
            } else {
                console.error("Failed to delete configuration:", data);
                toast.error("Failed to delete configuration");
            }
        } catch (error) {
            console.error("Error deleting configuration:", error);
            toast.error("Error deleting configuration");
        } finally {
            setDeleteInitiated(false);
        }
    };

    return (
        <div>
            <ToastContainer
                toastStyle={{ background: "#FF0000", color: "white" }}
            />
            <div className="popup-box">
                <div className="cust-box">
                    <p className="text-delete">{t('Config.sure')}</p>
                    <div className='ml-4'>
                        <button onClick={deleteConfiguration} className="ware-delete ">
                            {deleteInitiated ? t('Config.Yes') : t('Config.Yes')}
                        </button>
                        <button onClick={onClose} className="ware-nodelete">{t('Config.No')}</button></div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfigurationPopup