import React from 'react'
import { useState, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../../LanguageContext';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';
import { useParams } from 'react-router-dom';

const HTMLContentRenderer = ({ content }) => {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

const UpdateInventory = ({ handleClose, updateItemId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [isLoadingWarehouse, setIsLoadingWarehouse] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { t } = useLanguage();
  const { empId, phoneNumber } = useParams()
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState('');
  const [minStock, setMinStock] = useState('');
  const [GRNNo, setGRNNo] = useState('');
  const [batchNo, setBatchNo] = useState('');
  const [lotNo, setLotNo] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [selectedVariantPrices, setSelectedVariantPrices] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierData, setSupplierData] = useState([]);
  const [memberPrice, setMemberPrice] = useState('');
  const [regularPrice, setRegularPrice] = useState('');
  const [buyingPrice, setBuyingPrice] = useState('');
  const [marketPrice, setMarketPrice] = useState('');
  const [selectedManagerName, setSelectedManagerName] = useState('');
  const [selectedWareHouseId, setSelectedWareHouseId] = useState(null);
  const [gst, setGst] = useState('');
  const [igst, setIgst] = useState('');
  const [cgst, setCgst] = useState('');
  const [sgst, setSgst] = useState('');
  const [productError, setProductError] = useState('');
  const [supplierError, setSupplierError] = useState('');
  const [minStokError, setMinStockError] = useState('');
  const [quantityError, setQuantityError] = useState('');
  const [warehouseNameError, setWarehouseNameError] = useState('');
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [selectedManagers, setSelectedManagers] = useState({});

  const updateInventoryData = async () => {
    try {
      setIsUpdate(true)
      const response = await fetch(`${API.commerceAPI}/commerce/inventry/${updateItemId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selectedProduct: {
            productId: selectedProduct.value,
            name: selectedProduct.label,
          },
          selectedProduct: options,
          GRNNo: parseFloat(GRNNo),
          batchNo: parseFloat(batchNo),
          lotNo: parseFloat(lotNo),
          expiryDate: expiryDate ? expiryDate.toISOString() : null,
          buyingPrice: parseFloat(buyingPrice || selectedProduct?.buyingPrice),
          memberPrice: parseFloat(memberPrice || selectedProduct?.memberPrice),
          regularPrice: parseFloat(regularPrice || selectedProduct.regularPrice),
          marketPrice: parseFloat(marketPrice || selectedProduct?.marketPrice),
          groupId: groupId,
          productcode: selectedProduct.productcode,
          productId: selectedProduct.value,
          quantity: parseInt(quantity),
          minStock: parseInt(minStock),
          warehouse: {
            wareHouseId: selectedWareHouseId,
            name: selectedWarehouse.label
          },
          selectedVariants: selectedVariants,
          gst: parseInt(gst),
          igst: parseInt(igst),
          cgst: parseInt(cgst),
          sgst: parseInt(sgst),
          minStock: parseInt(minStock),
          selectedVariantPrices: selectedVariants.map((variant) => ({
            variant,
            ...selectedVariantPrices[variant],
          })),
          selectedSupplier: {
            supplierId: selectedSupplier.value,
            name: selectedSupplier.label,
            company: selectedSupplier.company,
            address: selectedSupplier.address,
            phoneNumber: selectedSupplier.phoneNumber,
          },
          managers: [
            {
              name: selectedManagerName,
              id: selectedManagers,
              phoneNumber: phoneNumbers
            }
          ]
        }),
      });

      if (response.ok) {
        handleClose();
        toast.success('Inventory Updated Successfully', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        fetchData()
      } else {
        throw new Error('Failed to update inventory data');
      }
    } catch (error) {
      console.error('Error updating inventory:', error);
    } finally {
      setIsUpdate(false);
    }

    let isValid = true
    if (!selectedProduct) {
      setProductError('Please select a product.');
      isValid = false;
    }

    if (!selectedSupplier) {
      setSupplierError('Please select a supplier');
      isValid = false
    }

    if (!quantity) {
      setQuantityError('Please Enter the quantity');
      isValid = false
    }

    if (!minStock) {
      setMinStockError('Please Enter the minStock');
      isValid = false
    }

    if (!selectedWarehouse) {
      setWarehouseNameError("Please Select a warehouse");
      isValid = false
      return;
    }

    if (!isValid) {
      return;
    }
  };

  useEffect(() => {
    if (!Array.isArray(selectedVariants)) {
      setSelectedVariants([]);
    }
  }, []);

  const handleProductSelection = (selected) => {
    if (selected?.length > 0) {
      setSelectedProduct(selected[0]);
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleMinStockChange = (e) => {
    setMinStock(e.target.value);
  };

  useEffect(() => {
    const fetchInventoryData = async () => {
      if (updateItemId) {
        try {
          const response = await fetch(`${API.commerceAPI}/commerce/inventry/${updateItemId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (data && data.data) {
            setQuantity(data.data.quantity?.toString() || '');
            setMinStock(data.data.minStock?.toString() || '');
            setGRNNo(data.data.GRNNo?.toString() || '');
            setBatchNo(data.data.batchNo || '');
            setMarketPrice(data.data.marketPrice?.toString() || '');
            setBuyingPrice(data.data.buyingPrice?.toString() || '');
            setMemberPrice(data.data.memberPrice?.toString() || '');
            setRegularPrice(data.data.regularPrice?.toString() || '');
            setGst(data.data.gst?.toString() || '');
            setCgst(data.data.cgst?.toString() || '');
            setIgst(data.data.igst?.toString() || '');
            setSgst(data.data.sgst?.toString() || '');
            setExpiryDate(data.data.expiryDate ? new Date(data.data.expiryDate) : null);
            setSelectedProduct({
              label: data.data.productId.name,
              value: data.data.productId,
            });
            setSelectedSupplier({
              label: data.data.selectedSupplier?.name || '',
              value: data.data.selectedSupplier?.phoneNumber || '',
            });
            setSelectedWareHouseId(data.data.warehouse?.wareHouseId || null);
            setSelectedWarehouse({
              label: data.data.warehouse?.name || '',
              value: data.data.warehouse?.wareHouseId || null,
            });
            setSelectedManagers(data.data.managers?.[0]?.id)
            setSelectedManagerName(data.data.managers?.[0]?.name);
            setPhoneNumbers(data.data.managers?.[0]?.phoneNumber);
          }
          else {
            console.error('Invalid or incomplete data received:', data);
          }
        } catch (error) {
          console.error('Error fetching inventory data:', error);
        }
      }
    };
    fetchInventoryData();
  }, [updateItemId]);

  const handleSearch = (query) => {
    setIsLoadingProduct(true);
    fetch(`${API.commerceAPI}/commerce/products/searchProductByPrice/${groupId}?search=${query}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseJson) => {
        const { data } = responseJson;
        if (data && Array.isArray(data.items)) {
          const productOptions = data.items.map((product) => ({
            label: product.name,
            value: product._id,
            description: product?.description,
            category: product.category,
            variants: product.variants,
            subCategory: product.subCategory,
            supplier: product.supplier,
            buyingPrice: product.buyingPrice,
            memberPrice: product.memberPrice,
            regularPrice: product.regularPrice,
            marketPrice: product.marketPrice,
            quantity: parseInt(product.quantity, 10),
          }));
          setOptions(productOptions);
          setSelectedProduct(false);
        } else {
          console.error('No products found in the API response:', data);
          setOptions([]);
        }
        setIsLoadingProduct(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoadingProduct(false);
      });
  };

  const handleButtonClick = (value, variantIndex) => {
    if (selectedVariants.includes(value)) {
      setSelectedVariants(selectedVariants.filter(variant => variant !== value));
      delete selectedVariantPrices[value];
    } else {
      setSelectedVariants([...selectedVariants, value]);
      if (selectedProduct?.variants && selectedProduct.variants[variantIndex]) {
        const variant = selectedProduct.variants[variantIndex];
        const variantPrice = {
          memberPrice: variant.memberPrice || '--',
          regularPrice: variant.regularPrice || '--',
          marketPrice: variant.marketPrice || '--',
        };
        setSelectedVariantPrices({
          ...selectedVariantPrices,
          [value]: variantPrice,
        });
      }
    }
  };

  const handleInputChange = (input) => {
    setProductError(null);
  };

  const handleSelect = (selectedOptions) => {
    const selectedOption = selectedOptions[0];
    setSelectedSupplier(selectedOption);
    setSupplierError("")
  };

  const handleSupplierSearch = (query) => {
    setIsLoadingSupplier(true);
    fetch(`${API.commerceAPI}/commerce/supplier/all/getByGroupId/${groupId}?name=${query}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data?.data?.items)) {
          const supplierOptions = data?.data?.items?.map((supplier) => ({
            label: supplier.name,
            value: supplier.id,
            address: supplier?.address,
            phoneNumber: supplier.phoneNumber
          }));
          setSupplierData(supplierOptions);
        } else {
          toast.error('No suppliers found in the API response:', data);
        }
        setIsLoadingSupplier(false);
      })
      .catch((error) => {
        console.error('Error fetching supplier data:', error);
        setIsLoading(false);
      });
  };

  const handleWarehouseSearch = (query) => {
    setIsLoadingWarehouse(true);
    fetch(`${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}?WareHouseName=${query}`)
      .then((response) => response.json())
      .then((responseJson) => {
        const { data } = responseJson;
        if (data && Array.isArray(data.items)) {
          const warehouseOptions = data.items.map((warehouse) => {
            const firstManager = warehouse.manager?.[0];
            return {
              label: warehouse.WareHouseName,
              value: warehouse.wareHouseId,
              wareHouseId: warehouse.wareHouseId,
              managerName: firstManager?.name || '',
              managerId: firstManager?.id || '',
              phoneNo: firstManager?.phoneNumber || '',
            };
          });
          setWarehouseOptions(warehouseOptions);
        } else {
          console.error('No warehouse options found in the API response:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching warehouse data:', error);
      })
      .finally(() => {
        setIsLoadingWarehouse(false);
      });
  };

  const handleCloseSidebar = () => {
    handleClose(false);
  }

  const handleWarehouseSelection = (selected) => {
    const selectedWarehouse = selected[0];
    setSelectedWarehouse(selectedWarehouse);

    if (selectedWarehouse) {
      setSelectedWareHouseId(selectedWarehouse.value);
      setSelectedManagerName(selectedWarehouse.managerName);
      setSelectedManagers(selectedWarehouse.managerId);
      setPhoneNumbers(selectedWarehouse.phoneNo);
    } else {
      setSelectedWarehouse(null);
      setSelectedWareHouseId(null);
      setSelectedManagerName('');
      setPhoneNumbers('')
    }
  }

  return (
    <>
      <div className='invertery-sidebar'>
        <div className='inventery-box pt-2'>
          <div className='d-inline-flex '>
            <b className='inventery-text-up'> {t('inventoryUpdate.Update Product to Inventory')} </b>
          </div>

          <i className="fa-solid fa-circle-xmark update-inventory-cancel cursor-pointer  mt-1 "
            onClick={handleClose}></i>
          <hr className='inventery-hr-up' />

          <div className='scrollbar-enventary'>
            <div>
              <AsyncTypeahead
                id="productTypeahead"
                isLoading={isLoadingProduct}
                labelKey="label"
                minLength={3}
                onSearch={handleSearch}
                onChange={handleProductSelection}
                options={options}
                selected={selectedProduct ? [selectedProduct] : []}
                placeholder={t('Search for a product')}
                className="inventory-products"
              />
              {productError && <p className="inventory-error">{productError}</p>}
            </div>

            <div className='pt-3'>
              <HTMLContentRenderer content={selectedProduct?.description} />
            </div>

            <div className='row'>
              <div className='col-4'>
                <h6 className='color-color'>{t('inventorySidebar.Category')} </h6>
                <p>{selectedProduct?.category ? selectedProduct?.category : "--"}</p>
              </div>

              <div className='col-4'>
                <h6 className='color-color'>{t('inventorySidebar.Sub Category')}</h6>
                <p>{selectedProduct?.subCategory ? selectedProduct?.subCategory : "--"}</p>
              </div>
            </div>

            <div className='mt-3'>
              <b className='color-color'>{t('inventorySidebar.Buying Price')}</b><br />
              <input type='text'
                className='inventory-product pl-2 mt-2'
                placeholder={t('inventorySidebar.Buying Price')}
                value={buyingPrice || selectedProduct?.buyingPrice}
                onChange={(e) => setBuyingPrice(e.target.value)}
              />
            </div>

            <div className='mt-3'>
              <b className='color-color'>{t('inventorySidebar.Member Price')}</b><br />
              <input type='text'
                className='inventory-product pl-2 mt-2'
                placeholder={t('inventorySidebar.Member Price')}
                value={memberPrice || selectedProduct?.memberPrice}
                onChange={(e) => setMemberPrice(e.target.value)}
              />
            </div>

            <div className='mt-3'>
              <b className='color-color'>{t('invenSidebar.Regular Price')}</b><br />
              <input type='text'
                className='inventory-product pl-2 mt-2'
                placeholder={t('invenSidebar.Regular Price')}
                value={regularPrice || selectedProduct?.regularPrice}
                onChange={(e) => setRegularPrice(e.target.value)}
              />
            </div>

            <div className='mt-3'>
              <b className='color-color'>{t('inventorySidebar.Market Price')}</b><br />
              <input type='text'
                className='inventory-product pl-2 mt-2'
                placeholder={t('inventorySidebar.Market Price')}
                value={marketPrice || selectedProduct?.marketPrice}
                onChange={(e) => setMarketPrice(e.target.value)}
              />
            </div>

            <div className='mt-3'>
              <b className='color-color'>{t('inventorySidebar.Total GST')}</b><br />
              <input type='text'
                className='inventory-product pl-2 mt-2'
                placeholder={t('inventorySidebar.Total GST')}
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
            </div>

            <div className='row '>
              <div className='col-lg-4 mt-2'>
                <b className='color-color'>{t('inventorySidebar.CGST')}</b><br />
                <input type='text'
                  className='update-gst'
                  placeholder={t('inventorySidebar.CGST')}
                  value={cgst}
                  onChange={(e) => setCgst(e.target.value)}
                />
              </div>

              <div className='col-lg-4 mt-2'>
                <b className='color-color'>{t('inventorySidebar.SGST')}</b><br />
                <input type='text'
                  className='update-gst'
                  placeholder={t('inventorySidebar.SGST')}
                  value={sgst}
                  onChange={(e) => setSgst(e.target.value)}
                />
              </div>

              <div className='col-lg-4 mt-2'>
                <b className='color-color'>{t('inventorySidebar.IGST')}</b><br />
                <input type='text'
                  className='update-gst'
                  placeholder={t('inventorySidebar.IGST')}
                  value={igst}
                  onChange={(e) => setIgst(e.target.value)}
                />
              </div>
            </div>

            <h6 className='color-color pt-4'>{t('inventorySidebar.Supplier')}
              <span className='text-danger'>*</span></h6>
            <AsyncTypeahead
              id="supplier-search"
              labelKey="label"
              isLoading={isLoadingSupplier}
              options={supplierData}
              onChange={handleSelect}
              onSearch={handleSupplierSearch}
              selected={selectedSupplier ? [selectedSupplier] : []}
              placeholder={t('inventorySidebar.Search Suppliers by Name')}
              className={`inventory-products ${supplierError ? 'error-border' : ''}`}
            />
            {supplierError && <p className='inventory-error'>{supplierError}</p>}

            {selectedSupplier && (
              <div className='pt-2'>
                <b>{selectedSupplier?.company}</b>
                <p className='color-color'>{selectedSupplier?.address}</p>
                <span>Phone No: {selectedSupplier?.phoneNumber}</span>
              </div>
            )}

            <div className='pt-3'>
              <table className='varient-pricelist-table'>
                <thead className='color-color'>
                  <tr>
                    <th className='color-color'>
                      {t('inventorySidebar.Variant')}
                    </th>

                    <th className='color-color'>
                      {t('inventorySidebar.Member Price')}
                    </th>

                    <th className='color-color'>
                      {t('invenSidebar.Regular Price')}
                    </th>

                    <th className='color-color'>
                      {t('inventorySidebar.Market Price')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Array.isArray(selectedVariants) && selectedVariants.map((variant, index) => (
                    <tr key={index}>
                      <td>{variant}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <h6 className='color-color pt-3'>{t('inventorySidebar.Variants')}</h6>
            {selectedProduct?.variants &&
              selectedProduct.variants.map((variant, index) => (
                <div key={index}>
                  {Object.entries(variant).map(([key, values]) => (
                    <div key={key}>
                      <strong>{key}:</strong>
                      {values.map((value, valueIndex) => (
                        <label key={valueIndex} className='ml-2'>
                          <button
                            className={`variants-btn ${selectedVariants.includes(value) ? 'selected' : ''}`}
                            onClick={() => handleButtonClick(value)}>
                            {value}
                          </button>
                        </label>
                      ))}
                    </div>
                  ))}
                </div>
              ))}

            <div className='mt-3'>
              <b>{t('inventorySidebar.Enter the minimum quantity')}</b>
              <span className='text-danger'>*</span><br />
              <input
                className='inventory-product mt-2'
                type="number"
                value={minStock}
                onChange={(e) => setMinStock(e.target.value)}
                placeholder={t('inventorySidebar.Enter Quantity')}
              />
              {minStokError && <p className='inventory-error'>{minStokError}</p>}
            </div>

            <div className='mt-3'>
              <b>{t('inventorySidebar.Enter the Quantity')}</b>
              <span className='text-danger'>*</span><br />
              <input
                className='inventory-product mt-2'
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder={t('inventorySidebar.Enter Quantity')}
              />
              {quantityError && <p className='inventory-error'>{quantityError}</p>}
            </div>

            <div className='mt-3'>
              <b >{t('inventorySidebar.Enter GRN Number')}</b>
              <input
                className='inventory-product'
                type='number'
                value={GRNNo}
                onChange={(e) => setGRNNo(e.target.value)}
                placeholder={t('inventorySidebar.Enter GRN Number')}
              />
            </div>

            <div className='mt-3'>
              <b>{t('inventorySidebar.Batch Number/Enter Lot')}</b><br />
              <input
                className='inventory-product mt-2'
                type="text"
                value={batchNo}
                onChange={(e) => setBatchNo(e.target.value)}
                placeholder={t('inventorySidebar.Batch Number/Enter Lot')}
              />
            </div>

            <div className='mt-3'>
              <b>{t('inventorySidebar.Expiry Date')}</b><br />
              <DatePicker
                selected={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                className='inventory-date-u mt-2'
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy" />
            </div>

            <div className='mt-2'>
              <b>{t('inventorySidebar.Select Warehouse')}</b>
              <span className='text-danger'>*</span><br></br>
              <AsyncTypeahead
                id="warehouse-search"
                labelKey="label"
                valueKey="wareHouseId"
                isLoading={isLoadingWarehouse}
                options={warehouseOptions}
                onSearch={handleWarehouseSearch}
                onChange={handleWarehouseSelection}
                onInputChange={handleInputChange}
                selected={selectedWarehouse ? [selectedWarehouse] : []}
                className={`inventory-products ${warehouseNameError ? 'error-border' : ''}`}
                placeholder={t('inventorySidebar.Enter Warehouse Name')}
              />
              {warehouseNameError && <p className='inventory-error'>{warehouseNameError}</p>}
              <i className="fa-solid fa-magnifying-glass inventery-icon"></i>
            </div>
            <div key={selectedManagers?.value}>
              <h6>Manager: {selectedManagerName} : {phoneNumbers}</h6>
            </div>
          </div>

          <div className='mt-4'>
            <button
              className='update-inventery-btn'
              onClick={() => {
                if (!isUpdate) {
                  setIsUpdate(true);
                  updateInventoryData();
                }
              }}>
              {t(isUpdate ? 'customer.Updating..' : 'intentCreate.Update')}
            </button>

            <button className='cancel-inventery-btn ml-2'
              onClick={handleClose}>
              {t("intentCreate.Cancel")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateInventory;