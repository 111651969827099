import React from 'react';

const SaleProfitReport = () => {
    return (
        <>
            <div className="main">
                <div className="body_container">
                    <div className="main-padding">

                    </div>
                </div>
            </div>
        </>
    )
}

export default SaleProfitReport;