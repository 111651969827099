import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';
import Avatar from '../../components/avatar/Avatar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';
import { fetchManager } from '../../utils/Helper';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';

const WareHouseUpdateSidebar = ({ handleClose, selectedWarehouse, _id, fetchWareHouse }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useLanguage();
  const [areInputsFilled, setAreInputsFilled] = useState(false);
  const [apiCallMade, setApiCallMade] = useState(false);
  const [selectedManagers, setSelectedManagers] = useState(null);
  const [isLoadingManager, setIsLoadingManager] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const { empId, phoneNumber } = useParams()
  const [managerError, setManagerError] = useState('');
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const [warehouse, setWarehouse] = useState({
    pictures: selectedWarehouse.pictures || '',
    WareHouseName: selectedWarehouse.WareHouseName || '',
    manager: selectedWarehouse?.manager || '',
    capacity: selectedWarehouse.capacity || '',
    locationName: selectedWarehouse.locationName || '',
    groupId: groupId,
    latitude: selectedWarehouse.location?.coordinates[0] || '',
    longitude: selectedWarehouse.location?.coordinates[1] || '',
    pinCode: selectedWarehouse.pinCode || '',
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    setAreInputsFilled(validateInputs());
  }, []);

  const validateInputs = () => {
    const newErrors = {};
    if (!warehouse.WareHouseName || !warehouse.WareHouseName.trim()) {
      newErrors.WareHouseName = 'Enter warehouse name';
    } else if (!/^[a-zA-Z\s]+$/.test(warehouse.WareHouseName.trim())) {
      newErrors.WareHouseName = 'Warehouse name can only contain letters and spaces';
    }

    if (!warehouse.capacity || !warehouse.capacity.trim()) {
      newErrors.capacity = 'Enter warehouse capacity';
    }

    if (!warehouse.locationName || !warehouse.locationName.trim()) {
      newErrors.location = 'Enter Warehouse Location';
    } else if (!/^[a-zA-Z\s]+$/.test(warehouse.locationName.trim())) {
      newErrors.location = 'Warehouse location can only contain alphabetic characters';
    }
    const latitudeString = String(warehouse.latitude).trim();
    if (!warehouse.latitude || !warehouse.latitude.toString().trim()) {
      newErrors.latitude = 'Enter Warehouse latitude*';
    } else if (!/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(warehouse.latitude.toString().trim()) && warehouse.latitude !== "0") {
      newErrors.latitude = 'Invalid Latitude';
    }

    if (!warehouse.longitude || !warehouse.longitude.toString().trim()) {
      newErrors.longitude = 'Enter Warehouse Longitude*';
    } else if (!/^[-+]?(180(\.0+)?|((1[0-7]\d)|(\d{1,2}))(\.\d+)?)$/.test(warehouse.longitude.toString().trim()) && warehouse.longitude !== "0") {
      newErrors.longitude = 'Invalid Longitude';
    }

    if (!warehouse.pinCode || !warehouse.pinCode.trim()) {
      newErrors.pinCode = 'Enter pincode';
    }
    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      [name]: value,
    }));
    setAreInputsFilled(validateInputs());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedManagers?.name) {
      toast.error('Please select a manager')
      return
    }

    if (!validateInputs()) {
      return;
    }

    if (apiCallMade) {
      return;
    }
    const manager = {
      name: selectedManagers?.name || "",
      id: selectedManagers?.empId || empId || "",
      phoneNumber: selectedManagers?.phoneNumber || phoneNumber,
    };

    setIsSaving(true);
    const location = {
      coordinates: [parseFloat(warehouse.latitude), parseFloat(warehouse.longitude)],
      type: "Point"
    };

    const { latitude, longitude, ...warehouseWithoutLatLng } = warehouse;
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/warehouse/${_id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...warehouseWithoutLatLng,
          manager,
          location: {
            coordinates: [latitude, longitude],
            type: "Point"
          }
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        toast.success('Warehouse Updated Successfully...', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        handleClose();
        setTimeout(() => {
          fetchWareHouse('')
        }, 1000);

        setApiCallMade(true);
      } else {
        throw new Error('Warehouse update failed');
      }
    } catch (error) {
      console.error(error);
      toast.error('Warehouse Update Failed');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setWarehouse({
      pictures: '',
      WareHouseName: '',
      manager: '',
      capacity: '',
      locationName: '',
      latitude: '',
      longitude: '',
      pinCode: '',
    });
    setSelectedManagers(null)
    setAreInputsFilled(false);
  };

  const changeProfileURL = (url) => {
    setWarehouse({ ...warehouse, pictures: url });
  };

  useEffect(() => {
    if (selectedWarehouse?.manager) {
      setSelectedManagers(selectedWarehouse?.manager[0]);
    } else {
      setSelectedManagers({});
    }
  }, [selectedWarehouse]);

  const handleManagerSelection = (selected) => {
    setSelectedManagers(selected[0]);
  };

  const handleManagerSearch = async (query) => {
    setIsLoadingManager(true);
    try {
      const data = await fetchManager(query);
      setManagerOptions(data?.data?.items || []);
    } catch (error) {
      console.error('Error fetching manager data:', error);
      setManagerOptions([]);
    }
    setIsLoadingManager(false);
    setManagerError('');
  };

  return (
    <div>
      <div className='sidebar-update'>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="d-inline-flex pt-4">
              <h6 className="pl-3 ware-name ">
                <b>{t('warehouseUpdate.Update Warehouse')}</b>
              </h6>
              <div className='close-icon' onClick={handleClose}>
                <i className="fa-solid fa-circle-xmark"></i>
              </div>
            </div>
            <hr className='hr-wareside' />

            <div className=' mt-2 scrollbar-warehouse'>
              <div>
                <h6 className="pl-3 "> </h6>
                <p className="warehouse-line pl-1"> </p>
                <div className="col-lg-2">
                  <div className='avatar-image'>
                    {areInputsFilled && (
                      <Avatar warehouse={warehouse} onUploadSuccess={changeProfileURL} />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3 ware-info ">
                <p>
                  <h6 className='ware-text'>
                    {t("warehouseSidebar.Warehouse name")}
                    <span className='text-danger'>*</span> </h6>
                  <input className="prods-inputs  Manager-input mt-1"
                    type="text"
                    placeholder="Warehouse name"
                    name="WareHouseName"
                    value={warehouse.WareHouseName}
                    onChange={handleInputChange} />
                  {errors.WareHouseName && <p className='validation-error'>{errors.WareHouseName}</p>}

                  <h6 className='ware-text pt-3'>{t('inventorySidebar.Select warehouse manager')}<span className='text-danger'>*</span></h6>
                  <AsyncTypeahead
                    id="manager-search"
                    labelKey="name"
                    isLoading={isLoadingManager}
                    options={managerOptions}
                    selected={selectedManagers ? [selectedManagers] : []}
                    onSearch={handleManagerSearch}
                    onChange={handleManagerSelection}
                    placeholder={t('inventorySidebar.Search Managers')}
                    className={`warehouse-manager ${errors.name ? 'error-border' : ''}`}
                  />

                  <h6 className='ware-text pt-3'>
                    {t("warehouseSidebar.Capacity")}
                    <span className='text-danger'>*</span> </h6>
                  <input
                    className=" Manager-input mt-1 "
                    type="text"
                    placeholder={t("warehouseSidebar.Capacity")}
                    name="capacity"
                    value={warehouse.capacity}
                    onChange={handleInputChange} />
                  {errors.capacity && <p className='validation-error'>{errors.capacity}</p>}

                  <h6 className='ware-text pt-3'>
                    {t('warehouseSidebar.Location')}
                    <span className='text-danger'>*</span> </h6>
                  <input
                    className=" Manager-input mt-1"
                    type="text"
                    placeholder={t('warehouseSidebar.Location')}
                    name="locationName"
                    value={warehouse.locationName}
                    onChange={handleInputChange} />
                  {errors.location && <p className='validation-error'>{errors.location}</p>}

                  <h6 className='ware-text pt-3'>
                    {t('serviceSidebar.Latitude')}
                    <span className='text-danger'>*</span> </h6>
                  <input type="number"
                    className=" Manager-input mt-1"
                    name="latitude"
                    id="latitude"
                    value={warehouse.latitude}
                    onChange={handleInputChange}
                    placeholder={t('serviceSidebar.Latitude')} />
                  {errors.latitude && <p className='validation-error'>{errors.latitude}</p>}

                  <h6 className='ware-text pt-3'>
                    {t('serviceSidebar.Longitude')}
                    <span className='text-danger'>*</span> </h6>
                  <input type="number"
                    name="longitude"
                    className=" Manager-input mt-1"
                    value={warehouse.longitude}
                    onChange={handleInputChange}
                    id="longitude"
                    placeholder={t('serviceSidebar.Longitude')} />
                  {errors.longitude && <p className='validation-error'>{errors.longitude}</p>}

                  <h6 className='ware-text pt-3'>
                    {t('requestsidebar.pinCode')}
                    <span className='text-danger'>*</span> </h6>
                  <input
                    className={`${errors.pinCode ? "is-invalid" : ""} Manager-input mt-1`}
                    type='text'
                    name='pinCode'
                    placeholder={t('warehouseSidebar.Enter the pin code')}
                    value={warehouse.pinCode}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                      handleInputChange(e);
                    }}
                  />
                  {errors.pinCode && <p className='validation-error'>{errors.pinCode}</p>}
                </p>
              </div>
            </div>

            <button className="warehouse-button mt-3 " type="submit">
              {t(isSaving ? 'Updating...' : 'intentCreate.Update')}
            </button>
            <button className="cancel-ware ml-2 " type="button" onClick={handleCancel}>
              <b>{t("intentCreate.Cancel")}</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WareHouseUpdateSidebar;