import API from "../services/Api";

const Utils = {
  getGroup: () => {
    const g = localStorage.getItem("group");
    return g !== "undefined" ? JSON.parse(g) : {};
  },

  getParentCategory: () => {
    const g = localStorage.getItem("group");
    return g !== "undefined" ? JSON.parse(g) : {};
  },
  capitalizeFirstLetter: (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
};
export default Utils;

export const fetchParentCategories = async () => {
  const groupId = localStorage.getItem("selectedGroupId");
  try {
    const response = await fetch(`${API.commerceAPI}/commerce/category/all/getByGroupId/${groupId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching parent categories:', error);
    return [];
  }
};

export const fetchServices = async () => {
  const groupId = localStorage.getItem("selectedGroupId");
  try {
    const response = await fetch(`${API.commerceAPI}/commerce/services/all/getByGroupId/${groupId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching parent categories:', error);
    return [];
  }
};

export const fetchManager = async () => {
  const groupId = localStorage.getItem("selectedGroupId");
  try {
    const response = await fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching parent categories:', error);
    return [];
  }
};

export const fetchWarehouse=async ()=>{
  const groupId=localStorage.getItem("selectedGroupId");
  try{
    const response=await fetch(`${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}`);
    const data=await response.json()
    return data;
  }catch(error){
    console.error(`Errof fetching warehouse:`,error)
  }
  }

  export const fetchProducts=async ()=>{
    const groupId=localStorage.getItem("selectedGroupId");
    try{
      const response=await fetch(`${API.commerceAPI}/commerce/products/all/group/${groupId}`);
      const data=await response.json()
      return data;
    }catch(error){
      console.error(`Errof fetching products:`,error)
    }
  }
  
  export const fetchSupplier=async ()=>{
    const groupId=localStorage.getItem("selectedGroupId");
    try{
      const response=await fetch(`${API.commerceAPI}/commerce/supplier/all/getByGroupId/${groupId}`);
      const data=await response.json()
      return data;
    }catch(error){
      console.error(`Errof fetching products:`,error)
    }
  }