import React from "react";

function Popup(props) {
  return (
    <div>
      <div className="popup-box">
        <div className="cust-box">
          <i className="fa-solid fa-circle-xmark description-close" onClick={props.handleClose}></i>
          {props.content}
        </div>
      </div>
    </div>
  );
}

export default Popup;