import React, { useState } from "react";
import API from "../../services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Avatar.scss";

function  Avatar({ data, onUploadSuccess, onImageChange, warehouse, user, formData }) {
  const [uploading, setUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState(data?.imageUrl || user?.imageUrl || warehouse?.pictures || formData?.imageUrl || '');
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      setUploading(true);
      const response = await fetch(
        `${API.imageUpload}/doc-upload/signed-url?folderPath=baap_commerce&fileName=${file.name}&public=true&action=write`);
      if (!response.ok) {
        throw new Error("Failed to get signed URL");
      }
      const fileResponse = await response.json();
      const signedUrl = fileResponse.url;
      const uploadResponse = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file");
      }
      let parts = signedUrl.split("?");
      let Image = parts[0];
      setCurrentFile(Image);
      onUploadSuccess(Image);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      onImageChange(imageUrl);
    }
  };

  return (
    <div className="file-upload-container">
      <input type="file"
        id="file-input-avatar"
        className="hidden"
        onChange={handleFileChange}
      />
      <label htmlFor="file-input-avatar" className="file-upload-label">
        <div className="bordered-box">
          {uploading ? (
            <div className="loading-text">Uploading...</div>
          ) : (
            <>
              <img src={currentFile !== '' ? currentFile : (warehouse) || (user?.imageUrl) || (user?.pictures) || (warehouse?.pictures) || (user?.profile_pic)}
                className={`avatar ${currentFile ? 'show' : 'hide'}`}
              />
              {!currentFile && (!user || !user?.profile_pic || !user?.imageUrl || !user?.pictures) && (warehouse) && (warehouse?.pictures) && (!formData?.imageUrl) && (
                <span className="avatar-file">Click to Upload</span>
              )}
            </>
          )}
        </div>
      </label>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
export default Avatar;

export function ProductsImage({ data, onUploadSuccess }) {
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files || files?.length === 0) return;

    try {
      setUploading(true);
      const uploadPromises = [];
      const newFileUrls = [];
      for (const file of files) {
        const response = await fetch(`${API.imageUpload}/doc-upload/signed-url?folderPath=baap_commerce&fileName=${file.name}&public=true&action=write`);

        if (!response.ok) {
          throw new Error("Failed to get signed URL");
        }
        const fileResponse = await response.json();
        const signedUrl = fileResponse.url;
        const uploadResponse = await fetch(signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (!uploadResponse.ok) {
          throw new Error("Failed to upload file");
        }
        const uploadedImageUrl = signedUrl.split('?')[0];;
        newFileUrls.push(uploadedImageUrl);
      }
      onUploadSuccess(newFileUrls);
    } catch (error) {
      toast.error("Error uploading images");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="file-upload-container">
      <input
        type="file"
        id="file-input-avatar"
        className="hidden"
        onChange={handleFileChange}
        multiple
        disabled={uploading}
      />
      <label htmlFor="file-input-avatar" className="products-label-box">
        <div className="bordered-box">
          {uploading ? (
            <div className="mt-5">
              <small>Uploading...</small>
            </div>
          ) : (
            <small>Click here to upload Image</small>
          )}
        </div>
      </label>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

export function Icons({ data, onUploadSuccess }) {
  const [uploading, setUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState(data?.profile_pic || '');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      setUploading(true);
      const response = await fetch(`${API.imageUpload}/doc-upload/signed-url?folderPath=baap_commerce&fileName=${file.name}&public=true&action=write`);
      if (!response.ok) {
        throw new Error('Failed to get signed URL');
      }
      const fileResponse = await response.json();
      const signedUrl = fileResponse.url;
      const uploadResponse = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file');
      }
      const newFileUrl = signedUrl.split('?')[0];;
      setCurrentFile(newFileUrl);
      onUploadSuccess(newFileUrl);
      event.target.value = null;
      toast.success('Image Uploaded Successfully',{
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="icon-upload-container">
      <input
        type="file"
        id="file-input-avatar"
        className="hidden"
        onChange={handleFileChange}
        disabled={uploading}
      />
      <label htmlFor="file-input-avatar" className="icons-label-box">
        <div className="bordered-box">
          {uploading && <div className="loading-text">Uploading...</div>}
          {currentFile && <img src={currentFile} alt="Uploaded" className="uploaded-icon" />}
        </div>
      </label>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

export function GroupImage({ data, onUploadSuccess, onImageChange, groupData }) {
  const [uploading, setUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState(data?.logo_url || '');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      setUploading(true);
      const response = await fetch(`https://mzdj9zyo62.execute-api.ap-south-1.amazonaws.com/doc-upload/signed-url?fileName=${file.name}&public=true&action=write`);
      if (!response.ok) {
        throw new Error('Failed to get signed URL');
      }
      const fileResponse = await response.json();
      const uploadResponse = await fetch(fileResponse.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file');
      }
      setCurrentFile(fileResponse.imageUrl);
      onUploadSuccess(fileResponse.imageUrl);
      toast.success('Image Uploaded Successfully!',{
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error(`Failed to upload image: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCurrentFile(imageUrl);
      onImageChange(imageUrl);
    }
  };

  return (
    <div className="file-upload-container">
      <input type="file" id="file-input-avatar" className="hidden" onChange={handleFileChange} />
      <label htmlFor="file-input-avatar" className="file-upload-label">
        <div className="bordered-box">
          {uploading ? (
            <div className="loading-text">Uploading...</div>
          ) : (
            <>
              <img src={currentFile !== '' ? currentFile : groupData?.logo_url} className={`avatar ${currentFile ? 'show' : 'hide'}`} alt="" />
              {!currentFile && <span className="avatar-file">Click to Upload</span>}
            </>
          )}
        </div>
      </label>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}