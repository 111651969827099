import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ChromePicker } from 'react-color'
import { useLanguage } from '../../LanguageContext';
import Select from 'react-select';
import "./ServiceSidebar.scss";
import { fetchParentCategories } from '../../utils/Helper';
import { Icons } from '../avatar/Avatar';
import API from '../../services/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import STORAGE_KEYS from '../../utils/storageKey';

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent', border: "1px solid #C0C2DE",
    }),
};

const ServiceSidebar = ({ closeSidebar, fetchService }) => {
    const [showServices, setShowServices] = useState(false);
    const [hideServices, setHideServices] = useState(false);
    const { t } = useLanguage();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [longitude, setLongitude] = useState('');
    const [longitudeError, setLongitudeError] = useState('');
    const [latitude, setLatitude] = useState('');
    const [latitudeError, setLatitudeError] = useState('');
    const [icon, setIcon] = useState('');
    const [descError, setDescError] = useState('');
    const [webURLError, setWebURLError] = useState('');
    const [templateError, setTemplateError] = useState('');
    const [listenerError, setListenerError] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [nameError, setNameError] = useState('')
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const navigate = useNavigate();
    const [error, setError] = useState({});

    const initialFormData = {
        name: '',
        desc: '',
        groupId: groupId,
        icon: '',
        action: '',
        tags: '',
        webURL: '',
        listener: [],
        featured: false,
        messageCustomers: false,
        paymentEnabled: false,
        needCloseConfirmation: false,
        template: '',
        categoryId: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetchParentCategories();
                const categoriesData = response.data.items;
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const validateForm = () => {
        let valid = true;

        if (!selectedCategory) {
            setCategoryError('Please select a category');
            valid = false;
        } else {
            setCategoryError('');
        }

        if (!formData.name) {
            setNameError('Name is required');
            valid = false;
        } else {
            setNameError('');
        }

        if (!formData.desc) {
            setDescError('Description is required');
            valid = false;
        } else {
            setDescError('');
        }

        if (!formData.template) {
            setTemplateError('WhatsApp message template is required');
            valid = false;
        } else {
            setTemplateError('');
        }

        if (!longitude) {
            setLongitudeError('Longitude is required');
            valid = false;
        } else {
            setLongitudeError('');
        }

        if (!latitude) {
            setLatitudeError('Latitude is required');
            valid = false;
        } else {
            setLatitudeError('');
        }

        if (!formData.listener?.length) {
            setListenerError('Notification Mobile Number is required');
            valid = false;
        } else {
            setListenerError('');
        }

        if (!formData.tags) {
            setTagsError('Tags are required');
            valid = false;
        } else {
            setTagsError('');
        }

        return valid;
    };

    const handleInputChange = (field, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
            groupId: groupId,

        }));

        setError(prevErrors => ({
            ...prevErrors,
            [field]: ''
        }));
        switch (field) {
            case 'name':
                setNameError('');
                break;
            case 'desc':
                setDescError('');
                break;
            case 'webURL':
                setWebURLError('');
                break;
            case 'template':
                setTemplateError('');
                break;
            case 'listener':
                setListenerError('');
                break;
            case 'tags':
                setTagsError('');
                break;
            case 'longitude':
                setLongitudeError('');
                break;
            case 'latitude':
                setLatitudeError('');
                break;
            default:
                break;
        }
        if (field === 'notificationNumbers' || field === 'listener') {
            const numbersArray = value.split(',').map(number => Number(number.trim()));
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: numbersArray,
                groupId: groupId,
            }));
        } else if (field === 'productSearchTags' || field === 'tags') {
            const tagsArray = value.split(',').map(tag => tag.trim());
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: tagsArray,
                groupId: groupId,
            }));
        } else if (field === 'longitude' || field === 'latitude') {
            setFormData(prevFormData => ({
                ...prevFormData,
                location: {
                    type: 'Point',
                    coordinates: [
                        field === 'longitude' ? Number(value) : prevFormData.location.coordinates[0],
                        field === 'latitude' ? Number(value) : prevFormData.location.coordinates[1],
                    ],
                },
                groupId: groupId,
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: value,
                groupId: groupId,
            }));
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            if (icon?.length === 0) {
                toast.error('Please upload icons before submitting.');
                return;
            }

            const response = await fetch(`${API.commerceAPI}/commerce/services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    categoryId: selectedCategory.value,
                    icon: icon,
                    location: {
                        type: 'Point',
                        coordinates: [Number(longitude), Number(latitude)],
                    },
                    showServices: showServices,
                    hideServices: hideServices,
                    theme: {
                        background: color.replace('#', ''),
                        color: 'FFFFFF',
                    },
                }),
            });

            if (response.ok) {
                toast.success("Service Create Successfully..", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                })
                closeSidebar();
                fetchService();
                navigate('/service');
            }
            else {
                const errorMessage = await response.text();
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            toast.error('An error occurred while submitting the form');
        }
    };
    const handleLongitudeChange = (e) => {
        const value = e.target.value;
        setLongitude(value);
        if (!value) {
            setLongitudeError('Longitude is required');
        } else {
            setLongitudeError('');
        }
    };
    const handleLatitudeChange = (e) => {
        const value = e.target.value;
        setLatitude(value);
        if (!value) {
            setLatitudeError('Latitude is required');
        } else {
            setLatitudeError('');
        }
    };
    const handleCheckboxChange = (field) => {
        setFormData({
            ...formData,
            [field]: !formData[field],
        });
    };
    const handleCancel = () => {
        setFormData(initialFormData);
        closeSidebar();
    };
    const handleIconsUploadSuccess = (uploadedIcon) => {
        setIcon(uploadedIcon);
    };

    const [color, setColor] = useState('#ffffff');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const handleColorChange = (newColor) => {
        const hexColor = newColor.hex;
        setColor(hexColor);
    };

    const handleToggleColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    return (
        <div className='container-fluid'>
            <div className="profile-sidebar">
                <div className=" pl-2">
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='service-header1'>
                                <b>{t('serviceSidebar.Create Services')}</b>
                                <br />
                                <b className='service-subtitles'>{t('serviceSidebar.Create Service Request, and manage it')}</b>
                                <button className="fa-solid fa-circle-xmark service-sidebar-close" onClick={closeSidebar}></button>
                                <hr className='service-hrs'></hr>
                            </div>

                            <div className='scrollbar-service pl-3'>
                                <p className='request-title mt-2'>{t('serviceSidebar.Parent category')} <span className="text-danger">*</span></p>
                                <Select
                                    styles={customStyles}
                                    className={`service-create-select ${categoryError ? 'error-border' : ''}`}
                                    value={selectedCategory}
                                    onChange={(selectedOption) => {
                                        setSelectedCategory(selectedOption);
                                        handleInputChange('categoryId', selectedOption.value);
                                    }}
                                    options={categories.map((category) => ({
                                        label: category.name, value: category.categoryId,
                                    }))}
                                />
                                {categoryError && <span className="validation-error">{categoryError}</span>}

                                <p className='request-title'>
                                    {t('serviceSidebar.Service request')} <span className="text-danger">*</span><br />
                                    <input type='text'
                                        className={`feature-input-1 set-width ${nameError ? 'error-border' : ''}`}

                                        value={formData.name}
                                        name='name'
                                        placeholder={t('serviceSidebar.Name')}
                                        onChange={(e) => handleInputChange('name', e.target.value)}
                                    />
                                    {nameError && <span className="validation-error">{nameError}</span>}
                                </p>

                                <p className='request-title mt-2'>
                                    {t('serviceSidebar.Description')}<span className="text-danger">*</span> <br />
                                    <textarea
                                        className={`feature-input-1 set-width pt-2 ${descError ? 'error-border' : ''}`}
                                        name='desc'
                                        placeholder={t('serviceSidebar.Description')}
                                        value={formData.desc}
                                        onChange={(e) => handleInputChange('desc', e.target.value)}></textarea>
                                    {descError && <span className="validation-error">{descError}</span>}
                                </p>

                                <p className='request-title mt-2'>{t('serviceSidebar.Icon')} <span className="text-danger">*</span></p>
                                <Icons onUploadSuccess={handleIconsUploadSuccess} />
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <p className='request-title'>{t('serviceSidebar.Action')}</p>
                                        <Select styles={customStyles}
                                            className='service-select'
                                            value={categories.find(category => category.value === formData.action)}
                                            onChange={(selectedOption) => handleInputChange('action', selectedOption.value)}
                                            options={[
                                                { label: 'whatsapp', value: 'whatsapp' },
                                                { label: 'list', value: 'list' },
                                            ]}
                                        />
                                    </div>

                                    <div className='col-lg-8'>
                                        <p className='request-title'>{t('serviceSidebar.tags')} <span className="text-danger">*</span>
                                            <input
                                                type='text'
                                                className={`feature-input-1 set-width ${tagsError ? 'error-border' : ''}`}
                                                name='tags'
                                                value={formData.tags}
                                                onChange={(e) => {
                                                    handleInputChange('tags', e.target.value);
                                                }}
                                                placeholder={t('serviceSidebar.Enter tags separated by commas')}
                                            /></p>
                                        {tagsError && <span className="validation-error">{tagsError}</span>}
                                    </div>
                                </div>

                                <p className='request-title mt-2'>{t('serviceSidebar.Latitude')}<span className="text-danger">*</span>
                                    <input
                                        type='text'
                                        className={`feature-input-1 set-width ${latitudeError ? 'error-border' : ''}`}
                                        name='latitude'
                                        placeholder={t('serviceSidebar.Latitude')}
                                        value={latitude}
                                        onChange={handleLatitudeChange}
                                    />
                                    {latitudeError && <span className="validation-error">{latitudeError}</span>}
                                </p>

                                <p className='request-title mt-4'>{t('serviceSidebar.Longitude')}<span className="text-danger">*</span>
                                    <input
                                        type='text'
                                        className={`feature-input-1 set-width ${longitudeError ? 'error-border' : ''}`}

                                        name='longitude'
                                        placeholder={t('serviceSidebar.Longitude')}
                                        value={longitude}
                                        onChange={handleLongitudeChange}
                                    />
                                    {longitudeError && <span className="validation-error">{longitudeError}</span>}
                                </p>

                                <p className='request-title mt-2'>
                                    {t('serviceSidebar.Web URL')} <span className="text-danger">*</span><br />
                                    <input type='text'
                                        className='feature-input-1 set-width'
                                        name='webURL'
                                        placeholder={t('serviceSidebar.Web URL')}
                                        value={formData.webURL}
                                        onChange={(e) => handleInputChange('webURL', e.target.value)} />
                                </p>

                                <p className='request-title mt-2'>
                                    {t('serviceSidebar. Notification Mobile Number')}<span className="text-danger">*</span>
                                    <input
                                        type='number'
                                        className={`feature-input-1 set-width ${listenerError ? 'error-border' : ''}`}
                                        name='listener'
                                        value={formData.listener}
                                        onChange={(e) => {
                                            handleInputChange('listener', e.target.value);

                                        }}
                                        placeholder={t('serviceSidebar.Enter numbers separated by commas')}
                                        maxLength={10}
                                    />
                                    {listenerError && <span className="validation-error">{listenerError}</span>}
                                </p>

                                <p className='request-title'>{t('serviceSidebar.Selected Color')}: {color}</p>
                                <button onClick={handleToggleColorPicker} className='color-pick'>{t('serviceSidebar.Service color')}</button>
                                {showColorPicker && (
                                    <ChromePicker color={color} onChange={handleColorChange} className="chrome-picker-small" />
                                )}<br></br>

                                <input type='checkbox' name='featured' checked={formData.featured} onChange={() => handleCheckboxChange('featured')}>
                                </input>
                                <span className='checkbox-feature'> {t('serviceSidebar.is featured ?')}</span><br />

                                <input type='checkbox' name='messageCustomers'
                                    checked={formData.messageCustomers}
                                    onChange={() => handleCheckboxChange('messageCustomers')}>
                                </input>
                                <span className='checkbox-feature'> {t('serviceSidebar.Message Customers?')}</span><br />

                                <input type='checkbox' name='paymentEnabled'
                                    checked={formData.paymentEnabled}
                                    onChange={() => handleCheckboxChange('paymentEnabled')}>
                                </input>
                                <span className='checkbox-feature'> {t('serviceSidebar.Payment enabled?')}</span><br />

                                <input type='checkbox' className='' name='needCloseConfirmation' checked={formData.needCloseConfirmation} onChange={() => handleCheckboxChange('needCloseConfirmation')}>
                                </input>
                                <span className='checkbox-feature'>{t('serviceSidebar. Need close confirmation from customers?')}</span><br />

                                <input type='checkbox' name='showServices' checked={showServices} onChange={() => setShowServices(!showServices)}
                                />
                                <span className='checkbox-feature'> {t('serviceSidebar.Show Services on App?')}</span>

                                <br />
                                <input type='checkbox' name='hideServices'
                                    checked={hideServices} onChange={() => setHideServices(!hideServices)}
                                />
                                <span className='checkbox-feature'> {t('serviceSidebar.Hide Services on App?')}</span>

                                <p className='request-title mt-2'>
                                    {t('serviceSidebar.WhatsApp Message Template(customer)')}<span className="text-danger">*</span>
                                    <input type='text'
                                        className={`feature-input-1 set-width ${templateError ? 'error-border' : ''}`}
                                        name='template'
                                        placeholder={t('serviceSidebar.WhatsApp Message')}
                                        value={formData.template}
                                        onChange={(e) => handleInputChange('template', e.target.value)} />
                                    {templateError && <span className="validation-error">{templateError}</span>}
                                </p></div>

                            <div className='mt-2 pl-3'>
                                <button className='service-save' onClick={handleSubmit}>{t('serviceSidebar.Save')}</button>

                                <button className='service-cancel' onClick={handleCancel}>{t('serviceSidebar.Cancel')}</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceSidebar;