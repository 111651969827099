import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const DeleteStock = ({ onClose, checkedItems, filteredStocks, fetchStockData }) => {
    const [deleteInitiated, setDeleteInitiated] = useState(false);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();

    const handleDeleteStock = async () => {
        const stoIds = checkedItems.map(index => filteredStocks[index].stoId);
        try {
            const response = await fetch('https://mbcut2mq36.execute-api.ap-south-1.amazonaws.com/commerce/sto/delete/sto', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    groupId: 1703228300417,
                    stoId: stoIds,
                }),
            });
            if (response.ok) {
                toast.success("Stock Deleted Successfully", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                    icon: (
                        <i
                            className="fa-solid fa-trash float-right cis pr-2"
                            style={{ color: "white" }}
                        ></i>
                    ),
                    progressClassName: "custom-progress",
                }); fetchStockData();
                onClose();
                setTimeout(() => {
                    window.location.reload();
                }, 200)
            }
        } catch (error) {
            console.error('Error deleting stock:', error);
            toast.error('Error deleting stock');
        }
    };

    return (
        <div>
            <ToastContainer autoClose={1000} />
            <div className="popup-box">
                <div className="cust-box">
                    <p className="text-center  delete-message">{t("sto.Are")}</p>
                    <button onClick={handleDeleteStock} className="emp-delete" disabled={deleteInitiated}>
                        {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}
                    </button>
                    <button onClick={onClose} className="emp-nodelete">
                        {t("productdetails.No")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteStock;