import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Product from "./pages/products/Products";
import CreateEmployee from './components/create-employee/CreateEmployee';
import Employee from './pages/employee/Employee'
import Warehouse from "./pages/warehouse/Warehouse";
import Category from "./pages/category/Category";
import Registration from "./pages/registration/Registration";
import VerifyOTP from "./pages/verify-otp/VerifyOTP";
import ProductsForm from "./pages/product-form/AddProducts";
import Delivery from "./pages/delivery/Delivery";
import Inventory from "./pages/inventory/Inventory";
import CreateSupplier from "./pages/create-supplier/CreateSupplier";
import AddSuppliersSidebar from "./components/add-supplier-sidebar/AddSupplierSidebar";
import SelectGroup from "./pages/select-group/SelectGroup";
import SupplierTable from "./pages/supplier/SupplierTable";
import Service from "./pages/services-request/Service";
import Orders from "./pages/order-page/Orders";
import CustomerDetails from "./pages/customer-details/CustomerDetails";
import CustomerList from "./pages/customer-list/CustomerList";
import OrderReport from "./pages/order-report/OrderReport";
import TripInfo from "./components/trip-sidebar/TripInfo";
import EmployeeDetails from "./components/employee-details/EmployeeDetails";
import SignIn from "./SignIn";
import SaleProfitReport from "./pages/sale&profit-reports/SaleProfitReport";
import Intent from "./pages/intent/Intent";
import Quotation from "./pages/quotation/Quotation";
import PurchaseOrder from "./pages/purchase-order/PurchaseOrder";
import StockTransfer from "./pages/stock-transfer/StockTransfer";
import ConfigurationList from "./pages/configuration/ConfigurationList";
import AddConfiguration from "./pages/configuration/AddConfiguration";
import TermsCondition from "./pages/termsConditions/TermsCondition";
import LeftSideMenu from "./components/sidemenu/Sidemenu";
import PrivateRoute from "./pages/authContext/PrivateRoute";
import Packages from "./pages/packages/Packages";
import Dashboard from "./pages/dashboard/Dashboard";

function RouteSettings() {
  const location = useLocation();
  const noSidebarRoutes = ['/', '/verify-otp', '/select-group'];

  if (noSidebarRoutes.includes(location.pathname)) {
    return (
      <div className='no-sidebar'>
        <Routes>
          <Route path="/" element={<Registration />}></Route>
          <Route path="/verify-otp" element={<VerifyOTP />}></Route>
          <Route path="/select-group" element={<SelectGroup />}></Route>
        </Routes>
      </div>
    );
  };

  return (
    <div className="main">
      <div className="side_container">
        <LeftSideMenu />
      </div>

      <div className="body_container">
        <Routes>
          <Route path="auth/single-sign-on" element={<PrivateRoute element={SignIn} />}></Route>
          <Route path='/orders-report' element={<PrivateRoute element={OrderReport} />} />
          <Route path="/customers" element={<PrivateRoute element={CustomerList} />}></Route>
          <Route path="/create-employee/:userId" element={<PrivateRoute element={CreateEmployee} />}></Route>
          <Route path="/employee" element={<PrivateRoute element={Employee} />}></Route>
          <Route path="/products" element={<PrivateRoute element={Product} />}></Route>
          <Route path="/product-form/:productId" element={<PrivateRoute element={ProductsForm} />}></Route>
          <Route path="/create-supplier/:supplierId" element={<PrivateRoute element={CreateSupplier} />}></Route>
          <Route path="/warehouse" element={<PrivateRoute element={Warehouse} />}></Route>
          <Route path="/category" element={<PrivateRoute element={Category} />}></Route>
          <Route path="/delivery-page" element={<PrivateRoute element={Delivery} />} ></Route>
          <Route path="/supplier" element={<PrivateRoute element={SupplierTable} />} ></Route>
          <Route path="/inventory" element={<PrivateRoute element={Inventory} />}></Route>
          <Route path="/add-suppliers/new" element={<PrivateRoute element={AddSuppliersSidebar} />}></Route>
          <Route path="/intents" element={<PrivateRoute element={Intent} />}></Route>
          <Route path="/service" element={<PrivateRoute element={Service} />}></Route>
          <Route path="/orders" element={<PrivateRoute element={Orders} />} />
          <Route path='/sale-profit' element={<PrivateRoute element={SaleProfitReport} />}></Route>
          <Route path='employee-details/:userId' element={<PrivateRoute element={EmployeeDetails} />} />
          <Route path='trip-side/:tripId' element={<PrivateRoute element={TripInfo} />} />
          <Route path='/quotation/:intentId' element={<PrivateRoute element={Quotation} />} />
          <Route path='/purchase-order' element={<PrivateRoute element={PurchaseOrder} />} />
          <Route path='/stock-transfer' element={<PrivateRoute element={StockTransfer} />} />
          <Route path='/configuration-list' element={<PrivateRoute element={ConfigurationList} />}></Route>
          <Route path='/add-configuration' element={<PrivateRoute element={AddConfiguration} />}></Route>
          <Route path='/terms-conditions' element={<PrivateRoute element={TermsCondition} />}></Route>
          <Route path='customer-details/:userId' element={<PrivateRoute element={CustomerDetails} />} />
          <Route path='package' element={<PrivateRoute element={Packages} />} />
          <Route path='/dashboard' element={<Dashboard  />} />

        </Routes>
      </div>
    </div>

  );
}
export default RouteSettings;
