import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './VariantTable.scss';
import { useLanguage } from '../../LanguageContext';

const VariantTable = ({ onVariantsUpdate, onPriceListUpdate, priceTable, productVariants }) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [prices, setPrices] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [variants, setVariants] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const { t } = useLanguage();

  const addProperty = () => {
    if (selectedProperty && selectedValue) {
      const existingPropertyIndex = properties.findIndex(
        (prop) => prop.property === selectedProperty
      );
      if (existingPropertyIndex !== -1) {
        const updatedProperties = [...properties];
        updatedProperties[existingPropertyIndex].values.push(selectedValue);
        setProperties(updatedProperties);
      } else {
        const newProperty = {
          property: selectedProperty,
          values: [selectedValue],

          description: selectedDescription,
        };
        setProperties([...properties, newProperty]);
      }
      setSelectedOptions({
        ...selectedOptions,
        [selectedProperty]: selectedValue,
      });
      setSelectedValue('')
    } else {
      toast.error('Please fill all the fields', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value)
    setPrices((prevPrices) => ({
      ...prevPrices,
      [name]: isNaN(numericValue) ? '' : numericValue,
    }));
  };
  const saveData = () => {
    if (properties?.length > 0 && Object.values(prices).every((price) => price !== '')) {
      const newVariants = properties.map((property) => ({
        [property.property]: property.values,
      }));
      setVariants(newVariants);
      onVariantsUpdate(newVariants);
      const newData = { ...prices };
      properties.forEach((property) => {
        newData[property.property] = selectedOptions[property.property] || '';
      });
      newData.description = selectedDescription !== '' ? selectedDescription : '-----';
      const updatedPriceTable = [...priceTable, newData];
      setSavedData([...savedData, newData]);
      onPriceListUpdate(updatedPriceTable);
      setPrices({
        buyingPrice: '',
        memberPrice: '',
        marketPrice: '',
        regularPrice: '',
      });
      setSelectedProperty('');
      setSelectedValue('');
      setSelectedDescription('');
    } else {
      alert('Please fill in all other fields');
    }
  };
  const deleteRow = (index) => {
    const updatedSavedData = [...savedData];
    updatedSavedData.splice(index, 1);
    setSavedData(updatedSavedData);
    const updatedPriceTable = [...priceTable];
    updatedPriceTable.splice(index, 1);
    onPriceListUpdate(updatedPriceTable);
  };
  const handleUpdateVariants = (updatedVariants) => {
    onVariantsUpdate(updatedVariants);
  };

  return (
    <div className='variants-section'>
      <ToastContainer position="top-right" autoClose={1000}
      />

      <input
        className='feature-property'
        type="text"
        value={selectedProperty}
        onChange={(e) => setSelectedProperty(e.target.value)}
        placeholder={t("product.Property Name")}
      />

      <input
        type="text"
        value={selectedValue}
        onChange={(e) => setSelectedValue(e.target.value)}
        className='feature-value'
        placeholder={t("product.Value")}
      />

      <input
        type="text"
        value={selectedDescription}
        onChange={(e) => setSelectedDescription(e.target.value)}
        className='feature-description'
        placeholder={t("product.Description")}
      />

      <button onClick={addProperty} className='feature-add-property'>
        {t("product.Add Variants")}
      </button>

      {properties?.length > 0 && (
        <div className='mt-3'>
          <table className='table'>
            <thead>
              <tr className='color-color'>{properties.map((property, index) => (<th key={index}>{property.property}</th>))}
                <th className='color-color'>{t("product.Buying Price")}</th>
                <th className='color-color'>{t("product.Member Price")}</th>
                <th className='color-color'>{t("product.Regular Price")}</th>
                <th className='color-color'>{t("product.Market Price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {properties.map((property, index) => (
                  <td key={index}>
                    <select
                      value={
                        selectedOptions[property.property] || ''
                      }
                      onChange={(e) =>
                        setSelectedOptions({
                          ...selectedOptions,
                          [property.property]: e.target.value,
                        })
                      }
                      className='feature-select'
                    >
                      {property.values.map((value, i) => (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </td>
                ))}

                <td>
                  <input
                    type="text"
                    name="buyingPrice"
                    placeholder={t("product.Buying Price")}
                    value={prices.buyingPrice}
                    onChange={handlePriceChange}
                    className='price-inputs'
                  />
                </td>

                <td>
                  <input
                    type="text"
                    name="memberPrice"
                    placeholder={t("product.Member Price")}
                    value={prices.memberPrice}
                    onChange={handlePriceChange}
                    className='price-inputs'
                  />
                </td>

                <td>
                  <input
                    type="text"
                    name="regularPrice"
                    placeholder={t("product.Regular Price")}
                    value={prices.regularPrice}
                    onChange={handlePriceChange}
                    className='price-inputs'
                  />
                </td>

                <td>
                  <input
                    type="text"
                    name="marketPrice"
                    placeholder={t("product.Market Price")}
                    value={prices.marketPrice}
                    onChange={handlePriceChange}
                    className='price-inputs'
                  />
                </td>

                <td>
                  <button onClick={saveData} className='feature-save'>
                    {t("product.Save")}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {savedData?.length > 0 && (
        <div className='ml-2'>
          <b className="color-color">Price List Table</b>
          <table className='table'>
            <thead className="color-color">
              <tr >
                {properties.map((property, index) => (
                  <th key={index}>{property.property}</th>
                ))}
                <th>Buy Price</th>
                <th>Member Price</th>
                <th>Market Price</th>
                <th>Regular Price</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody className="color-color">
              {savedData.map((data, index) => (
                <tr key={index}>
                  {properties.map((property, propertyIndex) => (
                    <td key={propertyIndex} className="color-color">
                      {data[property.property]}
                    </td>
                  ))}
                  <td className="color-color">{data.buyingPrice}</td>
                  <td className="color-color">{data.memberPrice}</td>
                  <td className="color-color">{data.marketPrice}</td>
                  <td className="color-color">{data.regularPrice}</td>

                  <td>
                    <button onClick={() => deleteRow(index)} className='feature-delete-button'>
                      Delete
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VariantTable;
