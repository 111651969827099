import React, { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import API from '../../services/Api';

const Trips = () => {
    const [tripsData, setTripsData] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const { userId } = useParams();
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();
    const [pageCount, setPageCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = 30;
    const navigate = useNavigate();

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const fetchTripsData = async () => {
        try {
            const refreshToken = localStorage.getItem('refresh-token');
            const response = await fetch(`${API.memberUpdate}/peopleManagement/trip/get/tripsUseIdAndGroupId/${groupId}/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${refreshToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            const trips = data?.trips || [];
            const pages = Math.ceil(trips?.length / perPage);
            setPageCount(pages);
            setTripsData(trips);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSingleCheckboxChange = (event, index) => {
        if (event.target.checked) {
            setCheckedItems([...checkedItems, index]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== index));
        }
    };

    const handleOnChange = (item) => {
        navigate(`/trip-side/${item.tripId}`);
    };

    const onPageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    useEffect(() => {
        fetchTripsData();
    }, []);

    return (
        <div className=''>
            <div className='row'>
                <div className="mt-3 table-scroll-sr1 trip">
                    <table className="table table-hover">
                        <thead className="font14">
                            <tr className="dark-text-theme table-head">
                                <th className="checkbox-input"
                                    style={{ width: "50px" }}>
                                    <input type="checkbox"
                                        className="cursor-pointer"
                                        name="check" />
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "90px" }}
                                    className="table-head">
                                    {t("Trip.Trip ID")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "150px" }}
                                    className="table-head">
                                    {t("Trip.Start Time")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "140px" }}
                                    className="table-head">
                                    {t("Trip.From")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "140px" }}
                                    className="table-head">
                                    {t("Trip.Destination")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "150px" }}
                                    className="table-head">
                                    {t("Trip.End Time")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "80px" }}
                                    className="table-head">
                                    {t("Trip.Duration")}
                                </th>

                                <th
                                    scope="col"
                                    style={{ width: "80px" }}
                                    className="table-head">
                                    {t("Trip.Distance")}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(tripsData) &&
                                tripsData
                                    ?.slice(currentPage * perPage, (currentPage + 1) * perPage)
                                    ?.map((item, index) => (
                                        <tr className="font14" key={index}>
                                            <td className="t-data">
                                                <input
                                                    type="checkbox"
                                                    className="cursor-pointer"
                                                    name="check"
                                                    checked={checkedItems.includes(index)}
                                                    onChange={(e) => handleSingleCheckboxChange(e, index)}
                                                />
                                            </td>

                                            <td
                                                className="t-data font13 cursor-pointer"
                                                onClick={() => handleOnChange(item)}>
                                                {typeof item?.tripId === "object" ? "-" : item?.tripId}
                                            </td>

                                            <td className="t-data font13">{formatTimestamp(item.startTime)}</td>
                                            <td className="t-data font13">{item.from}</td>
                                            <td className="t-data font13">{item.destination?.length > 0 ? item.destination[0] : '-'}</td>
                                            <td className="t-data font13">{formatTimestamp(item.endTime)}</td>
                                            <td className="t-data font13">{item.duration ? `${item.duration.hours}:${item.duration.minutes}:${item.duration.seconds}` : '-'}</td>
                                            <td className="t-data font13">{item.distance}</td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>

                    {Array?.isArray(tripsData) && tripsData?.length > 0 && (
                        <div className='d-inline-flex pagination-container'>
                            <ReactPaginate
                                className="pagination-bar"
                                previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                                nextLabel={<i className="fa-solid fa-angle-right"></i>} breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={0}
                                onPageChange={onPageChange}
                                containerClassName={"pagination "}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Trips;