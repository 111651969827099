import React from "react";

const Addresses = ({ address }) => {
    return (
        <>
            <small className="emp-text-supplier">{address?.tag}</small><br />
            <small className="emp-text-supplier"> {address?.locality}</small><br />
            <small className="emp-text-supplier"> {address?.city}</small><br></br>
            <small className="emp-text-supplier">{address?.state}</small>
            <small className="emp-text-supplier">{address?.country}</small><br />
            <small className="emp-text-supplier">{address?.zip}</small><br />
        </>
    )
}

export default Addresses;