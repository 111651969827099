import React from "react";
import "./AddaddressSideBar.scss";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import API from "../../services/Api";

function AddaddressSideBar({ data, fetchAddressData, updateAddressData, setOpenAddressSidebar }) {
  const [selectedAddressType, setSelectedAddressType] = useState("इतर");
  const [dataOpen, setDataOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [setIsFormFilled] = useState(false);
  const [formData, setFormData] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const handleAddressTypeChange = (e) => {
    setSelectedAddressType(e.target.value);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    const formFilled = Object.values(formData).some(
      (value) => value.trim() !== ""
    );
    setIsFormFilled(formFilled);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API.commerceAPI}/commerce/customer/updateByUserId/${data?.userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            addresses: [
              {
                addressType: selectedAddressType,
                tag: formData.cars,
                locality: formData.line2,
                state: formData.state,
                city: formData.city,
                zip: formData.zip,
                profileAddress: formData.profileAddress,
                billingAddress: selectedAddressType === "Billing",
                shippingAddress: selectedAddressType === "Shipping",
                default: true,
              },
            ],
          }),
        }
      );

      if (response.ok) {
        setIsLoading(false);
        const responseData = await response.json();

        setDataOpen(false);
        toast.success("Address Added Successfully !", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        fetchAddressData()
        setOpenAddressSidebar(false)
        updateAddressData(responseData?.data.addresses);
      } else {
        console.error("Failed to update address");
      }
    } catch (error) {
      console.error("Error while updating address", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = () => {
    setDataOpen(false);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ background: "red", color: "white" }}
      />

      {dataOpen ? (
        <div className="add-address-sidebar">
          <div className="side-padding">
            <div className="container-fluid">
              <div className="d-flex">
                <div>
                  <h6>
                    <b>Add Customer Address</b>
                  </h6>
                  <p className="font14">
                    Add details of the new customer here.
                  </p>
                </div>
                <div>
                  <i className="fa-solid fa-circle-xmark close-icon-xmark ml-3 mt-2" ></i>
                </div>
              </div>

              <div className=" radi">
                <input
                  type="radio"
                  id="Billing"
                  name="addressType"
                  value="Billing"
                  checked={selectedAddressType === "Billing"}
                  onChange={handleAddressTypeChange}
                  onMouseDown={(e) => e.preventDefault()}
                  className="radio mr-2"
                />

                <label htmlFor="Billing">Billing Address</label>
                <input
                  type="radio"
                  id="Shipping"
                  name="addressType"
                  value="Shipping"
                  checked={selectedAddressType === "Shipping"}
                  onChange={handleAddressTypeChange}
                  onMouseDown={(e) => e.preventDefault()}
                  className="radio ml-2 mr-2"
                />
                <label htmlFor="Shipping">Shipping Address</label>
              </div>

              <hr className=" mt-4" />
              <div className="Car font14 " >
                <label for="cars" >Tag:</label>
                <select name="cars" id="cars" onChange={handleInputChange} style={{ cursor: "pointer" }}>
                  <option value="">SelectAddress</option>
                  <option value="इतर">इतर </option>
                  <option value="घर">घर </option>
                  <option value="ऑफिस">ऑफिस </option>
                </select>
              </div>

              {selectedAddressType && (
                <form className="">
                  <div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <label className="font14">
                          Line1 &nbsp;<span className="text-danger">*</span>
                        </label>{" "}
                        <br />
                        <input
                          className="add-Address-inputs"
                          placeholder="Line1"
                          name="line1"
                          value={formData.line1}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-6">
                        <label className="font14">
                          Line2 &nbsp;<span className="text-danger">*</span>
                        </label>
                        <br />
                        <input
                          className="add-Address-inputs input_width1"
                          placeholder="Line2"
                          name="line2"
                          value={formData.line2}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-6">
                        <label className="font14">
                          City &nbsp;<span className="text-danger">*</span>
                        </label>
                        <br />
                        <input
                          className="add-Address-inputs"
                          placeholder="City"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-6 ">
                        <label className="font14">
                          State &nbsp;<span className="text-danger">*</span>
                        </label>{" "}
                        <br />
                        <input
                          className="add-Address-inputs"
                          placeholder="State"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <label className=" font14 add-customer-address">
                          Country&nbsp;<span className="text-danger">*</span>
                        </label>{" "}
                        <br />
                        <input
                          className="add-Address-inputs"
                          placeholder="Country"
                          name="country"
                          value={formData.country}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-6 pt-2">
                        <label className=" font14">
                          Zip &nbsp;<span className="text-danger">*</span>{" "}
                        </label>
                        <br />
                        <input
                          type="number"
                          className="add-Address-inputs"
                          placeholder="Zip"
                          name="zip"
                          value={formData.zip}
                          
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-4 d-flex">
                      <button
                        className="saveDraft-btn mx-1 mt-4  ml-1"
                        onClick={handleSave}
                        disabled={isLoading || !Object.values(formData).every(value => value.trim() !== "")}>
                        {isLoading ? (
                          <span>
                            <i className="spinner-border spinner-border-sm text-dark spin"></i>
                          </span>
                        ) : (
                          "Save"
                        )}
                      </button>
                      <button
                        className="CloseBtn  mt-4 ml-2"
                        onClick={handleCancelClick}>
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>

      ) : (
        ""
      )}
    </>
  );
}

export default AddaddressSideBar;
