import React from "react";
import ReactPaginate from "react-paginate";
import "./CustomerList.scss";
import { useLanguage } from "../../LanguageContext";
import { useState, useEffect } from "react";
import AddCustomer from "../../components/customer-sidebar/AddCustomer";
import { Link } from "react-router-dom";
import API from "../../services/Api";
import { ToastContainer } from "react-toastify";
import DeleteCust from "./DeleteCustomer";
import SkeletonLoader from "../../components/skeleton-loader/SkeletonLoader";
import CardSekeletonLoader from "../../components/skeleton-loader/CardSekeletonLoader";
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from "../../utils/storageKey";
import axios from 'axios';

const CustomerList = () => {
  const { t } = useLanguage();
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [openCustomerSidebar, setOpenCustomerSidebar] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deleteServiceRequestId, setDeleteServiceRequestId] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [departmentOptions, setDepartmentOptions] = useState()
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const maxLength = 20;
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfColumns = 9;

  const openDeleteConfirmation = (userId) => {
    setDeleteServiceRequestId(userId);
    setIsDeleteConfirmationVisible(true);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleClose = () => {
    setOpenCustomerSidebar(!openCustomerSidebar);
  };

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = data.map((item, index) => index);
      setCheckedItems(checkedItems);
    } else {
      setCheckedItems([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleSingleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, index]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let apiUrl = `${API.commerceAPI}/commerce/customer/all/GetByGroupId/${groupId}`;
      const params = {
        page: currentPage,
        limit: itemsPerPage
      };
      if (searchInput) {
        params.search = searchInput;
      }
      if (selectedPlatform) {
        params.source = selectedPlatform;
      }

      if (selectedLocation) {
        params.location = selectedLocation;
      }
      apiUrl += `?${new URLSearchParams(params).toString()}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const res = await response.json();
      setData(res.data?.items);
      setTotalCustomerCount(res.totalItemsCount);
      setPageCount(res.totalPages);
      setCheckedItems([]);
      setAllChecked(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOpenDropdown = (item) => {
    setShowDropdown(!showDropdown);
    setSelectedItem(item);
  };

  const handleOptionClick = (option, e) => {
    e.stopPropagation();
    if (option === "WhatsApp" && selectedItem?.phoneNumber) {
      const whatsappUrl = `https://web.whatsapp.com/send?phone=${selectedItem?.phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else if (option === "Email" && selectedItem?.email) {
      const emailUrl = `mailto:${selectedItem.email}`;
      window.location.href = emailUrl;
    } else {
      console.log(`Selected option "${option}" for item:`, selectedItem);
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    fetchData(searchInput);
  }, [groupId, searchInput, selectedPlatform, itemsPerPage, currentPage, selectedLocation]);

  useEffect(() => {
    fetch(`${API.commerceAPI}/commerce/category/all/getByGroupId/${groupId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const items = data?.data?.items;
        if (Array.isArray(items)) {
          const categories = items.map((item) => ({
            categoryId: item.categoryId,
            name: item.name,
          }));
          setCategoryOptions(categories);
        } else {
          console.error("Expected an array, but got:", items);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [groupId]);


  useEffect(() => {
    fetch(`${API.peopleManagentService}/peopleManagement/deparment/groupId/${groupId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const departments = data?.data?.items.map((item) => ({
            departmentId: item.departmentId,
            name: item.name,
          }));
          setDepartmentOptions(departments);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${API.commerceAPI}/commerce/leads/location/${groupId}`);
        if (response.data.status === 'Success') {
          setLocations(response.data.data.items.filter(item => item !== null));
        } else {
          setError('Failed to fetch locations');
        }
      } catch (error) {
        setError('Error fetching locations');
      }
    };

    fetchLocations();
  }, []);
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />

      <div className="main">
        <div className="body_container">
          <div className="main-padding">
            <div className="line-height">
              <p className="customer-request-title">{t("customer.title")}</p>
              <p className="sub-title">{t("customer.subtitle")}</p>
            </div>

            {loading ? (
              <CardSekeletonLoader />
            ) : (
              <div className="col-2 cardstyle-cust ">

                <p className="cardname text-center mt-1">
                  <span className="totalcustomercolor">Total Customers</span>
                </p>
                <p className="card-num text-center totalcustomercolor">{totalCustomerCount}</p>

              </div>
            )}

            <div className="select_flex pt-3 responsive-scroll-bar">
              <div className="dropdown_flex">
                <div className="lineheight0 cursor-pointer">
                  <div className=" ml-0 mt-2 ">
                    {/* <select className=" cursor-pointer select-year font12 dropdown_head-location">
                      <option className="dropdown_head">
                        {t("sidebarcustomers.Select Location")}
                      </option>
                    </select> */}
                    <select
                      className="cursor-pointer select-year-cust font14 select-month dropdown_head-location"
                      value={selectedLocation}
                      onChange={handleLocationChange}
                    >
                      <option className="dropdown_head" value="">
                        {t("sidebarcustomers.Select Location")}
                      </option>
                      {error && <option className="dropdown_head" disabled>{error}</option>}
                      {locations.map((location, index) => (
                        <option key={index} value={location}>
                          {truncateText(location, maxLength)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mx-3 font14 lineHeight0 cursor-pointer mt-2">
                  <select
                    className="select-month font14 cursor-pointer"
                    value={selectedPlatform}
                    onChange={handlePlatformChange}>
                    <option value="">
                      {" "}
                      {t("sidebarcustomers.Select Platform")}
                    </option>
                    <option value="app">App</option>
                    <option value="web">Web</option>
                  </select>
                </div>

                {tokenPermissions && tokenPermissions.includes(Permissions.MCS) && (
                  <div className=" font14 lineHeight0 cursor-pointer fix-input ml-2 mt-1">
                    <input
                      type="text"
                      placeholder={t("Customer.Name")}
                      className="cust-search-input border-bottom font14 dropdown_head"
                      value={searchInput}
                      onChange={handleSearchInputChange}>
                    </input>
                  </div>
                )}
              </div>

              <div className="delete-addnew-btn">
                {tokenPermissions && tokenPermissions.includes(Permissions.MC4) && (
                  <button
                    type="button"
                    className={`btn_disable cursor-pointer ${checkedItems?.length === 0 ? "light-button" : ""
                      }`}
                    onClick={() => openDeleteConfirmation(checkedItems._id)}
                    disabled={checkedItems?.length === 0}
                    style={{ width: "80px", height: "34px" }}>
                    {t("button.delete")}
                  </button>
                )}

                {tokenPermissions && tokenPermissions.includes(Permissions.MCAC1) && (
                  <button
                    type="button"
                    className="btn_primary-cust font14 cursor-pointer"
                    onClick={() => setOpenCustomerSidebar(!openCustomerSidebar)}>
                    {t("button.Add Customer")}
                  </button>
                )}

                {openCustomerSidebar && (
                  <AddCustomer
                    handleClose={handleClose}
                    fetchData={fetchData}
                    setOpenCustomerSidebar={setOpenCustomerSidebar}
                  />
                )}
              </div>
            </div>

            {isDeleteConfirmationVisible && (
              <DeleteCust
                data={data}
                onClose={() => setIsDeleteConfirmationVisible(false)}
                fetchData={fetchData}
                checkedItems={checkedItems}
                setIsDeleteConfirmationVisible={setIsDeleteConfirmationVisible}
              />
            )}

            <div className=" responsive-scroll-bar table-scroll-sr">
              <table className="table table-hover">
                <thead className="font14 theadstyle">
                  <tr className="dark-text-theme table-head">
                    <th className="checkbox-input">
                      <input
                        type="checkbox"
                        className="cursor-pointer "
                        name="check"
                        checked={allChecked}
                        onChange={handleAllChecked}
                      ></input>
                    </th>

                    <th
                      scope="col"
                      className="table-head">
                      {t("table.Id")}
                    </th>

                    <th
                      scope="col"
                      className="table-head "
                      style={{ width: "220px" }}>
                      {t("table.customerName")}
                    </th>

                    <th scope="col"
                      className="table-head">
                      {t("table.phoneNo")}
                    </th>

                    <th
                      scope="col"
                      className="table-location"
                      style={{ width: "150px" }}>
                      {t("table.location")}
                    </th>

                    <th
                      scope="col"
                      className="table-head "
                      style={{ width: "130px" }}>
                      {t("table.locate")}
                    </th>

                    <th
                      scope="col"
                      className="table-head text-center">
                      {t("table.Service Request")}
                    </th>

                    <th
                      scope="col"
                      className="table-head text-center">
                      {t("table.Order")}
                    </th>

                    <th
                      scope="col"
                      className="table-head font14">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>

                {loading ? (
                  <SkeletonLoader columns={numberOfColumns}/>
                ) : (
                  <tbody>
                    {(Array.isArray(data) && data?.length > 0) || data.e ? (
                      data.map((item, index) => (
                        <tr className="font14" key={index}>
                          <td className="t-data">
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              name="check"
                              checked={checkedItems.includes(index)}
                              onChange={(e) =>
                                handleSingleCheckboxChange(e, index)
                              }
                            />
                          </td>

                          <td className="t-data text-dark">
                            {item?.custId || "--"}
                          </td>

                          <td className="t-data capitalize-first-letter">
                            <Link
                              to={`/customer-details/${item?.userId}`}
                              className="text-decoration-none locate-color" >
                              {item?.name || "--"}
                            </Link>
                          </td>

                          <td className="t-data">{item.phoneNumber || "--"}</td>
                          <td className="t-data">{item.location || "--"}</td>
                          <td className="t-data locate-color text-center">
                            <a
                              href={`https://www.google.com/maps?q=${item.location}`}
                              className="locate-color"
                              target="_blank"
                            >
                              Google Map
                            </a>
                          </td>

                          <td className="t-data text-center">
                            {item?.serviceCount}
                          </td>

                          <td className="t-data text-center">
                            {item?.orderCount}
                          </td>

                          <td className="t-data font13 cursor-pointer">
                            <div className="dropdown">
                              <i
                                className="fas fa-ellipsis-h dot-icon"
                                id={`dropdownMenuButton${index}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => handleOpenDropdown(item)}>
                              </i>

                              <div
                                className="dropdown-menu"
                                aria-labelledby={`dropdownMenuButton${index}`}>
                                <button
                                  onClick={(e) => handleOptionClick("WhatsApp", e)}
                                  className={`dropdown-item font14 ${!selectedItem?.phoneNumber ? "disabled" : ""}`}>
                                  WhatsApp {t("table.action")}
                                </button>

                                <button
                                  onClick={(e) => handleOptionClick("Email", e)}
                                  className={`dropdown-item font14 ${!selectedItem?.email ? "disabled" : ""}`}>
                                  Email {t("table.action")}
                                </button>

                                <Link
                                  to={`/customer-details/${item?.userId}`}
                                  className="dropdown-item font14">
                                  View Customer {t("table.action")}
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))

                    ) : (
                      <div>
                        <img src="/no-data-image.png" alt="No data found"
                          className="no-data" />
                      </div>
                    )}
                  </tbody>
                )}
              </table>
            </div>

            {Array.isArray(data) && data?.length > 0 && (
              <div className="d-inline-flex pagination-container">
                <div className="col-12 p-0">
                  <ReactPaginate
                    className="pagination-bar"
                    previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                    nextLabel={<i className="fa-solid fa-angle-right"></i>}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={0}
                    onPageChange={onPageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName={'Pagination-products'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerList;