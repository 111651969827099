import React from 'react';
import AddSupplier from '../../components/add-supplier-sidebar/AddSupplier';

const CreateSupplier = () => {
  return (
    <div className="main">
      <div className="body_container">
        <div className="main-padding1">
          <AddSupplier />
        </div>
      </div>
    </div>

  )
}

export default CreateSupplier;