import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLanguage } from '../../LanguageContext';


const AddSuppliersSidebar = ({ supplier, supplierId, setSupplier, errors, handleClose, updateSupplierInfo, saveDataInfo }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useLanguage();
  const isUpdate = supplierId !== 'new';

  const handleSaveData = () => {
    if (!isSaving) {
      setIsSaving(true);
      saveDataInfo(supplierId)
        .then(() => {
          setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupplier((prevSupplier) => ({
      ...prevSupplier,
      [name]: value,
      setErrors: {
        ...prevSupplier.setErrors,
        [name]: '',
      },
    }));
  };

  const cancelSupplierProfile = () => {
    setSupplier({
      name: '',
      company: '',
      phoneNumber: '',
      address: '',
      email: '',
      aadharcard: '',
      pancard: '',
      passport: '',
      whatsapp: '',
      source: '',
      currency: '',
      paymentTerms: '',
      language: ''
    });
  };

  return (
    <div className='suppliers-sidebar'>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />

      <div className='suppliers-padding'>
        <h4 className='title-add'>
          {t(supplierId !== 'new' ? "AddSupplier.Update suppliers" : "AddSupplier.Add suppliers")}
        </h4>
        <div className='close-icon'>
          <i className='fa-solid fa-circle-xmark mr-1' onClick={handleClose}></i>
        </div>

        <hr className='suppliers-hr' />
        <div className='supplier-scrollar '>
          <div className='row'>
            <div className='col-6'>
              <div>
                <label className='mt-2 text-color'>{t('AddSupplier.Name')}&nbsp;<span className='text-danger'>*</span>
                  <i className="fa-solid fa-circle-info info-icons ml-2 " data-tooltip-id="my-tooltip-1" aria-hidden="true"></i> </label><br />
                <input
                  className={`inputess ${errors.name ? 'error-border' : ''}`}
                  type='text'
                  placeholder={t('AddSupplier.Name of the supplier')}
                  name='name'
                  value={supplier.name}
                  onChange={handleInputChange}
                ></input>
                {errors.name && <div className='validation-error pt-2'>{errors.name}</div>}
              </div><br />

              <br></br>
              <div className='pt-2'>
                <label className='text-color'>{t('AddSupplier.Company')}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  className={`inputess ${errors.company ? 'error-border' : ''}`}
                  type='text'
                  placeholder={t('AddSupplier.Name of the Company')}
                  name='company'
                  value={supplier.company}
                  onChange={handleInputChange}
                ></input>
                {errors.company && <div className='validation-error pt-2'>{errors.company}</div>}
              </div><br />

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.Phone Number')}&nbsp;<span className='text-danger'>*</span>
                  <i className="fa-solid fa-circle-info info-icons ml-2" data-tooltip-id="my-tooltip-3" aria-hidden="true"></i>
                </label><br />
                <input
                  className={`inputess ${errors.phoneNumber ? 'error-border' : ''}`}
                  type='text'
                  placeholder={t('AddSupplier.Phone Number')}
                  name='phoneNumber'
                  value={supplier.phoneNumber}
                  maxLength={10}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const onlyNumbers = enteredValue.replace(/[^0-9]/g, '');
                    updateSupplierInfo({ target: { name: 'phoneNumber', value: onlyNumbers } });
                  }}
                ></input>
              </div><br />
              {errors.phoneNumber && <span className='validation-error '>{errors.phoneNumber}</span>}

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.WhatsApp')}</label><span className='text-danger'>*</span><br />
                <input
                  className={`inputess ${errors.whatsapp ? 'error-border' : ''}`}
                  type='text'
                  placeholder={t('AddSupplier.WhatsApp')}
                  name='whatsapp'
                  value={supplier.whatsapp}
                  maxLength={10}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const onlyNumbers = enteredValue.replace(/[^0-9]/g, '');
                    updateSupplierInfo({ target: { name: 'whatsapp', value: onlyNumbers } });
                  }}
                ></input>
              </div><br />
              {errors.whatsapp && <span className='validation-error '>{errors.whatsapp}</span>}

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.Address')}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  className={`inputess ${errors.address ? 'error-border' : ''}`}
                  type='text'
                  placeholder={t('AddSupplier.Address')}
                  name='address'
                  value={supplier.address}
                  onChange={updateSupplierInfo}
                ></input>
              </div><br />
              {errors.address && <span className='validation-error '>{errors.address}</span>}

              <div className='pt-3'>
                <label className='text-color' >{t('AddSupplier.Email')}
                  <i className="fa-solid fa-circle-info info-icons ml-2" data-tooltip-id="my-tooltip-2" aria-hidden="true"></i>
                </label><br />
                <input
                  className={`inputess`}
                  type='email'
                  placeholder={t('AddSupplier.Email')}
                  name='email'
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  value={supplier.email}
                  onChange={updateSupplierInfo}
                ></input>
              </div><br />

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.Aadhar Card')}</label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.Aadhar Card')}
                  name='aadharcard'
                  value={supplier.aadharcard}
                  onChange={handleInputChange}
                  maxLength={12}
                ></input>
              </div><br />

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.GST No')}</label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.GST No')}
                  name='gstNo'
                  value={supplier.gstNo}
                  onChange={updateSupplierInfo}
                  maxLength={15}
                ></input>
              </div><br />
            </div>

            <div className='col-6'>
              <div className='mt-2'>
                <label className='text-color'>{t('AddSupplier.TIN No')}</label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.TIN No')}
                  name='tanNo'
                  value={supplier.tanNo}
                  onChange={updateSupplierInfo}
                  maxLength={10}
                ></input>
              </div><br />

              <div className='pt-4'>
                <label className='text-color'>{t('AddSupplier.CIN No')}</label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.CIN No')}
                  name='cinNo'
                  value={supplier.cinNo}
                  onChange={updateSupplierInfo}
                  maxLength={21}
                ></input>
              </div><br />

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.Source Of Supply')}</label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.Source Of Supply')}
                  name='source'
                  value={supplier.source}
                  onChange={updateSupplierInfo}
                ></input>
              </div><br />

              <div className='pt-3'>
                <label className='text-color'>{t('AddSupplier.Currency')}</label><br />
                <select
                  className={`inputess currency-supplier`}
                  name='currency'
                  onChange={handleInputChange} >
                  <option value='Indian Rupee'>{t('AddSupplier.Indian Rupees')}</option>
                  <option value='Euro'>{t('AddSupplier.Euro')}</option>
                  <option value='US Dollar'>{t('AddSupplier.US Dollar')}</option>
                  <option value='South African Rand'>{t('AddSupplier.South African Rand')}</option>
                  <option value='JPY- Japanese Yen'>{t('AddSupplier.JPY- Japanese Yen')}</option>
                  <option value='Saudi Riyal'>{t('AddSupplier.Saudi Riyal')}</option>
                  <option value='Pound Sterling'>{t('AddSupplier.Pound Sterling')}</option>
                  <option value='Yuan Renminbi'>{t('AddSupplier.Yuan Renminbi')}</option>
                  <option value='Canadian Dollar'>{t('AddSupplier.Canadian Dollar')}</option>
                  <option value='Australian Dollar'>{t('AddSupplier.Australian Dollar')}</option>
                  <option value='UAE Dirham'>{t('AddSupplier.UAE Dirham')}</option>
                </select>
              </div><br />

              <div className='pt-4'>
                <label className='text-color'>{t('AddSupplier.Payment Terms')}</label><br />
                <select
                  className={`inputess currency-supplier`}
                  name='paymentTerms'
                  onChange={handleInputChange}>
                  <option value=''>{t('AddSupplier.Select Payment Terms')}</option>
                  <option value='Due on Receipt'>{t('AddSupplier.Due on Receipt')}</option>
                </select>
              </div><br />

              <div className='pt-4'>
                <label className='text-color'>{t('AddSupplier.Portal Language')}</label><br />
                <select
                  className={`inputess currency-supplier`}
                  name='language'
                  onChange={handleInputChange}>
                  <option value=''>{t('AddSupplier.Select Language')}</option>
                  <option value='English'>{t('AddSupplier.English')}</option>
                  <option value='Spanish'>{t('AddSupplier.Spanish')}</option>
                  <option value='French'>{t('AddSupplier.French')}</option>
                  <option value='German'>{t('AddSupplier.German')}</option>
                  <option value='Chinese'>{t('AddSupplier.Chinese')}</option>
                  <option value='Japanese'>{t('AddSupplier.Japanese')}</option>
                  <option value='Arabic'>{t('AddSupplier.Arabic')}</option>
                  <option value='Russian'>{t('AddSupplier.Russian')}</option>
                  <option value='Hindi'>{t('AddSupplier.Hindi')}</option>
                  <option value='Portuguese'>{t('AddSupplier.Portuguese')}</option>
                  <option value='Bengali'>{t('AddSupplier.Bengali')}</option>
                  <option value='Marathi'>{t('AddSupplier.Marathi')}</option>
                </select>
              </div><br />

              <div className='pt-3'>
                <label className='text-color' >{t('AddSupplier.Pan Card')}
                  <i className="fa-solid fa-circle-info info-icons ml-2" data-tooltip-id="my-tooltip-4" aria-hidden="true"></i>
                </label><br />
                <input
                  className={`inputess`}
                  type='text'
                  placeholder={t('AddSupplier.Pan Card')}
                  name='pancard'
                  value={supplier.pancard}
                  onChange={handleInputChange}
                  maxLength={16}
                ></input>
              </div><br />
            </div>
          </div>
        </div>

        <div className=''>
          <button className={`save-supplier saves ${isSaving ? 'disabled' : ''}`} onClick={handleSaveData}>
            {isSaving ? isUpdate ? t("customer.Updating..") : t("intentCreate.Saving....") : isUpdate ? t("product.Update") : t("product.Save")}
          </button>

          <button className='cancel-supplier ml-2' onClick={cancelSupplierProfile}>{t('AddSupplier.Cancel')}</button>
        </div>
      </div>

      <ReactTooltip className="tooltip-style" id="my-tooltip-1" place="right" content={
        <div>
          The name you enter here
          <br />
          <br />will be for your primary
          <br />
          <br /> contact.
        </div>
      } style={{ height: "10%", width: "30%" }} />

      <ReactTooltip className="tooltip-style" id="my-tooltip-2" place="right" content={
        <div>
          This data will be stored
          <br />
          <br />without encryption
        </div>
      } style={{ height: "10%", width: "30%" }} />

      <ReactTooltip className="tooltip-style" id="my-tooltip-3" place="right" content={
        <div>
          This data will be
          <br /> <br />stored<br />
          <br /> without encryption
        </div>
      } style={{ height: "10%", width: "25%" }} />

      <ReactTooltip className="tooltip-style" id="my-tooltip-4" place="right" content={
        <div>
          This data will be
          <br />
          <br /> encrypted and stored.<br />  <br />
        </div>
      } style={{ height: "10%", width: "30%" }} />
    </div>
  )
};

export default AddSuppliersSidebar;