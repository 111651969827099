import React from 'react'
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import { useLanguage } from "../../LanguageContext";
import API from '../../services/Api';
import { Link } from 'react-router-dom';
import STORAGE_KEYS from '../../utils/storageKey';

const Leads = ({ employee }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [noRecordsMessage, setNoRecordsMessage] = useState("");
  const [data, setData] = useState([]);
  const [setShowDropdown] = useState(false);
  const [selectedItem] = useState(null);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 30;
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const empId = localStorage.getItem("loginEmpId");
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = allOrders.map((index) => index);
      setCheckedItems(checkedItems);
    } else {
      setCheckedItems([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleSingleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, index]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };
  const handleOptionClick = (option, e) => {
    e.stopPropagation();
    if (option === "WhatsApp" && selectedItem?.phoneNumber) {
      const whatsappUrl = `https://web.whatsapp.com/send?phone=${selectedItem.phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else if (option === "Email" && selectedItem?.email) {
      const emailUrl = `mailto:${selectedItem.email}`;
      window.location.href = emailUrl;
    } else {
      console.log(`Selected option "${option}" for item:`, selectedItem);
    }
    setShowDropdown(false);
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const headers = {
    Authorization: `${refreshToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    setLoading(true);

    fetch(`${API.commerceAPI}/commerce/leads/user/leads/${groupId}/${employee?.empId}`, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {

        if (data?.data) {
          setAllOrders(data?.data);
        } else {
          setNoRecordsMessage("No Data Found.");
          setData([]);
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupId, empId]);

  useEffect(() => {
    const totalItems = allOrders?.length;
    const pages = Math.ceil(totalItems / perPage);
    setPageCount(pages);
  }, [allOrders, perPage]);

  function addSpaceToStatus(status) {
    if (status === "Inprogress" || status === "Inprocess") {
      return status
        .replace("Inprogress", "In Progress")
        .replace("Inprocess", "In Process");
    } else {
      return status;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "#6ba2b4";
      case "inprogress":
        return "orange";
      case "cancelled":
        return "red";
      case "completed":
        return "#1BCB4B";
      case "dispatched":
        return "#df14df";
      case "intransit":
        return "gray";
      default:
        return "black";
    }
  };

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className="mt-3 table-scroll-sr service">
            <table className="table table-hover">
              <thead className="font14">
                <tr className="dark-text-theme table-head font14">
                  <th className="checkbox-input">
                    <input
                      type="checkbox"
                      className="cursor-pointer "
                      name="check"
                      checked={allChecked}
                      onChange={handleAllChecked}
                    ></input>
                  </th>

                  <th
                    scope="col"
                    className="font14"
                    style={{ width: "250px" }}>
                    {t("table.Name")}
                  </th>

                  <th
                    scope="col"
                    className="font14"
                    style={{ width: "160px" }}>
                    {t("table.Phone No")}
                  </th>

                  <th
                    scope="col"
                    className="font14">
                    {t("table.Source")}
                  </th>

                  <th
                    scope="col"
                    className="font14">
                    {t("table.Status")}
                  </th>

                  <th
                    scope="col"
                    className="font14">
                    {t("table.Location")}
                  </th>

                  <th
                    scope="col"
                    className="font14">
                    {t("table.Type")}
                  </th>

                  <th
                    scope="col"
                    className="font14"
                    style={{ width: "70px" }}>
                    {t("table.Action")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <div className="spinner-border table-loader" role="status"></div>
                    </td>
                  </tr>) :
                  (Array.isArray(allOrders) && allOrders?.length > 0) || allOrders.e ? (
                    allOrders
                      ?.slice(currentPage * perPage, (currentPage + 1) * perPage)
                      ?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="t-data font13">
                            <input
                              type="checkbox"
                              className="cursor-pointer "
                              name="check"
                              checked={checkedItems.includes(index)}
                              onChange={(e) =>
                                handleSingleCheckboxChange(e, index)
                              }
                            ></input>
                          </td>

                          <td
                            className="t-data text-dark cursor-pointer">
                            {item?.name || "--"}
                          </td>

                          <td className="t-data">
                            {item?.phoneNumber || "--"}
                          </td>

                          <td className="t-data">
                            {item?.source
                              ? item.source.toLowerCase() === "fb"
                                ? "Facebook"
                                : item.source.toLowerCase() === "ig"
                                  ? "Instagram"
                                  : item.source.toLowerCase() === "yt"
                                    ? "Instagram"
                                    : item.source.toLowerCase() === "wp"
                                      ? "Instagram"
                                      : item.source.toLowerCase() === "call"
                                        ? "Instagram"
                                        : item.source.charAt(0).toUpperCase() +
                                        item.source.slice(1)
                              : "--"}
                          </td>

                          <td
                            className="t-data font13 status-color">
                            {item?.status ? (
                              <span
                                style={{ color: getStatusColor(item?.status), fontWeight: "900" }}>
                                {addSpaceToStatus(
                                  item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)
                                )}
                              </span>
                            ) : (
                              "--"
                            )}
                          </td>

                          <td className="t-data locate-color">
                            {" "}
                            {item?.location ? item.location : "--"}
                          </td>

                          <td className="t-data">
                            {" "}
                            {item?.type
                              ? item.type.charAt(0).toUpperCase() +
                              item.type.slice(1)
                              : "--"}
                          </td>

                          <td className="t-data font13 cursor-pointer">
                            <div className="dropdown">
                              <i
                                className="fas fa-ellipsis-h dot-icon"
                                id={`dropdownMenuButton${index}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                              </i>

                              <div
                                className="dropdown-menu dropdown-style "
                                aria-labelledby={`dropdownMenuButton${index}`}>
                                <button
                                  onClick={(e) => handleOptionClick("WhatsApp", e)}
                                  className={`p-2 dropdown-item text-primary font13 ${!selectedItem?.phoneNumber ? "disabled" : ""}`}>
                                  WhatsApp
                                </button>

                                <button
                                  onClick={(e) => handleOptionClick("Email", e)}
                                  className={`p-2 dropdown-item font13 ${!selectedItem?.email ? "disabled" : ""}`}>
                                  Email
                                </button>

                                <Link to={`/customer-details/${item?.userId}`}>
                                  <button
                                    onClick={(e) => handleOptionClick("View Customer", e)}
                                    className="p-2 dropdown-item font13 text-primary">
                                    View Customer
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        {noRecordsMessage ? (
                          <img
                            src="./../no-data-image.png"
                            className="data-image"
                            alt="No Data Found"
                          />
                        ) : (
                          <img
                            src="./../no-data-image.png"
                            className="no-order"
                            alt="No Data Found"
                          />
                        )}
                      </td></tr>
                  )}
              </tbody>
            </table>

            {Array?.isArray(allOrders) && allOrders?.length > 0 && (
              <div className='d-inline-flex pagination-container'>
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={onPageChange}
                  containerClassName={"pagination "}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Leads;