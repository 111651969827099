import React, { useState } from "react";
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";
import STORAGE_KEYS from "../../utils/storageKey";

function KYCDetails({ openKycinfoSidebar, setOpenKycinfoSidebar, fetchEmployeeData, userId, userData }) {
  const [aadhar, setAadhar] = useState(userData?.aadhar || '');
  const [pan, setPan] = useState(userData?.pan || '');
  const [passportNo, setPassportNo] = useState(userData?.passportNo || '');
  const [aadharError, setAadharError] = useState('');
  const [panError, setPanError] = useState('');
  const [passportError, setPassportError] = useState('');
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLanguage();

  const handleClose = () => {
    setOpenKycinfoSidebar(!openKycinfoSidebar);
  };

  const handleSave = () => {
    setAadhar('');
    setPan('');
    setPassportNo('');
  };

  const handleAadharChange = (e) => {
    setAadhar(e.target.value);
    setAadharError('');
  };

  const handlePanChange = (e) => {
    let formattedPan = e.target.value.toUpperCase();
    formattedPan = formattedPan.replace(/[^A-Z0-9]/g, '');
    formattedPan = formattedPan.slice(0, 10);
    formattedPan = formattedPan.replace(/^([A-Z]{5})([0-9]{4})([A-Z]{1})$/, '$1$2$3');
    setPan(formattedPan);
    setPanError('');
  };

  const handlePassportChange = (e) => {
    setPassportNo(e.target.value);
    setPassportError('');
  };

  const validateInputs = () => {
    let isValid = true;

    const aadharStr = String(aadhar).trim();
    if (!aadharStr) {
      setAadharError("Aadhar Card number is required");
      isValid = false;
    } else if (!/^\d{12}$/.test(aadharStr)) {
      setAadharError("Aadhar Card number must be 12 digits");
      isValid = false;
    }

    if (!pan.trim()) {
      setPanError("Pan Card number is required");
      isValid = false;
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan.trim())) {
      setPanError("Invalid Pan Card Number");
      isValid = false;
    }
    return isValid;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    const data = {
      userId: parseInt(userId),
      aadhar,
      pan,
      passportNo

    };

    fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update customer information');
        }

        handleClose();
        setOpenKycinfoSidebar();
        fetchEmployeeData();
      })
      .catch((error) => {
        console.error('Error updating customer information:', error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="add-customerinfo-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0">
            <div className=" container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b>{t('Payment.KYC')}</b>
                </h6>
                <p className="font14  title-info pl-1">{t('KYC.details')}</p>
                <hr className="sr-hr-info  "></hr>
              </div>

              <div className="close-icon">
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={handleClose}
                ></i>
              </div>
            </div>

            <div className="sidebar-wrapper pl-3">
              <form className=" font14 ml-1">
                <div>
                  <label className="font14 ">
                    {t('Payment.Aadhar Card')}<span className="text-danger mt-1">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${aadharError ? 'error-border' : ''}`}
                    placeholder={t('Payment.Aadhar Card')}
                    value={aadhar}
                    onChange={handleAadharChange}
                    maxLength="12"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '').slice(0, 12);
                    }}
                  />
                  {aadharError && <div className="text-danger mt-2 font14">{aadharError}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-3">
                    {t('Payment.Pan Card')}<span className="text-danger mt-1">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${panError ? 'error-border' : ''}`}
                    placeholder={t('Payment.Pan Card')}
                    value={pan}
                    onChange={handlePanChange}
                  />
                  {panError && <div className="text-danger mt-2 font14">{panError}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-3">
                    {t('Payment.Passport')}
                  </label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs pl-2"
                    placeholder={t('Payment.Passport')}
                    value={passportNo}
                    onChange={handlePassportChange}
                  />
                </div>
              </form>
            </div>

            <div className="mt-3 pl-3 set-button">
              <button className="saveDraft-btn mt-4" onClick={handleUpdate}>
                {isLoading ? (
                  <i className="spinner-border spinner-border-sm  spin"></i>
                ) : (
                  t('Personal.Update'))}
              </button>
              <button className="cancel-info-btn ml-2" onClick={handleSave} >
                {t('Personal.Cancel')}
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default KYCDetails;