import React, { useEffect, useState } from 'react';
import STORAGE_KEYS from '../../utils/storageKey';

const JwtTokenDecodeService = () => {
  const [refreshToken, setRefreshToken] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);

  const decodeToken = () => {
    let refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
    try {
      const base64Url = refreshToken?.split('.')[1];

      if (base64Url) {
        const paddedBase64 = base64Url + '=='.slice(0, (4 - base64Url?.length % 4) % 4);
        const base64 = paddedBase64.replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = JSON.parse(atob(base64));
        setRefreshToken(refreshToken);
        setDecodedToken(decodedPayload);
        localStorage.setItem('decodedToken', JSON.stringify(decodedPayload));
        const permissionsString = JSON.stringify(decodedPayload?.role?.permissions || []);
        localStorage.setItem('permissions', permissionsString);
      } else {
        console.error('Error decoding JWT: Base64 URL is undefined');
      }
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <div>
    </div>
  );
};

export default JwtTokenDecodeService;