import React, { useEffect, useState } from 'react';
import { EmployeeHeader } from '../../components/header/Header';
import "./Employee.scss";
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import EmployeePopup from './EmployeePopup';
import API from '../../services/Api';
import { ToastContainer} from 'react-toastify';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const Employee = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const defaultImageUrl = "/images/defualt-image.png";
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigation = useNavigate();
  const { t } = useLanguage();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [userIdToDelete, setUserIdToDelete] = useState([]);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [reverseEmployee, setReverseEmployee] = useState(false);
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const fetchEmployee = async () => {
    let apiUrl = `${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`;
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        setLoading(false);
        throw new Error('Network response was not ok');
      }
      const resp = await response.json();
      const items = resp?.data?.items || [];
      setEmployee(items);
      setLoading(false);
      setEmployeeList(items);
      filterEmployees(items, searchQuery);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filterEmployees = (employees, query) => {
    const filtered = employees.filter((emp) => {
      const name = emp.name && emp.name.toLowerCase().includes(query.toLowerCase());
      const phoneNumber = emp.phoneNumber && emp.phoneNumber.toString().toLowerCase().includes(query.toLowerCase());
      const ID = emp.roleId && emp.roleId.toString().toLowerCase().includes(query.toLowerCase());
      return name || phoneNumber || ID;
    });
    setFilteredEmployee(reverseEmployee ? filtered.reverse() : filtered);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    filterEmployees(employeeList, searchQuery);
  }, [searchQuery, reverseEmployee]);

  const offset = currentPage * itemsPerPage;
  const currentData = Array.isArray(filteredEmployee) ? filteredEmployee.slice(offset, offset + itemsPerPage) : [];

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const onColumnClick = (userId) => {
    navigation(`/create-employee/${userId}`);
  };

  useEffect(() => {
    const getZoomLevel = () => {
      return Math.round((window.outerWidth / window.innerWidth) * 100);
    };
    const updateItemsPerPage = () => {
      const zoomLevel = getZoomLevel();
      if (zoomLevel === 100) {
        setItemsPerPage(10);
      } else if (zoomLevel <= 90) {
        setItemsPerPage(10);
      }
    };
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const openDeleteConfirmation = (selectedUserId) => {
    setUserIdToDelete([selectedUserId]);
    setIsDeleteConfirmationVisible(true);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text.substring(0, maxLength) + "...";
  };

  const handleReverseEmployee = () => {
    setReverseEmployee(!reverseEmployee);
  };

  return (
    <div className="main">
      <ToastContainer />

      <div className="body_container">
        <div className="main-padding1">
          <EmployeeHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <hr className='hr-line-1' />
          <div className="row m-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {loading ? (
                <div className='spinn'>
                  <div className='spinner-border'></div>
                </div>
              ) : (
                <div className=''>
                  {currentData?.length === 0 ? (
                    <img src='..\no-data-image.png' className='emp-not-found' alt='no-data' />
                  ) : (
                    <div className="grid-container employee-list-scroll">
                      {currentData.map((emp, index) => (
                        <div key={index} className=" card card-employee">
                          <div className="d-inline-flex">
                            <Link to={`/employee-details/${emp?.userId}`}>
                              <img
                                src={emp?.imageUrl || defaultImageUrl}
                                alt="Employee Image"
                                className="image-emp"
                              />
                            </Link>
                          </div>
                          <div className="d-inline-flex">
                            <p className='emp-name'>{truncateText(emp.name, 20)}</p>
                          </div>

                          <div className='d-inline-flex'>
                            <p className="emp-designation">{emp?.roleId}</p>
                          </div>

                          <div className='card-emp pt-1 p-1'>

                            <h6 className="d-inline-flex emp-number-em" >
                              <i className="fa-solid fa-phone m-1"></i><span className='phone-fa'>{emp?.phoneNumber}</span>
                            </h6>
                            <div className=''>
                              <h6 className="d-inline-flex emp-number-em" data-tooltip-id={`tooltip-${emp._id}`}>
                                <i className="fa-regular fa-envelope m-1"></i>
                                {emp?.email ? <span className='email-fa'>{truncateText(emp.email, 16)}</span> : ''}
                              </h6>
                            </div>

                            <div className='d-inline-flex me-5 emp-e-d'>
                              <h6>
                                <i className="fa-solid fa-pencil emp-edit pt-1"
                                  onClick={() => onColumnClick(emp?.userId)} />

                                <i className="fa-solid fa-trash ware-trash-2 ml-1 pt-1"
                                  onClick={() => openDeleteConfirmation(emp.userId)} />
                              </h6>
                            </div>

                            {isDeleteConfirmationVisible && (
                              <EmployeePopup
                                userId={userIdToDelete}
                                onClose={() => setIsDeleteConfirmationVisible(false)}
                                fetchEmployee={fetchEmployee}
                                setIsDeleteConfirmationVisible={setIsDeleteConfirmationVisible}
                              />
                            )}
                          </div>

                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="d-inline-flex pagination-container-emp">
              <div className="col-12 p-0">
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={'...'}
                  pageCount={Math.ceil(filteredEmployee?.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={onPageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'Pagination-products'}
                />
              </div>

              <div className='col-5'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;