import React from "react";
import { BrowserRouter } from "react-router-dom";
import RouteSettings from "./route";
import { AppProvider } from "./state-management/AppContext";
import { useTheme } from './ThemeContext';

function App() {
  const { theme } = useTheme();
  return (
    <div className={`app ${theme}-theme`}>
    <BrowserRouter>
      <AppProvider>
        <RouteSettings />
        
      </AppProvider>
    </BrowserRouter>
     </div>
   
  );
}

export default App;
