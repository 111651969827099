import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import { useLanguage } from '../../LanguageContext';
import { useTheme } from '../../ThemeContext';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./Sidemenu.scss";
import API from "../../services/Api";
import LogoutPopup from "./LogoutPopup";
import JwtTokenDecodeService from "../jwttoken-decode-service/JwtTokenDecodeService";
import Permissions from "../jwttoken-decode-service/Permission";
import STORAGE_KEYS from "../../utils/storageKey";

function LeftSideMenu() {
  const [isNotActive, setNotActive] = useState(true);
  const { toggleTheme } = useTheme();
  const { setLanguage, t } = useLanguage();
  const [isDropdownActive, setDropdownActive] = useState(true);
  const [selectedText, setSelectedText] = useState(null);
  const [isLangDropdownActive, setIsLangDropdownActive] = useState()
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [loginUser, setLoginUser] = useState()
  const defaultProfile = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDvuO6Rt_aRqhdDf8ZJeYJoe_HxoTszWo4Sg-dXWQ&s`
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const userId = localStorage.getItem(STORAGE_KEYS.USERID);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [newOrderStatus, setNewOrderStatus] = useState(null);
  const appName = localStorage.getItem(STORAGE_KEYS.SELECTED_GROUP_NAME);
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION)  
  const [showExitPopup, setShowExitPopup] = useState(false);
  const img = "./ionic-ios-exit.jpg";
  const refreshToken = localStorage.getItem('refresh-token')

  const TruncateText = ({ text, maxLength }) => {
    if (text?.length <= maxLength) {
      return <span>{text}</span>;
    }
    const truncatedText = `${text?.slice(0, maxLength)}...`;
    return <span title={text}>{truncatedText}</span>;
  };

  const handleTextClick = (text) => {
    setSelectedText(text);
  };

  const handleExit = () => {
    setShowExitPopup(true);
  };

  const handleLogout = () => {
    console.log("Logout logic goes here");
    setShowExitPopup(false);
  };

  const onClose = () => {
    setShowExitPopup(false);
  };

  const [isDropdownActiveNow, setDropdownActiveNow] = useState(true);
  const toggleLanguageDrop = () => {
    setDropdownActiveNow(!isDropdownActiveNow)
  };

  const toggleDropdownActive = () => {
    setDropdownActive(!isDropdownActive)
  };

  const toggleLanguageDropdown = () => {
    setIsLangDropdownActive(!isLangDropdownActive)
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [isSideOpen, setSideOpen] = useState(true);
  const toggleSidebarOpen = () => {
    setSideOpen(!isSideOpen);
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    toggleLanguageDropdown();
    setSelectedLanguage(e.target.value);
  };

  useEffect(() => {
    fetch(
      `${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`,
      {
        headers: {
          Authorization: `${refreshToken}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoginUser(data?.data?.items)
        const loginEmp = data?.data?.items[0]?.empId;
        localStorage.setItem('loginEmpId', JSON.stringify(loginEmp));
      })
      .catch((error) => {
        console.error("API request error:", error);
      });
  }, [userId])

  useEffect(() => {
    const storedOrderValue = localStorage.getItem("newOrderStatus");
    if (storedOrderValue !== null) {
      try {
        const parsedOrderValue = JSON.parse(storedOrderValue);
        if (
          parsedOrderValue !== undefined &&
          parseInt(parsedOrderValue) !== 0
        ) {
          setNewOrderStatus(parsedOrderValue);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  return (
    <div className={`container-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <JwtTokenDecodeService />
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <span className={isNotActive ? "" : "hidden"}>
              <span className="me-1 title-style group-name">
                <TruncateText text={appName} maxLength={25} />
              </span><img
                src="../../images/exiticon12.png" className="close-sidebar mx-2 font25" height="20" />
            </span>
          ) : (
            <span className={isNotActive ? "active" : ""}><b>{appName.charAt(0)}</b></span>
          )}
        </button>

        <ul className="list-unstyled wrapper">
        <li className="list-item">
            <NavLink
              to="/dashboard">
              <img src="../../icons8-dashboard-new.png"
                data-tooltip-id="my-tooltip-77"
                height="22"
              />
            </NavLink>

            {isSidebarOpen && (
              <span>
                <NavLink
                  to="/dashboard"
                  className="pl-2 ml-2 text-decoration crm-menu font16">
                  {t("sidebar.Dashboard")}{" "}
                </NavLink>
              </span>
            )}
          </li><br />

           {/* {tokenPermissions && tokenPermissions.includes(Permissions.MORM2) && (  */}
          <li className="list-item">
            <NavLink
              to="/orders">
              <img src="../../images/orders.png"
                data-tooltip-id="my-tooltip-77"
                height="22"
              />
            </NavLink>

            {isSidebarOpen && (
              <span>
                <NavLink
                  to="/orders"
                  className="pl-2 ml-2 text-decoration crm-menu font16">
                  {t("sidebar.orders")}{" "}
                  {newOrderStatus && `(${newOrderStatus})`}
                </NavLink>
              </span>
            )}
          </li><br/>
           {/* )}  */}

          {tokenPermissions && tokenPermissions.includes(Permissions.MIM2) && (
            <li className="list-item">
              <NavLink
                to="/inventory">
                <img src="../../images/inventory.png"
                  height="22"
                  data-tooltip-id="my-tooltip-13"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/inventory"
                  className="pl-3 role-ico  text-decoration crm-menu font16">
                  {t('sidebar.inventory')}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MSTM2) && (
            <li className="list-item">
              <NavLink
                to="/stock-transfer">
                <img src="../../images/transfer-3819.png"
                  height="22"
                  data-tooltip-id="my-tooltip-92"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/stock-transfer"
                  className="pl-3 role-ico  text-decoration crm-menu font16">
                  {t('STO.Stock Transfer')}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MIM2) && (
            <li className="list-item">
              <NavLink
                to="/products">
                <img src="../../images/cart.png"
                  height="22"
                  data-tooltip-id="my-tooltip-14"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/products"
                  className="pl-3 text-decoration crm-menu font16">
                  {t('sidebar.products')}
                </NavLink>
              </span>}
            </li>
          )}<br />

          {tokenPermissions && tokenPermissions.includes(Permissions.MITM2) && (
            <li className="list-item">
              <NavLink
                to="/intents">
                <img src="../../images/intent.png"
                  height="22"
                  data-tooltip-id="my-tooltip-90"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/intents"
                  className="pl-3 text-decoration crm-menu font16">
                  {t('sidebar.RFQ')}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MPOM2) && (
            <li className="list-item">
              <NavLink
                to="/purchase-order">
                <img src="../../images/quotation.png"
                  height="22"
                  data-tooltip-id="my-tooltip-91"
                />
              </NavLink>
              {isSidebarOpen && <span >
                <NavLink
                  to="/purchase-order"
                  className="pl-3 text-decoration font16 crm-menu">
                  {t("sidebar.Purchase Order")}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MCM2) && (
            <li className="list-item">
              <NavLink
                to="/customers">
                <img src="../../images/customers.png"
                  height="22"
                  data-tooltip-id="my-tooltip-9"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/customers"
                  className="pl-3 text-decoration font16 crm-menu">
                  {t('sidebar.customer')}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MEM2) && (
            <li className="list-item">
              <NavLink
                to="/employee"  >
                <img src="../../images/employee.png"
                  data-tooltip-id="my-tooltip-10"
                  height="22"
                />
              </NavLink>
              {isSidebarOpen && <span >
                <NavLink
                  to="/employee"
                  className="pl-3 text-decoration font16 crm-menu">
                  {t('sidebar.employee')}
                </NavLink>
              </span>}
            </li>
          )}<br />

          {tokenPermissions && tokenPermissions.includes(Permissions.MRM2) && (
            <li className="list-item">
              <NavLink
                to="/service">
                <img src="../../images/service request.png"
                  height="22"
                  data-tooltip-id="my-tooltip-12"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/service"
                  className="pl-3 role-ico  text-decoration crm-menu font16">
                  {t('sidebar.service')}
                </NavLink>
              </span>}
            </li>
          )}<br />

          {tokenPermissions && tokenPermissions.includes(Permissions.MRM2) && (
            <li className="list-item">
              <NavLink
                to="/package">
                <img src="../../images/packages-card.png"
                  height="23"
                  data-tooltip-id="my-tooltip-12"
                />
              </NavLink>
              {isSidebarOpen && <span>
                <NavLink
                  to="/package"
                  className="pl-3 role-ico  text-decoration crm-menu font16">
                  {t('sidebar.Packages')}
                </NavLink>
              </span>}
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MMDM2) && (
            <li className="list-item">
              <img src="../../images/masterdata.png"
                alt=""
                height={22}
                width={22}
                data-tooltip-id="my-tooltip-93">
              </img>

              {isSidebarOpen && <span className="pl-0">
                <NavLink
                  href="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle text-decoration font16 crm-menu pl-3"
                  onClick={() => setDropdownActive(!isDropdownActive)}>
                  {t('sidebar.masterdata')}
                </NavLink>
              </span>}

              <ul
                className={isDropdownActive ? "list-unstyled collapse " : "list-unstyled"}
                id="homeSubmenu" >
                {tokenPermissions && tokenPermissions.includes(Permissions.MWM2) && (
                  <li className="list-item">
                    {isSidebarOpen && <span>
                      <NavLink
                        to="/warehouse"
                        className="pl-3 text-decoration crm-menu font16 "
                        onClick={() => handleTextClick(t("warehouse"))}
                      >{t('sidebar.warehouse')}
                      </NavLink>
                    </span>}
                  </li>
                )}

                {tokenPermissions && tokenPermissions.includes(Permissions.MPOM2) && (
                  <li className="list-item">
                    {isSidebarOpen && <span>
                      <NavLink
                        to="/category"
                        className="pl-3 text-decoration crm-menu font16"
                        onClick={() => handleTextClick(t("category"))}>
                        {t('sidebar.category')}
                      </NavLink>
                    </span>}
                  </li>
                )} 

                {tokenPermissions && tokenPermissions.includes(Permissions.MSM2) && (
                  <li className="list-item">
                    {isSidebarOpen && <span>
                      <NavLink
                        to="/supplier"
                        className="pl-3 text-decoration crm-menu font16"
                        onClick={() => handleTextClick(t("supplier"))}>
                        {t('sidebar.supplier')}
                      </NavLink>
                    </span>}
                  </li>
                )}

                {tokenPermissions && tokenPermissions.includes(Permissions.MDM2) && (
                  <li className="list-item">
                    {isSidebarOpen && <span>
                      <NavLink
                        to="/delivery-page"
                        className="pl-3 text-decoration crm-menu font16"
                        onClick={() => handleTextClick(t("Delivery-page"))}>
                        {t('sidebar.delivery-page')}
                      </NavLink>
                    </span>}
                  </li>
                )}

                <li className="list-item">
                  {isSidebarOpen && <span>
                    <NavLink
                      to="/terms-conditions"
                      className="pl-3 text-decoration crm-menu font16"
                      onClick={() => handleTextClick(t("Delivery-page"))}>
                      {t('sidebar.termsCondition')}
                    </NavLink>
                  </span>}
                </li>
              </ul>
            </li>
          )}

          {tokenPermissions && tokenPermissions.includes(Permissions.MRM2) && (
            <li className="list-item">
              <img src="../../images/reports.png"
                alt=""
                height={22}
                width={22}
                data-tooltip-id="my-tooltip-95">
              </img>

              {isSidebarOpen && <span className="pl-0">
                <NavLink
                  href="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle text-decoration font16 crm-menu pl-3"
                  onClick={() => setDropdownActiveNow(!isDropdownActiveNow)}>
                  {t("sidebar.reports")}
                  {newOrderStatus && `(${newOrderStatus})`}
                </NavLink>
              </span>}

              <ul
                className={isDropdownActiveNow ? "list-unstyled collapse " : "list-unstyled"}
                id="homeSubmenu" >

                <li className="list-item">
                  {isSideOpen && <span>
                    <NavLink
                      to="/orders-report"
                      className="pl-3 text-decoration crm-menu font16 "
                      onClick={() => handleTextClick(t("warehouse"))}
                      style={{
                        fontWeight: selectedText === t("warehouse") ? 'bold' : 'normal',
                      }}> {t("sidebar.order")}{" "}
                    </NavLink>
                  </span>}
                </li>

                <li className="list-item">
                  {isSideOpen && <span>
                    <NavLink
                      to="/sale-profit"
                      className="pl-3 text-decoration crm-menu font16"
                      onClick={() => handleTextClick(t("category"))}
                      style={{
                        fontWeight: selectedText === t("category") ? 'bold' : 'normal',
                      }}>
                      {t('sidebar.saleprofit')}
                    </NavLink>
                  </span>}
                </li>
              </ul>
            </li>
          )}
        </ul>

        {
          isSidebarOpen ? (
            <div className="pl-1z mt-3">
              {loginUser &&
                loginUser.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {isSidebarOpen && (
                        <span>
                          <Link className="m-0 p-0 text-decoration mx-1">
                            <img
                              className="rounded-circle"
                              width={25}
                              height={25}
                              alt="img"
                              src={item?.profileImage || defaultProfile}
                            />
                          </Link>
                        </span>
                      )}
                      {isSidebarOpen && (
                        <span>
                          <label
                            className="text-decoration mx-1 overflow-ellipsis font14 cursor-pointer"
                            title={item?.name}>
                            {item?.name ? item.name : "\u00A0"}
                          </label>
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}


              <NavLink
                to="/configuration-list">
                <svg
                  className="footer-icon mx-2"
                  data-tooltip-id="my-tooltip-17"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 22.971 21.905" >
                  <path
                    id="Icon_ionic-ios-settings"
                    data-name="Icon ionic-ios-settings"
                    d="M24.074,13.95a2.826,2.826,0,0,1,1.9-2.629,10.891,10.891,0,0,0-1.418-3.256,3.12,3.12,0,0,1-1.2.245,3.035,3.035,0,0,1-2.088-.821,2.724,2.724,0,0,1-.61-3.137A12,12,0,0,0,17.243,3a3.007,3.007,0,0,1-5.515,0A12.038,12.038,0,0,0,8.312,4.352,2.716,2.716,0,0,1,7.7,7.488a3.066,3.066,0,0,1-3.29.576A11.129,11.129,0,0,0,3,11.326a2.772,2.772,0,0,1,.006,5.258,10.891,10.891,0,0,0,1.418,3.256,3.107,3.107,0,0,1,1.2-.24,3.035,3.035,0,0,1,2.088.821,2.724,2.724,0,0,1,.61,3.131,12.108,12.108,0,0,0,3.416,1.352,3,3,0,0,1,5.5,0,12.038,12.038,0,0,0,3.416-1.352,2.726,2.726,0,0,1,.61-3.131,3.014,3.014,0,0,1,2.088-.821,3.085,3.085,0,0,1,1.2.24,10.954,10.954,0,0,0,1.418-3.256A2.838,2.838,0,0,1,24.074,13.95Zm-9.535,4.557a4.677,4.677,0,0,1-4.786-4.562,4.677,4.677,0,0,1,4.786-4.562,4.677,4.677,0,0,1,4.786,4.562A4.677,4.677,0,0,1,14.539,18.507Z"
                    transform="translate(-3 -3)"
                    fill="#989898"
                  />
                </svg>
              </NavLink>

              {isSidebarOpen && (
                <span>
                  <svg
                    className="footer-icon mx-1"
                    data-tooltip-id="my-tooltip-4"
                    onClick={toggleLanguageDropdown}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.566"
                    height="20.566"
                    viewBox="0 0 20.566 20.566"  >
                    <path
                      id="Icon_metro-earth"
                      data-name="Icon metro-earth"
                      d="M12,1.285A10.283,10.283,0,1,0,22.279,11.568,10.283,10.283,0,0,0,12,1.285Zm0,19.281a8.967,8.967,0,0,1-3.559-.732l4.682-5.268a.643.643,0,0,0,.162-.427V12.211a.643.643,0,0,0-.643-.643c-2.269,0-4.664-2.359-4.687-2.382A.643.643,0,0,0,7.5,9H4.927a.643.643,0,0,0-.643.643V13.5a.643.643,0,0,0,.355.575l2.215,1.108v3.773a9,9,0,0,1-2.99-11.24H6.213a.643.643,0,0,0,.454-.188L9.238,4.953A.643.643,0,0,0,9.426,4.5V2.944a9.023,9.023,0,0,1,6.513.534c-.083.07-.164.144-.242.222a3.857,3.857,0,0,0,2.724,6.583q.1,0,.191,0a15.092,15.092,0,0,1-.169,7.478.641.641,0,0,0-.017.1,8.969,8.969,0,0,1-6.43,2.7Z"
                      transform="translate(-1.714 -1.285)"
                      fill="#989898"
                    />
                  </svg>
                </span>
              )}

              {isLangDropdownActive && (
                <ol onChange={handleLanguageChange} className="lang-style">
                  <div className="select-language">
                    <h6 className="mt-2">Select Language</h6>
                    <hr></hr>
                    <div className="pl-3 pt-1">
                      <label className="select-lang cursor-pointer">
                        <input
                          type="radio"
                          className="cursor-pointer"
                          name="language"
                          value="english"
                          checked={selectedLanguage === "english"}
                        />
                        English
                      </label>

                      <label className="select-lang cursor-pointer">
                        <input
                          type="radio"
                          className="cursor-pointer"
                          name="language"
                          value="marathi"
                          checked={selectedLanguage === "marathi"}
                        />
                        Marathi
                      </label>
                    </div>
                  </div>
                </ol>
              )}

              {isSidebarOpen && (
                <span>
                  <svg
                    className="footer-icon mx-1"
                    data-tooltip-id="my-tooltip-5"
                    onClick={toggleTheme}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 21.25 21.25" >
                    <g
                      id="Group_1"
                      data-name="Group 1"
                      transform="translate(-0.001 0)">
                      <path
                        id="Icon_awesome-circle"
                        data-name="Icon awesome-circle"
                        d="M11,.375A10.625,10.625,0,1,0,21.625,11,10.623,10.623,0,0,0,11,.375Z"
                        transform="translate(-0.374 -0.375)"
                      />
                      <path
                        id="Icon_awesome-circle-2"
                        data-name="Icon awesome-circle"
                        d="M11,11.644C6.4,7.679.375,5.783.375,11.644a10.625,10.625,0,0,0,21.25,0C21.625,5.783,15.6,15.609,11,11.644Z"
                        transform="translate(-0.374 -1.004)"
                        fill="#c9c9c9"
                      />
                    </g>
                  </svg>
                </span>
              )}

              <span>
                <svg
                  onClick={handleExit}
                  className="footer-icon mx-2 mt-1 cursor-pointer"
                  data-tooltip-id="my-tooltip-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.28"
                  height="20.566"
                  viewBox="0 0 19.28 20.566">
                  <path
                    id="Icon_metro-exit"
                    data-name="Icon metro-exit"
                    d="M17.138,14.139V11.568H10.711V9h6.427V6.427l3.856,3.856Zm-1.285-1.285v5.141H9.426v3.856L1.714,17.995V1.285H15.853V7.712H14.567V2.571H4.285L9.426,5.141V16.71h5.141V12.854Z"
                    transform="translate(-1.714 -1.285)"
                  />
                </svg>
                {showExitPopup && <LogoutPopup handleLogout={handleLogout} onClose={onClose} />}
              </span>
            </div>

          ) : (
            <div className="mb10">
              <div>
                <img
                  src=" ../../images/setting.png"
                  className="footer-icon mx-3"
                  data-tooltip-id="my-tooltip-17">
                </img>
              </div>

              <div className="mt-2">
                <NavLink to='/'>
                  <img
                    src="../../images/exit.png"
                    className="footer-icon mx-3 mt-2"
                    data-tooltip-id="my-tooltip-6" >
                  </img>
                </NavLink>
              </div>
            </div>
          )
        }
      </div>

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-1"
        place="right"
        content="Home"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-2"
        place="right"
        content="Authorization"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-3"
        place="right"
        content="Master-Data"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-7"
        place="right"
        content="Warehouse"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-8"
        place="right"
        content="Category"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-9"
        place="right"
        content="Customer"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-10"
        place="right"
        content="Employee"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-11"
        place="right"
        content="Group"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-12"
        place="right"
        content="Service"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-13"
        place="right"
        content="Inventory"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-14"
        place="right"
        content="Product"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-15"
        place="right"
        content="Delivery Code"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-16"
        place="right"
        content="Supplier"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-77"
        place="right"
        content="Order"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-4"
        place="top"
        content="Select Language"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-5"
        place="top"
        content="Theme"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-17"
        place="top"
        content="Setting"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-6"
        place="top"
        content="Exit"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-90"
        place="top"
        content="Intent"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-91"
        place="top"
        content="Purchase Order"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-92"
        place="top"
        content="Stock Transfer"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-93"
        place="top"
        content="Masterdata"
      />

      <ReactTooltip
        className="tooltip-style"
        id="my-tooltip-95"
        place="top"
        content="Reports"
      />
    </div>
  )
}
export default LeftSideMenu;