import './TripInfo.scss';
import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from "../../LanguageContext";
import API from '../../services/Api';
import 'leaflet/dist/leaflet.css'; 
import STORAGE_KEYS from '../../utils/storageKey';

const TripInfo = () => {
    const [tripData, setTripData] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [destinationLatLong, setDestinationLatLong] = useState([]);
    const params = useParams();
    const tripId = params.tripId;
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1);
    };

    useEffect(() => {
        axios.get(`${API.peopleManagentService}/peopleManagement/trip/get/trips/${groupId}/${tripId}`)
            .then(response => {
                setDestinationLatLong(response?.data?.trip?.destinationLatLong);
                setTripData(response?.data?.trip);
            })
            .catch(error => {
                console.error('Error fetching destination:', error);
            });

        navigator.geolocation.getCurrentPosition(
            position => {
                setCurrentLocation([position?.coords?.latitude, position?.coords?.longitude]);
            },
            error => {
                console.error('Error fetching current location:', error);
            }
        );
    }, [groupId, tripId]);

    useEffect(() => {
        if (destinationLatLong?.length === 0) return;

        const map = L.map('map');
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);

        const polyline = L.polyline(destinationLatLong, { color: 'red' }).addTo(map);

        if (currentLocation) {
            L.marker(currentLocation, { icon: blueIcon }).addTo(map).bindPopup('You are here');
        }

        map.fitBounds(polyline.getBounds());

        return () => {
            map.remove();
        };
    }, [destinationLatLong, currentLocation]);

    const blueIcon = L.icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    return (
        <div className="trip-info-container">
            <div className='header-map'>
                <div className='row'>
                    <div className='col'>
                        <h2 className='m-3'>{tripData && `${tripData?.from} to ${tripData?.destination}`}</h2>
                    </div>

                    <div className='col-1'>
                        <i
                            className="fa-solid fa-circle-xmark cursor-pointer close-trip  mt-3"
                            onClick={handleClose}
                        ></i>
                    </div>
                </div>

                <div>
                    <div>
                        <p className='ml-2'>{t("table.Distance")}: {tripData?.distance}</p>
                        <p className='ml-2'>{t("table.Duration")}: {tripData?.duration?.hours} {t("table.hours")} {tripData?.duration?.minutes} {t("table.minutes")} {tripData?.duration?.seconds} {t("table.seconds")}</p>
                    </div>
                </div>
            </div>
            
            <hr />
            <div className="mt-3 table-scroll-sr ">
                <div id="map" style={{ height: '100vh', width: '100%' }}>map</div>
            </div>
        </div>
    );
}

export default TripInfo;