import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";
import API from './services/Api';

const SignIn = () => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    const groupIdParam = searchParams.get('groupId');

    if (tokenParam && groupIdParam) {
      setToken(tokenParam);
      decodeToken(tokenParam);
      signin(tokenParam, groupIdParam);
    }
  }, [location.search]);

  const decodeToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      localStorage.setItem("selectedUser", decodedToken.userId)
      localStorage.setItem("logedInEmpName", decodedToken.name)
    } catch (error) {
      console.error('Failed to decode token:', error);
    }
  };

  const handlePostRequest = async (url, payload) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error('Failed to verify token');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to verify token: ' + error.message);
    }
  };

  const signin = async (token, groupId) => {
    const payload = {
      token: token,
      groupId: groupId,
    };

    try {
      const response = await handlePostRequest(`${API.apiGateway}/authgw/verify-token`, payload);
      localStorage.setItem("refresh-token", response.token);
      navigate('/orders');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    const groupIdParam = searchParams.get('groupId');
    localStorage.setItem('selectedGroupId', groupIdParam);
    if (tokenParam && groupIdParam) {
      setToken(tokenParam);
      decodeToken(tokenParam);
      signin(tokenParam, groupIdParam);
    }
  }, [location.search]);

  return null;
}

export default SignIn;
