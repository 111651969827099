import React, { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "./OrderSidebar.scss";
import { useLanguage } from "../../LanguageContext";
import PaymentDetailsSidebar from "../payment-details-sidebar/PaymentDetailsSidebar";
import API from "../../services/Api";
import { toast } from 'react-toastify';
import ConfirmationPopup from "./invoice-popup/ConfirmationPopup";
import STORAGE_KEYS from "../../utils/storageKey";

const Order = ({ handleclose, setSelectedOrders, selectedOrders, fetchData, setOpenSidebar }) => {
  const { t } = useLanguage();
  const [viewHistory] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [openNewUpdateOrdersAddAddress, setOpenNewUpdateOrdersAddAddress] = useState(false);
  const [openPaymentSidebar, setOpenPaymentSidebar] = useState();
  const [driverName, setDriverName] = useState("");
  const [status, setstatus] = useState("");
  const [driverNo, setDriverContactNo] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [trackingNo, setTrackingNo] = useState("");
  const [trackingURL, setTrackingUrl] = useState("");
  const [selectedDepartment, setselectedDepartment] = useState("");
  const [dispatchNo, setDispatchNo] = useState();
  const [departmentOptions, setDepartmentOptions] = useState(orderDetails?.selectedDepartment || "");
  const [refNo, setRefNo] = useState();
  const [refDate, setRefDate] = React.useState(new Date());
  const [assign, setAssign] = useState(selectedOrders?.handledBy || "");
  const [vehicleNo, setVehicleNo] = useState("");
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [transporter, settransporter] = useState();
  const [error, setError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [LocationError, setLocationError] = useState("");
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  const [transporterError, setErrortransporter] = useState("");
  const [DispatNoError, setDispatNoError] = useState("");
  const [NameError, setNameError] = useState("");
  const [updateNewQty, setUpdateNewQty] = useState('')
  const [contctError] = useState("");
  const [trackingNoError, settrackingNoError] = useState("");
  const [trackingURLError, setTrackingURLError] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [statusError, setStatusError] = useState("");
  const [assignError, setAssignError] = useState("");
  const [setRefNoError] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [eWayBillNo, setEWayBillNo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [errorQuantity, setErrorQuantity] = useState({ updateNewQty: '' });
  const [eWayBillNoError, setEWayBillNoError] = useState();
  const allInputsFilled = driverName && transporter && vehicleNo && driverNo;
  const paymentStatus = orderDetails?.paymentInfo?.paymentStatus;
  let serialNumber = 1;
  const currentINDate = new Date();
  const year = currentINDate.getFullYear();
  const month = String(currentINDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentINDate.getDate()).padStart(2, "0");
  const formattedSerialNumber = String(serialNumber).padStart(4, "0");
  let invoiceNumber = `${day}${month}${year}`;
  let invoiceDate = `${day}/${month}/${year}`;
  const invoiceNo = `${invoiceNumber}-${formattedSerialNumber}`;
  serialNumber++;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCreditNote, setShowCreditNote] = useState(false);
  const [quantities, setQuantities] = useState([]);

  const openGoogleMap = () => {
    console.log("Selected Orders", selectedOrders);
    const latitude = selectedOrders?.latitude;
    const longitude = selectedOrders?.longitude;
    if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
      const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsURL);
    } else {
      console.error("Latitude or Longitude is missing or invalid:", { latitude, longitude });
      alert("Coordinates are not available for this request.");
    }
  };
  
  



  const chooseUpdateOrdersAddAddress = () => {
    setOpenNewUpdateOrdersAddAddress(!openNewUpdateOrdersAddAddress);
  };

  const getReceiptType = (selectedDepartment) => {
    switch (selectedDepartment) {
      case "मेडिकल":
        return "medicalReceipt";
      case "कृषि सेवा केंद्र":
        return "CRMInvoice";
      case "पॅथाॅलाॅजी":
        return "pathalogyReceipt";
      default:
        return "CRMInvoice";
    }
  };

  const handleViewImage = (pictureUrl) => {
    window.open(pictureUrl, "_blank");
  };

  const sendInvoice = async () => {
    try {
      const response = await fetch(
        `${API.sendInvoice}/whatsapp/send-message/${orderDetails?.userId?.phoneNumber}?messageType=document`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filename: 'invoiceBill',
            document: `${API.pdfGenerator}/generate-pdf?groupId=${groupId}&orderId=${orderDetails.orderId}&type=${getReceiptType(selectedDepartment)}`,
            caption: `${orderDetails?.userId?.phoneNumber}`,
            groupId: parseInt(groupId),
            type: selectedDepartment,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success('Invoice Send Successfully !', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
      } else {
        throw new Error(`Failed to send invoice: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error sending invoice:', error.message);
      toast.error('Error sending invoice.', {
        style: { backgroundColor: "red", zIndex: "999" }
      });
    }
  };

  const handleConfirm = () => {
    sendInvoice();
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const { totalQuantity, totalProductPrice } = orderDetails?.orderDetails?.reduce(
    (accumulator, product) => {
      accumulator.totalQuantity += product.quantity || 0;
      accumulator.totalProductPrice += product.totalProductPrice || 0;
      return accumulator;
    },
    { totalQuantity: 0, totalProductPrice: 0 }
  ) || { totalQuantity: 0, totalProductPrice: 0 };
  const hasReturnedProduct = orderDetails?.orderDetails?.some(
    (product) => product?.totalReturnQuantity > 0
  );

  const renderPaymentButton = () => {
    const paymentStatus = orderDetails?.paymentInfo?.paymentStatus;
    switch (paymentStatus) {
      case "paid":
        return (
          <button type="button" className="btn-paid">
            Paid
          </button>
        );
      case "unpaid":
        return (
          <button type="button" className="btn-unpaid">
            Unpaid
          </button>
        );
      case "partially paid":
        return (
          <button type="button" className="btn-partialy-paid">
            Partially Paid
          </button>
        );
      case "credit":
        return (
          <button type="button" className="btn-Credit-paid ml-1">
            Credit
          </button>
        );
      case "online":
        return (
          <button type="button" className="btn-online-paid">
            Online
          </button>
        );
      default:
        return (
          <button type="button" className="btn-unpaid">
            Unpaid
          </button>
        );
    }
  };

  const handleToggleDetail = () => {
    setShowDetail(!showDetail);
  };

  const handleToggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleDriverNameChange = (event) => {
    const inputValue = event.target.value;
    setDriverName(inputValue);
    if (/\d/.test(inputValue)) {
      setError("Please enter valid name");
    } else {
      setError("");
    }
  };

  const handleEWayBillNoChange = (e) => {
    const value = e.target.value;
    setEWayBillNo(value);
    if (!value) {
      setEWayBillNoError("Please enter E-way bill number.");
    } else {
      setEWayBillNoError("");
    }
  };

  const handletransporter = (event) => {
    const inputValue = event.target.value;
    settransporter(inputValue);
    if (/\d/.test(inputValue)) {
      setErrortransporter("Please enter valid transporter name");
    } else {
      setErrortransporter("");
    }
  };

  const handleStatusChange = (e) => {
    setstatus(e.target.value);
    setStatusError("");
  };


  const handleVehicalNoChange = (e) => {
    const inputValue = e.target.value;
    const vehicleNoRegex = /^[A-Z]{0,2}\s?\d{0,2}\s?[A-Z]{0,2}\s?\d{0,4}$/i;

    if (vehicleNoRegex.test(inputValue) || inputValue === "") {
      const uppercaseValue = inputValue.toUpperCase();
      setVehicleNo(uppercaseValue);
    } else {
    }
  };

  const handleDriverContactNoChange = (event) => {
    const inputValue = event.target.value;
    setDriverContactNo(inputValue);
    if (/[a-zA-Z]/.test(inputValue)) {
      setContactNoError("Please enter the valid contact number");
    } else if (inputValue?.length > 10) {
      setContactNoError("Please enter 10 digit contact number");
    } else {
      setContactNoError("");
    }
  };

  const handleLocationChange = (event) => {
    const inputValue = event.target.value;
    setLocation(inputValue);
    if (/\d/.test(inputValue)) {
      setLocationError("Please enter valid location");
    } else {
      setLocationError("");
    }
  };

  const handleDispatNoChange = (event) => {
    const inputValue = event.target.value;
    setDispatchNo(inputValue);
    if (/[a-zA-Z]/.test(inputValue)) {
      setDispatNoError("Please enter valid number");
    } else {
      setDispatNoError("");
    }
  };

  const handleNameChange = (event) => {
    const inputValue = event.target.value;
    setName(inputValue);
    if (/\d/.test(inputValue)) {
      setNameError("Please enter valid name");
    } else {
      setNameError("");
    }
  };

  const handleContactPersonChange = (event) => {
    const inputValue = event.target.value;
    setContactPerson(inputValue);
  };

  const handleTrackingNoChange = (event) => {
    const inputValue = event.target.value;
    setTrackingNo(inputValue);
    if (/[a-zA-Z]/.test(inputValue)) {
      settrackingNoError("Please enter valid tracking number");
    } else {

      settrackingNoError("");
    }
  };
  const handleTrackingUrlChange = (event) => {
    const inputValue = event.target.value;
    setTrackingUrl(inputValue);
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!urlRegex.test(inputValue)) {
      setTrackingURLError("Please enter a valid URL.");
    } else {
      setTrackingURLError("");
    }
  };

  const handledepartmentChange = (event) => {
    setselectedDepartment(event.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "#000080";
      case "shipment":
        return "#A020F0";
      case "cancelled":
        return "#FF0000";
      case "delivered":
        return "#008000";
      case "dispatched":
        return "#A020F0";
      case "In Transit":
        return "#d1ba06";
      default:
        return "";
    }
  };

  const handleRefNoChange = (e) => {
    setRefNo(e.target.value);
    setRefNoError("");
  };

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };

  const validateRefNo = () => {
    if (!refNo.trim()) {
      setRefNoError("Please enter a reference number.");
      return false;
    }
    return true;
  };

  const handleAssignChange = (e) => {
    setAssign(e.target.value);
    setAssignError("");
  };

  const fetchSearchCust = async () => {
    if (selectedOrders?.userId?.userId) {
      try {
        const response = await fetch(`${API.commerceAPI}/commerce/customer/address/${groupId}/${selectedOrders?.userId?.userId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAddresses(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }
  };
  const updatePayload = {
    groupId: parseInt(groupId),
    orderId: orderDetails.orderId,
    status,
    refDate,
    orderId,
    handledBy: assign,
    department: selectedDepartment,
    shippingDetails: {
      vehicleNo,
      driverName,
      driverNo,
      location,
      name,
      contactPerson,
      trackingNo,
      trackingURL,
      dispatchNo,
      invoiceNumber,
      transporter: transporter,
      eWayBillNo: eWayBillNo || orderDetails?.shippingDetails?.eWayBillNo
    },
  }

  const QuantityIsValid = (value) => {
    let isValid = true;
    if (value <= 0 || value === '') {
      setErrorQuantity(prevErrors => ({ ...prevErrors, newQuantity: 'valid Qty' }));
      isValid = false;
    } else {
      setErrorQuantity(prevErrors => ({ ...prevErrors, newQuantity: '' }));
    }
    return isValid;
  };

  const handleChange = (index, event) => {
    const value = event.target.value;
    const product = orderDetails?.orderDetails[index];
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = value === '' ? '' : Number(value);
      return newQuantities;
    });
    if (value !== '' && product?.product?.productcode) {
      updateQuantityAPI(product.product?.productcode, Number(value));
    }
  }

  const updateQuantityAPI = async (productcode, newQuantity) => {
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/order/updateByOrderId/${groupId}/${orderDetails?.orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productcode,
          newQuantity,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update quantity');
      }
      const data = await response.json();
      fetchPaymentHistory()
      getAllByGroupId()
      console.log('Quantity updated successfully:', data);
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  useEffect(() => {
    if (orderDetails?.orderDetails) {
      const initialQuantities = orderDetails.orderDetails.map(product => product.quantity || 1);
      setQuantities(initialQuantities);
    }
  }, [orderDetails]);

  const updateOrder = async () => {
    if (!QuantityIsValid(updateNewQty)) {
      return;
    }

    const isStatusValid = status !== "";

    if (!isStatusValid) {
      if (!isStatusValid) {
        setStatusError("Please select a status.");
      }
      return;
    }

    try {
      setIsUpdating(true);
      const response = await fetch(`${API.apiGateway}/commerce-gw/assign/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${refreshToken}`
          },
          body: JSON.stringify(updatePayload),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success("Order Updated Successfully !", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        setOpenSidebar(false);
        setTimeout(() => {
          fetchData();
        }, 3000);
        setIsUpdating(false);
      } else {
        throw new Error(`Failed to update order: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("Error updating order", {
        style: {
          background: "red",
        },
        bodyClassName: "toast-error-body",
      });
      console.error("Error updating order:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (orderDetails) {
      setDriverName(orderDetails?.shippingDetails?.driverName || "");
      setstatus(orderDetails.status || "");
      setVehicleNo(orderDetails?.shippingDetails?.vehicleNo || "");
      setDriverContactNo(orderDetails?.shippingDetails?.driverNo || "");
      setLocation(orderDetails?.shippingDetails?.location || "");
      setName(orderDetails?.shippingDetails?.name || "");
      setContactPerson(orderDetails?.shippingDetails?.contactPerson || "");
      setTrackingNo(orderDetails?.shippingDetails?.trackingNo || "");
      setTrackingUrl(orderDetails?.shippingDetails?.trackingURL || "");
      setDispatchNo(orderDetails?.shippingDetails?.dispatchNo || "");
      setselectedDepartment(orderDetails?.department || "");
      setOrderId(orderDetails.orderId || "");
      setRefDate(orderDetails?.refDate || "");
      settransporter(orderDetails?.shippingDetails?.transporter);
      setUpdateNewQty(orderDetails?.orderDetails?.[0]?.quantity)
      // setShowCreditNote(hasReturnedProduct);
      setShowCreditNote(paymentStatus === "paid" && hasReturnedProduct);
    }
  }, [orderDetails, paymentStatus, hasReturnedProduct]);

  const getAllByGroupId = () => {
    if (orderDetails) {
      fetch(`${API.commerceAPI}/commerce/order/all/getByGroupId/${groupId}?orderId=${selectedOrders?.orderId}`,
        {}
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.data && Array.isArray(data.data.items)) {
            setOrderDetails(data.data.items[0]);
          } else {
            console.error("Data.data.items is not an array:", data);
            setOrderDetails([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching service response data:", error);
        });
    }
  }

  useEffect(() => {
    getAllByGroupId()
  }, [groupId]);

  useEffect(() => {
    if (orderDetails) {
      setContactPerson(orderDetails.contactPerson || "");
    }
  }, [orderDetails]);

  useEffect(() => {
    fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const employees = data?.data?.items?.map((employee) => ({
            empId: employee.empId,
            name: employee.name
          }));
          setEmployeeOptions(employees);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API.peopleManagentService}/peopleManagement/deparment/groupId/${groupId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const departments = data?.data?.items.map((item) => ({
            departmentId: item.departmentId,
            name: item.name,
          }));
          setDepartmentOptions(departments);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedOrders?.userId?.userId) {
      fetchSearchCust();
    }
  }, [selectedOrders?.userId?.userId, groupId]);

  useEffect(() => {
  }, [assign]);

  const [paymentHistory, setPaymentHistory] = useState([]);

  const fetchPaymentHistory = async () => {
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/paymenthistory/payment-history/${groupId}/${selectedOrders?.orderId}`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setPaymentHistory(data?.data);
      } else {
        throw new Error('Failed to fetch payment history');
      }
    } catch (error) {
      console.error('Error fetching payment history:', error);
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleToggleInformation = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenDispatch, setIsOpenDispatch] = useState(false);
  const [isOpenServiceProvider, setIsOpenServiceProvider] = useState(false);

  const handleToggleDispatch = () => {
    setIsOpenDispatch(!isOpenDispatch);
  };

  const handleToggleServiceProvider = () => {
    setIsOpenServiceProvider(!isOpenServiceProvider);
  };

  return (
    <>
      <div className="order-sidebar-update">
        <div className="side-padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-5">
                <div className="service-request ">
                  <p className="font18 fontw-800 text-left">
                    {t("order.title")} {orderDetails?.orderId || "-"}
                  </p>
                  <div className="d-inline-flex">
                    <p className="font14 text-left">
                      {t("neworder.items")} {totalQuantity ?? ""}, {t("table.Amount")} &nbsp;&nbsp;
                      <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                      {totalProductPrice ?? ""}
                    </p>
                  </div>
                  <div className="d-flex payment-btn">
                    <div>{renderPaymentButton()}</div>
                    <button
                      className="order-status-btn ml-2"
                      style={{
                        backgroundColor: getStatusColor(orderDetails?.status),
                      }}>
                      {orderDetails?.status
                        ? orderDetails.status.charAt(0).toUpperCase() +
                        orderDetails.status.slice(1)
                        : ""}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-3 font14">
                <p className="category font14">{t("select.Department")}</p>
                <select
                  className="department cursor-pointer"
                  value={selectedDepartment}
                  onChange={handledepartmentChange}>
                  <option value="" className="fontw-500 dropdown_head">
                    {t("sidebarcustomers.Select Department")}
                  </option>
                  {departmentOptions
                    ? departmentOptions.map((option, index) => (
                      <option
                        key={index}
                        value={option.departmentId}>
                        {option?.name}
                      </option>
                    ))
                    : null}
                </select>
              </div>
              <div className="col-4">
                <div className="date-time ">
                  <p className="font14">{t("requestsidebar.date")}</p>
                  <p className="font14 cursorPointer">
                    <span>
                      {new Date(orderDetails?.orderDate).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}{" "}
                      {new Date(orderDetails?.orderDate).toLocaleTimeString(
                        "en-GB",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )}
                    </span>
                  </p>{" "}
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
            <div className="row amount-font">
              <div className="col-4 rem-amt">
                <p className="Balance">
                  Balance Due :&nbsp;&nbsp;
                  <i className="fa-solid fa-indian-rupee-sign"></i>
                  {orderDetails?.RemainingAmount !== undefined
                    ? ` ${orderDetails?.RemainingAmount?.toFixed(2)}`
                    : " --"}
                </p>
              </div>
              <div className="col-4 paid mt-2">
                <p className="totalPaid">
                  Paid Amt :&nbsp;&nbsp;
                  <i className="fa-solid fa-indian-rupee-sign"></i>
                  {orderDetails?.totalPaidAmount !== undefined
                    ? ` ${orderDetails?.totalPaidAmount?.toFixed(2)}`
                    : " --"}
                </p>
              </div>
              <div className="col-4 mt-2">
                <p className="totalReturnAmt">
                  Return Amt:&nbsp;&nbsp;
                  <i className="fa-solid fa-indian-rupee-sign"></i>
                  {orderDetails?.totalReturnAmount !== undefined
                    ? ` ${orderDetails?.totalReturnAmount?.toFixed(2)}`
                    : " --"}
                </p>
              </div>
            </div>
            <hr className="" />
            <div className="sidebar-wrapper-os">
              <div className="row paddingx10">
                <div className="col-5 line-height p-0 ">
                  <p className="font14 black-color pl-3">
                    {t("table.customerName")}
                  </p>
                  <span className="font14 fontw-800 pl-3">
                    {orderDetails?.userId?.name || "--"}
                    {orderDetails?.userId?.membership?.map(
                      (membershipItem, index) => (
                        <React.Fragment key={index}>
                          {membershipItem.membershipPremium === "Premium" && (
                            <button className="Premium font14 fontw-600 ml-5">
                              {membershipItem.membershipPremium}
                            </button>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </span>
                </div>
                <div className="col-5">
                  <div className="line-height">
                    <p className="font14 black-color">{t("table.phoneNo")}</p>
                    <p className="font14 fontw-800">
                      {orderDetails?.userId?.phoneNumber || "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row paddingx10">
                <div className="col-5 line-height pl-3 p-0">
                  <p className="font14 pl-3">{t("table.location")}</p>
                  <p className="font14 fontw-600 pl-3">
                    {orderDetails?.userId?.location || "--"}
                  </p>
                </div>
                <div className="col-5">
                  <div className="line-height">
                    <p className="font14">{t("requestsidebar.pinCode")}</p>
                    <p className="font14 fontw-600">
                      {orderDetails?.userId?.pinCode || "--"}
                      <span className="font14 color-grey-light pl-2">
                        {t("newOrder.serving")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="line-height">
                    <p className=" font14">{t("requestsidebar.locateMap")}</p>
                    <p
                      className="textBlue cursor-pointer font13 fontw-600"
                      onClick={openGoogleMap}>
                      {t("newOrder.click")}
                      <span className="font13 mr-2 color-grey-light">
                        {t("newOrder.location")}
                      </span>
                    </p>
                  </div>
                </div>
                <hr className="hr-dash" />
              </div>
              <div className="row">
                <p className="OrderDetails">{t("neworder.details")}</p>
                <div className="col-12">
                  {orderDetails && orderDetails.orderDetails ? (
                    <table className="table p-table">
                      <thead className=" p-thead">
                        <tr>
                          <th className="classdate">{t("product.Product Name")}</th>
                          <th className="classdate">{t("SKU No")}</th>
                          <th className="Tobias ">{t("neworder.qty")}</th>
                          <th className="Tobias">{t("newOrder.Return")}</th>
                          <th className="Linus p-th">{t("neworder.rates")}</th>
                          <th className="Rate-UI">{t("neworder.disc")}</th>
                          <th className="RS p-th">{t("neworder.tax")}</th>
                          <th className="Titlo p-th">{t("neworder.total-price")}</th>
                        </tr>
                      </thead>
                      <tbody className="p-tbody">
                        {orderDetails.orderDetails.map((product, index
                        ) => (
                          <tr key={index}>
                            <td className="classdate ">
                              {product?.product?.name}
                            </td>
                            <td className="classdate ">
                              {product?.product?.skuNumber}
                            </td>
                            <td className="Tobias">
                              <input
                                type="number"
                                value={quantities[index] !== undefined ? quantities[index] : ''}
                                onChange={event => handleChange(index, event)}
                                className="table-input"
                                name="quantity"
                                min="1"
                              />
                              {errorQuantity.updateNewQty && <div className="validation-error order-qty">{errorQuantity.updateNewQty}</div>}
                            </td>
                            <td>
                              {product?.totalReturnQuantity}
                            </td>
                            <td className="Linus">
                              <i className="fa-solid fa-indian-rupee-sign"></i>
                              &nbsp;{`${product?.product?.regularPrice}`}
                            </td>
                            <td className="Tobias">
                              <i className="fa-solid fa-indian-rupee-sign"></i>
                              &nbsp;{`${product?.product?.discount}`}
                            </td>
                            <td className="RS">
                              <i className="fa-solid fa-indian-rupee-sign"></i>
                              &nbsp;{`${product?.product?.tax || "0"}`}
                            </td>
                            <td className="Titlo">
                              <i className="fa-solid fa-indian-rupee-sign"></i>
                              &nbsp;{`${product?.totalProductPrice}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div className="row total_a_sec">
                <div className="col-5">
                  <div>
                    <label className="font14">{t("newOrder.total-amount")}</label>
                  </div>
                  <div>
                    <label>{t("neworder.tax")}</label>
                  </div>
                  <div>
                    <label>{t("neworder.payable")}</label>
                  </div>
                  <div>
                    <label>{t("neworder.saving")}</label>
                  </div>
                </div>
                <div className="col-4">
                  <div>
                    <label>
                      <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                      {orderDetails?.subtotal || "--"}
                    </label>
                  </div>
                  <div>
                    <label>
                      <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                      {orderDetails?.taxes || "0"}
                    </label>
                  </div>
                  <div>
                    <label>
                      <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                      {orderDetails?.totalCartPrice || "--"}
                    </label>
                  </div>
                  <div>
                    <label>
                      <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                      {orderDetails?.saving || "0"}
                    </label>
                  </div>
                </div>
              </div>
              <hr className=" mt-1" />
              <div className="row">
                <div className="col-6">
                  <p className="font16 billing-shipping-add">{t("AddressSidebar.Billing Address")}</p>
                  {addresses.map((address, index) => (
                    <div key={index} className="">
                      <div>
                        {address?.address &&
                          address?.address?.billingAddress && (
                            <>
                              <p className="classfont_size mb-0 mt-1 color cls-lcs">
                                {t("neworder.address-type")} {address?.address?.tag || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address?.address?.street || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address?.address?.locality || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address?.address?.city || ""},{" "}
                                {address?.address?.state || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address.address.zip || ""}
                              </p>
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-6">
                  <p className="foont16 billing-shipping-add">{t("AddressSidebar.Shipping Address")}</p>
                  {addresses.map((address, index) => (
                    <div key={index} className="">
                      <div>
                        {address?.address &&
                          address?.address?.shippingAddress && (
                            <>
                              <p className="classfont_size mb-0 mt-1 color cls-lcs">
                                {t("neworder.address-type")} {address?.address?.tag || ""}
                              </p>
                              <p className=" mb-0 cls-lcs font13">
                                {address?.address?.street || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address?.address?.locality || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address?.address?.city || ""},{" "}
                                {address?.address?.state || ""}
                              </p>
                              <p className="mb-0 cls-lcs font13">
                                {address.address.zip || ""}
                              </p>
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6 col-md-6">
                  <p className="pay_det mb-0 mt-2">{t("neworder.payment-detail")}</p>
                </div>
                <div className="col-6 col-md-6 text-end">
                  {/* {(paymentStatus !== "paid" || isQuantityUpdated) && ( */}
                  <button
                    className="saveDraft-btn"
                    onClick={() => setOpenPaymentSidebar(!openPaymentSidebar)}>
                    {t("neworder.getpayment")}
                  </button>
                  {/* )} */}
                </div>
              </div>
              {openPaymentSidebar && (
                <PaymentDetailsSidebar
                  orderDetails={orderDetails}
                  totalQuantity={totalQuantity}
                  totalProductPrice={totalProductPrice}
                  status={status}
                  driverName={driverName}
                  vehicleNo={vehicleNo}
                  driverNo={driverNo}
                  location={location}
                  name={name}
                  contactPerson={contactPerson}
                  trackingNo={trackingNo}
                  trackingURL={trackingURL}
                  RemainingAmount={orderDetails?.RemainingAmount}
                  assign={assign}
                  dispatchNo={dispatchNo}
                  refNo={refNo}
                  refDate={refDate}
                  transporter={transporter}
                  selectedDepartment={selectedDepartment}
                  fetchData={fetchData}
                  setOpenSidebar={setOpenSidebar}
                  setOpenPaymentSidebar={setOpenPaymentSidebar}
                  orderId={orderId}
                />
              )}
              <hr className=" mt-1" />
              <div className="row">
                <p className="classfont_size mb-0 color">
                  {t("neworder.source")} {orderDetails?.source}
                </p>
                {Array.isArray(paymentHistory) &&
                  paymentHistory.map((payment, index) => (
                    <div className="col-6 pt-4" key={index}>
                      <p className="classfont_size mb-0">
                        Date of payment: {payment?.paymentDateTime ? formatDate(payment.paymentDateTime) : '--'}
                      </p>
                      <p className="classfont_size mb-0">
                        {t("neworder.mode-payment")}
                        {payment?.mode || '--'}
                      </p>
                      {payment?.mode === 'Pay by Cash' && (
                        <p className="classfont_size mb-0">
                          Paid Amount: <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;{payment?.paidAmount || '--'}
                        </p>
                      )}
                      {payment?.mode === 'Pay by Credit' && (
                        <>
                          <p className="classfont_size mb-0">
                            Due Date: {payment?.creditPaymentDate || "--"}
                          </p>
                        </>
                      )}
                      {payment?.mode === 'Pay by Cheque' && (
                        <>
                          <p className="classfont_size mb-0">
                            Cheque No: {payment?.chequeNo || "--"}
                          </p>
                          <p className="classfont_size mb-0">
                            Bank Name: {payment?.bankName || "--"}
                          </p>
                          <p className="classfont_size mb-0">
                            Cheque Amount: {payment?.chequeAmount || "--"}
                          </p>
                          <p className="classfont_size mb-0">
                            Cheque Date: {payment?.chequeDate || "--"}
                          </p>
                        </>
                      )}
                      {payment?.mode === 'Pay by UPI' && (
                        <div>
                          <p className="classfont_size mb-0">
                            {t("sidebarcustomers.UPI ID")}: {payment?.upi}
                          </p>
                          <p className="classfont_size mb-0">
                            {t("neworder.transaction-id")} {payment?.txnId}
                          </p>
                          <p className="classfont_size mb-0">
                            {t("neworder.date-of-payment")} {payment?.paymentDateTime}
                          </p>
                          <p className="classfont_size mb-0">
                            Paid Amount: <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;{payment?.paidAmount || '--'}
                          </p>
                        </div>
                      )}
                      {payment?.mode === "Pay by Online" && (
                        <>
                          <p className="classfont_size mb-0">
                            Transaction Id: {payment?.onlineTxnId?.length > 0 ? payment.onlineTxnId.join(", ") : ""}
                          </p>
                          {payment?.mode === 'Pay by Online' && payment?.pictures && payment.pictures?.length > 0 && (
                            <p className="classfont_size mb-0">
                              Document:
                              {payment.pictures.map((picture, index) => (
                                <span key={index} className="view-style-upload ml-2" onClick={() => handleViewImage(picture)}>
                                  View {index + 1}
                                </span>
                              ))}
                            </p>
                          )}
                          <p className="classfont_size mb-0">
                            Paid Amount: <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;{payment?.paidAmount || '--'}
                          </p>
                        </>
                      )}
                    </div>
                  ))}
              </div>
              <div className="row pt-2 font16 mt-2">
                <div className="order-status">
                  <p className="text-orange text-orange1">{t("neworder.Dispatch")}</p>
                  {!isOpen && (
                    <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleInformation}
                    ></i>
                  )}
                  {isOpen && (
                    <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleInformation}
                    ></i>
                  )}
                </div>
              </div>
              {isOpen && (
                <div >
                  <div>
                    <div className="row pt-2 font14">
                      <hr className="" />
                      <div className="col-6">
                        <span>{t("select.status")}</span>
                        <span className="text-danger">*</span>
                        <br />
                        <select
                          className={`select-status ${statusError ? "error-border" : ""}`}
                          value={status}
                          onChange={handleStatusChange}>
                          <option value="">{t("order.status")}</option>
                          <option value="new">New</option>
                          <option value="package">Package</option>
                          <option value="shipment">Shipment</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="delivered">Delivered</option>
                        </select>
                        {statusError && (
                          <div className="error-message">{statusError}</div>
                        )}
                      </div>
                      <div className="col-6 margin-top10">
                        <span className="">{t("neworder.select-employee")}</span>
                        <br />
                        <select
                          className={`input-style font14 cursor-pointer border-radius-5 mt-2 ${assignError ? "error" : ""}`}
                          value={assign}
                          onChange={handleAssignChange}>
                          <option>Select an Employee</option>
                          {employeeOptions.map((employee, index) => (
                            <option
                              key={index}
                              value={employee.empId}
                              selected={employee.name === assign}>
                              {employee.name}
                            </option>
                          ))}
                        </select>
                        {assignError && (
                          <div className="error-message">{assignError}</div>
                        )}
                      </div>
                      <div className="col-6 mt-3">
                        <p>
                          {t("neworder.driver-name")}
                          <input
                            className={`select-status pl-2 ${error && "error-border"}`}
                            placeholder={t("neworder.driver-name")}
                            value={driverName}
                            onChange={handleDriverNameChange}
                          />
                          {error && <div className="error-message">{error}</div>}
                        </p>
                      </div>
                      <div className="col-6 mt-3">
                        <p>
                          {t("neworder.Transporter Name")}
                          <input
                            className={`select-status pl-2 ${transporterError && "error-border"}`}
                            placeholder={t("neworder.Transporter Name")}
                            value={transporter}
                            onChange={handletransporter}
                          ></input>
                          {transporterError && (
                            <div className="error-message">{transporterError}</div>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row font14">
                      <div className="col-6">
                        <span>{t("neworder.vehical-no")}</span>
                        <br />
                        <input
                          className='select-status pl-2 '
                          placeholder={t("neworder.vehical-no")}
                          value={vehicleNo}
                          onChange={handleVehicalNoChange}
                        />

                      </div>
                      <div className="col-6">
                        <span>{t("neworder.Driver Contact No")}</span>
                        <br />
                        <input
                          className={`select-status pl-2 ${contactNoError && "error-border"}`}
                          placeholder={t("neworder.Driver Contact No")}
                          value={driverNo}
                          onChange={handleDriverContactNoChange}
                          maxLength={10}
                        />
                        {contactNoError && (
                          <div className="error-message">{contactNoError}</div>
                        )}
                      </div>
                    </div>
                    <div className="row font14 pt-2 mt-2">
                      <div className="col-6">
                        <span>{t("neworder.Order ID")}</span>
                        <span className="text-danger">*</span>
                        <br />
                        <input
                          className={`select-status pl-2 `}
                          placeholder={t("neworder.Order ID")}
                          value={orderId}
                          onChange={handleOrderIdChange}
                        />
                      </div>
                      <div className="col-6 mt-2">
                        <span className="fo">{t("neworder.Reference Date")}</span>
                        <br />
                        <input
                          type="date"
                          className="select-status"
                          placeholder={t("neworder.Reference Date")}
                          value={refDate}
                          onChange={(e) => setRefDate(e.target.value)}
                        ></input>
                      </div>
                    </div>
                    <div className="row font14 pt-2 mt-2">
                      <div className="col-6">
                        <span>{t("neworder.Dispatch Number")}</span>
                        <br />
                        <input
                          className={`select-status pl-2 ${DispatNoError && "error-border"}`}
                          placeholder={t("neworder.Dispatch Number")}
                          value={dispatchNo}
                          onChange={handleDispatNoChange}
                        />
                        {DispatNoError && (
                          <div className="error-message">{DispatNoError}</div>
                        )}
                      </div>
                      <div className="col-6">
                        <span>{t("table.location")}</span>
                        <br />
                        <input
                          className={`select-status pl-2 ${LocationError && "error-border"}`}
                          placeholder={t("table.location")}
                          value={location}
                          onChange={handleLocationChange}
                        />
                        {LocationError && (
                          <div className="error-message">{LocationError}</div>
                        )}
                      </div>
                    </div>
                    <div className="row font14 pt-2 mt-2">
                      <div className="col-6 mt-2">
                        <span>{t("neworder.E-waybill")}</span>
                        <br />
                        <input
                          type="number"
                          className={eWayBillNoError ? "select-status pl-2 error-border" : "select-status pl-2"}
                          placeholder={t("neworder.E-waybill")}
                          value={eWayBillNo || orderDetails?.shippingDetails?.eWayBillNo}
                          onChange={(e) => setEWayBillNo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row pt-2 font16 mt-2">
                <div className="order-status">
                  <span className="text-orange  text-orange1">
                    {t("neworder.Shipping")}
                  </span>
                  {!isOpenServiceProvider && (
                    <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleServiceProvider}
                    ></i>
                  )}
                  {isOpenServiceProvider && (
                    <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleServiceProvider}
                    ></i>
                  )}
                </div>
              </div>
              {isOpenServiceProvider && (
                <div>
                  <div className="row pt-2 font14">
                    <hr />
                    <div className="col-6 pt-2">
                      <span>{t("neworder.name")}</span>
                      <br />
                      <input
                        className={`select-status pl-2 ${NameError && "error-border"}`}
                        placeholder={t("neworder.name")}
                        value={name}
                        onChange={handleNameChange}
                      ></input>
                      {NameError && (
                        <div className="error-message">{NameError}</div>
                      )}
                    </div>
                    <div className="col-6 pt-2">
                      <span>{t("neworder.Contact Person")}</span>
                      <br />
                      <input
                        className={`select-status pl-2 ${contctError && "error-border"}`}
                        placeholder={t("neworder.Contact Person")}
                        value={contactPerson}
                        onChange={handleContactPersonChange}
                      ></input>
                      {contctError && (
                        <div className="error-message">{contctError}</div>
                      )}
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-6 pt-2 font14">
                      <span className="font14">{t("neworder.tracking-no")}</span>
                      <br />
                      <input
                        className={`select-status pl-2 ${trackingNoError && "error-border"}`}
                        placeholder={t("neworder.tracking-no")}
                        value={trackingNo}
                        onChange={handleTrackingNoChange}
                      ></input>
                      {trackingNoError && (
                        <div className="error-message">{trackingNoError}</div>
                      )}
                    </div>
                    <div className="col-6 mt-2 font14">
                      <span className="font14">{t("neworder.tracking-url")}</span>
                      <br />
                      <input
                        className={`select-status pl-2 ${trackingURLError && "error-border"}`}
                        placeholder={t("neworder.tracking-url")}
                        value={trackingURL}
                        onChange={handleTrackingUrlChange}
                      ></input>
                      {trackingURLError && (
                        <div className="error-message">{trackingURLError}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!viewHistory ? (
              <div className="d-flex pt-2 update-class-container">
                <div className="Update-Class">
                  <button
                    className="saveDraft-btn mx-1"
                    onClick={updateOrder}
                    disabled={isUpdating}>
                    {isUpdating ? (
                      t("customer.Updating..")
                    ) : (
                      t("intentCreate.Update")
                    )}
                  </button>
                  <>

                    <button className="saveDraft-btn mx-2 mb-2">
                      <a
                        className="font14  print-button-page"
                        href={`${API.pdfGenerator}/generate-pdf?groupId=${groupId}&orderId=${orderDetails.orderId}&type=${getReceiptType(selectedDepartment)}`}
                        rel="noopener noreferrer">
                        {t("orderSidebar. Invoice")}
                      </a>
                    </button>
                    {showCreditNote && (
                      <button className="saveDraft-btn mx-2 mb-2">
                        <a
                          className="font14 print-button-page"
                          href={`${API.pdfGenerator}/generate-pdf?groupId=${groupId}&orderId=${orderDetails.orderId}&type=creditInvoice`}
                          rel="noopener noreferrer"
                        >
                          {t("orderSidebar.Credit Note")}
                        </a>
                      </button>
                    )}
                    <button className="saveDraft-btn mx-1"
                      onClick={() => setShowConfirmation(true)}>
                      {t("orderSidebar.Send Invoice")}
                    </button>
                    {allInputsFilled && (
                      <button className="saveDraft-btn mx-1">
                        <a
                          href={`${API.pdfGenerator}/generate-pdf?groupId=${groupId}&orderId=${orderDetails.orderId}&type=deliveryChallan`}
                          rel="noopener noreferrer">
                          {t("orderSidebar.Delivery Challan")}
                        </a>
                      </button>
                    )}
                  </>
                  <button className="ml-1 cancel-btn"
                    onClick={handleclose}>
                    {t("intentCreate.Cancel")}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {showConfirmation && (
          <ConfirmationPopup onConfirm={handleConfirm} onCancel={handleCancel} />
        )}
      </div >
    </>
  );
};
export default Order;