import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import ServiceDeletePopup from './ServiceDeletePopup';
import ServiceUpdateSidebar from './ServiceUpdateSidebar';
import ServiceSidebar from '../../components/services-request-sidebar/ServiceSidebar';
import "./Service.scss";
import Popup from './Popup';
import { ToastContainer } from 'react-toastify';
import STORAGE_KEYS from '../../utils/storageKey';

function Service() {
  const [isCreateSidebarOpen, setCreateSidebarOpen] = useState(false);
  const [isUpdateSidebarOpen, setUpdateSidebarOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [themes, setThemes] = useState([]);
  const { t } = useLanguage();
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [_id, setId] = useState(null);
  const [selectedServiceRequest, setSelectedServiceRequest] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(18);
  const [isLoading, setIsLoading] = useState(true);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [popupContent, setPopupContent] = useState(null);
  const offset = currentPage * itemsPerPage;
  const currentData = services.slice(offset, offset + itemsPerPage);

  const openServiceSidebar = (_id) => {
    const selected = services.find((item) => item._id === _id);
    setSelectedServiceRequest(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const handleClose = () => {
    setSelectedServiceRequest(null);
  };

  const openSidebar = () => {
    setCreateSidebarOpen(true);
    setUpdateSidebarOpen(false);
  };

  const closeSidebar = () => {
    setCreateSidebarOpen(false);
    setUpdateSidebarOpen(false);
    setIsDeleteConfirmationVisible(false);
  };

  const FetchServices = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/services/all/getByGroupId/${groupId}`);
      const data = await response.json();
      setServices(data?.data?.items);
      setThemes(data?.data?.themes);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchServices();
  }, []);

  const openDeleteConfirmation = (_id) => {
    setId(_id);
    setIsDeleteConfirmationVisible(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationVisible(false);
    setId(null);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const togglePopup = (content) => {
    setPopupContent(content);
  };

  return (
    <div className="main">
      <ToastContainer
        position='top-right'
        autoClose={2000} />

      <div className="body_container">
        {selectedServiceRequest && (
          <ServiceUpdateSidebar
            isOpen={isUpdateSidebarOpen}
            closeSidebar={closeSidebar}
            fetchService={FetchServices}
            _id={selectedServiceRequest._id}
            selectedServiceRequest={selectedServiceRequest}
            setSelectedServiceRequest={setSelectedServiceRequest}
          />
        )}

        <div className="main-padding1">
          <div className='container-fluid p-0 m-0'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-header d-flex m-0 pt-2'>
                  <label>
                    <b className='service-title'>{t('service.SERVICES ')}</b><br />
                    <p className='service-subtitle'>{t('service.Create a service and their action here ')}</p>
                  </label>
                  <button
                    className='add-service-button'
                    onClick={openSidebar}>
                    <b><i className="fa-sharp fa-regular fa-plus service_create"></i></b>
                  </button>
                </div>
              </div>

              <hr className='service-hr' />
              <div className='row table-scroll-service'>
                <div className='col-lg-12'>
                  <div className='row p-0 m-0'>
                    {isLoading ? (
                      <div className="spinner-border text-center spinn"
                        role="status">
                      </div>
                    ) : (
                      services && services?.length > 0 ? (
                        currentData.map(service => (
                          !service.hideServices && (
                            <div className="col-lg-2"
                              key={service._id}>
                              <div
                                className='card w-100 position-relative mt-2'
                                style={{ background: `#${service.theme.background}` }}>
                                <img
                                  src={service.icon}
                                  alt="Service Icon"
                                  className='services-image text-white'
                                />
                                <b
                                  className='service-name'
                                  style={{ color: `#${service.theme.color}` }}>
                                  {service.name}
                                </b>
                                <a
                                  href='#'
                                  className='text-read'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    togglePopup(<p className='describe'>{service.desc}</p>);
                                  }}>
                                  {t('service.Read description')}
                                </a>
                                {popupContent && (
                                  <Popup
                                    handleClose={() => setPopupContent(null)}
                                    content={popupContent}
                                  />
                                )}
                                {service.featured === true && (
                                  <div className="feature-label">
                                    <b className='service-feature-label'>Featured</b>
                                  </div>
                                )}
                                <div className='service_edit'>
                                  <div>
                                    <i className="fa-solid fa-pencil ware-trash edit-warehouse"
                                      onClick={() => {
                                        openServiceSidebar(service._id);
                                        setCreateSidebarOpen(false);
                                        setUpdateSidebarOpen(true);
                                      }}
                                      alt={`category ${service._id}`} />
                                  </div>
                                  <div>
                                    <i className="fa-solid fa-trash ware-trash delete-warehouse mx-5"
                                      onClick={() => openDeleteConfirmation(service._id)} />
                                  </div>
                                  {isDeleteConfirmationVisible && (
                                    <ServiceDeletePopup _id={_id} closeSidebar={closeSidebar}
                                      onClose={() => setIsDeleteConfirmationVisible(false)}
                                      fetchService={FetchServices} />
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-center">
                            <img
                              className='no-data'
                              src="/no-data-image.png"
                              alt="No data found"
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </div>
                </div>

                <div className='col-lg-2'>
                  {isCreateSidebarOpen && (
                    <div className='service-sidebar'>
                      <ServiceSidebar closeSidebar={closeSidebar} fetchService={FetchServices} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-inline-flex pagination-container ml-1">
          <div className="col-12 ml-2 mt-3">
            <ReactPaginate
              className="pagination-bar"
              previousLabel={<i className="fa-solid fa-chevron-left"></i>}
              nextLabel={<i className="fa-solid fa-angle-right"></i>}
              breakLabel={'...'}
              pageCount={Math.ceil(services?.length / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={0}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'Pagination-products'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;