import React, { useState, useEffect } from "react";
import "./CustomerAddress.scss";
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";
import STORAGE_KEYS from "../../utils/storageKey";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CustomerAddress({ handleCloseAddress, fetchCustomerData, userId, custId, customerData, customer }) {
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');
  const [addressType, setAddressType] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const { t } = useLanguage();


  useEffect(() => {
    if (customerData?.addresses?.length > 0) {
      const address = customerData.addresses[0].address;
      setLine1(address.line1 || '');
      setLine2(address.line2 || '');
      setCity(address.city || '');
      setState(address.state || '');
      setCountry(address.country || '');
      setZip(address.zip || '');
      setAddressType(customerData.addresses[0].address.type || '');
      setSelectedTag(customerData.addresses[0].address.tag || '');
      setAddressId(customerData.addresses[0].addressId || null);
    }
  }, [customerData]);

  const handleAddressTypeChange = (e) => setAddressType(e.target.value);
  const handleLine1Change = (e) => {
    setLine1(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, line1: '' }));
  };
  const handleLine2Change = (e) => {
    setLine2(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, line2: '' }));
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, city: '' }));
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, state: '' }));
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, country: '' }));
  };
  const handleZipChange = (e) => {
    setZip(e.target.value.replace(/[^0-9]/g, '').slice(0, 6));
    setErrors((prevErrors) => ({ ...prevErrors, zip: '' }));
  };
  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, tag: '' }));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = {};
    if (!line1.trim()) {
      errors.line1 = 'Line1 is required.';
    }

    if (!line2.trim()) {
      errors.line2 = 'Line2 is required.';
    }

    if (!city.trim()) {
      errors.city = 'City is required.';
    }

    if (!state.trim()) {
      errors.state = 'State is required.';
    }

    if (!country.trim()) {
      errors.country = 'Country is required.';
    }

    if (!zip.trim()) {
      errors.zip = 'Zip is required.';
    } else if (zip.trim()?.length !== 6) {
      errors.zip = 'Zip must be 6 digits.';
    }

    if (!selectedTag) {
      errors.tag = 'Please select an address tag.';
    }

    if (Object.keys(errors)?.length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    const updatedAddress = {
      line1,
      line2,
      city,
      state,
      country,
      zip,
      tag: selectedTag,
      billingAddress: addressType === "Billing",
      shippingAddress: addressType === "Shipping",
      default: true
    };

    const payload = {
      userId: parseInt(userId),
      address: updatedAddress,
      custId: parseInt(custId)
    };

    if (addressId) {
      // Update existing address
      fetch(`${API.commerceAPI}/commerce/customer/updateAddress/${groupId}/${userId}/${addressId}`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          toast.success("Customer Address Updated Successfully", {
            style: { backgroundColor: '#328b17', color: 'white', height: '50px' }
          })
          if (!response.ok) throw new Error('Failed to update customer address');
          return response.json();
        })
        .then(() => {
          setIsLoading(false);
          handleCloseAddress();
          fetchCustomerData();
        })
        .catch((error) => {
          console.error('Error updating customer address:', error);
          setIsLoading(false);
        });
    } else {
      // Create new address
      fetch(`${API.commerceAPI}/commerce/customer/updateByCustId/${customerData?.custId}`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ addresses: [updatedAddress] })
      })
        .then((response) => {
          toast.success("Customer Address Saved Successfully", {
            style: { backgroundColor: '#328b17', color: 'white', height: '50px' }
          })
          if (!response.ok) throw new Error("Failed to save address");
          return response.json();
        })
        .then(() => {
          setIsLoading(false);
          handleCloseAddress();
          fetchCustomerData()
        })
        .catch((error) => {
          console.error('Error saving customer address:', error);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="add-customeraddress-sidebar">
      <div className="side-padding">
        <div className="container-fluid">
          <div className="">
            <div className="">
              <b>{t('cust.Customer Address')}</b>
              <p className="font14">{t('cust.details')}</p>
            </div>

            <div className="close-icon">
              <i
                className="fa-solid fa-circle-xmark"
                onClick={handleCloseAddress}
              ></i>
            </div>
          </div>

          <div className="radi ">
            <input
              type="radio"
              id="Billing"
              name="addressType"
              value="Billing"
              className="radio mr-2 cursor-pointer"
              checked={addressType === "Billing"}
              onChange={handleAddressTypeChange}
            />
            <label htmlFor="Billing">{t("sidebarcustomers.Billing Address ")}</label>

            <input
              type="radio"
              id="Shipping"
              name="addressType"
              value="Shipping"
              className="radio ml-2 mr-2 cursor-pointer"
              checked={addressType === "Shipping"}
              onChange={handleAddressTypeChange}
            />
            <label htmlFor="Shipping">{t("sidebarcustomers.Shipping Address ")}</label>
          </div>

          <hr className="mt-5" />
          <div className="sidebar-wrapper-update">
            <div className="font14">
              <label htmlFor="cars">{t("sidebarcustomers.Tag :")}</label>
              <select
                name="cars"
                id="cars"
                style={{ cursor: "pointer" }}
                value={selectedTag}
                onChange={handleTagChange}>
                <option value="">{t("sidebarcustomers.Select Address")}</option>
                <option value="इतर">इतर</option>
                <option value="घर">घर</option>
                <option value="ऑफिस">ऑफिस</option>
              </select>
              {errors.tag && <div className="text-danger mt-2 font14">{errors.tag}</div>}
            </div>

            <form className="">
              <div>
                <div className="mt-2">
                  <div className="">
                    <label className="font14 mt-1">
                      {t("sidebarcustomers.Line1")}<span className="text-danger">*</span>
                    </label>{" "}
                    <br />
                    <input
                      className={`add-Address-inputs pl-2 ${errors.line1 ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.Line1")}
                      name="line1"
                      value={line1}
                      onChange={handleLine1Change}
                    />
                    {errors.line1 && <div className="text-danger mt-2 font14">{errors.line1}</div>}
                  </div>

                  <div className="">
                    <label className="font14 mt-2">
                      {t("sidebarcustomers.Line2")}<span className="text-danger">*</span>
                    </label>
                    <br />
                    <input
                      className={`add-Address-inputs input_width1 pl-2 ${errors.line2 ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.Line2")}
                      name="line2"
                      value={line2}
                      onChange={handleLine2Change}
                    />
                    {errors.line2 && <div className="text-danger mt-2 font14">{errors.line2}</div>}
                  </div>
                </div>

                <div className="mt-2">
                  <div className="">
                    <label className="font14 mt-2">
                      {t("sidebarcustomers.City")}<span className="text-danger">*</span>
                    </label>
                    <br />
                    <input
                      className={`add-Address-inputs pl-2 ${errors.city ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.City")}
                      name="city"
                      value={city}
                      onChange={handleCityChange}
                    />
                    {errors.city && <div className="text-danger mt-2 font14">{errors.city}</div>}
                  </div>

                  <div>
                    <label className="font14 mt-2">
                      {t("sidebarcustomers.State")}<span className="text-danger">*</span>
                    </label>{" "}
                    <br />
                    <input
                      className={`add-Address-inputs pl-2 ${errors.state ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.State")}
                      name="state"
                      value={state}
                      onChange={handleStateChange}
                    />
                    {errors.state && <div className="text-danger mt-2 font14">{errors.state}</div>}
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <label className="font14 mt-2">
                      {t("sidebarcustomers.Country")}<span className="text-danger">*</span>
                    </label>{" "}
                    <br />
                    <input
                      className={`add-Address-inputs pl-2 ${errors.country ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.Country")}
                      name="country"
                      value={country}
                      onChange={handleCountryChange}
                    />
                    {errors.country && <div className="text-danger mt-2 font14">{errors.country}</div>}
                  </div>

                  <div className="pt-2">
                    <label className="font14 mt-2">
                      {t("sidebarcustomers.Zip")}<span className="text-danger">*</span>{" "}
                    </label>
                    <br />
                    <input
                      type="text"
                      className={`add-Address-inputs pl-2 ${errors.zip ? 'error-border' : ''}`}
                      placeholder={t("sidebarcustomers.Zip")}
                      name="zip"
                      value={zip}
                      maxLength="6"
                      onChange={handleZipChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                      }}
                    />
                    {errors.zip && <div className="text-danger mt-2 font14">{errors.zip}</div>}
                  </div>
                </div>
              </div>

              <div className="set-button footer-btn-container">
                <div className="d-flex">
                  <button className="saveDraft-btn mt-4"
                    onClick={handleUpdate}
                    style={{ width: "94px", height: "30px" }}>
                    {isLoading ? (
                      t("customer.Updating..")
                    ) : (
                      t("Customer.update")
                    )}
                  </button>

                  <button type="button"
                    className="CloseBtn mt-4 ml-2"
                    onClick={handleCloseAddress}>
                    {t("sidebarcustomersbtn.Cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerAddress;
