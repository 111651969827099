//Production URL
const API = {
  peopleManagentService: "https://api.baap.company/member",
  commerceAPI: "https://api.baap.company/commerce",
  authAPI: "https://api.baap.company/auth",
  apiGateway: "https://api.baap.company/gateway",
  upiVerify: "https://h3gr9lo7kb.execute-api.ap-south-1.amazonaws.com",
  sendInvoice: "https://api.baap.company/whatsapp",
  s3Url: 'https://baap-app-images.s3.ap-south-1.amazonaws.com/',
  memberUpdate: "https://so1h005xk4.execute-api.ap-south-1.amazonaws.com",
  pdfGenerator: "https://api.baap.company/v1/api/pdf-generator",
  imageUpload: 'https://mzdj9zyo62.execute-api.ap-south-1.amazonaws.com',
  categoryAPI: "https://t1ojrighqa.execute-api.us-east-2.amazonaws.com",
  peopleManagement: "https://api.baap.company/member"
}
export default API