import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';

function SupplierAddressSidebar({ supplier, cancel, handleCloseSide }) {
  const { supplierId } = useParams();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState({
    tag: "",
    locality: "",
    city: "",
    stateName: "",
    country: "",
    zip: "",
  });

  const handleSupplierAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleAddressTypeChange = (e) => {
    setSelectedAddressType(e.target.value);
    setAddress({
      tag: '',
      locality: '',
      city: '',
      stateName: '',
      country: '',
      zip: '',
    });
    setErrors({});
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    const requiredFields = ['tag', 'locality', 'city', 'stateName', 'country', 'zip'];
    requiredFields.forEach((field) => {
      if (!address[field]) {
        formIsValid = false;
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (!/^\d{6}$/.test(address.zip)) {
      formIsValid = false;
      newErrors.zip = 'Invalid ZIP code';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    if (validateForm()) {
      setLoading(true);

      const formattedAddress = { ...address };

      try {
        const response = await saveAddress(formattedAddress, selectedAddressType);
        setAddress({
          tag: '',
          locality: '',
          city: '',
          stateName: '',
          country: '',
          zip: ''
        });
        toast.success('Address Created Successfully!', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        setTimeout(() => {
          cancel();
        }, 1000);
        handleCloseSide();
      } catch (error) {
        console.error('Error saving address:', error);
        toast.error('Failed to save address');
      } finally {
        setLoading(false);
      }
    }
  };

  const cancelAddress = () => {
    setAddress({
      tag: '',
      locality: '',
      city: '',
      stateName: '',
      country: '',
      zip: ''
    });
    setErrors({});
  };

  const saveAddress = async (address) => {
    if (!supplier.addresses) {
      supplier.addresses = [];
    }
    supplier.addresses.push(address);

    const response = await fetch(`${API.commerceAPI}/commerce/supplier/updateBySupplierId/${supplierId}`, {
      method: 'PUT',
      body: JSON.stringify(supplier),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to save address');
    }

    return response.json();
  };

  return (
    <div className='padding'>
      <div className='supplier-sidebars'>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="#F5F5FC"
          toastStyle={{ color: 'black' }}
        />

        <div className='suppliers-padding'>
          <p className='suppliers-style'>{t("SupplierAddress.Address Suppliers")}</p>
          <div className='close-icon'>
            <i className='fa-solid fa-circle-xmark mr-4' onClick={handleCloseSide}></i>
          </div>
          <p className="text-color1">Bank Details: {supplier.supplierId}</p>
          <hr className='suppliers-hr' />

          <form onSubmit={handleSubmit}>
            <div className="supplier-scrollar2">
              <div className=" ">
                <label className='text-color1'>{t("SupplierAddress.Tag")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  type='text'
                  placeholder={t("SupplierAddress.Tag")}
                  name='tag'
                  className={`inputes1  ${errors.tag ? 'error-border' : ''}`}
                  value={address.tag || ''}
                  onChange={handleSupplierAddressChange}
                />
              </div>
              {errors.tag && <p className='validation-error ml-1'>{errors.tag}</p>}

              <div className="pt-1">
                <label className='text-color1'>{t("SupplierAddress.locality")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  type='text'
                  placeholder={t("SupplierAddress.locality")}
                  name='locality'
                  className={`inputes1  ${errors.locality ? 'error-border' : ''}`}
                  value={address.locality || ''}
                  onChange={handleSupplierAddressChange}
                />
              </div>
              {errors.locality && <p className='validation-error ml-1'>{errors.locality}</p>}

              <div className="pt-1">
                <label className='text-color1'>{t("SupplierAddress.city")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  type='text'
                  placeholder={t("SupplierAddress.city")}
                  name='city'
                  className={`inputes1  ${errors.city ? 'error-border' : ''}`}
                  value={address.city || ''}
                  onChange={handleSupplierAddressChange}
                />
              </div>
              {errors.city && <p className='validation-error ml-1'>{errors.city}</p>}

              <div className="pt-1">
                <label className='text-color1'>{t("SupplierAddress.Country / Region")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  className={`inputes-country`}
                  name='country'
                  value={address.country || ''}
                  onChange={handleSupplierAddressChange} />
              </div>
              {errors.country && <p className='validation-error ml-1'>{errors.country}</p>}

              <div className="pt-1">
                <label className='text-color1'>{t("SupplierAddress.State")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  className={`inputes-country`}
                  name='stateName'
                  value={address.stateName || ''}
                  onChange={handleSupplierAddressChange}>
                </input>
                {errors.stateName && <p className='validation-error ml-1'>{errors.stateName}</p>}
              </div>

              <div className="pt-1">
                <label className='text-color1'>{t("SupplierAddress.ZIP")}&nbsp;<span className='text-danger'>*</span></label><br />
                <input
                  type='text'
                  placeholder={t("SupplierAddress.ZIP")}
                  name='zip'
                  className={`inputes1  ${errors.zip ? 'error-border' : ''}`}
                  value={address.zip}
                  onChange={handleSupplierAddressChange}
                  maxLength={6}
                  pattern="[0-9]{0,6}"
                />
                {errors.zip && <p className='validation-error ml-1'>{errors.zip}</p>}
              </div>
            </div>

            <div className='mt-3'>
              <button className='save-supplier' type='submit' disabled={loading}>
                {t(loading ? 'intentCreate.Saving....' : 'product.Save')}
              </button>
              <button className='cancel-supplier ml-2 ' onClick={cancelAddress}>{t("AddSupplier.Cancel")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SupplierAddressSidebar;