import React, { useState } from 'react';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

function EmployeePaymentInfo({ openPaymentinfoSidebar, setOpenPaymentinfoSidebar, fetchEmployeeData, userId, userData }) {
  const [bank, setBank] = useState(userData?.bank || '');
  const [upi, setUpi] = useState(userData?.upi || '');
  const [upiError, setUpiError] = useState('');
  const [accountNo, setAccountNo] = useState(userData?.accountNo || '');
  const [ifsc, setIfsc] = useState(userData?.ifsc || '');
  const [errors, setErrors] = useState({});
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLanguage();

  const [touchedFields, setTouchedFields] = useState({
    bank: false,
    upi: false,
    accountNo: false,
    ifsc: false
  });

  const handleClose = () => {
    setOpenPaymentinfoSidebar(!openPaymentinfoSidebar);
  };

  const handleClick = () => {
    setBank('');
    setUpi('');
    setAccountNo('');
    setIfsc('');
  };

  const handleBankChange = (e) => {
    setBank(e.target.value);
    setTouchedFields({ ...touchedFields, bank: true });
    clearError('bank');
  };

  const handleUpiChange = (e) => {
    setUpi(e.target.value);
    setUpiError('');

    const regex = /^[a-zA-Z0-9@]+$/;
    const onlyNumbers = /^\d+$/;
    const onlyCharacters = /^[a-zA-Z]+$/;

    if (!regex.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    } else if (onlyNumbers.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    } else if (onlyCharacters.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    }
  };

  const handleAccountNoChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,16}$/.test(value)) {
      setAccountNo(value);

      if (value?.length < 12) {
        setErrors({ ...errors, accountNo: 'Account number must be at least 12 digits' });
      } else {
        clearError('accountNo');
      }
    }
  };

  const handleIfscNoChange = (e) => {
    const ifscValue = e.target.value;
    const formattedIfsc = ifscValue.trim().toUpperCase();
    setIfsc(formattedIfsc);
    const isValidIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(formattedIfsc);
  };

  const accountNoPattern = /^\d{9,18}$/;
  const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  const isValidAccountNo = (accountNo) => {
    return accountNoPattern.test(accountNo);
  };

  const isValidIFSC = (ifsc) => {
    return ifscPattern.test(ifsc);
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!bank.trim()) {
      errors.bank = 'Bank name is required';
    }

    if (!upi) {
      setUpiError('UPI is required');
      isValid = false;
    } else if (upiError) {
      isValid = false;
    }

    if (!accountNo.trim()) {
      errors.accountNo = 'Account number is required';
    } else if (!isValidAccountNo(accountNo)) {
      errors.accountNo = 'Invalid Account number';
    }

    if (!ifsc.trim()) {
      errors.ifsc = 'IFSC is required';
    } else if (!isValidIFSC(ifsc)) {
      errors.ifsc = 'Invalid IFSC';
    }

    setErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const data = {
        userId: parseInt(userId),
        bank,
        upi,
        accountNo,
        ifsc
      };

      fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to update customer information');
          }

          setOpenPaymentinfoSidebar(false);
          fetchEmployeeData();
        })
        .catch((error) => {
          console.error('Error updating customer information:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: ''
      };
    });
  };

  return (
    <>
      <div className="add-customerinfo-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0">
            <div className=" container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b>{t('Payment.Payment Info')}</b>
                </h6>
                <p className="font14  title-info pl-1">{t('Payment.details')}</p>
                <hr className="sr-hr-info pl-1  "></hr>
              </div>

              <div className="close-icon">
                <i className="fa-solid fa-circle-xmark" onClick={handleClose}> </i>
              </div>
            </div>

            <div className='sidebar-wrapper pl-3'>
              <form className="font14 ml-1">
                <div>
                  <label className="font14">{t('Payment.Bank')}<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.bank ? 'error-border' : ''}`}
                    placeholder={t('Payment.Bank')}
                    value={bank}
                    onChange={handleBankChange}
                  ></input>
                  {errors.bank && <div className="text-danger mt-2 font14">{errors.bank}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">UPI</label><br />
                  <input
                    className={`payment-input ${upiError ? 'error-border' : ''}`}
                    placeholder={t("SupplierBank.UPI")}
                    name="upi"
                    value={upi}
                    onChange={handleUpiChange}
                  /><br />
                  <span className='err-alert'>{upiError}</span>
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">{t('Payment.Account No')}<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.accountNo ? 'error-border' : ''}`}
                    placeholder={t('Payment.Account No')}
                    value={accountNo}
                    onChange={handleAccountNoChange}
                    maxLength={12}
                  ></input>
                  {errors.accountNo && <div className="text-danger mt-2 font14">{errors.accountNo}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">IFSC<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.ifsc ? 'error-border' : ''}`}
                    placeholder="IFSC"
                    maxLength="11"
                    value={ifsc}
                    onChange={handleIfscNoChange}
                  />
                  {errors.ifsc && <div className="text-danger mt-2 font14">{errors.ifsc}</div>}
                </div>
              </form>
            </div>

            <div className="mt-3 pl-3 set-button">
              <button className="saveDraft-btn mt-4" onClick={handleUpdate}>
                {isLoading ? (
                  <i className="spinner-border spinner-border-sm  spin"></i>
                ) : (
                  t('Personal.Update'))}
              </button>

              <button className="cancel-info-btn ml-2" onClick={handleClick}>
                {t('Personal.Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeePaymentInfo;