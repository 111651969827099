import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import './Packages.scss';
import React, { useState, useEffect } from 'react';
import API from '../../services/Api';
import { toast, ToastContainer } from 'react-toastify';
import Order from '../../components/order-sidebar/OrderSidebar';

const Packages = () => {
    const { t } = useLanguage();
    const location = useLocation();
    const [notShippedPackages, setNotShippedPackages] = useState([]);
    const [shippedPackages, setShippedPackages] = useState([]);
    const [deliveredPackages, setDeliveredPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState();
    const [isPackageSelected, setIsPackagesSelected] = useState(null)
    const [checkedItems, setCheckedItems] = useState([])
    const [allCheck, setAllCheck] = useState(false)
    const [shippedChecked, setShippedChecked] = useState([])
    const [allCheckShipped, setAllCheckShipped] = useState(false)
    const [deliveredChecked, setDeliveredChecked] = useState([])
    const [allCheckedDelivered, setAllCheckedDelivered] = useState(false)
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

    const handleXmarkClick = () => {
        setCheckedItems([]);
        setAllCheck(false);
        setShippedChecked([])
        setAllCheckShipped(false)
        setDeliveredChecked([])
        setAllCheckedDelivered(false)
    };

    useEffect(() => {
        if (!groupId) {
            setError("GroupId not found");
            return;
        }
        fetchPackagesForOrder()
        fetchAllInProgressOrders();
        fetchAllDeliveryOrders();
    }, [groupId]);

    const fetchAllData = async () => {
        await fetchPackagesForOrder();
        await fetchAllInProgressOrders();
        await fetchAllDeliveryOrders();
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    const fetchPackagesForOrder = async () => {
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/order/order/group/${groupId}?status=package`, {
                method: 'GET'
            });
            if (response.ok) {
                const data = await response.json();
                setNotShippedPackages(data?.data?.items || []);
                setLoading(false);
                setCheckedItems([])
                setAllCheck(false);
            } else {
                setLoading(false);
                throw new Error('Error fetching packages for the specific order');
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchAllInProgressOrders = async () => {
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/order/order/group/${groupId}?status=shipment`, {
                method: 'GET'
            });
            if (response.ok) {
                const data = await response.json();
                setShippedPackages(data?.data?.items || []);
                setLoading(false)
                setShippedChecked([])
                setAllCheckShipped(false)
            } else {
                throw new Error('Failed to fetch in-progress packages');
            }
        } catch (error) {
            setError('Error fetching in-progress packages');
            setLoading(false);
        }
    };

    const fetchAllDeliveryOrders = async () => {
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/order/order/group/${groupId}?status=delivered`, {
                method: 'GET'
            });
            if (response.ok) {
                const data = await response.json();
                setDeliveredPackages(data?.data?.items || []);
                setLoading(false)
                setDeliveredChecked([])
                setAllCheckedDelivered(false)
            } else {
                throw new Error('Failed to fetch delivered packages');
            }
        } catch (error) {
            setError('Error fetching delivered packages');
            setLoading(false);
        }
    };

    const handleDeletePackage = async () => {
        const packagesDeleting = [
            ...checkedItems.map(index => notShippedPackages[index]?.orderId),
            ...shippedChecked.map(index => shippedPackages[index]?.orderId),
            ...deliveredChecked.map(index => deliveredPackages[index]?.orderId)
        ].filter(orderId => orderId);

        for (let orderId of packagesDeleting) {
            const payload = {
                groupId: parseInt(groupId),
                orderId,
                status: "new"
            };
            try {
                setIsDeleting(true)
                const response = await fetch(`${API.apiGateway}/commerce-gw/assign/order`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${refreshToken}`
                    },
                    body: JSON.stringify(payload)
                });
                if (response.ok) {
                    toast.success(`Package deleted successfully.`, {
                        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                    })
                    fetchAllData()
                    setIsDeleteConfirmationOpen(false)
                    setIsDeleting(false)
                } else {
                    throw new Error(`Failed to delete package`);
                }

            } catch (error) {
                console.error("Error deleting selected packages", error);
                toast.error("Error deleting selected packages.");
                setIsDeleteConfirmationOpen(false);
                setIsDeleting(false)
            }
        }
    };

    const handleAllChecked = (event) => {
        if (event.target.checked) {
            let newCheckedItems = [...new Set([...checkedItems, ...notShippedPackages.map((_, index) => index)])];
            setCheckedItems(newCheckedItems);
        } else {
            let uncheckedItems = checkedItems.filter(index => !notShippedPackages.map((_, i) => i).includes(index));
            setCheckedItems(uncheckedItems);
        }
        setAllCheck(event.target.checked);
    };

    const handleAllCheckedShipped = (event) => {
        if (event.target.checked) {
            let newshippedChecked = [...new Set([...shippedChecked, ...shippedPackages.map((_, index) => index)])];
            setShippedChecked(newshippedChecked);
        } else {
            let uncheckedShippedItems = shippedChecked.filter(index => !shippedPackages.map((_, i) => i).includes(index));
            setShippedChecked(uncheckedShippedItems);
        }
        setAllCheckShipped(event.target.checked);
    }

    const handleAllCheckedDelivered = (event) => {
        if (event.target.checked) {
            let newDeliveredChecked = [...new Set([...deliveredChecked, ...deliveredPackages.map((_, index) => index)])];
            setDeliveredChecked(newDeliveredChecked);
        } else {
            let uncheckedDeliveredItems = deliveredChecked.filter(index => !deliveredPackages.map((_, i) => i).includes(index));
            setDeliveredChecked(uncheckedDeliveredItems);
        }
        setAllCheckedDelivered(event.target.checked);
    }

    const handleSingleCheckboxChange = (event, index) => {
        if (event.target.checked) {
            setCheckedItems([...new Set([...checkedItems, index])]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== index));
        }
    };

    const handleSingleCheckboxChangeShipped = (event, index) => {
        if (event.target.checked) {
            setShippedChecked([...new Set([...shippedChecked, index])]);
        } else {
            setShippedChecked(shippedChecked.filter((item) => item !== index));
        }
    };

    const handleSingleCheckboxChangeDelivered = (event, index) => {
        if (event.target.checked) {
            setDeliveredChecked([...new Set([...deliveredChecked, index])]);
        } else {
            setDeliveredChecked(deliveredChecked.filter((item) => item !== index));
        }
    }

    useEffect(() => {
        setAllCheck(checkedItems.length === notShippedPackages.length);
    }, [checkedItems, notShippedPackages.length]);

    useEffect(() => {
        setAllCheckShipped(shippedChecked.length === shippedPackages.length);
    }, [shippedChecked, shippedPackages.length]);

    useEffect(() => {
        setAllCheckedDelivered(deliveredChecked.length === deliveredPackages.length);
    }, [deliveredChecked, deliveredPackages.length]);


    const handleOpenSidebar = (orderId) => {
        setIsOpen(true);
        setIsPackagesSelected(orderId);
    };

    const handleClose = () => {
        setIsOpen(!isOpen);
    };

    const handleDeletePackages = () => {
        setIsDeleteConfirmationOpen(true)
    }

    const handleCloseDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(false)
    }

    const truncateText = (text, maxLength) => {
        return text?.length <= maxLength ? text : text.substring(0, maxLength) + "...";
    }

    return (
        <div className="container-fluid">
            <ToastContainer autoClose={2000} />
            <div className='row'>
                <div className='col-2'>
                </div>
            </div>

            <div className='mt-3'>
                <div className='line-heigh'>
                    <p className='service-request-title'>
                        <b>{t("package.All Packages")}</b>
                    </p>
                </div>
            </div>

            <div className='main-package'>
                <div className='row'>
                    <div className='col-3 main-2'>
                        <div className='not-shiping'>

                            {checkedItems.length > 0 ? (
                                <>
                                    <input type='checkbox'
                                        className='cursor-pointer package-name'
                                        onChange={handleAllChecked}
                                        checked={allCheck}
                                    />
                                    <i className='fa-solid fa-trash delete-package font13'
                                        onClick={handleDeletePackages}
                                    ></i>
                                    <i className="fa-solid fa-xmark icon pl-4" onClick={handleXmarkClick}></i>
                                </>
                            ) : (
                                <>
                                    <span className='package-name'>{t('package.Package')}</span>
                                    <i className='fa-solid fa-bars icon pl-4'></i>
                                </>
                            )}
                        </div>
                        <div className='scrollbar-package'>
                            {loading ? (
                                <div className='loader' role='status'>
                                </div>
                            ) : error ? (
                                <div>
                                    <img src="/no-data-image.png" alt="No data found" className="allorder-img mt-5 ml-4" />
                                </div>
                            ) : notShippedPackages.length === 0 ? (
                                <div className='card card-style'>
                                    <div className='no-data-found'>
                                        <b>No Package Found</b>
                                    </div>
                                </div>
                            ) : (
                                notShippedPackages.map((items, index) => (
                                    <div key={index} className='card card-style'>
                                        <span className='row mt-3'>
                                            <div className='col-1 checkbox-package'>
                                                <input type='checkbox'
                                                    className='cursor-pointer'
                                                    onChange={(e) => handleSingleCheckboxChange(e, index)}
                                                    checked={checkedItems.includes(index)}
                                                />
                                            </div>
                                            <b className='font16 col-7 pl-3 cursor-pointer pack-colors'
                                                onClick={() => handleOpenSidebar(items)}>
                                                {truncateText(items?.name, 17)}
                                            </b>
                                            <b className='font-style col-3'>{items.orderDetails[0]?.quantity || 0}</b>
                                        </span>
                                        <div className='packing'>
                                            <span className='oid-id'>OID-</span><span className='text-primary'>{items?.orderId}</span>                                            <br />
                                            <span className='font-style pl-1'>{formatDate(items?.orderDate)}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                    <div className='col-3 main-2'>
                        <div className='ship-pack'>
                            {shippedChecked.length > 0 ? (
                                <>
                                    <input type='checkbox'
                                        className='cursor-pointer package-name'
                                        onChange={handleAllCheckedShipped}
                                        checked={allCheckShipped}
                                    />
                                    <i className='fa-solid fa-trash delete-package font13'
                                        onClick={handleDeletePackages}
                                    ></i>
                                    <i className="fa-solid fa-xmark icon pl-4" onClick={handleXmarkClick}></i>
                                </>
                            ) : (
                                <>
                                    <span className='package-name'>{t("package.Shipped Packages")}</span>
                                    <i className="fa-solid fa-bars icon pl-4"></i>
                                </>
                            )}

                        </div>
                        <div className='scrollbar-package'>
                            {loading ? (
                                <div className='loader' role='status'>
                                </div>
                            ) : error ? (
                                <div>
                                    <img src="/no-data-image.png" alt="No data found" className="allorder-img mt-5 ml-4" />
                                </div>
                            ) : shippedPackages.length === 0 ? (
                                <div className='card card-style'>
                                    <div className='no-data-found'>
                                        <b>No Shipped Package Found</b>
                                    </div>
                                </div>
                            ) : (
                                shippedPackages.map((items, index) => (
                                    <div key={index} className='card card-style'>
                                        <span className='row mt-3'>
                                            <div className='col-1 checkbox-package'>
                                                <input type='checkbox'
                                                    className='cursor-pointer'
                                                    onChange={(e) => handleSingleCheckboxChangeShipped(e, index)}
                                                    checked={shippedChecked.includes(index)}
                                                />
                                            </div>
                                            <b className='font16 col-7 pl-3 cursor-pointer pack-colors'
                                                onClick={() => handleOpenSidebar(items)}>
                                                {truncateText(items?.name, 17)}
                                            </b>
                                            <b className='font-style col-3 pl-3'>{items.orderDetails[0]?.quantity || 0}</b>
                                        </span>
                                        <div className='packing'>
                                            <span className='oid-id'>OID-</span><span className='text-primary'>{items?.orderId}</span>                                            <br />
                                            <span className='font-style pl-1'>{formatDate(items?.orderDate)}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                    <div className='col-3 main-2'>
                        <div className='deli-pack'>
                            {deliveredChecked.length > 0 ? (
                                <>
                                    <input type='checkbox'
                                        className='cursor-pointer package-name'
                                        onChange={handleAllCheckedDelivered}
                                        checked={allCheckedDelivered}
                                    />
                                    <i className='fa-solid fa-trash delete-package font13'
                                        onClick={handleDeletePackages}
                                    ></i>
                                    <i className="fa-solid fa-xmark icon pl-4" onClick={handleXmarkClick}></i>
                                </>
                            ) : (
                                <>
                                    <span className='package-name'>{t("package.Delivered Packages")}</span>
                                    <i className="fa-solid fa-bars icon pl-4"></i>
                                </>
                            )}
                        </div>
                        <div className='scrollbar-package'>
                            {loading ? (
                                <div className='loader' role='status'>
                                </div>
                            ) : error ? (
                                <div>
                                    <img src="/no-data-image.png" alt="No data found" className="allorder-img mt-5 ml-4" />
                                </div>
                            ) : deliveredPackages.length === 0 ? (
                                <div className='card card-style'>
                                    <div className='no-data-found'>
                                        <b>No Delivered Package Found</b>
                                    </div>
                                </div>
                            ) : (
                                deliveredPackages.map((items, index) => (
                                    <div key={index} className='card card-style'>
                                        <span className='row mt-3'>
                                            <div className='col-1 checkbox-package'>
                                                <input type='checkbox'
                                                    className='cursor-pointer'
                                                    onChange={(e) => handleSingleCheckboxChangeDelivered(e, index)}
                                                    checked={deliveredChecked.includes(index)}
                                                />
                                            </div>
                                            <b className='font16 col-7 pl-3 cursor-pointer pack-colors'
                                                onClick={() => handleOpenSidebar(items)}>
                                                {truncateText(items?.name, 17)}
                                            </b>
                                            <b className='font-style col-3 pl-3'>{items.orderDetails[0]?.quantity || 0}</b>
                                        </span>
                                        <div className='packing'>
                                            <span className='oid-id'>OID-</span><span className='text-primary'>{items?.orderId}</span>
                                            <br />
                                            <span className='font-style pl-1'>{formatDate(items?.orderDate)}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>

            </div>
            {
                isOpen && (
                    <Order
                        handleclose={handleClose}
                        selectedOrders={isPackageSelected}
                        setSelectedOrders={setIsPackagesSelected}
                        setOpenSidebar={setIsOpen}
                        fetchData={fetchAllData}
                    />
                )
            }

            {isDeleteConfirmationOpen && (
                <div className="term-popup-box">
                    <div className="popup_content">
                        <p>{t('package.Are')}</p>
                        <div className="popup_buttons">
                            <button className="terms-delete" onClick={handleDeletePackage}>{t(isDeleting ? 'Yes...' : 'terms.Yes')}</button>
                            <button className="terms-nodelete" onClick={handleCloseDeleteConfirmation}>{t('terms.No')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Packages;
