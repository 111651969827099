import React, { useState} from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext'
import STORAGE_KEYS from '../../utils/storageKey';

const AddConfiguration = ({ setOpenConfigSidebar, openConfigSidebar, fetchConfigOnSetting, deparment, locations }) => {
    const handleClose = () => {
        setOpenConfigSidebar(!openConfigSidebar);
    };

    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const { t } = useLanguage();

    const [formData, setFormData] = useState({
        key: '',
        dataType: '',
        value: '',
        type: '',
        appId: 1696412566259,
        location: '',
        deparment: '',
        groupId: parseInt(groupId)
    });

    const validateForm = () => {
        const errors = {};

        if (!formData.key) {
            errors.key = "Key is required";
        }

        if (!formData.dataType) {
            errors.dataType = "Data type is required";
        }

        if (!formData.value) {
            errors.value = "Value is required";
        }

        if (!formData.type) {
            errors.type = "Type is required";
        }
        setFormErrors(errors);
        return Object.keys(errors) ?.length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!validateForm()) return;
        setIsLoading(true);
        let dynamicValue = formData.value;
        if (formData.dataType === "number") {
            dynamicValue = parseInt(formData.value);
        } else if (formData.dataType === "boolean") {
            dynamicValue = formData.value === "true";
        } else if (formData.dataType === "array") {
            dynamicValue = formData.value.split(',').map(item => item.trim());
        }
        const payload = {
            key: formData.key,
            dataType: formData.dataType,
            [formData.key]: dynamicValue,
            type: formData.type,
            appId: formData.appId,
            location: formData.location,
            department: formData.deparment,
            groupId: formData.groupId
        };

        try {
            const response = await fetch(`${API.commerceAPI}/commerce/configuration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setOpenConfigSidebar(false);
            fetchConfigOnSetting();
            setIsLoading(false);
            toast.success('Config Created Successfully !', {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsLoading(false);
            toast.error('Failed to save config');
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ background: "#FF0000", color: "white" }}
            />

            <div className="add-customer-sidebar">
                <div className="side-padding">
                    <div className="container-fluid p-0">
                        <div className="container-fluid add-customer-title">
                            <div>
                                <h6 className="add-customer-title-1">
                                    <b>
                                        {t('Config.Add Config')}
                                    </b>
                                </h6>
                                <p className="font14  title-info">
                                    {t('Config.config details')}
                                </p>
                                <hr className="sr-hr-info"></hr>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="font14">{t('Config.Key')}
                                    <span className="star_color">*</span>
                                </label>
                                <br />
                                <input
                                    type="text"
                                    className={`pl-2 font14 add-config-inputs ${formErrors.key && 'error-border'}`}
                                    placeholder={t('Config.Enter Key Name')}
                                    name="key"
                                    value={formData.key}
                                    onChange={handleChange}
                                />
                                {formErrors.key && <div className="error-message config-validation">{formErrors.key}</div>}
                            </div>

                            <div className="col-4">
                                <label className="font14">{t('Config.Data Type')}
                                    <span className="star_color">*</span>
                                </label>
                                <br />
                                <select
                                    className={`pl-2 font14 add-config-inputs cursor-pointer ${formErrors.dataType && 'error-border'}`}
                                    name="dataType"
                                    value={formData.dataType}
                                    onChange={handleChange}>
                                    <option value="">{t('Config.Data Type')}</option>
                                    <option value="array">Array</option>
                                    <option value='number'>Number</option>
                                    <option value="string">String</option>
                                    <option value="boolean">Boolean</option>
                                </select>
                                {formErrors.dataType && <div className="error-message config-validation">{formErrors.dataType}</div>}
                            </div>

                            <div className="col-4">
                                <label className="font14">{t('Config.Value')}
                                    <span className="star_color">*</span>
                                </label>
                                <br />
                                <input
                                    type="text"
                                    className={`pl-2 font14 add-config-inputs ${formErrors.value && 'error-border'}`}
                                    placeholder={t('Config.Enter Value')}
                                    name="value"
                                    value={formData.value}
                                    onChange={handleChange}
                                />
                                {formErrors.value && <div className="error-message config-validation">{formErrors.value}</div>}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <label className="font14">{t('Config.Type')}
                                    <span className="star_color">*</span>
                                </label>
                                <br />
                                <select
                                    className={`pl-2 font14 add-config-inputs cursor-pointer ${formErrors.type && 'error-border'}`}
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}>
                                    <option>{t('Config.Select Type')}</option>
                                    <option>Backend</option>
                                    <option>Frontend</option>
                                    <option>Both</option>
                                </select>
                                {formErrors.type && <div className="error-message config-validation">{formErrors.type}</div>}

                            </div>
                            <div className="col-4">
                                <label className="font14">{t('Config.Location')}</label>
                                <select
                                    className="pl-2 font14 add-config-inputs cursor-pointer"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}>
                                    <option>{t('Config.Select Location')}</option>
                                    {Array.isArray(locations) && locations.map((location) => (
                                        <option key={location.id} value={location.name}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-4">
                                <label className="font14">{t('Config.Department')}
                                </label>
                                <select
                                    className="pl-2 font14 add-config-inputs cursor-pointer"
                                    name="deparment"
                                    value={formData.deparment}
                                    onChange={handleChange}>
                                    <option> {t('Config.Select Department')}</option>
                                    {Array.isArray(deparment) && deparment.map((dept) => (
                                        <option key={dept?.id} value={dept?.name}>
                                            {dept?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mt-2"> </div>

                        <div className="mt-3 ml-1 set-button">
                            <button className="saveDraft-btn mt-4" onClick={handleSubmit}
                                disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <span className="mr-2 ">
                                            <i className="spinner-border spinner-border-sm text-dark spin mt-1"></i>
                                        </span>
                                    </>
                                ) : (
                                    t('Config.Save')
                                )}
                            </button>

                            <button className="cancel-info-btn ml-2" onClick={handleClose}>
                                {t('Config.Cancel')}
                            </button>
                        </div>
                    </div></div></div>
        </>
    )
}

export default AddConfiguration