import React, { useState, useEffect, useMemo } from 'react';
import './TermsCondition.scss';
import { useLanguage } from '../../LanguageContext';
import TermsConditionSidebar from '../../components/termsConditionSidebar/TermsConditionsSidebar';
import UpdateTermsCondition from '../../components/termsConditionUpdate/UpdateTermsCondition';
import { toast, ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import TermSkeletonLoader from '../../components/skeleton-loader/TermSkeletonLoader';
import STORAGE_KEYS from '../../utils/storageKey';

const TermsCondition = () => {
    const { t } = useLanguage();
    const [allChecked, setAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const [isOpenTermsSidebar, setIsOpenTermsSidebar] = useState(false);
    const [termsConditions, setTermsConditions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoding, setIsLoding] = useState(true);
    const [editTerms, setEditTerms] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const PageSize = 10;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedTermsConditions, setSelectedTermsConditions] = useState([]);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID)

    const fetchTermsConditions = () => {
        setIsLoding(true);
        fetch(`${API.commerceAPI}/commerce/termsconditions/all/getByGroupId/${groupId}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'Success' && data.data && Array.isArray(data.data.items)) {
                    setTermsConditions(data.data.items.reverse());
                    setIsLoding(false);
                } else {
                    console.error('Unexpected API response:', data);
                    setTermsConditions([]);
                    setIsLoding(false);
                }
                setIsLoding(false);
            })
            .catch(error => {
                console.error('Error fetching terms and conditions:', error);
                setIsLoding(false);
            });
    };

    useEffect(() => {
        fetchTermsConditions();
    }, []);

    const handleAllChecked = (event) => {
        if (event.target.checked) {
            const allIndexes = termsConditions.map((_, index) => index);
            setCheckedItems(allIndexes.map(index => ({ index, termsConditionsId: termsConditions[index].termsConditionsId })));
        } else {
            setCheckedItems([]);
        }
        setAllChecked(event.target.checked);
    };

    const handleSingleCheckboxChange = (event, index) => {
        const termConditionId = termsConditions[index].termsConditionsId;
        if (event.target.checked) {
            setCheckedItems(prevCheckedItems => [
                ...prevCheckedItems,
                { index, termsConditionsId: termConditionId }
            ]);
        } else {
            setCheckedItems(prevCheckedItems =>
                prevCheckedItems.filter(item => item.index !== index)
            );
        }
    };

    const handleDelete = () => {
        if (checkedItems.length === 0) {
            toast.warn('No terms and conditions selected for deletion');
            return;
        }
        setLoading(true);
        const termsConditionsIds = checkedItems.map(item => item.termsConditionsId);
        fetch(`${API.commerceAPI}/commerce/termsconditions/delete/termsConditions`, {
            method: 'DELETE',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                groupId: 1703228300417,
                termsConditionsId: termsConditionsIds
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.message === 'TermsConditions(s) deleted successfully') {
                    fetchTermsConditions(currentPage);
                    setCheckedItems([]);
                    setAllChecked(false);
                    toast.success('Terms and conditions deleted successfully!', {
                        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                        icon: (
                            <i
                                className="fa-solid fa-trash float-right cis pr-2"
                                style={{ color: "white" }}
                            ></i>
                        ),
                        progressClassName: "custom-progress-leads",
                    });
                } else {
                    toast.error('Failed to delete terms and conditions!');
                    console.error('Delete API error:', data);
                }
            })
            .catch(error => {
                toast.error('Error deleting terms and conditions!');
                console.error('Error deleting terms and conditions:', error);
            })
            .finally(() => {
                setLoading(false);
                setIsDeleteConfirmationOpen(false);
            });
    };

    const handleOpenSidebar = () => {
        setIsOpenSidebar(true);
    };

    const handleOpenUpdateSidebar = (selected) => {
        setIsOpenTermsSidebar(true);
        setEditTerms(selected)
    };

    const handleCloseSidebar = () => {
        setIsOpenSidebar(false);
        setIsOpenTermsSidebar(false);
    };

    const handleOpenPopup = (termsConditions) => {
        const activeTerms = Array.isArray(termsConditions)
            ? termsConditions.filter(term => term.is_active)
            : [];

        setSelectedTermsConditions(activeTerms);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(false);
    };

    const onPageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return termsConditions.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, termsConditions]);

    return (
        <div className='main'>
            <ToastContainer autoClose={2000} />

            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='term-flex'>
                        <div className=''>
                            <strong>{t('terms.Terms & Conditions')}</strong><br />
                            <small>{t('terms.Here all the terms & conditions')}</small>
                        </div>

                        <div className='term-flex'>
                            <div className=''>
                                <button
                                    type="button"
                                    className={`terms_delete cursor-pointer ${checkedItems.length === 0 ? "disabled-text" : "enabled-text"}`}
                                    disabled={checkedItems.length === 0}
                                    onClick={handleDeleteConfirmation}>
                                    {t('terms.Delete')}
                                </button>

                            </div>

                            <div className='ml-2'>
                                <button className='terms-button' onClick={handleOpenSidebar}>
                                    {t('terms.Add')}
                                </button>
                            </div>

                            {isOpenSidebar && (
                                <TermsConditionSidebar
                                    handleClose={handleCloseSidebar}
                                    setIsOpenSidebar={setIsOpenSidebar}
                                    fetchTermsConditions={fetchTermsConditions}
                                />
                            )}

                            {isOpenTermsSidebar && (
                                <UpdateTermsCondition
                                    handleClose={handleCloseSidebar}
                                    fetchTermsConditions={fetchTermsConditions}
                                    editTerms={editTerms}
                                />
                            )}
                        </div>
                    </div>
                </div><hr className='term-code-hr' />

                <div className='row'>
                    <div className="table-scroll-sto">
                        <table className="table table-hover">
                            <thead className="font14">
                                <tr className="dark-text-theme table-head">
                                    <th className="checkbox-input">
                                        <input
                                            type="checkbox"
                                            className="cursor-pointer"
                                            name="check"
                                            checked={allChecked}
                                            onChange={handleAllChecked}>
                                        </input>
                                    </th>
                                    <th className="table-head">{t('terms.Module Name')}</th>
                                    <th className="table-head">{t('terms.Terms & Conditions')}</th>
                                    <th className="table-head">{t('terms.Action')}</th>
                                </tr>
                            </thead>

                            {isLoding ? (
                                <TermSkeletonLoader />
                            ) : (
                                <>
                                    {termsConditions.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" className="term-center">
                                                <img src="/no-data-image.png" alt="No data found" />
                                            </td>
                                        </tr>
                                    ) : (
                                        <tbody>
                                            {currentTableData.map((term, index) => (
                                                <tr key={index}>
                                                    <td className="t-data">
                                                        <input
                                                            type="checkbox"
                                                            className="cursor-pointer"
                                                            name="check"
                                                            checked={checkedItems.some(item => item.index === index)}
                                                            onChange={(e) => handleSingleCheckboxChange(e, index)}
                                                        />
                                                    </td>
                                                    <td className='t-data'>{term.propertieType}</td>
                                                    <td className='t-data'>
                                                        <div onClick={() => handleOpenPopup(term?.termsConditions)} className='clickable'>
                                                            {t("term.View all")} [{term.termsConditions.filter(condition => condition.is_active).length}]
                                                        </div>
                                                    </td>

                                                    <td className='t-data cursor-pointer'>
                                                        <i className="fa-solid fa-pen-to-square cancel-term" onClick={() => handleOpenUpdateSidebar(term)}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </>
                            )}
                        </table>

                        {isPopupOpen && (
                            <div className="term-detail-popup">
                                <div className="popup_term">
                                    <div className='popup_flex'>
                                        <div>
                                            <h4>{t('terms.Terms & Conditions')}</h4>
                                        </div>

                                        <div>
                                            <i className="fa-solid fa-circle-xmark cursor-pointer close_popup"
                                                onClick={handleClosePopup}
                                            />
                                        </div>
                                    </div>
                                    <div className='scroll-terms'>
                                        {selectedTermsConditions.length > 0 ? (
                                            selectedTermsConditions.map((tc, idx) => (
                                                <div key={idx}>{tc.index}.{tc.content}</div>
                                            ))
                                        ) : (
                                            <p className='terms-code'>{t("terms.No terms and conditions available.")}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="d-inline-flex pagination-container">
                        <div className="col-12 p-0">
                            <ReactPaginate
                                className="pagination-bar"
                                previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                                nextLabel={<i className="fa-solid fa-angle-right"></i>}
                                breakLabel={'...'}
                                pageCount={Math.ceil(termsConditions.length / PageSize)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={onPageChange}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                pageClassName={'Pagination-products'}
                            />
                        </div>
                    </div>
                </div>

                {isDeleteConfirmationOpen && (
                    <div className="term-popup-box">
                        <div className="popup_content">
                            <p>{t('terms.confirmation')}</p>
                            <div className="popup_buttons">
                                <button className="terms-delete" onClick={handleDelete}>{t('terms.Yes')}</button>
                                <button className="terms-nodelete" onClick={handleCloseDeleteConfirmation}>{t('terms.No')}</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default TermsCondition;