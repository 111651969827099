import "./ConfigurationList.css";
import { useState, useEffect } from "react";
import AddConfiguration from "./AddConfiguration";
import API from "../../services/Api";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateConfiguration from "./UpdateConfiguration";
import DeleteConfigurationPopup from "./DeleteConfiguration";
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from "../../utils/storageKey";

const ConfigurationList = () => {
  const [openConfigSidebar, setOpenConfigSidebar] = useState();
  const [configurations, setConfigurations] = useState([]);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [openConfigUpdateSidebar, setOpenConfigUpdateSidebar] = useState();
  const [locations, setLocations] = useState([]);
  const [deparment, setDepartment] = useState();
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [currentConfigId, setCurrentConfigId] = useState(null);
  const { t } = useLanguage();

  const showDeleteConfirmation = (configurationId) => {
    setCurrentConfigId(configurationId);
    setIsDeleteConfirmationVisible(true);
  };

  const fetchConfigOnSetting = async () => {
    try {
      const response = await fetch(
        `${API.commerceAPI}/commerce/configuration/all/getByGroupId/${groupId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();

      if (responseData.data.message === "Data fetched successfully") {
        setConfigurations(responseData.data.items);
        console.log(
          "Configurations fetched successfully:",
          responseData.data.items
        );
        const isPhoneNumberMaskingConfig = responseData.data.items.find(
          (item) => item.hasOwnProperty('isNumberMasking')
        );
        const isPhoneNumberMasking = isPhoneNumberMaskingConfig ? isPhoneNumberMaskingConfig.isNumberMasking : false;
        localStorage.setItem("isPhoneNumberMasking", isPhoneNumberMasking);

      } else {
        console.error("Failed to fetch configurations:", responseData);
      }
    } catch (error) {
      console.error("Error fetching configurations:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await fetch(
        `${API.peopleManagentService}//peopleManagement/locations/all/getByGroupId/${groupId}?page=1&limit=1000`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setLocations(data?.data?.items);
    } catch (error) { }
  };

  const fetchDepartment = async () => {
    try {
      const response = await fetch(
        `${API.peopleManagentService}//peopleManagement/deparment/groupId/1698658253245?page=1&limit=1000`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setDepartment(data?.data?.items);
    } catch (error) { }
  };

  const handleUpdateButtonClick = (configurationId) => {
    setSelectedConfigId(configurationId);
    setOpenConfigUpdateSidebar(true);
    fetchDepartment();
    fetchLocations();
  };

  const handleCreateConfig = () => {
    setOpenConfigSidebar(true);
    fetchDepartment();
    fetchLocations();
  };

  useEffect(() => {
    fetchConfigOnSetting();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ background: "#FF0000", color: "white" }}
      />

      <div className="main">
        <div className="body_container">
          <div className="main-padding">
            <div className="dashboard-header">
              <div className="line-height">
                <p className="customer-request-title">
                  {t('Config.flags')}
                </p>
                <p className="sub-title">
                  {t('Config.stored')}
                </p>
              </div>

              <div>
                <button
                  className="btn_primary font14 add-config-btn"
                  onClick={handleCreateConfig}>
                  {t('Config.Add Config')}
                </button>

                {openConfigSidebar && (
                  <AddConfiguration
                    openConfigSidebar={openConfigSidebar}
                    setOpenConfigSidebar={setOpenConfigSidebar}
                    fetchConfigOnSetting={fetchConfigOnSetting}
                    deparment={deparment}
                    locations={locations}
                  />
                )}
              </div>
            </div>

            <div className="config-scroll">
              {configurations.map((config, index) => (
                <div key={index} className="config-cardstyle row pt-3 font14">
                  <div className="col-3">
                    <div className=" ml-1">{t('Config.Data Type')}</div>
                    <div className="ml-1 fontw-900">
                      {config.dataType || "--"}
                    </div>

                    <div className="mt-3  ml-1">{t('Config.Id')}</div>
                    <div className=" ml-1 fontw-900">
                      {config.configurationId || "--"}
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="">{t('Config.Key')}</div>
                    <div className="fontw-900">{config.key || "--"} </div>
                    <div className="mt-3">{t('Config.Value')}</div>
                    <div className="fontw-900">
                      {config.dataType === "array" &&
                        Array.isArray(config[config.key]) ? (
                        <ul>
                          {config[config.key].map((item, index) => (
                            <span key={index}>
                              {item} {",  "}
                            </span>
                          ))}
                        </ul>
                      ) : (
                        config[config.key] || "--"
                      )}
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="">{t('Config.Type')}</div>
                    <div className="fontw-900">{config.type || "--"}</div>
                  </div>

                  <div className="col-3 mt-5">
                    <button
                      className="btn_primary"
                      onClick={() => showDeleteConfirmation(config.configurationId)}>
                      {t('Config.Delete')}
                    </button>

                    <button
                      className="ml-4 btn_primary"
                      onClick={() => handleUpdateButtonClick(config)}>
                      {t('Config.Update')}
                    </button>

                    {openConfigUpdateSidebar && (
                      <UpdateConfiguration
                        openConfigUpdateSidebar={openConfigUpdateSidebar}
                        setOpenConfigUpdateSidebar={setOpenConfigUpdateSidebar}
                        deparment={deparment}
                        locations={locations}
                        selectedConfigId={selectedConfigId}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isDeleteConfirmationVisible && (
        <DeleteConfigurationPopup
          onClose={() => setIsDeleteConfirmationVisible(false)}
          fetchConfigOnSetting={fetchConfigOnSetting}
          currentConfigId={currentConfigId}
          selectedConfigId={selectedConfigId}
        />
      )}
    </>
  );
};

export default ConfigurationList;