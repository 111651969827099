import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const PurchaseOrderSkeletonLoader = ({ columns }) => {
    return (
        <SkeletonTheme color="#007bff" highlightColor="#C0C2DE">
            <tbody>
                {[...Array(8)].map((_, index) => (
                    <tr className="font14" key={index}>
                        {[...Array(columns)].map((_, colIndex) => (
                            <td className="t-data" key={colIndex}>
                                <Skeleton />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </SkeletonTheme>
    );
};

export default PurchaseOrderSkeletonLoader;
