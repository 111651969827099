import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './EmployeeInformation.scss';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import DatePicker from 'react-datepicker';
import STORAGE_KEYS from '../../utils/storageKey';

function EmployeeInformation({ openEmployeeinfoSidebar, setOpenEmployeeinfoSidebar, fetchEmployeeData, userId, userData }) {
  const parseDateString = (dateStr) => {
    if (!dateStr) return null;
    const parts = dateStr.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  const [dateOfBirth, setDateOfBirth] = useState(parseDateString(userData?.dateOfBirth));
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [locations, setLocations] = useState(userData?.locations || '');
  const [gender, setGender] = useState(userData?.gender || null);
  const [email, setEmail] = useState(userData?.email || '');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(userData?.name || '');
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || '');
  const { t } = useLanguage();

  const handleClose = () => {
    setOpenEmployeeinfoSidebar(!openEmployeeinfoSidebar);
  };

  const handleSave = () => {
    setGender('');
    setLocations('');
    setEmail('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(sanitizedValue);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    clearError('email');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailInput)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Please enter a valid email address'
      }));
    }
  };

  const handleDateOfBirthChange = (date) => {
    setDateOfBirth(date);
    clearError('dateOfBirth');
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    clearError('gender');
  };

  const handleLocationChange = (e) => {
    setLocations(e.target.value);
    clearError('location');
  };

  const validateForm = () => {
    const errors = {};

    if (!name.trim()) {
      errors.name = 'Name is required';
    }

    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (!gender) {
      errors.gender = 'Gender is required';
    }

    if (!locations.trim()) {
      errors.locations = 'Location is required';
    }
    setErrors(errors);

    return Object.keys(errors)?.length === 0;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      let formattedDateOfBirth = '';
      if (dateOfBirth) {
        const day = String(dateOfBirth.getDate()).padStart(2, '0');
        const month = String(dateOfBirth.getMonth() + 1).padStart(2, '0');
        const year = dateOfBirth.getFullYear();
        formattedDateOfBirth = `${day}-${month}-${year}`;
      }
      const data = {
        userId: parseInt(userId),
        gender,
        locations,
        email,
        dateOfBirth: formattedDateOfBirth,
        name,
        phoneNumber
      };
      fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to update customer information');
          }
          setOpenEmployeeinfoSidebar();
          fetchEmployeeData();
        })
        .catch((error) => {
          console.error('Error updating customer information:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return
    }
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: ''
      };
    });
  };

  return (
    <>
      <div className="add-customerinfo-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0 m-0">
            <div className="container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b className=''>{t('employee.Employee Information')}</b>
                </h6>
                <p className="font14  title-info pl-1">{t('Personal.details')}</p>
                <hr className="sr-hr-info"></hr>
              </div>

              <div className="close-icon">
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={handleClose}
                ></i>
              </div>
            </div>

            <div className='sidebar-wrapper pl-3'>
              <form className="mt-2 font14 ml-1">
                <div>
                  <label className="font14">{t('employee.EmployeeName')}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.name ? 'error-border' : ''}`}
                    value={name}
                    onChange={handleNameChange}
                    placeholder={t('employee.EmployeeName')}
                  />
                  {errors.name && <div className="text-danger mt-2 font14">{errors.name}</div>}
                </div>
                <div>

                  <label className="font14">{t('sidebarcustomers.Phone Number')}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.phoneNumber ? 'error-border' : ''}`}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder={t('sidebarcustomers.Phone Number')}
                  />
                  {errors.phoneNumber && <div className="text-danger mt-2 font14">{errors.phoneNumber}</div>}
                </div>

                <div className="font14 mt-0">
                  <label className='pl-1'>{t('Personal.Gender')}<span className="text-danger mt-1">*</span></label>
                  <input
                    className="ml-2 cursor-pointer"
                    type="radio"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-1">{t('Personal.Male')}</label>
                  <input
                    className="ml-2 cursor-pointer"
                    type="radio"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-1">{t('Personal.Female')}</label>
                  {errors.gender && <div className="text-danger mt-2 font14">{errors.gender}</div>}
                </div>

                <div>
                  <label className="font14">{t('Personal.Location')}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.locations ? 'error-border' : ''}`}
                    value={locations}
                    onChange={handleLocationChange}
                    placeholder={t('Personal.Location')}
                  />
                  {errors.locations && <div className="text-danger mt-2 font14">{errors.locations}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-3">{t("Customer.Date of Birth")}</label>
                  <br />
                  <DatePicker
                    selected={dateOfBirth}
                    onChange={handleDateOfBirthChange}
                    placeholderText="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    id="icondisplay"
                    className='add-Address-inputs line-height-add pl-2 empl-date-picker' // Add your custom class here
                  />
                </div>

                <div className='mt-2'>
                  <label className="font14 pt-3">{t("Customer.Email")}</label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs line-height-add pl-2 mt-2"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={t("Customer.Email")}
                  />
                </div>
              </form>
            </div>

            <div className="mt-3 pl-3 set-button">
              <button className="saveDraft-btn mt-4" onClick={handleUpdate}>
                {isLoading ? (
                  <i className="spinner-border spinner-border-sm  spin"></i>
                ) : (
                  t('Personal.Update'))}
              </button>

              <button className="cancel-info-btn ml-2" onClick={handleSave}>
                {t('Personal.Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeInformation;
