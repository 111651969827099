import { React, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useLanguage } from '../../LanguageContext';
import { fetchWarehouse } from '../../utils/Helper';
import { fetchProducts } from '../../utils/Helper';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import API from '../../services/Api';

const UpdateStock = ({ handleClose, fetchStockData, currentStock }) => {
    const { t } = useLanguage();
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [products, setProducts] = useState([]);
    const groupId = localStorage.getItem("selectedGroupId");
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedSourceWarehouse, setSelectedSourceWarehouse] = useState(null);
    const [selectedDestinationWarehouse, setSelectedDestinationWarehouse] = useState(null);
    const [selectedProductForDescription, setSelectedProductForDescription] = useState(null);
    const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    // const [isSaving, setIsSaving] = useState(false);
    const [transactionType, setTransactionType] = useState("In");
    const [formStatus, setFormStatus] = useState("");
    const [formDriverContactNumber, setFormDriverContactNumber] = useState("");
    const [formDriverName, setFormDriverName] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
    const [formVehicelNo, setFormVehicelNo] = useState("");
    const [formContactNo, setFormContactNo] = useState("");
    const [formContactPersonName, setFormContactPersonName] = useState("");
    const [isSaving, setIsSaving] = useState(false); // Track save state
    const [isUpdated, setIsUpdated] = useState(false);

    const formattedDate = currentDateTime.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const TruncateText = ({ text, maxLength }) => {
        if (text?.length <= maxLength) {
            return <span>{text}</span>;
        }
        const truncatedText = `${text.slice(0, maxLength)}...`;
        return <span title={text}>{truncatedText}</span>;
    };

    useEffect(() => {
        const loadProducts = async () => {
            try {
                const productData = await fetchProducts();
                if (productData && productData.data && productData.data.items) {
                    setProducts(productData.data.items);
                }
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        };
        loadProducts();
    }, []);

    useEffect(() => {
        const loadWarehouses = async () => {
            try {
                const response = await fetchWarehouse();
                if (response.data && response.data.items) {
                    const formattedWarehouses = response.data.items.map((item) => ({
                        name: item.WareHouseName,
                        wareHouseId: item.wareHouseId,
                    }));
                    setWarehouses(formattedWarehouses);
                }
            } catch (error) {
                console.error('Failed to fetch warehouses:', error);
            }
        };
        loadWarehouses();
    }, []);


    useEffect(() => {
        fetch(
            `${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data?.data?.items)) {
                    const employees = data?.data?.items?.map((employee) => ({
                        value: employee,
                    }));
                    setEmployeeList(employees);
                } else {
                    console.error("Data is not an array:", data);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const handleDeleteProduct = (index) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts.splice(index, 1);
        setSelectedProducts(updatedProducts);
    };

    useEffect(() => {
        handleFetchWarehouses();
    }, []);

    const handleFetchWarehouses = async () => {
        try {
            const response = await fetchWarehouse();
            if (response.data && response.data.items) {
                const formattedWarehouses = response.data.items.map((item) => ({
                    name: item.WareHouseName,
                    wareHouseId: item.wareHouseId,
                }));
                setWarehouses(formattedWarehouses);
            }
        } catch (error) {
            console.error("Failed to fetch warehouses:", error);
        }
    };

    const handleSourceWarehouseSelect = (selected) => {
        if (selected?.length > 0) {
            setSelectedSourceWarehouse(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sourceWarehouse: '',
            }));
        } else {
            setSelectedSourceWarehouse(null);
        }
    };

    const handleDestinationWarehouseSelect = (selected) => {
        if (selected?.length > 0) {
            setSelectedDestinationWarehouse(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                destinationWarehouse: '',
            }));
        } else {
            setSelectedDestinationWarehouse(null);
        }
    };

    const handleOpenDescriptionPopup = (product) => {
        setSelectedProductForDescription(product);
        setShowDescriptionPopup(true);
    };

    const handleCloseDescriptionPopup = () => {
        setShowDescriptionPopup(false);
    };

    const handleDeliveryDateChange = (date) => {
        setExpectedDeliveryDate(date);
        if (date) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                deliveryDate: '',
            }));
        }
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value?.length <= 10) {
            setter(value);
        }
    };
    const handleDriverName = (e) => {
        setFormDriverName((e.target.value))
        setValidationErrors((prevErrors) => ({ ...prevErrors, driverName: '' }))
    }
    const handleDriverCoNo = (value) => {
        setFormDriverContactNumber(value)
        setValidationErrors((prevErrors) => ({ ...prevErrors, driverContactNumber: '' }))
    }
    
    const handleCoPersonName = (e) => {
        const value = e.target.value;
        const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, ''); 
        setFormContactPersonName(alphabeticValue);
     };
    const handlePersonNo = (value) => {
        setFormContactNo(value);
        setValidationErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
    };
    const handleVehicalNo = (e) => {
        setFormVehicelNo((e.target.value))
        setValidationErrors((prevErrors) => ({ ...prevErrors, vehicelNo: '' }))
    }

    const validateForm = () => {
        const newErrors = {};
        if (!formStatus) {
            newErrors.status = "Status is required";
        }
        if (!formDriverContactNumber) {
            newErrors.driverContactNumber = "Driver Contact Number is required";
        }
        if (!formDriverName) {
            newErrors.driverName = "Driver Name is required";
        }
        if (!formVehicelNo) {
            newErrors.vehicelNo = "Vehicle Number is required";
        }
        if (!formContactNo) {
            newErrors.contactNo = "Contact Person Number is required";
        }
        if (!formContactPersonName) {
            newErrors.contactPersonName = "Contact Person Name is required";
        }
        setValidationErrors(newErrors);
        return Object.keys(newErrors)?.length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }
        if (isUpdated) {
            return; 
        }

        setIsSaving(true);
        try {
            const payload = {
                groupId: parseInt(groupId),
                from: selectedSourceWarehouse?.wareHouseId,
                destination: selectedDestinationWarehouse?.wareHouseId,
                status: formStatus,
                driverContactNumber: parseInt(formDriverContactNumber),
                driverName: formDriverName,
                vehicelNo: formVehicelNo,
                contactNo: parseInt(formContactNo),
                contactPersonName: formContactPersonName,
                products: selectedProducts.map((product) => ({
                    productcode: product.productcode,
                    _id: product._id,
                    Qty: product.Qty
                })),
                expectedDeliveryDate: expectedDeliveryDate ? expectedDeliveryDate.toISOString() : null,
                type: transactionType
            };
            const response = await fetch(`${API.commerceAPI}/commerce/sto/group/${groupId}/sto/${currentStock?.stoId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update stock');
            }
            toast.success('Stock Updated Successfully...!', {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
            });
            handleClose();
            fetchStockData();
        } catch (error) {
            console.error('Failed to update stock:', error);
            toast.error('Failed to update stock: ' + (error.message || 'An unknown error occurred.'), { autoClose: 2000 });
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (currentStock && warehouses.length > 0) {
            setSelectedProducts(currentStock.products || []);
            const sourceWarehouse = warehouses.find(w => w.name === currentStock.from);
            const destinationWarehouse = warehouses.find(w => w.name === currentStock.destination);
            setSelectedSourceWarehouse(sourceWarehouse || { name: currentStock?.from });
            setSelectedDestinationWarehouse(destinationWarehouse || { name: currentStock?.destination });
            setExpectedDeliveryDate(currentStock.expectedDeliveryDate ? new Date(currentStock.expectedDeliveryDate) : null);
            setTransactionType(currentStock.type);
            setFormStatus(currentStock.status || "");
            setFormDriverContactNumber(currentStock.driverContactNumber || "");
            setFormDriverName(currentStock.driverName || "");
            setFormVehicelNo(currentStock.vehicelNo || "");
            setFormContactNo(currentStock.contactNo || "");
            setFormContactPersonName(currentStock.contactPersonName || "");
        }
    }, [currentStock, warehouses]);


    const handleTransactionTypeChange = (event) => {
        const selectedType = event.target.value;
        setTransactionType(selectedType);
    };
    
     

    return (
        <div className='Sto-sidebar'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <h5 className='Intent-create-title'>
                            {t('STO.Update Stock')}
                        </h5>
                        <small className='ware-color'>{t('STO.Update here Stock')}
                        </small>
                    </div>

                    <div className='col-lg-5'></div>

                    <div className='col-lg-3'>
                        <p className="font16 date-Time mt-3 ml-2">
                            <strong> {t("newOrder.date-time")}</strong><br />
                            <small>{formattedDate}</small>{""}
                        </p>
                    </div>
                </div>

                <hr className='hr-line hrrr' />
                <div className='scroller-bar-intent'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <table className='table'>
                                <thead>
                                    <tr className='head-theme'>
                                        <th>{t("STO.Product Code")}</th>
                                        <th>{t("STO.Product Name")}</th>
                                        <th>{t("STO.Description")}</th>
                                        <th>{t("STO.Qty")}</th>
                                        <th>{t("STO.Unit")}</th>
                                        <th>{t("STO.Action")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {selectedProducts.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.productcode}</td>
                                            <td>{product.name}</td>
                                            <td>
                                                <i className="fa-solid fa-ellipsis cursor-pointer"
                                                    onClick={() => handleOpenDescriptionPopup(product)}></i>
                                            </td>
                                            <td>{product.Qty}</td>
                                            <td>{product.unit}</td>
                                            <td>
                                                <i className="fa-solid fa-trash-can intent-trash"
                                                    onClick={() => handleDeleteProduct(index)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                {showDescriptionPopup && (
                                    <div className="products-descriptions">
                                        <div className="popup-content">
                                            <strong>Product Description
                                                <i className="fa-solid fa-circle-xmark close-icon"
                                                    onClick={handleCloseDescriptionPopup}></i></strong>
                                            <hr />
                                            <div dangerouslySetInnerHTML={{ __html: selectedProductForDescription.value?.description }}></div>
                                        </div>
                                    </div>
                                )}
                            </table>
                        </div>
                    </div>
                    <hr />

                    <div className='row'>
                        <div className='col-lg-5'>
                            <b className='ware-color'>{t("STO.Source Warehouse")}</b>
                            <span className='text-danger'>*</span><br />
                            <Typeahead
                                id="sourceWarehouseTypeahead"
                                labelKey="name"
                                options={warehouses}
                                selected={selectedSourceWarehouse ? [selectedSourceWarehouse] : []}
                                onChange={handleSourceWarehouseSelect}
                                placeholder={t("STO.Source Warehouse")}
                                className="type-head_sto"
                            />
                            {validationErrors.sourceWarehouse && (
                                <span className="error-message">{validationErrors.sourceWarehouse}</span>
                            )}
                        </div>

                        <div className='col-lg-1'></div>

                        <div className='col-lg-5'>
                            <b className='ware-color'>{t("STO.Destination Warehouse")}</b>
                            <span className='text-danger'>*</span><br />
                            <Typeahead
                                id="destinationWarehouseTypeahead"
                                labelKey="name"
                                options={warehouses}
                                selected={selectedDestinationWarehouse ? [selectedDestinationWarehouse] : []}
                                onChange={handleDestinationWarehouseSelect}
                                placeholder={t("STO.Destination Warehouse")}
                                className="type-head_sto"
                            />
                            {validationErrors.destinationWarehouse && (
                                <span className="error-message">{validationErrors.destinationWarehouse}</span>
                            )}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-5 mt-2'>
                            <b className='ware-color'>{t("intentCreate.Delivery Date")}</b>
                            <span className='text-danger'>*</span><br />
                            <DatePicker
                                selected={expectedDeliveryDate}
                                onChange={handleDeliveryDateChange}
                                className={`select-stock2 ware-color ${validationErrors.deliveryDate ? 'is-invalid' : ''}`}
                                placeholderText="dd-mm-yyyy"
                                dateFormat="dd-MM-yyyy"
                            />
                            <br />
                            {validationErrors.deliveryDate && (
                                <span className="error-message">{validationErrors.deliveryDate}</span>
                            )}
                        </div>

                        <div className='col-lg-1'></div>

                        <div className="col-lg-5 mt-2 mr-2">
                            <b className='ware-color'>{t("STO.Type")}</b>
                            <span className="text-danger">*</span>
                            <br />
                            <select
                                className="select-stock2 ware-color"
                                value={transactionType}
                                onChange={handleTransactionTypeChange}
                            >
                                <option value="In">{t("STO.In")}</option>
                                <option value="Out" disabled>{t("STO.Out")}</option>
                            </select>
                        </div>

                    </div>
                    <hr></hr>

                    <p className='text-orange1'>{t("STO.STO Dispatch")}</p>

                    <div className='row'>
                        <div className="col-6">
                            <span className='ware-color'>{t("select.status")}</span>
                            <span className="text-danger">*</span>
                            <br />
                            <select
                                onChange={(e) => setFormStatus(e.target.value)}
                                value={formStatus}
                                className={`select-sto_inpt ${validationErrors.status ? 'error-border' : ''}`}>
                                <option value="">{t("order.status")}</option>
                                <option value="dispatched">Dispatched</option>
                                <option className="intransit">In Transit</option>
                                <option value="received">Received</option>
                            </select>
                            <div className='validation-error'>{validationErrors.status}</div>
                        </div>

                        <div className="col-6">
                            <span className='ware-color'>{t("neworder.vehical-no")}</span>
                            <span className='text-danger'>*</span>
                            <br />
                            <input
                                className={`select-sto_inpt pl-2 ${validationErrors.vehicelNo ? 'error-border' : ''}`}
                                placeholder={t("neworder.vehical-no")}
                                value={formVehicelNo}
                                onChange={handleVehicalNo}
                            />
                            {validationErrors.vehicelNo && <span className="validation-error pt-3">{validationErrors.vehicelNo}</span>}
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className="col-6">
                            <p className='ware-color'>
                                <span className='ware-color'> {t("neworder.driver-name")}</span>
                                <span className='text-danger'>*</span>
                                <select
                                    className={`select-sto_inpt ${validationErrors.driverName ? 'error-border' : ''} font14 cursor-pointer border-radius-5`}
                                    onChange={handleDriverName}
                                    value={formDriverName}>
                                    <option value="">{t('order.select-Driver')}</option>
                                    {employeeList.map((employee, index) => {
                                        return (
                                            <option key={index}
                                            >
                                                {employee.value.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </p>
                            {validationErrors.driverName && <div className="validation-error pt-3">{validationErrors.driverName}</div>}
                        </div>

                        <div className="col-6">
                            <span className='ware-color'>{t("neworder.Driver Contact No")}</span>
                            <span className='text-danger'>*</span>
                            <br />
                            <input
                                className={`select-sto_inpt pl-2 ${validationErrors.driverContactNumber ? "error-border" : ''}`}
                                placeholder={t("neworder.Driver Contact No")}
                                maxLength={10}
                                value={formDriverContactNumber}
                                onChange={handleInputChange(handleDriverCoNo)}
                            />
                            {validationErrors.driverContactNumber && <span className="validation-error pt-3">{validationErrors.driverContactNumber}</span>}
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6">
                            <span className='ware-color'>{t("STO.Contact Person")}</span>
                            <span className='text-danger'>*</span>
                            <br />
                            <input
                                className={`select-sto_inpt pl-2 ${validationErrors.contactPersonName ? 'error-border' : ''}`}
                                placeholder={t("STO.person name")}
                                value={formContactPersonName}
                                onChange={handleCoPersonName}
                            />
                            {validationErrors.contactPersonName && <span className="validation-error pt-3">{validationErrors.contactPersonName}</span>}
                        </div>

                        <div className="col-6">
                            <span className='ware-color'>{t("STO.Contact Person No")}</span>
                            <span className='text-danger'>*</span>
                            <br />
                            <input
                                className={`select-sto_inpt pl-2 ${validationErrors.contactNo ? 'error-border' : ''}`}
                                placeholder={t("STO.person No")}
                                maxLength={10}
                                value={formContactNo}
                                onChange={handleInputChange(handlePersonNo)}
                            />
                            {validationErrors.contactNo && <span className="validation-error pt-3">{validationErrors.contactNo}</span>}
                        </div>
                    </div>
                </div>

                <div
                    className='sto_update-btn'>
                    <button
                        className="intent-save"
                        onClick={handleSave}
                        disabled={isSaving || isUpdated} // Disable if saving or already updated
                    >
                        {t(isSaving ? "customer.Updating.." : isUpdated ? "STO.Updated" : "STO.Update")}
                    </button>

                    <button className="sto_challan mx-2 mb-2">
                        <a
                            className="font14 sto-update-delivery"
                            href={`${API.pdfGenerator}/generate-pdf?groupId=${groupId}&stoId=${currentStock?.stoId}&type=warehouseInvoice`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {console.log(currentStock, "dsgdsgh")}
                            {t("STO.delivery challan")}
                        </a>
                    </button>

                    <button
                        className='intent-cancel'
                        onClick={handleClose}>
                        {t("intentCreate.Cancel")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UpdateStock;