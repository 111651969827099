const STORAGE_KEYS = {
  REFRESH_TOKEN: 'refresh-token',
  TOKEN: 'token',
  SELECTED_GROUP_ID: 'selectedGroupId',
  SELECTED_USER: 'selectedUser',
  SELECTED_GROUP_NAME: 'selectedGroupName',
  SELECTED_ROLE: 'selectedRole',
  SELECTED_IMAGE: 'selectedImage',
  GROUPID:"selectedGroupId",
  PERMISSION:"permissions",
  EMPID:"selectedempId",
  USERID:"selectedUser"
  

};

export default STORAGE_KEYS;