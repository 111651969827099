import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Quotation.scss'
import { useParams } from 'react-router-dom';
import UpdateQuotation from '../../components/update-quotation/UpdateQuotation';
import API from '../../services/Api';
import PurchaseOrderSkeletonLoader from '../../components/skeleton-loader/PurchaseOrderSkeletonLoader';
import { useLanguage } from "../../LanguageContext";
import STORAGE_KEYS from '../../utils/storageKey';

const Quotation = () => {
    const [selectedOption, setSelectedOption] = useState(false);
    const [selectedQuatationData, setSelectedQuatationData] = useState(null)
    const [quotationData, setQuotationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoging, setIsLoading] = useState(false)
    const { intentId } = useParams();
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const { t } = useLanguage();

    const handleOpenSidebar = (quotationData) => {
        setSelectedQuotation(quotationData);
        setSidebarOpen(true);
    };

    const updateQuantity = (index, newQuantity) => {
        if (quotationData && quotationData.items && quotationData.items[index]) {
            const updatedQuotationData = { ...quotationData };
            updatedQuotationData.items[index].totalProductQty = newQuantity;
            setQuotationData(updatedQuotationData);
        } else {
            console.error('Error updating quantity: Quotation data or items array is undefined.');
        }
    };

    const handleSave = async (quotationId) => {
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/purchaseorder/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: parseInt(groupId),
                    purchaseOrderDate: new Date().toISOString(),
                    quotation: quotationId,
                })
            });

            if (response.ok) {
                const data = await response.json();
                setSelectedOption(false);
                toast.success(data.message || "Purchase Order Created Successfully!", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                });
                fetchData();
                return;
            }
            const errorData = await response.json();
            setSelectedOption(false);
            toast.error(errorData.message || 'Failed to save data.', {
                style: { backgroundColor: "red", color: "white", height: "70px" },
            });
        } catch (error) {
            setSelectedOption(false);
            toast.error(error.message || "Error saving data.", {
                style: { backgroundColor: "red", color: "white", height: "70px" },
            });
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${API.commerceAPI}/commerce/productsquotation/group/${groupId}/intent/${intentId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setQuotationData(data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [intentId]);

    if (!quotationData) {
        return <div></div>;
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ background: "#FF0000", color: "white" }}
            />
            <div className="main">
                <div className="body_container">
                    <div className="main-padding1">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
                            <div className="left-content">
                                <h5 className='head-theme'><strong>{t("quotation.title")}</strong></h5>
                                <p className="font14 head-theme">{t("quotation.subTitle")}</p>
                            </div>
                        </div>
                        <table className="table">
                            <thead className="font14 table-head">
                                <tr>
                                    <th scope="col" className="table-head">{t("supplier.Supplier Name")}</th>
                                    <th scope="col" className="table-head">{t("sidebarcustomers.Phone Number")}</th>
                                    <th scope="col" className="table-head">{t("inventory.Qty")}</th>
                                    <th scope="col" className="table-head text-center">{t("quotation.productAmt")}</th>
                                    <th scope="col" className="table-head">{t("po.totalTax")}</th>
                                    <th scope="col" className="table-head">{t("quotation.taxAmount")}</th>
                                    <th scope="col" className="table-head text-center">{t("neworder.total")}</th>
                                    <th scope="col" className="table-head text-center">{t("Action")}</th>
                                </tr>
                            </thead>

                            {loading ? (
                                <PurchaseOrderSkeletonLoader columns={9} />
                            ) : (
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="9" className="font14 text-center">Loading...</td>
                                        </tr>
                                    ) : !quotationData || quotationData.items?.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" className="font14 text-center">No data found</td>
                                        </tr>
                                    ) : quotationData.items.map((item, index) => (
                                        <tr key={index} className="font14">
                                            <td className="table-head supp-bold container_ cursor-pointer" onClick={() => handleOpenSidebar(item)}>{item.supplier.name}</td>
                                            <td className="table-head">{item.supplier.phoneNumber}</td>
                                            <td className="table-head">{item?.totalProductQty || "--"}</td>
                                            <td className="table-head text-center">{item?.totalProductAmount || "--"}</td>

                                            <td className="table-head" style={{ width: "120px" }}>{item.totalTax}</td>
                                            <td className="table-head" style={{ width: "120px" }}>{item.products[0]?.taxAmount}</td>
                                            <td className="table-head text-center">{item.totalProductPriceExcludingTax}</td>
                                            <td className='table-head'><button className='accept-btn' onClick={() => handleSave(item?.quotationId)}>{("Accept")}</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        {sidebarOpen && (
                            <UpdateQuotation
                                sidebarOpen={sidebarOpen}
                                setSidebarOpen={setSidebarOpen}
                                fetchData={fetchData}
                                selectedQuotation={selectedQuotation}
                                setSelectedQuotation={setSelectedQuotation}
                                updateQuantity={updateQuantity}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Quotation