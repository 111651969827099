import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import StarRatings from 'react-star-ratings';
import 'react-quill/dist/quill.snow.css';
import './AddProduct.scss';
import { fetchParentCategories } from '../../utils/Helper';
import { fetchServices } from '../../utils/Helper';
import { ProductsImage } from '../../components/avatar/Avatar';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import VariantTable from '../../components/add-variants/VariantTable';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import Permissions from '../../components/jwttoken-decode-service/Permission';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent', border: "1px solid #C0C2DE",
  }),
};

const ProductsForm = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [product, setProduct] = useState()
  const [newTag, setNewTag] = useState('');
  const [productTags, setProductTags] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [priceTable, setPriceTable] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [selectedSubcategoryId, setSelectedsubCategoryId] = useState();
  const [isPaymentOffline, setIsPaymentOffline] = useState(false);
  const [isPaymentOnline, setIsPaymentOnline] = useState(false);
  const isUpdate = productId !== 'new';
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [errors, setErrors] = useState('')
  const { t } = useLanguage();
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const defaultImageUrl = "/images/productdefault.jpg";

  useEffect(() => {
    const selectedCategoryObject = categoryOptions.find((option) => option.name === selectedCategory);
    if (selectedCategoryObject) {
      const categoryId = selectedCategoryObject.categoryId;
      setSelectedCategoryId(categoryId);
      setSubcategories(selectedCategoryObject.subcategory || []);
    }
  }, [selectedCategory, categoryOptions]);

  const handleSubcategoryChange = (selectedOption) => {
    const subCategoryId = selectedOption.value;
    const subCategoryName = selectedOption.label;
    setSelectedSubcategory(subCategoryId);
  };

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const subcategoryResponse = await fetchServices(selectedCategoryId);
        if (subcategoryResponse?.data?.items) {
          const subcategories = subcategoryResponse.data.items;
          setSubCategoryOption(subcategories);
          if (subcategories?.length > 0) {
            const defaultSubcategory = subcategories[0];
            const defaultSubcategoryId = defaultSubcategory.subcategoryId;
            const defaultSubcategoryName = defaultSubcategory.name;
            setFormData((prevData) => ({
              ...prevData,
              subcategoryId: defaultSubcategoryId,
              subcategoryName: defaultSubcategoryName
            }));
          }
        }
      } catch (error) {
        alert('Error fetching subcategories:', error);
      }
    };

    if (selectedCategoryId) {
      fetchSubcategories();
    }
  }, [selectedCategoryId]);

  const handleAddVariant = (newVariant) => {
    setVariants([...variants, newVariant]);
  };

  const [value, setValue] = useState({
    description: '',
    termsAndConditions: ''
  });

  const handleEditorChange = (editorName, content) => {
    setValue((prevValue) => ({
      ...prevValue,
      [editorName]: content
    }));
  };

  const [formData, setFormData] = useState({
    productName: '',
    skuNumber: '',
    buyingPrice: '',
    memberPrice: '',
    regularPrice: '',
    marketPrice: '',
    groupId: groupId,
    category: '',
    subCategory: '',
    description: '',
    gst: '',
    igst: '',
    sgst: '',
    cgst: '',
    categoryId: selectedCategoryId,
    hsnNo: '',
    subcategoryId: '',
    unit: ''
  });

  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag();
      handleAddCharacteristic()
    }
  };

  const handleOfflinePaymentChange = (e) => {
    setIsPaymentOffline(e.target.checked);
  };

  const handleOnlinePaymentChange = (e) => {
    setIsPaymentOnline(e.target.checked);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const selected = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setPictures(selected);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.productName) {
      isValid = false;
      newErrors.productName = 'Enter Product Name';
    }

    if (!formData.hsnNo) {
      isValid = false;
      newErrors.hsnNo = 'Select HSN Number';
    }

    if (!formData.unit) {
      isValid = false;
      newErrors.unit = 'Select Unit';
    }

    if (!formData.buyingPrice) {
      isValid = false;
      newErrors.buyingPrice = 'Enter Buying Price';
    }

    if (!formData.memberPrice) {
      isValid = false;
      newErrors.memberPrice = 'Enter Member Price';
    }

    if (!formData.regularPrice) {
      isValid = false;
      newErrors.regularPrice = 'Enter Regular Price';
    }

    if (!formData.marketPrice) {
      isValid = false;
      newErrors.marketPrice = 'Enter Market Price';
    }

    setErrors(newErrors);
    return isValid;
  };

  const [characteristics, setCharacteristics] = useState([]);
  const [newCharacteristic, setNewCharacteristic] = useState('');
  const [variants, setVariants] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSaving) {
      return;
    }

    if (!validateForm()) {
      return;
    }
    setIsSaving(true);

    const productData = {
      value,
      tags,
      pictures: pictures?.map(image => image),
      name: formData.productName,
      rating: formData.rating,
      skuNumber: (formData.skuNumber),
      buyingPrice: parseFloat(formData.buyingPrice),
      memberPrice: parseFloat(formData.memberPrice),
      regularPrice: parseFloat(formData.regularPrice),
      marketPrice: parseFloat(formData.marketPrice),
      gst: parseFloat(formData.gst),
      igst: parseFloat(formData.igst),
      sgst: parseFloat(formData.sgst),
      cgst: parseFloat(formData.cgst),
      characteristics,
      groupId,
      category: selectedCategory,
      categoryId: selectedCategoryId,
      subCategory: selectedSubcategory,
      rating: formData.rating,
      variants: productVariants || productData?.data?.variants,
      priceList: priceTable,
      subcategoryId: selectedSubcategoryId,
      hsnNo: parseFloat(formData.hsnNo),
      isPaymentOffline: isPaymentOffline || false,
      isPaymentOnline: isPaymentOnline || false,
      unit: formData.unit,
    };

    try {
      let response;
      if (productId === 'new') {
        response = await fetch(`${API.commerceAPI}/commerce/products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productData),
        });

        if (response.ok) {
          toast.success('Product Saved Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          });
          setTimeout(() => {
            navigate('/products');
          }, 1000);
        } else {
          toast.error('Product not saved');
        }
      } else {
        response = await fetch(`${API.commerceAPI}/commerce/products/${productId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productData),
        });

        if (response.ok) {
          toast.success('Product Updated Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          });
          setTimeout(() => {
            navigate('/products');
          }, 1000);
        } else {
          toast.error('An error occurred while updating product');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while saving/updating product');
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleCharacteristicRemove = (index) => {
    const updatedCharacteristics = characteristics.filter((_, i) => i !== index);
    setCharacteristics(updatedCharacteristics);
  };

  const handleAddCharacteristic = () => {
    if (newCharacteristic.trim() !== '') {
      setCharacteristics([...characteristics, newCharacteristic]);
      setNewCharacteristic('');
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchParentCategories();
        if (response?.data?.items) { setCategoryOptions(response.data.items) }
      } catch (error) {
      }
    }
    fetchData();
  }, []);

  function changeProfileURL(urls) {
    setPictures(prevPictures => [...prevPictures, ...urls]);
  }

  const handleRatingChange = (newRating) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rating: newRating,
    }));
  };

  const handleVariantsUpdate = (updatedVariants) => {
    setProductVariants(updatedVariants);
  };

  const handlePriceListUpdate = (updatedPriceTable) => {
    setPriceTable(updatedPriceTable);
  };

  const handleRemoveImage = (index) => {
    const updatedPictures = [...pictures];
    updatedPictures.splice(index, 1);
    setPictures(updatedPictures);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
  }

  useEffect(() => {
    if (productId) {
      fetchProductData(productId);
    }
  }, [productId]);

  const fetchProductData = async (productId) => {
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/products/${productId}`);
      if (response.ok) {
        const productData = await response.json();
        setIsPaymentOnline(productData.data.isPaymentOnline || false);
        setIsPaymentOffline(productData.data.isPaymentOffline || false);
        setPictures(productData?.data?.pictures || []);
        if (productData?.data?.value?.description) {
          setFetchedDescription(productData.data.value.description);
        }

        if (productData?.data?.category) {
          setSelectedCategory(productData?.data?.category)
        }

        if (productData?.data?.subCategory) {
          setSelectedSubcategory(productData?.data?.subCategory)
        }

        if (productData?.data?.value?.termsAndConditions) {
          setFetchedTermsAndConditions(productData.data.value.termsAndConditions);
        }

        setCharacteristics(productData.data.characteristics || []);
        setFormData({
          productName: productData.data?.name || '',
          // rating: productData.data?.rating || '',
          skuNumber: productData.data?.skuNumber || '',
          buyingPrice: productData.data?.buyingPrice || '',
          memberPrice: productData.data?.memberPrice || '',
          regularPrice: productData.data?.regularPrice || '',
          marketPrice: productData.data?.marketPrice || '',
          category: productData.data?.selectedCategory || '',
          hsnNo: productData.data?.hsnNo || '',
          subCategory: '',
          description: '',
          gst: productData.data?.gst || '',
          igst: productData.data?.igst || 0,
          sgst: productData.data?.sgst || 0,
          cgst: productData.data?.cgst || 0,
          category: productData.category,
          subcategoryId: '',
          unit: productData?.data?.unit || ''
        });

        setTags(productData?.data?.tags || []);
        setProductTags(productData?.data?.tags || []);
        if (productData?.data?.tags) {
          setProductTags(productData.data.tags);
        }
      } else {
        console.error('Error fetching product data');
      }
    } catch (error) { console.error('Error:', error) }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    if (!tags.includes(tagToRemove) && productTags.includes(tagToRemove)) {
      const updatedProductTags = productTags.filter((tag) => tag !== tagToRemove);
      setProductTags(updatedProductTags);
    }
  };

  const [fetchedDescription, setFetchedDescription] = useState('');
  const [fetchedTermsAndConditions, setFetchedTermsAndConditions] = useState('');

  useEffect(() => {
    if (productId !== 'new') {
      if (fetchedDescription) {
        setValue((prevValue) => ({
          ...prevValue,
          description: fetchedDescription,
        }));
      }
      if (fetchedTermsAndConditions) {
        setValue((prevValue) => ({
          ...prevValue,
          termsAndConditions: fetchedTermsAndConditions,
        }));
      }
    }
  }, [productId, fetchedDescription, fetchedTermsAndConditions]);

  return (
    <div className="main">
      <div className="body_container">
        <div className="main-padding1">
          <div className='product-add-header pt-3'>
            <Link to={"/products"} className='text-dark'><i className="fa-solid fa-angle-left arrow-icon" ></i></Link>
            <label><h4 className='title-add ml-2'>{productId === 'new' ? t("product.Add Product") : t("product.Update Product")}</h4>
            </label>
          </div>

          <div className='product-scroll-line'>
            <div className="row mt-5 ">
              <div className="col-lg-5 col-md-2  p-0 ">
                <b className="product-preview">{t("product.Product Preview")}</b><br />
                <div className='image-sections'>
                  <div id="carouselExampleControls" className="carousel slide ml-4" data-ride="carousel">
                    <div className="carousel-inner">
                      {pictures.map((url, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                          <img className="preview-image" src={url} alt={`Product ${index + 1}`} />
                        </div>
                      ))}
                    </div>

                    <a className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev">
                      <span className="carousel-control-prev-icon "
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">{t("product.Previous")}</span>
                    </a>

                    <a className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button" data-slide="next">
                      <span className="carousel-control-next-icon"
                        aria-hidden="true"></span>
                      <span className="sr-only"
                      >{t("product.Next")}</span>
                    </a>
                  </div>

                  <div className='subLine'><strong>{formData.productName}</strong>
                    <br />
                    <StarRatings
                      rating={formData.rating}
                      starRatedColor="rgb(255, 93, 28)"
                      changeRating={handleRatingChange}
                      numberOfStars={5}
                      starDimension="14px"
                      starSpacing="3px"
                      name="rating" />
                    <br />
                    {formData.regularPrice && (<b>₹{formData.regularPrice}</b>)}
                    {formData.marketPrice && (<b className='text-price'>₹ {formData.marketPrice}</b>)} </div>
                </div>
              </div>

              <div className='col-lg-1 col-md-1 p-0 m-0'></div>

              <div className="col-lg-6 col-sm-12 col-md-9 m-0 p-0">
                <h4 className="product-images ml-3">{t("product.Product Images")}</h4>
                <p className="sub-line ml-3">
                  {t("product.format")}
                </p>

                <div className="d-flex  ml-3">
                  <ProductsImage product={product} onUploadSuccess={changeProfileURL} />
                  <div className="image-section">
                    {pictures?.length > 0 ? (
                      pictures.map((url, index) => (
                        <div key={index} className="image-container ml-2">
                          <img src={url}
                            height="160px"
                            width="115px"
                            alt={`Product ${index + 1}`} />
                          <br />
                          <button onClick={() => handleRemoveImage(index)}
                            className="feature-remove btn-sm mt-2">
                            {t("product.Remove")}
                          </button>
                        </div>
                      ))

                    ) : (
                      <div className="image-container ml-2">
                        <img src={defaultImageUrl}
                          className='image-product'
                          height="160px"
                          width="115px"
                          alt="Default Product" />
                        <br />
                        <span className='font14'>{t("product.No images uploaded")}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-lg-4 col-md-3 p-0 m-0'>
                  <p className='feature-texts'>{t("product.Product information")}</p>
                  <div>
                    <b className="product-lable">{t("product.Product Name")}</b><span className='text-danger'>*</span>
                    <input name='productName'
                      className={`feature-inputs ${errors.productName ? 'error-border' : ''}`}
                      placeholder={t("product.Name of the Product")}
                      value={formData.productName}
                      onChange={handleInputChange}
                    /></div>
                  {errors.productName && <div className='validation-errors ml-4' >{errors.productName}</div>}

                  <div>
                    <b className="product-lable">{t("product.SKU Number")}</b>
                    <input
                      name='skuNumber'
                      className='feature-inputs'
                      placeholder={t("product.SKU Number")}
                      value={formData.skuNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <b className="product-lable">{t("product.HSN Number")}</b><span className='text-danger'>*</span>
                    <input className={`feature-inputs ${errors.hsnNo ? 'error-border' : ''}`}
                      type='text'
                      placeholder={t("product.HSN Number")}
                      name='hsnNo' value={formData.hsnNo}
                      onChange={handleInputChange}
                    />
                  </div>

                  {errors.hsnNo && <div className='validation-errors ml-4' >{errors.hsnNo}</div>}
                  <div>
                    <b className="product-lable">{t("intentCreate.Unit")}</b><span className='text-danger'>*</span>
                    <input name='unit'
                      className={`feature-inputs ${errors.unit ? 'error-border' : ''}`}
                      placeholder={t("product.Unit of the Product")}
                      value={formData.unit}
                      onChange={handleInputChange}
                    /></div>
                  {errors.unit && <div className='validation-errors ml-4' >{errors.unit}</div>}
                </div>

                <div className='col-lg-3 col-md-3 '>
                  <p className='feature-text-1'>{t("product.Pricing")}</p>
                  <div>
                    <div> <b className="product-price">{t("product.Buying Price")}</b><span className='text-danger'>*</span></div>
                    <input type='number'
                      name='buyingPrice'
                      className={`feature-input-1 ${errors.buyingPrice ? 'error-border' : ''}`}
                      placeholder={t("product.Buying Price")}
                      value={formData.buyingPrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.buyingPrice && <div className='validation-error mr-2'>{errors.buyingPrice}</div>}

                  <div>
                    <b className="product-price">{t("product.Member Price")}</b><span className='text-danger'>*</span><br></br>
                    <input type='number'
                      name='memberPrice'
                      className={`feature-input-1 ${errors.memberPrice ? 'error-border' : ''}`}
                      placeholder={t("product.Member Price")}
                      value={formData.memberPrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.memberPrice && <div className='validation-error'>{errors.memberPrice}</div>}

                  <div>
                    <div><b className="product-price">{t("product.Regular Price")}</b><span className='text-danger'>*</span></div>
                    <input type='number'
                      name='regularPrice'
                      className={`feature-input-1 ${errors.regularPrice ? 'error-border' : ''}`} placeholder={t("product.Regular Price")}
                      value={formData.regularPrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.regularPrice && <div className='validation-error'>{errors.regularPrice}</div>}

                  <div>
                    <div><b className="product-price">{t("product.Market Price")}</b><span className='text-danger'>*</span></div>
                    <input type='number'
                      name='marketPrice'
                      className={`feature-input-1 ${errors.marketPrice ? 'error-border' : ''}`} placeholder={t("product.Market Price")}
                      value={formData.marketPrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.marketPrice && <div className='validation-error'>{errors.marketPrice}</div>}
                </div>

                <div className='col-lg-5 col-md-6'>
                  <p className='feature-text-1'>{t("product.Product Details")}</p>
                  <ReactQuill
                    className='description-product'
                    theme="snow"
                    value={value.description || fetchedDescription || ''}
                    onChange={(content) => handleEditorChange('description', content)}
                    placeholder='Enter product description'
                    style={{ height: '110px' }}
                  />
                  <br />
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-4 mt-3'>
                  <b className='taxes-title'>{t("product.Taxes")}</b>
                  <input
                    type='text'
                    placeholder='Total GST %'
                    className='gst'
                    name='gst'
                    value={formData.gst}
                    onChange={handleInputChange}
                  />
                  <br />

                  <input
                    type='text'
                    placeholder='SGST %'
                    className='sgst'
                    name='sgst'
                    value={formData.sgst}
                    onChange={handleInputChange}
                  />

                  <input
                    type='text'
                    placeholder='CGST %'
                    className='sgst'
                    name='cgst'
                    value={formData.cgst}
                    onChange={handleInputChange}
                  />

                  <input
                    type='text'
                    placeholder='IGST %'
                    className='sgst'
                    name='igst'
                    value={formData.igst}
                    onChange={handleInputChange}
                  />
                </div>

                <div className='col-lg-3 '></div>
                <div className='col-lg-5 '>
                  <b className='taxes-title'>{t("product.Terms and Conditions")}</b>
                  <ReactQuill
                    className='description-product'
                    theme="snow"
                    value={value.termsAndConditions || fetchedTermsAndConditions || ''}
                    onChange={(content) => handleEditorChange('termsAndConditions', content)}
                    placeholder="Enter terms and conditions"
                    style={{ height: '110px' }}
                  />
                </div>
              </div>

              <div className='row m-0 p-0'>
                <div className='col-lg-4 col-md-4 p-0'>
                  <p className='feature-texts'>{t("product.Categories and Tags")}</p>
                  <Select styles={customStyles}
                    className="select-category" options={categoryOptions.map((option) => ({
                      label: option.name, value: option.name,
                    }))}
                    placeholder={t("product.Select the Category")}
                    onChange={handleCategoryChange}
                    style={{ backgroundColor: '#E7E8FA' }}
                  />

                  <input className='feature-inputs mt-3'
                    placeholder={t("product.Add tags")}
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onKeyPress={handleKeyPress} ></input>
                </div>

                <div className='col-lg-8 col-md-8 mt-1'>
                  <Select styles={customStyles}
                    className='select-subcategory '
                    value={selectedSubcategory ? { label: selectedSubcategory, value: selectedSubcategory } : null} options={subCategoryOption.map((subCategory) => ({
                      label: subCategory.name, value: subCategory.name,
                    }))} onChange={handleSubcategoryChange}
                    placeholder={t("product.select subcategory")}
                  />
                  <br />

                  {tags.map((tag, index) => (
                    <button key={index} className='btn choTup btn-sm ml-1'>
                      {tag}{' '}
                      <span className='badge'>
                        <i className='fa-solid fa-rectangle-xmark text-dark'
                          onClick={() => handleTagRemove(tag)}></i>
                      </span> </button>
                  ))}
                </div>
                <div className='col-lg-2'> </div>
              </div>

              <div className='row m-0 p-0'>
                <div className='col-lg-6 col-sm-12 mt-3 '>
                  <b className='product-info pl-3'>{t("product.Characteristics and feature")}</b>
                  <div className='feature-characteristics'>
                    {characteristics.map((characteristic, index) => (
                      <div key={index}>
                        {characteristic}
                        <i
                          className='fa-solid fa-rectangle-xmark character-cross'
                          onClick={() => handleCharacteristicRemove(index)}
                        ></i>
                      </div>
                    ))}
                  </div>

                  <span>
                    <input className=' add-character'
                      placeholder={t("product.Add the characteristic here")}
                      value={newCharacteristic}
                      onChange={(e) => setNewCharacteristic(e.target.value)}
                      onKeyPress={handleKeyPress} />
                  </span>
                  <button className='adds-button' onClick={handleAddCharacteristic}>{t("product.Add")}</button>
                </div>

                <div className='col-lg-6'></div>
                <b className='feature-variant'>{t("product.Variants")}</b>
                <div className='mt-3'></div>
                <VariantTable onVariantsUpdate={handleVariantsUpdate} productVariants={productVariants} onPriceListUpdate={handlePriceListUpdate}
                  priceTable={priceTable}
                />
              </div>

              <div className='row mt-4 '>
                <div className='col-lg-6 col-md-6 feature-save-button-responsive'>

                  {tokenPermissions && tokenPermissions.includes(Permissions.MP3) && (
                  <button className={`feature-save-button saves ${isSaving ? 'disabled' : ''}`} onClick={handleSubmit}>
                    {isSaving ? isUpdate ? t("customer.Updating..") : t("Saving...") : isUpdate ? t("product.Update") : t("product.Save")}
                  </button>
                 )} 

                  {tokenPermissions && tokenPermissions.includes(Permissions.MPAPC) && (
                  <Link to={"/products"}> <button className="feature-cancel-button cancels">{t("product.Cancel")}</button></Link>
                  )}
                </div>
                <div className='col-lg-6  col-md-6'></div>
              </div>
              <p className='feature-line ml-4 mt-4'>{t("product.reserved")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductsForm