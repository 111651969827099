import React, { useState } from 'react';
import './filterSidebar.scss';
import { useLanguage } from '../../LanguageContext';

function FilterComponent({ onClose, onApplyFilter }) {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const { t } = useLanguage();

  const handleClose = () => {
    onClose();
  };

  const handleApply = () => {
    const min = parseInt(minPrice);
    const max = parseInt(maxPrice);
    if (!isNaN(min) && !isNaN(max)) {
      onApplyFilter(min, max);
    }
    onClose();
  };

  return (
    <div className={`filter-component pt-5`}>
      <span className='feature-filter-title'> {t("po.updatePrice")}</span><br />
      <div>
        <label className='min-price'>{t("po.minimumprice")}</label>
        <p>
          <input type="number" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} className='min-price-input' />
        </p>
        <label className='max-price'>{t("po.maximumprice")}</label>
        <p>
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            className='max-price-input' />
        </p>
      </div>
      <button className='apply-filter' onClick={handleApply}>{t("button.apply")}</button>
      <button className='price-filter-cancel' onClick={handleClose}>{t("button.cancel")}</button>
    </div>
  );
}

export default FilterComponent;