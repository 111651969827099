import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';
import Utils from '../../utils/Helper';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import API from '../../services/Api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Avatar from '../avatar/Avatar';
import STORAGE_KEYS from '../../utils/storageKey';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CreateEmployee.scss';

const CreateEmployee = () => {
  const RFID = "your_value_here";
  const [options, setOptions] = useState([])
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchGroup, setSearchGroup] = useState(null);
  const navigate = useNavigate();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState(false);
  const [employee, setEmployee] = useState('');
  const [member, setMember] = useState('');
  const [errors, setErrors] = useState({});
  const { t } = useLanguage();
  const { userId } = useParams();
  const [activeSection, setActiveSection] = useState('address');
  const [ifscCodeError, setIfscCodeError] = useState('');
  const [upiError, setUpiError] = useState('');
  const [minEndDate, setMinEndDate] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isReferenceExists, setIsReferenceExists] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddressSaving, setIsAddressSaving] = useState(false);
  const [isKycSaving, setIsKycSaving] = useState(false);
  const [isExperienceSaving, setIsExperienceSaving] = useState(false);
  const [isReferenceSaving, setIsReferenceSaving] = useState(false);
  const [isReferenceUpdating, setIsReferenceUpdating] = useState(false);


  // personal details
  const [user, setUser] = useState({
    groupId: groupId,
    roleId: '',
    name: '',
    RFID: '',
    gender: '',
    phoneNumber: '',
    email: '',
    password: '',
    locationId: '',
    department_id: '',
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRoleChange = (selected) => {
    let selectedRole = null;
    if (selected && selected.length > 0) {
      selectedRole = selected[0];
    }
    setSelectedRole(selectedRole);

    if (selectedRole) {
      const { name, roleId } = selectedRole;
      setUser(prevUser => ({
        ...prevUser,
        role: {
          name: name,
          roleId: roleId
        },
        roleId: roleId,
      }));

      // Clear the role validation error
      setErrors(prevErrors => ({
        ...prevErrors,
        role: ''
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Select a role'
      }));
    }
  };

  const updateEmployeeInfo = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === 'aadhar' || name === 'phoneNumber' || name === 'whatsApp'
      ? (value.trim() === '' ? null : parseInt(value, 10))
      : value;

    setUser(prevUser => ({ ...prevUser, [name]: parsedValue }));
    setEmployee(prevEmployee => ({ ...prevEmployee, [name]: parsedValue }));

    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };
  let group = Utils.getGroup();
  const handleRFIDChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '').slice(0, 7);
    updateEmployeeInfo({ target: { name: 'RFID', value: inputValue } });
  };

  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;
    const isValidPhoneNumber = value.length === 10;
    if (!isValidPhoneNumber) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: 'Phone number must be 10 digits' }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
    updateEmployeeInfo(event);
  };

  const handleLocationChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedOption = selectedOptions[0];
      setSelectedLocation(selectedOption);
      setUser(prevUser => ({
        ...prevUser,
        locationId: selectedOption.locationId
      }));
      setLocations(selectedOptions);

      setErrors(prevErrors => ({
        ...prevErrors,
        selectedLocation: ''
      }));
    } else {
      setSelectedLocation(null);
      setUser(prevUser => ({
        ...prevUser,
        locationId: null
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        selectedLocation: 'Select a location'
      }));
    }
  };

  useEffect(() => {
    if (selectedLocation?.locationId && locations.length > 0) {
      const existingLocation = locations.find(location => location.locationId === selectedLocation.locationId);
      if (existingLocation) {
        setSelectedLocation(existingLocation);
      }
    }
  }, [locations, selectedLocation?.locationId]);

  useEffect(() => {
    fetchLocations();
  }, [groupId, selectedLocation?.locationId]);

  const fetchLocations = () => {
    fetch(`${API.peopleManagentService}/peopleManagement/locations/all/getByGroupId/${groupId}`, {
      method: 'GET',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        const fetchedLocations = data?.data?.items.map(item => ({
          locationId: item.locationId,
          name: item.name
        }));
        setLocations(fetchedLocations);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetch(`${API.authAPI}/auth/group/all/GetByGroupId/${groupId}?name=${query}`)
      .then((resp) => resp.json())
      .then(({ data }) => {
        setOptions(data.items);
        setIsLoading(false);
      });
  };

  const handleSearchInput = (input) => {
    setSearchInput(input);
    if (input === '') {
      setLocations([]);
      fetchLocations();
    } else {
      const filteredLocations = locations.filter(locations =>
        locations.name.toLowerCase().includes(input.toLowerCase())
      );
      setLocations(filteredLocations);
    }
  };

  const fetchUser = () => {
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
    fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    })
      .then((result) => {
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        }
        return result.json();
      })
      .then((resp) => {
        const userData = resp?.data?.items[0];
        setEmployee(userData);
        setMember(userData);
        setUser({
          groupId: userData?.groupId || groupId,
          name: userData?.name || '',
          role: userData?.role || '',
          phoneNumber: userData?.phoneNumber || '',
          email: userData?.email || '',
          userId: userData?.userId || '',
          RFID: userData?.RFID || '',
          password: userData?.password || '',
          gender: userData?.gender || '',
          imageUrl: userData?.imageUrl || '',
        });
        if (userData?.locationId) {
          const selected = locations.find(location => location?.locationId === userData?.locationId);
          if (selected) {
            setSelectedLocation(selected);
          } else {
            setSelectedLocation({ locationId: userData.locationId, name: '' });
          }
        }

      })
      .catch((error) => {
        console.error('Fetch Error:', error);
      });
  };

  useEffect(() => {
    fetchUser();
  }, [userId, groupId]);

  const fetchDepartments = () => {
    fetch(`${API.peopleManagentService}/peopleManagement/deparment/groupId/${groupId}`, {
      method: 'GET',
      headers: {}
    })
      .then(response => response.json())
      .then(data => {
        const fetchedDepartments = data?.data?.items.map(item => ({
          department_id: item.department_id,
          name: item.name
        }));
        setDepartments(fetchedDepartments);
        const options = fetchedDepartments.map(department => ({
          department_id: department.department_id,
          name: department.name
        }));
        setOptions(options);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const handleDepartmentChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedOption = selectedOptions[0];
      setSelectedDepartment(selectedOption);
      setUser(prevUser => ({
        ...prevUser,
        department_id: selectedOption.department_id
      }));

      setErrors(prevErrors => ({
        ...prevErrors,
        selectedDepartment: ''
      }));
    } else {
      setSelectedDepartment(null);
      setUser(prevUser => ({
        ...prevUser,
        department_id: null
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        selectedDepartment: 'Select a department'
      }));
    }
  };


  const handleSearchInputChange = (input) => {
    setSearchInput(input);
    if (input === '') {
      setDepartments([]);
      fetchDepartments();
    } else {
      const filteredDepartments = departments.filter(department =>
        department.name.toLowerCase().includes(input.toLowerCase())
      );
      setDepartments(filteredDepartments);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [groupId]);

  const setGroupSearch = (val) => {
    if (val && val.length === 1) val = val[0];

    if (val && val.groupId) {
      setRoles([]);
      fetch(`${API.authAPI}/auth/role/all/getByGroupId/${val.groupId}`)
        .then((resp) => resp.json())
        .then(({ data }) => {
          if (data?.items?.length > 0) {
            setRoles(data.items);
          }
        });

      setErrors(prevErrors => ({
        ...prevErrors,
        searchGroup: ''
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        searchGroup: 'Select a group'
      }));
    }

    setSearchGroup({
      ...searchGroup,
      parent_group: {
        groupId: val?.groupId,
        name: val?.name,
        shortName: val?.shortName,
      },
    });
  };


  useEffect(() => {
    fetchLocations();
  }, [groupId]);

  const changeProfileURL = (url) => {
    setUser({ ...user, imageUrl: url });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!user.name) {
      newErrors.name = "Employee name is required";
    }
    if (!user.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }
    if (!user.role) {
      newErrors.role = "Select a role";
    }
    if (!searchGroup) {
      newErrors.searchGroup = "Select a group";
    }
    if (!user.RFID) {
      newErrors.RFID = "RFID No is required";
    }
    if (!user.email) {
      newErrors.email = "Email is required";
    }
    if (!selectedLocation) {
      newErrors.selectedLocation = "Location is required";
    }
    if (!selectedDepartment) {
      newErrors.selectedDepartment = "Select a department";
    }
    if (!user.gender) {
      newErrors.gender = "Select a gender";
    }
    if (!user.password) {
      newErrors.password = 'Password is required'
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    const userWithGroupId = {
      ...user,
      groupId: searchGroup?.group?.id || groupId,
    };
    setLoading(true);
    setIsSaving(true);
    try {
      let response;
      if (user?.userId) {
        const updateUrl = `${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${user.userId}`;
        response = await fetch(updateUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userWithGroupId),
        });
      } else {
        const createUrl = `${API.apiGateway}/authgw/member/save`;
        response = await fetch(createUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('refresh-token')}`,
          },
          body: JSON.stringify(userWithGroupId),
        });
      }

      if (response.ok) {
        const responseData = await response.json();
        const userInfo = responseData?.user?.data || responseData?.data;
        setLoading(false);
        setEmployeeInfo(false);

        if (userInfo?.userId) {
          if (user?.userId) {
            toast.success('Employee Updated Successfully!', {
              style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
              duration: 2000,
            });
          } else {
            toast.success('Employee Created Successfully!', {
              style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
              duration: 2000,
            });
          }
          navigate(`/create-employee/${userInfo?.userId}`);
        }
      } else {
        console.error('Unexpected response status:', response.status);
        toast.success('Failed to create/update Employee', 'error');
      }
    } catch (error) {
      console.error('Error saving/updating employee:', error);
      toast.success('An error occurred while saving/updating Employee', 'error');
    } finally {
      setLoading(false);
      setIsSaving(false);
    }
  };

  //address
  const [address, setAddress] = useState({
    tag: '',
    locality: '',
    city: '',
    state: '',
    country: '',
    zip: ''
  });

  useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch address');
        }
        const { data } = await response.json();
        if (data?.items && data.items?.length > 0 && data.items[0].addresses && data.items[0].addresses?.length > 0) {
          const { tag, locality, city, state, country, zip } = data.items[0].addresses[0].address;
          setAddress({
            tag: tag || '',
            locality: locality || '',
            city: city || '',
            state: state || '',
            country: country || '',
            zip: zip || ''
          });
        }
      } catch (error) {
        console.error('Error fetching address:', error);
        setErrors(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchAddress();
  }, [userId, groupId]);

  const handleEmpAddressChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const handleCancelAddress = () => {
    setAddress({
      tag: '',
      locality: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    })
  }

  const ValidateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    if (!address.tag) {
      formIsValid = false;
      newErrors.tag = 'Tag is required';
    }
    if (!address.locality) {
      formIsValid = false;
      newErrors.locality = 'Locality is required';
    }
    if (!address.city) {
      formIsValid = false;
      newErrors.city = 'City is required';
    }
    if (!address.state) {
      formIsValid = false;
      newErrors.state = 'State is required'
    }
    if (!address.country) {
      formIsValid = false;
      newErrors.country = 'Country is required'
    }
    if (!address.zip) {
      formIsValid = false;
      newErrors.zip = 'Zip is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ValidateForm()) {
      return;
    }
    setLoading(true);
    setIsAddressSaving(true);

    const formattedAddress = {
      ...address,
      billingAddress: true,
      default: true,
      shippingAddress: true
    };
    try {
      const response = await fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch existing address');
      }
      const { data } = await response.json();
      const existingAddresses = data?.items[0]?.addresses;
      if (existingAddresses && existingAddresses.length > 0) {
        const existingAddress = existingAddresses[0];
        existingAddress.address = formattedAddress;
        const updateResponse = await fetch(`${API.peopleManagentService}/peopleManagement/members/updateAddress/${groupId}/${userId}/${existingAddresses[0].addressId}`, {
          method: 'PUT',
          body: JSON.stringify(existingAddress),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!updateResponse.ok) {
          throw new Error('Failed to update address');
        }
        toast.success('Address Updated Successfully...', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          duration: 2000
        });
      } else {
        const saveResponse = await fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
          method: 'PUT',
          body: JSON.stringify({
            addresses: [formattedAddress],
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!saveResponse.ok) {
          throw new Error('Failed to save address');
        }
        toast.success('Address Created Successfully!', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          duration: 2000
        });
      }
    } catch (error) {
      console.error('Error handling address submission:', error);
      toast.error('An error occurred while handling the address submission', {});
    } finally {
      setLoading(false);
      setIsAddressSaving(false);
    }
  };

  //kyc details
  const [accountDetails, setAccountDetails] = useState({
    accountNo: '',
    bankName: '',
    pancard: '',
    ifscCode: '',
    branchName: '',
    upi: '',
    adhar: '',
    passport: ''
  });

  const clearError = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: ''
      };
    });
  };

  const handlePanCardKYC=(event)=>{
    const { name, value } = event.target;
    setAccountDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
    setUser(prevUser => ({
      ...prevUser,
      accountDetails: [{
        ...prevUser?.accountDetails,
        [name]: value
      }]
    }));

    switch(name){
      case 'ifscCode':
        if (value.trim() === '') {
          setIfscCodeError('IFSC Code is required');
        } else {
          setIfscCodeError('');
        }
        break;
    }
  }

  const handleKYCChange = (event) => {
    const { name, value } = event.target;
    if (!/^\d*$/.test(value)) {
      return;
    }
    setAccountDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
    setUser(prevUser => ({
      ...prevUser,
      accountDetails: [{
        ...prevUser?.accountDetails,
        [name]: value
      }]
    }));

    switch (name) {
      case 'accountNo':
        if (value.length < 12 || value.length > 16) {
          setErrors({ ...errors, accountNo: 'Invalid Account No. It must be between 12 and 16 digits.' });
        } else {
          clearError('accountNo');
        }
        break;
      case 'adhar':
        if (value.length !== 12) {
          setErrors({ ...errors, adhar: 'Invalid Aadhaar No' });
        } else {
          clearError('adhar');
        }
        break;
      case 'upi':
        const regex = /^[a-zA-Z0-9@]+$/;
        const onlyNumbers = /^\d+$/;
        const onlyCharacters = /^[a-zA-Z]+$/;
        if (!regex.test(value) || onlyNumbers.test(value) || onlyCharacters.test(value)) {
          setUpiError('UPI should contain only alphanumeric characters');
        } else {
          setUpiError('');
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`)
      .then(response => response.json())
      .then(data => {
        const accountDetails = data?.data?.items;
        setAccountDetails({
          accountNo: accountDetails[0]?.accountDetails[0]?.accountNo || '',
          bankName: accountDetails[0]?.accountDetails[0]?.bankName || '',
          pancard: accountDetails[0]?.accountDetails[0]?.pancard || '',
          ifscCode: accountDetails[0]?.accountDetails[0]?.ifscCode || '',
          branchName: accountDetails[0]?.accountDetails[0]?.branchName || '',
          upi: accountDetails[0]?.accountDetails[0]?.upi || '',
          adhar: accountDetails[0]?.accountDetails[0]?.adhar || '',
          passport: accountDetails[0]?.accountDetails[0]?.passport || ''
        })
        setExperiences({
          companyName: accountDetails[0]?.experiences[0]?.experience?.companyName || '',
          role: accountDetails[0]?.experiences[0]?.experience?.role || '',
          empExperience: accountDetails[0]?.experiences[0]?.experience?.empExperience || '',
          startDate: accountDetails[0]?.experiences[0]?.experience?.startDate ? new Date(accountDetails[0]?.experiences[0]?.experience?.startDate) : null,
          endDate: accountDetails[0]?.experiences[0]?.experience?.endDate ? new Date(accountDetails[0]?.experiences[0]?.experience?.endDate) : null
        })
      })
      .catch(error => {
        console.error("There was an error fetching the KYC details!", error);
      });
  }, [API.peopleManagentService, groupId, userId]);

  const handleCancleKYC = () => {
    setAccountDetails({
      accountNo: '',
      bankName: '',
      pancard: '',
      ifscCode: '',
      branchName: '',
      upi: '',
      adhar: '',
      passport: ''
    });
  }
  const ValidateFormKYC = () => {
    let formIsValid = true
    const newErrors = {}

    if (!accountDetails?.bankName) {
      newErrors.bankName = 'Bank Name is required'
      formIsValid = false
    }
    if (!accountDetails?.accountNo) {
      newErrors.accountNo = 'Account No is required'
      formIsValid = false
    }
    if (!accountDetails?.ifscCode) {
      newErrors.ifscCode = 'IFC Code is required'
      formIsValid = false
    }
    if (!accountDetails?.upi) {
      newErrors.upi = 'UPI is required'
      formIsValid = false
    }
    if (!accountDetails?.branchName) {
      newErrors.branchName = 'Branch Name is required'
      formIsValid = false
    }
    if (!accountDetails?.pancard) {
      newErrors.pancard = 'PAN Card No is required'
      formIsValid = false
    }
    if (!accountDetails?.adhar) {
      newErrors.adhar = 'Adhar Card No is required'
      formIsValid = false
    }
    if (!accountDetails?.passport) {
      newErrors.passport = 'Passport is required'
      formIsValid = false
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleKycSave = async () => {
    if (!ValidateFormKYC()) {
      return
    }
    const kycPaylod = {
      "accountDetails": [
        {
          accountNo: accountDetails?.accountNo,
          bankName: accountDetails?.bankName,
          pancard: accountDetails?.pancard,
          ifscCode: accountDetails?.ifscCode,
          branchName: accountDetails?.branchName,
          upi: accountDetails?.upi,
          adhar: accountDetails.adhar,
          passport: accountDetails.passport
        }
      ]
    }
    setIsKycSaving(true);
    try {
      const response = await fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(kycPaylod)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      toast.success('KYC details Created successfully!', {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        duration: 2000
      });
    } catch (error) {
      console.error("There was an error updating the KYC details!", error);
    } finally {
      setIsKycSaving(false);
    }
  };

  //Experience
  const [experiences, setExperiences] = useState({
    companyName: '',
    role: '',
    empExperience: '',
    startDate: null,
    endDate: null,
  });

  const handleCancleexperienc = () => {
    setExperiences({
      companyName: '',
      role: '',
      empExperience: '',
      startDate: '',
      endDate: '',
    })
  }

  const handleEmpExperienceChange = (e) => {
    const { name, value } = e.target;
    setExperiences((prevExperience) => ({ ...prevExperience, [name]: value }));
  };

  const handleStartDateChange = (date) => {
    setExperiences(prevState => ({
      ...prevState,
      startDate: date
    }));
    if (date) {
      const nextMonth = new Date(date);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      setMinEndDate(nextMonth);
    } else {
      setMinEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setExperiences(prevState => ({
      ...prevState,
      endDate: date
    }));
  };

  const handleExperienceSave = async () => {

    if (!experiences?.companyName || !experiences?.role || !experiences?.startDate || !experiences?.endDate || !experiences?.empExperience) {
      toast.error('Failed to saved experience')
      return
    }

    const experienceData = {
      "experiences": [{
        companyName: experiences?.companyName || '',
        role: experiences?.role || '',
        empExperience: experiences?.empExperience || '',
        startDate: experiences.startDate ? experiences.startDate.toISOString() : null,
        endDate: experiences.endDate ? experiences.endDate.toISOString() : null,
      }]
    }
    setIsExperienceSaving(true);
    try {
      const putResponse = await fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(experienceData),
      });
      if (!putResponse.ok) {
        throw new Error('Failed to save experience data');
      }
      toast.success('Experience Created Successfully!', {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        duration: 2000
      });
    } catch (error) {
      console.error('Error saving experience data:', error);
      toast.error('Error saving experience data. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } finally {
      setIsExperienceSaving(false);
    }
  };

  //Reference
  const [reference, setReference] = useState({
    relation: '',
    name: '',
    email: '',
    phoneNumber: ''
  });

  const handleReferenceChange = (e) => {
    const { name, value } = e.target;
    setReference((prevReference) => ({ ...prevReference, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  useEffect(() => {
    const fetchReferenceData = async () => {
      try {
        const response = await fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const fetchedReference = data?.data?.items?.[0]?.reference || {};
        setReference({
          relation: fetchedReference.relation || '',
          name: fetchedReference.name || '',
          email: fetchedReference.email || '',
          phoneNumber: fetchedReference.phoneNumber || ''
        });
        setIsReferenceExists(!!fetchedReference.name);
      } catch (error) {
        console.error('Error fetching reference data:', error);
      }
    };
    fetchReferenceData();
  }, [API.peopleManagentService, groupId, userId]);

  const handleCancelReference = () => {
    setReference({
      relation: '',
      name: '',
      email: '',
      phoneNumber: ''
    })
  }
  const handleReferenceSave = async () => {

    if (!reference.relation || !reference.name || !reference.email || !reference.phoneNumber) {
      toast.error('Failed to Saved Reference');
      return
    }
    setIsReferenceSaving(true);
    setIsReferenceUpdating(isReferenceExists);
    try {
      const getResponse = await fetch(`${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`);
      if (!getResponse.ok) {
        throw new Error('Failed to fetch existing data');
      }
      const data = await getResponse.json();
      const existingReference = data?.data?.items?.[0]?.reference || {};

      const updatedReference = {
        ...existingReference,
        ...reference
      };
      const putResponse = await fetch(`${API.peopleManagentService}/peopleManagement/members/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reference: updatedReference })
      });

      if (!putResponse.ok) {
        throw new Error('Failed to update data');
      }
      toast.success(isReferenceExists ? 'Reference Updated Successfully!' : 'Reference Created Successfully!', {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        duration: 2000
      });
      setTimeout(() => {
        navigate('/employee');
      }, 1000);
    } catch (error) {
      console.error('Error updating reference data:', error);
    } finally {
      setIsReferenceSaving(false);
      setIsReferenceUpdating(false);
    }
  };

  return (
    <>
      <div className="main">
        <div className="body_container">
          <div className='main-padding1'>
            <div className='row mt-2'>
              <div className='col-2'>
                <span className='employee-theme'><b>{t("sidebar.employee")}</b></span>
              </div>
              <div className='col-9'></div>
              <div className='col-1'>
                <Link to='/employee'><i className="fa-solid fa-circle-xmark ml-5"></i></Link>
              </div>
            </div>
            <hr className='emp_hr' />

            <div className="emp_main ">
              <div className='row persnl_emp'>
                <div className='col-2 employee-theme'>
                  <strong className='employee-theme'>{t("sidebarcustomers.Personal Details")}</strong>
                </div>
                <div className='col-9'></div>
                <div className='col-1'>
                  <button
                    type='button'
                    className='emp_save'
                    onClick={handleSave}
                    disabled={isSaving}>
                    {isSaving ? (user?.userId ? t('customer.Updating..') : t('intentCreate.Saving....')) : (user?.userId ? t("intentCreate.Update") : t("intentCreate.Save"))}
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-2 '>
                  <p className='font14 ml-4 employee-theme'>Upload Image :</p>
                  <Avatar user={user} onUploadSuccess={changeProfileURL} ></Avatar>
                </div>

                <div className='col-10 emp_body'>
                  <div className='row'>
                    <div className='col-3'>
                      <span className='employee-theme'>{t("neworder.name")}</span>
                      <span className='text-danger'>*</span><br />
                      <input
                        type='text'
                        placeholder={t("Employee.Enter your Name")}
                        className={`emp-inpt ${errors.name ? 'error-border' : ''}`} name="name"
                        value={user?.name}
                        onChange={updateEmployeeInfo}
                      />
                      {errors.name && <span className='validation-error'>{errors.name}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("table.phoneNo")}</span>
                      <span className='text-danger'>*</span><br />
                      <input
                        type='text'
                        name='phoneNumber'
                        placeholder={t("Employee.Enter your number")}
                        className={`emp-inpt ${errors.phoneNumber ? 'error-border' : ''}`} value={user?.phoneNumber}
                        onChange={handlePhoneNumberChange}
                        maxLength={10}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          if (!/^\d+$/.test(keyValue)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors.phoneNumber && <span className='validation-error'>{errors.phoneNumber}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("sidebar.group-list")}</span>
                      <span className='text-danger'>*</span><br />
                      {searchGroup?.name}
                      <AsyncTypeahead
                        className={`group-section ${errors.searchGroup ? 'error-border' : ''}`}
                        filterBy={(option, state) => option.name.toLowerCase().indexOf(state.text.toLowerCase()) !== -1}
                        id="group"
                        isLoading={isLoading}
                        labelKey="name"
                        minLength={3}
                        onSearch={handleSearch}
                        onChange={setGroupSearch}
                        options={options}
                        placeholder={t("Employee.Search by Groups")}
                        renderMenuItemChildren={(option) => (
                          <> <span>{option.name}</span> </>
                        )}
                      />
                      {errors.searchGroup && <span className="validation-error">{errors.searchGroup}</span>}

                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("sidebar.role-list")}</span>
                      <span className='text-danger'>*</span><br />
                      <Typeahead
                        className={`group-section ${errors.role ? 'error-border' : ''}`}
                        id="role-typeahead"
                        labelKey="name"
                        options={roles}
                        onChange={handleRoleChange}
                        selected={selectedRole ? [selectedRole] : []}
                        value={user?.role?.name}
                        placeholder={t("Employee.Select a role...")}
                      />
                      {errors.role && <span className='validation-error'>{errors.role}</span>}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-3'>
                      <span className='employee-theme'>{t("Employee.RFID Code:")}</span>
                      <span className='text-danger'>*</span><br />
                      <input
                        type='text'
                        name='RFID'
                        placeholder={t("Employee.Enter RFID No")}
                        className={`emp-inpt ${errors.RFID ? 'error-border' : ''}`} value={user?.RFID}
                        onChange={handleRFIDChange}
                        maxLength={7}
                      />
                      {errors.RFID && <span className='validation-error'>{errors.RFID}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("Employee.Email")}</span>
                      <input
                        type='text'
                        name='email'
                        placeholder={t("Employee.Enter Email")}
                        className={`emp-inpt ${errors.email ? 'error-border' : ''}`} value={user?.email}
                        onChange={updateEmployeeInfo}
                      />
                      {errors.email && <span className='validation-error'>{errors.email}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("Personal.Location")}</span>
                      <span className='text-danger'>*</span><br />
                      <Typeahead
                        className={`group-section-1 ${errors.selectedLocation ? 'error-border' : ''}`}
                        id="location-typeahead"
                        labelKey="name"
                        options={locations}
                        onChange={handleLocationChange}
                        selected={selectedLocation ? [selectedLocation] : []}
                        placeholder={t("Employee.Select a location...")}
                        onInputChange={handleSearchInput}
                      />
                      {errors.selectedLocation && <span className='validation-error'>{errors.selectedLocation}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("Config.Department")}</span>
                      <span className='text-danger'>*</span><br />
                      <Typeahead
                        className={`group-section1 ${errors.selectedDepartment ? 'error-border' : ''}`}
                        id="department-typeahead"
                        labelKey="name"
                        options={departments}
                        onChange={handleDepartmentChange}
                        selected={selectedDepartment ? [selectedDepartment] : []}
                        placeholder={t("Employee.Select a department...")}
                        // isLoading={isLoading}
                        onInputChange={handleSearchInputChange}
                      />
                      {errors.selectedDepartment && <span className='validation-error'>{errors.selectedDepartment}</span>}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-3'>
                      <span className='employee-theme'>{t("Employee.Gender")}</span>
                      <span className='text-danger'>*</span><br />
                      <select
                        name="gender"
                        value={user?.gender}
                        onChange={updateEmployeeInfo}
                        className={`emp-inpt ${errors.gender ? 'error-border' : ''}`}                        >
                        <option value="">{t("Employee.Select Gender")}</option>
                        <option value="Male">{t("Employee.Male")}</option>
                        <option value="Female">{t("Employee.Female")}</option>
                      </select>
                      {errors.gender && <span className='validation-error'>{errors.gender}</span>}
                    </div>

                    <div className='col-3'>
                      <span className='employee-theme'>{t("Employee.Password")}</span>
                      <span className='text-danger'>*</span><br />
                      <div className='password-input-container'>
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          placeholder={t("Employee.Enter Password")}
                          className={`emp-inpt-passw ${errors.password ? 'error-border' : ''}`} value={user?.password || ''}
                          onChange={updateEmployeeInfo}
                          maxLength={10}
                        />
                        <span onClick={togglePasswordVisibility} className='eye-icon'>
                          {passwordVisible ? <i class="fa-solid fa-eye-slash mt-2"></i> : <i className="fa-solid fa-eye mt-2"></i>}
                        </span><br />
                      </div>
                      {errors.password && <span className='validation-error'>{errors.password}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div className='emp_dropdown'>
                <div
                  className={` employee-theme cursor-pointer ${activeSection === 'address' ? 'active' : ''}`}
                  onClick={() => handleSectionClick('address')}
                  disabled={activeSection && activeSection !== 'address'}>
                  {t("Employeeprofile.Address")}
                </div>

                <div
                  className={`ml-4 cursor-pointer employee-theme ${activeSection === 'kycDetails' ? 'active' : ''}`}
                  onClick={() => handleSectionClick('kycDetails')}
                  disabled={activeSection && activeSection !== 'kycDetails'}>
                  {t("Payment.KYC")}
                </div>

                <div
                  className={`ml-4 cursor-pointer employee-theme ${activeSection === 'experience' ? 'active' : ''}`}
                  onClick={() => handleSectionClick('experience')}
                  disabled={activeSection && activeSection !== 'experience'}>
                  {t("Employee.Experience")}
                </div>

                <div
                  className={`ml-4 cursor-pointer employee-theme ${activeSection === 'reference' ? 'active' : ''}`}
                  onClick={() => handleSectionClick('reference')}
                  disabled={activeSection && activeSection !== 'reference'}>
                  {t("Employee.Reference")}
                </div>
              </div>
              <hr className='drop_hr' />
              <div
                className='scroll-emp-create'
              >

                {activeSection === 'address' && (
                  <>
                    <div className="col-9 p-0 adress_active">
                      <div className='row'>
                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.Address Type")} <span className="text-danger">*</span>
                            <input
                              className={`emp-inpt ${errors.tag ? 'error-border' : ''}`}
                              type="text"
                              name="tag"
                              value={address.tag}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.Tag")}
                            /><br />
                            {errors.tag && <span className="validation-error">{errors.tag}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.Locality")} <span className="text-danger">*</span>
                            <input
                              className={`emp-inpt ${errors.locality ? 'error-border' : ''}`}
                              type="text"
                              name="locality"
                              value={address.locality}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.Locality")}
                            />
                            {errors.locality && <div className="validation-error">{errors.locality}</div>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.City")} <span className="text-danger">*</span><br />
                            <input
                              className={`emp-inpt ${errors.city ? 'error-border' : ''}`}
                              type="text"
                              name="city"
                              value={address.city}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.City")}
                            />
                            {errors.city && <div className="validation-error">{errors.city}</div>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.State")}  <span className="text-danger">*</span><br />
                            <input
                              className={`emp-inpt ${errors.state ? 'error-border' : ''}`}
                              type="text"
                              name="state"
                              value={address.state}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.State")}
                            />
                            {errors.state && <p className="validation-error">{errors.state}</p>}
                          </p>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.Country")}  <span className="text-danger">*</span>
                            <input
                              className={`emp-inpt ${errors.country ? 'error-border' : ''}`}
                              type="text"
                              name="country"
                              value={address.country}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.Country")}
                            />
                            {errors.country && <div className="validation-error">{errors.country}</div>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="contact-phone">{t("Employeeaddress.Zip")} <span className="text-danger">*</span><br />
                            <input
                              type="text"
                              name="zip"
                              value={address.zip}
                              onChange={handleEmpAddressChange}
                              placeholder={t("Employeeaddress.Zip")}
                              maxLength={6}
                              pattern="[0-9]{0,6}"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                              }}
                              className={`emp-inpt ${errors.zip ? 'error-border' : ''}`}
                            />
                            {errors.zip && <div className="validation-error">{errors.zip}</div>}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='emp_footer mb-2'>
                      <div className=''>
                        <button
                          type='button'
                          className='emp-save-kyc'
                          onClick={handleSubmit}
                          disabled={isAddressSaving}>
                          {t(isAddressSaving ? (address.addressId ? 'customer.Updating..' : 'intentCreate.Saving....') : (address.addressId ? "intentCreate.Update" : "intentCreate.Save"))}
                        </button>
                      </div>

                      <div className=''>
                        <button
                          type='button'
                          className='emp_cancle ml-1'
                          onClick={handleCancelAddress}>
                          {t("intentCreate.Cancel")}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {activeSection === 'kycDetails' && (
                  <>
                    <div className="col-9 p-0 adress_active">
                      <div className='row'>
                        <div className='col-3'>
                          <p className='text-color2 '>{t("SupplierBank.Bank Name")} <span className='text-danger'>*</span><br />
                            <input
                              className={`emp-inpt  ${errors.bankName ? 'error-border' : ''}`}
                              placeholder={t("SupplierBank.Bank Name")}
                              name="bankName"
                              value={accountDetails?.bankName}
                              onChange={handlePanCardKYC}
                            /><br />
                            {errors.bankName && <span className='validation-error'>{errors.bankName}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='accNo text-color2 '>{t("SupplierBank.Account No")} <span className='text-danger'>*</span><br />
                            <input
                              className={`emp-inpt ${errors.accountNo ? 'error-border' : ''}`}
                              placeholder={t("SupplierBank.Account No")}
                              name="accountNo"
                              value={accountDetails?.accountNo}
                              onChange={handleKYCChange}
                              maxLength={16}
                            /><br />
                            {errors.accountNo && <span className='validation-error'>{errors.accountNo}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='text-color2 '>{t("SupplierBank.IFSC Code")} <span className='text-danger'>*</span><br />
                            <input
                              className={`emp-inpt  ${errors.ifscCode ? 'error-border' : ''}`}
                              placeholder={t("SupplierBank.IFSC Code")}
                              name="ifscCode"
                              value={accountDetails?.ifscCode}
                              onChange={handlePanCardKYC}
                              maxLength={10}
                            /><br />
                            {errors.ifscCode && <span className='validation-error'>{errors.ifscCode}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='text-color2'>{t("SupplierBank.UPI")} <span className='text-danger'>*</span><br />
                            <input
                              className={`emp-inpt  ${errors.upi ? 'error-border' : ''}`}
                              placeholder={t("SupplierBank.UPI")}
                              name="upi"
                              value={accountDetails?.upi}
                              onChange={handleKYCChange}
                            /><br />
                            {errors.upi && <span className='validation-error'>{errors.upi}</span>}
                          </p>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-3'>
                          <p className='text-color2 '>{t("SupplierBank.Branch Name")} <span className='text-danger'>*</span><br />
                            <input
                              className={`emp-inpt ${errors.branchName ? 'error-border' : ''}`}
                              placeholder={t("SupplierBank.Branch Name")}
                              name="branchName"
                              value={accountDetails?.branchName}
                              onChange={handlePanCardKYC}
                            /><br />
                            {errors.branchName && <span className='validation-error'>{errors.branchName}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='text-color2 '>{t("sidebarcustomers.PAN Card")} 
                            <input
                              className={`emp-inpt  ${errors.bankName ? 'error-border' : ''}`}
                              placeholder={t("sidebarcustomers.PAN Card")}
                              name="pancard"
                              value={accountDetails?.pancard}
                              onChange={handlePanCardKYC}
                            /><br />
                            {errors.pancard && <span className='validation-error'>{errors.pancard}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='text-color2'>{t("sidebarcustomers.Aadhar Number")}<span className='text-danger'>*</span><br />
                            <input
                              type='text'
                              name='adhar'
                              placeholder={t("sidebarcustomers.Aadhar Number")}
                              className={`emp-inpt  ${errors.adhar ? 'error-border' : ''}`}
                              value={accountDetails?.adhar || ''}
                              onChange={handleKYCChange}
                              maxLength={12}
                            /><br />
                            {errors.adhar && <span className='validation-error'>{errors.adhar}</span>}
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className='text-color2'>{t("Employee.Passport")}<span className='text-danger'>*</span><br />
                            <input
                              type='text'
                              name="passport"
                              placeholder={t("Employee.PassportNo")}
                              className={`emp-inpt  ${errors.passport ? 'error-border' : ''}`}
                              value={accountDetails?.passport || ''}
                              onChange={handleKYCChange}
                              maxLength={8}
                            /><br />
                            {errors.passport && <span className='validation-error'>{errors.passport}</span>}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='emp_footer'>
                      <div className=''>
                        <button
                          type='button'
                          className='emp-save-kyc'
                          onClick={handleKycSave}
                          disabled={isKycSaving}>
                          {isKycSaving ? t('intentCreate.Saving....') : (accountDetails.userId ? t("intentCreate.Update") : t("intentCreate.Save"))}
                        </button>
                      </div>

                      <div className=''>
                        <button
                          type='button'
                          className='emp_cancle-kyc'
                          onClick={handleCancleKYC}>
                          {t("intentCreate.Cancel")}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {activeSection === 'experience' && (
                  <>
                    <div className="col-9 p-0 adress_active">
                      <div className='row'>
                        <div className='col-3'>
                          <span className='contact-phone'>{t("EmployeeExperiences.Company Name")}</span><br />
                          <input
                            name='companyName'
                            type='text'
                            value={experiences?.companyName}
                            onChange={handleEmpExperienceChange}
                            placeholder={t("EmployeeExperiences.Company Name")}
                            className='emp-inpt'
                          />
                        </div>

                        <div className='col-3'>
                          <span className='contact-phone'>{t("EmployeeExperiences.Role")}</span><br />
                          <input
                            type='text'
                            name="role"
                            value={experiences?.role}
                            onChange={handleEmpExperienceChange}
                            placeholder={t("EmployeeExperiences.Role")}
                            className='emp-inpt'
                          />
                        </div>

                        <div className='col-3'>
                          <span className='contact-phone'>{t("EmployeeExperiences.StartDate")}</span><br />
                          <DatePicker
                            selected={experiences.startDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-mm-yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            id="icondisplay"
                            className='emp-inpt'
                          />
                        </div>

                        <div className='col-3'>
                          <span className='contact-phone'>{t("EmployeeExperiences.EndDate")}</span><br />
                          <DatePicker
                            className='emp-inpt'
                            selected={experiences.endDate}
                            onChange={handleEndDateChange}
                            minDate={minEndDate}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-mm-yyyy"
                            id="icondisplay"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                          />
                        </div>
                      </div>

                      <div className='row mt-4'>
                        <div className='col-3'>
                          <span className='contact-phone'>{t("EmployeeExperiences.Employee experience")}</span><br />
                          <input
                            name="empExperience"
                            type='text'
                            value={experiences?.empExperience}
                            onChange={handleEmpExperienceChange}
                            placeholder={t("EmployeeExperiences.Employee experience")}
                            className='emp-inpt'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='emp_footer'>
                      <div className=''>
                        <button
                          type='button'
                          className='emp-save-kyc'
                          onClick={handleExperienceSave}
                          disabled={isExperienceSaving}>
                          {isExperienceSaving ? t('intentCreate.Saving....') : (experiences.experienceId ? t("intentCreate.Update") : t("intentCreate.Save"))}
                        </button>
                      </div>

                      <div className=''>
                        <button
                          type='button'
                          className='emp_cancle-kyc'
                          onClick={handleCancleexperienc}>
                          {t("intentCreate.Cancel")}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {activeSection === 'reference' && (
                  <>
                    <div className="col-9 p-0 adress_active">
                      <div className='row'>
                        <div className='col-3'>
                          <p className='contact-phone'>{t("EmployeeReference.Relation")}<br />
                            <input
                              className={`emp-inpt ${errors.relation ? 'error-border' : ''}`}
                              name='relation'
                              value={reference?.relation}
                              onChange={handleReferenceChange}
                              type='text'
                              placeholder='relation'
                            />
                          </p>
                          {errors.relation && <p className='validation-error ml-4 mt-3'>{errors.relation}</p>}
                        </div>

                        <div className='col-3'>
                          <p className='contact-phone'>{t("EmployeeReference.Name")}<br />
                            <input
                              className={`emp-inpt ${errors.name ? 'error-border' : ''}`}
                              name="name"
                              value={reference?.name}
                              onChange={handleReferenceChange}
                              placeholder={t("EmployeeReference.Name")}
                            />
                          </p>
                          {errors.name && <p className='validation-error ml-4 mt-3'>{errors.name}</p>}
                        </div>

                        <div className='col-3'>
                          <p className='contact-phone'>{t("EmployeeReference.Email")}<br />
                            <input
                              className={`emp-inpt ${errors.email ? 'error-border' : ''}`}
                              name="email"
                              value={reference?.email}
                              onChange={handleReferenceChange}
                              type='email'
                              placeholder={t("EmployeeReference.Email")}
                            />
                          </p>
                          {errors.email && <p className='validation-error  ml-4 mt-3'>{errors.email}</p>}
                        </div>

                        <div className='col-3'>
                          <p className='contact-phone'>{t("EmployeeReference.Phone Number")}
                            <input
                              className={`emp-inpt ${errors.phoneNumber ? 'error-border' : ''}`}
                              name="phoneNumber"
                              value={reference?.phoneNumber}
                              onChange={handleReferenceChange}
                              type='tel'
                              placeholder={t("EmployeeReference.Phone Number")}
                              maxLength={10}
                            />
                          </p>
                          {errors.phoneNumber && <p className='validation-error ml-4 mt-3'>{errors.phoneNumber}</p>}
                        </div>
                      </div>
                    </div>

                    <div className='emp_footer'>
                      <div className=''>
                        <button
                          type='button'
                          className='emp-save-kyc'
                          onClick={handleReferenceSave}
                          disabled={isReferenceSaving}>
                          {isReferenceSaving ? (isReferenceUpdating ? t('customer.Updating..') : t('intentCreate.Saving....')) : (isReferenceExists ? t("intentCreate.Update") : t("intentCreate.Save"))}
                        </button>
                      </div>

                      <div className=''>
                        <button
                          type='button'
                          className='emp_cancle-kyc'
                          onClick={handleCancelReference}>
                          {t("intentCreate.Cancel")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div></div>
      </div>
    </>
  )
}
export default CreateEmployee;