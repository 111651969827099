import React from 'react'
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import { useLanguage } from "../../LanguageContext";
import Order from '../order-sidebar/OrderSidebar';
import CardSekeletonLoader from '../skeleton-loader/CardSekeletonLoader';
import API from '../../services/Api';
import './AllOrder.scss';
import STORAGE_KEYS from '../../utils/storageKey';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';


const AllOrders = ({ userId, PendingAmount }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const { t } = useLanguage();
  const numberOfColumns = 9;
  const [loading, setLoading] = useState(true);
  const [noRecordsMessage, setNoRecordsMessage] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [openSidebar, setOpenSidebar] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [orderStatCount, setOrderStatCount] = useState({})
  const [orderCount, setOrderCount] = useState({})

  const handleOpenSidebar = (request) => {
    setSelectedOrders(request);
    setOpenSidebar(true);
  };

  const handleOpenDropdown = (item) => {
    setShowDropdown(!showDropdown);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setOpenSidebar(!openSidebar);
  };

  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

  const fetchData = () => {
    setLoading(true);
    fetch(
      `${API.commerceAPI}/commerce/order/order/group/${groupId}?userId=${userId}&page=${currentPage + 1}&limit=${perPage}`, {
      headers: {
        Authorization: `${refreshToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setAllOrders(data?.data?.items || []);
        const totalItems = data?.totalItemsCount || 0;
        setPageCount(Math.ceil(totalItems / perPage));
        setOrderStatCount(data.data)
        setNoRecordsMessage(data?.totalItemsCount ? '' : 'No Data Found.');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [userId, currentPage, perPage, itemsPerPage]);


  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = allOrders.map((item, index) => index);
      setCheckedItems(checkedItems);
    } else {
      setCheckedItems([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleSingleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, index]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleOptionClick = (option, e) => {
    e.stopPropagation();
    if (option === "WhatsApp" && selectedItem?.phoneNumber) {
      const whatsappUrl = `https://web.whatsapp.com/send?phone=${selectedItem.phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else if (option === "Email" && selectedItem?.email) {
      const emailUrl = `mailto:${selectedItem.email}`;
      window.location.href = emailUrl;
    } else {
      console.log(`Selected option "${option}" for item:`, selectedItem);
    }
    setShowDropdown(false);
  };

  const headers = {
    Authorization: `${refreshToken}`,
    "Content-Type": "application/json",
  };


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${API.commerceAPI}/commerce/order/order/group/${groupId}?userId=${userId}&page=${pageNumber + 1}&limit=${itemsPerPage * allOrders}`,
          { headers }
        );

        if (!response.ok) {
          if (response.status === 404) {
            setNoRecordsMessage("No Data Found.");
            setAllOrders([]);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } else {
          const data = await response.json();
          setAllOrders(data?.data?.items || []);
          setOrderStatCount(data?.data);
          setOrderCount(data)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setNoRecordsMessage("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId, groupId, pageNumber, itemsPerPage]);



  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * itemsPerPage;
  const currentAllOrder = allOrders.slice(offset, offset + itemsPerPage);

  function addSpaceToStatus(status) {
    if (status === "Inprogress" || status === "Inprocess") {
      return status
        .replace("Inprogress", "In Progress")
        .replace("Inprocess", "In Process");
    } else {
      return status;
    }
  }

  const totalItemsCount = orderCount?.totalItemsCount || 0;
  const receivedAmount = orderStatCount?.ReceivedAmount || 0;
  const pendingAmount = orderStatCount?.PendingAmount || 0;


  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "#6ba2b4";
      case "package":
        return '#8adfe6';
      case "shipment":
        return "orange";
      case "delivered":
        return "#008000";
      case "cancelled":
        return "red";
      case "completed":
        return "#1BCB4B";
      case "dispatched":
        return "#df14df";
      case "intransit":
        return "gray";
      default:
        return "black";
    }
  };

  const getKeyColor = (key) => {
    switch (key) {
      case 'Orders':
        return '#000080';
      case 'PendingAmount':
        return '#6495ED';
      case 'ReceivedAmount':
        return '#1ABC9C';
      default:
        return 'black';
    }
  };

  const formatKeyName = (key) => {
    switch (key) {
      case 'PendingAmount':
        return 'Pending Amt';
      default:
        return key;
    }
  };

  const getPaymentStatusColor = (paymentStatus) => {
    switch (paymentStatus) {
      case "unpaid":
        return " #FF0000";
      case "paid":
        return "#1bcb4a";
      case "partially paid":
        return "#FE6F5E";
      case "credit":
        return "#964B00";
      default:
        return "red";
    }
  };

  return (
    <>
      <div className=''>
        {loading ? (
          <CardSekeletonLoader />
        ) : (
          <div className="row card-order mx-1">
            <div className="col-2 all-order-statistics ml-1 card card-width">
              <p className="cardname text-center mt-1" style={{ color: getKeyColor('Orders') }}>
                <strong>Total Orders</strong>
                <br />
                <span style={{ color: getKeyColor('Orders') }}>{totalItemsCount}</span>
              </p>
            </div>
            <div className="col-2 all-order-statistics ml-2 card card-width">
              <p className="cardname text-center mt-1" style={{ color: getKeyColor('ReceivedAmount') }}>
                <strong>Received Amount</strong>
                <br />
                <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                <span style={{ color: getKeyColor('ReceivedAmount') }}>{receivedAmount}</span>
              </p>
            </div>
            <div className="col-2 all-order-statistics ml-2 card card-width">
              <p className="cardname text-center mt-1" style={{ color: getKeyColor('PendingAmount') }}>
                <strong>Pending Amount</strong>
                <br />
                <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                <span style={{ color: getKeyColor('PendingAmount') }}>{pendingAmount}</span>
              </p>
            </div>
          </div>
        )}

        <div className='row'>
          <div className="mt-3 table-scroll-sr service_os">
            <table className="table table-hover  ">
              <thead className="font14">
                <tr className="dark-text-theme table-head">
                  <th scope="col" className="table-head">
                    {t("order.title")}                </th>

                  <th scope="col" className="table-head ">
                    {t("table.date & time")}                </th>
                  <th scope="col" className="table-head ">
                    {t("table.location")}
                  </th>
                  <th scope="col" className="table-head " >{t("table.Payment Status")}</th>

                  <th scope="col" className="table-head">
                    {t("table.Amount")}
                  </th>
                  <th
                    scope="col"
                    className="table-head "
                    style={{ width: "100px" }}
                  >
                    {t("table.Credit")}
                  </th>
                  <th scope="col" className="table-head ">
                    {t("table.Debit")}
                  </th>
                  <th scope="col" className="table-head font14">
                    {t("table.status")}
                  </th>
                  <th scope="col" className="table-head font14">
                    {t("table.action")}                </th>
                </tr>
              </thead>
              {loading ? (
                <SkeletonLoader columns={numberOfColumns} />
              ) : Array.isArray(currentAllOrder) && currentAllOrder.length > 0 ? (
                <tbody>
                  {currentAllOrder.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="t-data font13 cursor-pointer"
                        onClick={() => handleOpenSidebar(item)}
                      >
                        {typeof item?.orderId === "object" ? "-" : item?.orderId}
                      </td>
                      <td className="t-data font13 locate-color">
                        {typeof item?.orderDate === "object"
                          ? "--"
                          : (
                            <span>
                              {new Date(item?.orderDate).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}{" "}
                              {new Date(item?.orderDate).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                            </span>
                          )
                        }
                      </td>
                      <td className="t-data font13">
                        {typeof item?.userId?.location === "object" ? "-" : item?.userId?.location || "-"}
                      </td>
                      <td className="t-data font14 cursor-pointer">
                        <span style={{ color: getPaymentStatusColor(item?.paymentInfo?.paymentStatus), fontWeight: "900" }}>
                          {typeof item?.paymentInfo?.paymentStatus === "object" ? "-" : capitalizeFirstLetter(item?.paymentInfo?.paymentStatus) || "-"}
                        </span>
                      </td>
                      <td className="t-data font13">
                        <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;{item?.totalCartPrice || "-"}
                      </td>
                      <td className="t-data font13">
                        {typeof item?.RemainingAmount === "object" ? "--" : item?.totalReturnAmount || "--"}
                      </td>
                      <td className="t-data font13">
                        {typeof item?.totalPaidAmount === "object" ? "--" : item?.totalPaidAmount || "--"}
                      </td>
                      <td className="t-data font13 status-color">
                        {item?.status ? (
                          <span style={{ color: getStatusColor(item?.status), fontWeight: "900" }}>
                            {addSpaceToStatus(item.status.charAt(0).toUpperCase() + item.status.slice(1))}
                          </span>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td className="t-data font13 cursor-pointer">
                        <div className="dropdown">
                          <i
                            className="fas fa-ellipsis-h dot-icon"
                            id={`dropdownMenuButton${index}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => handleOpenDropdown(item)}
                          ></i>
                          <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                            <button
                              onClick={(e) => handleOptionClick("WhatsApp", e)}
                              className={`dropdown-item font14 ${!item?.userId?.phoneNumber ? "disabled" : ""}`}
                            >
                              WhatsApp
                            </button>
                            <button
                              onClick={(e) => handleOptionClick("Email", e)}
                              className={`dropdown-item font14 ${!item?.userId?.email ? "disabled" : ""}`}
                            >
                              Email
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <td colSpan="9" className="text-center">
                    <img src="/no-data-image.png" alt="No data found" className="allorder-img" />
                  </td>
                </tbody>
              )}
            </table>

            <div className='d-inline-flex pagination-container'>
              <div className='col-12 p-0'>
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={'...'}
                  pageCount={Math.ceil(allOrders.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'Pagination-products'}
                />
              </div>
            </div>

            {openSidebar && (
              <Order
                handleclose={handleClose}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                setOpenSidebar={setOpenSidebar}
                fetchData={fetchData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default AllOrders