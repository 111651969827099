import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../../LanguageContext';
import './Category.scss';
import { fetchParentCategories } from '../../utils/Helper';
import slugify from 'slugify';
import Avatar from '../../components/avatar/Avatar';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import API from '../../services/Api';

const CategoryUpdateSidebar = ({ isOpen, handleClose, _id, fetchCategory, selectedCategory }) => {
  const [areInputsFilled, setAreInputsFilled] = useState(false);
  const [apiCallMade, setApiCallMade] = useState(false);
  const { t } = useLanguage();
  const [isSaving, setIsSaving] = useState(false);
  const [subcategoryInput] = useState(selectedCategory.subcategory || false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInputErrors((prevErrors) =>
      prevErrors.map((prevError) =>
        prevError.field === name ? { ...prevError, message: '' } : prevError
      )
    );
    let updatedValue;
    if (type === 'checkbox') {
      updatedValue = checked;
    } else if (type === 'text') {
      updatedValue = value;
    }

    setFormData({ ...formData, [name]: updatedValue });
    const isNameFilled = name === 'name' ? updatedValue.trim() !== '' : formData.name.trim() !== '';
    const isSlugFilled = name === 'slug' ? updatedValue.trim() !== '' : formData.slug.trim() !== '';
    const isTagFilled = name === 'tag' ? updatedValue?.length > 0 : formData.tag?.length > 0;
    const isSubcategoryFilled = name === 'subcategory' ? updatedValue !== undefined : formData.subcategory !== undefined;

    setAreInputsFilled(
      isNameFilled && isSlugFilled && isTagFilled && isSubcategoryFilled
    );
  };

  const [inputErrors, setInputErrors] = useState([
    { field: 'name', message: '' },
    { field: 'slug', message: '' },
    { field: 'tag', message: '' },
    { field: 'description', message: '' },
  ]);

  const groupId = localStorage.getItem("selectedGroupId");

  const [formData, setFormData] = useState({
    name: selectedCategory.name || '',
    slug: selectedCategory.slug || '',
    tag: selectedCategory.tags || [],
    parentCategory: selectedCategory.parentCategory || '',
    description: selectedCategory.description || '',
    groupId: groupId,
    categorySaved: false,
    imageUrl: selectedCategory.imageUrl || '',
    subcategory: subcategoryInput,
    selectedImage: [],
    selectedImages: [],
    isSaving: false,
    selectedFile: null,
    parentCategories: [],
    category: {},
    showUploadButton: false,
    profile_pic: '',
    showSuccessToast: false,
  });

  const clearInputs = () => {
    setFormData({
      ...formData,
      name: '',
      slug: '',
      tag: [],
      tags: '',
      parentCategory: '',
      description: '',
      selectedFile: null,
      subcategory: subcategoryInput,
    });
  };

  useEffect(() => {
    const newSlug = generateSlug(formData.name);
    setFormData({ ...formData, slug: newSlug });
  }, [formData.name]);

  const generateSlug = (input) => {
    const options = {
      lower: true,
      strict: true,
    };
    const slug = slugify(input, options);
    return slug.replace(/-/g, '_');
  };

  const handleSave = async () => {
    let hasError = false;
    inputErrors.forEach((error) => {
      if (!formData[error.field]) {
        error.message = `${error.field.charAt(0).toUpperCase() + error.field.slice(1)} is required`;
        hasError = true;
      }
      if (typeof formData.tag === 'string') {
        formData.tag = formData.tag.split(',').map((tag) => tag.trim());
      }
      if (!Array.isArray(formData.tag) || formData.tag?.length === 0) {
        inputErrors.find((error) => error.field === 'tag').message = 'Tag is required';
        hasError = true;
      }
    });
    setInputErrors([...inputErrors]);
    if (hasError) {
      return;
    }
    if (apiCallMade) {
      return;
    }
    setIsSaving(true);
    setFormData({ ...formData, isSaving: true });
    const data = {
      name: formData.name,
      slug: formData.slug,
      tags: formData.tag,
      parentCategory: formData.parentCategory,
      description: formData.description,
      groupId: formData.groupId,
      imageUrl: formData.imageUrl,
      subcategory: formData.subcategory,
    };
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/category/${_id}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchCategory('')
      toast.success('Category Updated Successfully!', {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
      });
      clearInputs();
      handleClose();
      setFormData((prevData) => ({ ...prevData, showSuccessToast: true }));
      setTimeout(() => {
        fetchCategory('')
      }, 4000);
      setApiCallMade(true);
    } catch (error) {
      toast.error('Category update failed');
    } finally {
      setFormData({ ...formData, isSaving: false });
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchParentCategories();
        const items = data.data.items;
        setFormData({ ...formData, parentCategories: items });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formData.showSuccessToast) {
      setTimeout(() => {
        setFormData((prevData) => ({ ...prevData, showSuccessToast: false }));
      }, 3000);
    }
  }, [formData.showSuccessToast]);

  const handleCancel = () => {
    clearInputs();
  };

  function changeProfileURL(url) {
    setFormData({ ...formData, imageUrl: url });
  }

  return (
    <div className={`feature-sidebar p-3 ${isOpen ? 'open' : ''}`}>
      <h4 className='feature-titles pt-4'>{t('categorySidebar.Update Category')}</h4>
      <hr className='update-category-hr' />
      <div className='close-icon' onClick={handleClose}>
        <i className="fa-solid fa-circle-xmark mt-4 mr-3"></i>
      </div>

      <div className="category-section">
        <div className=' mt-2 scrollbar-warehouse'>
          <div className='scrollbar-right'>
            <Avatar onUploadSuccess={changeProfileURL} warehouse={formData.imageUrl} />
            <br /><br />
          </div>

          {inputErrors.map((error) => (
            <p key={error.field}>
              <label htmlFor={error.field} className="input-label pl-2">
                {error.field === "description *"
                  ? "Category's Description"
                  : ` ${error.field.charAt(0).toUpperCase() + error.field.slice(1)}`}
              </label>

              <input
                type="text"
                className={`category-inputs ${error.message && 'error-border'}`}
                name={error.field} placeholder={
                  `Category's ${error.field.charAt(0).toUpperCase() + error.field.slice(1)} `}
                value={formData[error.field]} onChange={handleInputChange} />
              {error.message && <span className="error-message">{error.message}</span>}
            </p>
          ))}

          <div>
            <label className='input-labels'>{t('categorySidebar.Sub category')}</label><br />
            <label className='pl-3 category-select-true'>
              <input
                type="checkbox"
                name="subcategory"
                checked={formData.subcategory === true}
                onChange={handleInputChange}
              />
              {t('categorySidebar.True')}
            </label>

            <label className='pl-3 category-select-true'>
              <input
                type="checkbox"
                name="subcategory"
                checked={formData.subcategory === false}
                onChange={handleInputChange}
              />
              {t('categorySidebar.False')}
            </label>
          </div>
        </div>

        <p className='category-up-btn'>
          <button className='features-saves-btn' onClick={handleSave} disabled={isSaving}>
            {isSaving ? t('customer.Updating..') : t('product.Update')}
          </button>
          <button className='feature-cancel ml-2' onClick={handleCancel}>{t("product.Cancel")}</button>
        </p>
      </div>
    </div>
  );
};

export default CategoryUpdateSidebar;