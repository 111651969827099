import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import './Products.scss';
import { ProductHeader } from '../../components/header/Header';
import Button from '../../components/button/Button';
import ReactPaginate from 'react-paginate';
import ProductDetailPopup from '../../components/popups/ProductDetails';
import { Tooltip as ReactTooltip } from "react-tooltip";
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const Products = () => {
  const [product, setProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const defaultImageUrl = "/images/productdefault.jpg";
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const { t } = useLanguage();

  const fetchProductData = (query) => {
    setLoading(true);
    let url = `${API.commerceAPI}/commerce/products/all/group/${groupId}?search=${query}`;
    fetch(url)
      .then((result) => result.json())
      .then((resp) => {
        const items = resp?.data.items || [];
        setProduct(items);
        setFilteredProducts(items);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProductData(searchQuery);
  }, [searchQuery, selectedCategories]);

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    if (Array.isArray(product)) {
      const filtered = product.filter((productList) =>
        (productList.name.toLowerCase().includes(query) ||
          productList.tags.some(tag => tag.toLowerCase().includes(query))) &&
        (selectedCategories?.length === 0 || selectedCategories.includes(productList.category))
      );
      setFilteredProducts(filtered.reverse());
      setCurrentPage(0);
    }
  }, [searchQuery, product, selectedCategories]);

  useEffect(() => {
    const getZoomLevel = () => {
      return Math.round((window.outerWidth / window.innerWidth) * 100);
    };
    const updateItemsPerPage = () => {
      const zoomLevel = getZoomLevel();
      if (zoomLevel === 100) {
        setItemsPerPage(10);
      } else if (zoomLevel <= 90) {
        setItemsPerPage(10);
      }
    };
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const offset = currentPage * itemsPerPage;
  const currentData = Array.isArray(filteredProducts) ? filteredProducts.slice(offset, offset + itemsPerPage) : [];
  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleApplyFilter = (minPrice, maxPrice) => {
    let url = `${API.commerceAPI}/commerce/products/all/group/${groupId}?minPrice=${minPrice}&maxPrice=${maxPrice}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data && data?.data?.items && Array.isArray(data?.data?.items)) {
          setFilteredProducts(data?.data?.items);
        } else {
          setFilteredProducts([]);
        }
      })
      .catch(error => {
        console.error('Error fetching filtered products:', error);
        setFilteredProducts([]);
      });
  };

  const openPopup = (product) => {
    setSelectedProduct(product);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setSelectedProduct(null);
    setPopupOpen(false);
  };

  const handleDeleteProduct = (productId) => {
    fetch(`${API.commerceAPI}/commerce/products/${productId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Product Deleted Successfully", {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                className="fa-solid fa-trash float-right cis pr-2"
                style={{ color: "white" }}
              ></i>
            ),
            progressClassName: "custom-progress",
          });

          fetchProductData(searchQuery);
        } else {
          console.error('Error deleting product');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleLeftArrowClick = () => {
    fetchProductData(searchQuery);
  };

  return (
    <div className="main">
      <div className="body_container">
        <div className="main-padding1">
          {isPopupOpen && (
            <ProductDetailPopup product={selectedProduct} onClose={closePopup} onDeleteProduct={handleDeleteProduct} />
          )}
          <div>

            <ProductHeader
              onSearch={handleSearch}
              searchQuery={searchQuery}
              onApplyFilter={handleApplyFilter}
              onLeftArrowClick={handleLeftArrowClick}
            />

            <hr className="product-hr" />
            <ToastContainer position="top-right" autoClose={1000} />
            {loading && (
              <div className='spinner-loader product-spinner'>
                <div className='spinner-border spinner'></div>
              </div>
            )}

            {!loading && filteredProducts?.length === 0 ? (
              <div className='text-center mt-5'>
                <img src='..\no-data-image.png' alt='no-image-product' />
              </div>
            ) : (
              <div className="grid-container product-list-scroll">
                {currentData.map((productList, index) => (
                  <div className="card card_product products-item" key={index} value={productList.id}>
                    {productList && Array.isArray(productList.pictures) && productList.pictures?.length > 0 ? (
                      <img src={productList.pictures[0]}
                        alt={`Product 1`} className="product_img" />
                    ) : (
                      <img src={defaultImageUrl} alt="Default Product" className="product_img" />
                    )}
                    <b className="product-title" data-tooltip-id={`tooltip-${productList._id}`}>
                      {truncateText(productList.name, 12)}
                    </b>
                    <ReactTooltip id={`tooltip-${productList._id}`} place="top" effect="solid">
                      {productList.name}
                    </ReactTooltip>

                    <div className="d-flex stars">
                      {productList.rating && productList.rating !== "" ? (
                        <>
                          {Array.from({ length: Number(productList.rating) }).map((_, index) => (
                            <i key={index} className="fa-solid fa-star feature-stars" />
                          ))}
                          {Array.from({ length: 5 - Number(productList.rating) }).map((_, index) => (
                            <i key={index} className="fa-regular fa-star feature-stars" />
                          ))}
                        </>
                      ) : (
                        <>
                          <i className="fa-regular fa-star feature-stars" />
                          <i className="fa-regular fa-star feature-stars" />
                          <i className="fa-regular fa-star feature-stars" />
                          <i className="fa-regular fa-star feature-stars" />
                          <i className="fa-regular fa-star feature-stars" />
                        </>
                      )}
                    </div>

                    <div>
                      <b className="feature-price2">Rs.{productList.marketPrice}</b>
                      <b className="feature-price1">Rs.{productList.regularPrice}</b>
                    </div>

                    <Button onClick={() => openPopup(productList)} />
                  </div>

                ))}
              </div>
            )}

            <div className="d-inline-flex pagination-container">
              <div className="col-12 p-0">
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={'...'}
                  pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={onPageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'Pagination-products'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;