import { React, useState, useEffect } from 'react'
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './create-intent.scss'
import { useLanguage } from '../../LanguageContext'
import { fetchWarehouse } from '../../utils/Helper'
import { fetchSupplier } from '../../utils/Helper';
import { fetchProducts } from '../../utils/Helper';
import API from '../../services/Api';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import STORAGE_KEYS from '../../utils/storageKey';

export const CreateIntent = ({ handleClose, intentId, intent, fetchIntents }) => {
    const { t } = useLanguage();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [products, setProducts] = useState([]);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsUpper, setSelectedProductsUpper] = useState([])
    const [clearTypeahead, setClearTypeahead] = useState(false);
    const [currentQuantity, setCurrentQuantity] = useState('');
    const [currentQuantityUpper, setCurrentQuantityUpper] = useState('')
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [warehouse, setWarehouse] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedWarehouseUpper, setSelectedWarehouseUpper] = useState(null);
    const [currentProducts, setCurrentProducts] = useState(null);

    const [intentData, setIntentData] = useState({
        selectedProducts: [],
        selectedProductsUpper: [],
        expectedDeliveryDate: null,
        selectedWarehouse: null,
        selectedWarehouseUpper: null,
        selectedSuppliers: [],
    });

    const formattedDate = currentDateTime.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });

    const handleWarehouseSelectUpper = (selected) => {
        if (selected?.length > 0) {
            setSelectedWarehouseUpper(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                warehouse: '',
            }));
        } else {
            setSelectedWarehouseUpper(null);
        }
    };

    const handleProductSearch = (query, product) => {
        return product.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()));
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const loadProducts = async () => {
            const productData = await fetchProducts();
            if (productData && productData.data && productData.data.items) {
                setProducts(productData.data.items);
            }
        };
        loadProducts();
    }, []);

    const handleProductSelect = (selected) => {
        if (selected?.length) {
            setSelectedProduct(selected[0]);
        }
    };

    const handleProductSelectUpper = (selected) => {
        if (selected.length > 0) {
            setCurrentProducts(selected[0]);
        } else {
            setCurrentProducts(null);
        }
    };

    const handleAddProduct = () => {
        let errors = {};
        if (!selectedProduct || !selectedProduct.name) {
            errors.product = 'Please select a valid product.';
        }
        const quantity = parseInt(currentQuantity, 10);
        if (isNaN(quantity) || quantity <= 0) {
            errors.quantity = 'Enter the quantity.';
        }
        if (Object.keys(errors)?.length > 0) {
            setValidationErrors(errors);
            return;
        }
        setValidationErrors({});
        setClearTypeahead(!clearTypeahead);
        const updatedProducts = [...selectedProducts];
        const existingProduct = updatedProducts.find(
            (p) => p.productcode === selectedProduct.productcode
        );
        if (existingProduct) {
            existingProduct.quantity += quantity;
        } else {
            updatedProducts.push({
                ...selectedProduct,
                quantity,
            });
        }
        setSelectedProducts(updatedProducts);
        setSelectedProduct(null);
        setCurrentQuantity('');
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        setCurrentQuantity(value);
        if (isNaN(value) || parseInt(value, 10) <= 0) {
            setValidationErrors({ quantity: 'enter quantity.' });
        } else {
            setValidationErrors({});
        }
    };

    const handleQuantityChangeUpper = (e) => {
        const value = e.target.value;
        setCurrentQuantityUpper(value);
        if (isNaN(value) || parseInt(value, 10) <= 0) {
            setValidationErrors({ quantity: 'enter quantity.' });
        } else {
            setValidationErrors({});
        }
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts.splice(index, 1);
        setSelectedProducts(updatedProducts);
    };

    const fetchAndSetSuppliers = () => {
        fetchSupplier().then(response => {
            if (response.data && response.data.items) {
                const formattedSuppliers = response.data.items.map(item => ({
                    ...item,
                    name: item.name || item.supplierName || ''
                }));
                setSuppliers(formattedSuppliers);
            }
        }).catch(error => {
            console.error('Failed to fetch suppliers:', error);
        });
    };

    const handleSupplierSelect = (selected) => {
        if (selected?.length) {
            setSelectedSupplier(selected[selected?.length - 1]);
        }
    };

    const handleAddSupplier = () => {
        setClearTypeahead(!clearTypeahead);
        if (selectedSupplier) {
            const updatedSuppliers = [...selectedSuppliers];
            if (!updatedSuppliers.find(s => s.supplierId === selectedSupplier.supplierId)) {
                updatedSuppliers.push(selectedSupplier);
            }
            setSelectedSuppliers(updatedSuppliers);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                supplier: '',
            }));
            setSelectedSupplier(null);
        } else {
            toast.error('Please select a supplier to add.');
        }
    };

    const handleRemoveSupplier = (index) => {
        const updatedSuppliers = [...selectedSuppliers];
        updatedSuppliers.splice(index, 1);
        setSelectedSuppliers(updatedSuppliers);
    };

    const handleFetchWarehouses = async () => {
        try {
            const response = await fetchWarehouse();
            if (response.data && response.data.items) {
                const formattedWarehouses = response.data.items.map((item) => ({
                    name: item.WareHouseName,
                    wareHouseId: item.wareHouseId,
                }));
                setWarehouse(formattedWarehouses);
            }
        } catch (error) {
            console.error('Failed to fetch warehouses:', error);
        }
    };

    const handleWarehouseSelect = (selected) => {
        if (selected?.length > 0) {
            setSelectedWarehouse(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                warehouse: '',
            }));
        } else {
            setSelectedWarehouse(null);
        }
    };

    const handleDeliveryDateChange = (date) => {
        setExpectedDeliveryDate(date);

        if (date) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                expectedDeliveryDate: '',
            }));
        }
    };

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleProductSearchUpper = (query, option) => {
        if (!option || !option.name) return false;
        return option.name.toLowerCase().includes(query.toLowerCase());
    };

    const isValidateForm = () => {
        const errors = {};
        let isValid = true;
        if (!selectedWarehouseUpper) {
            errors.warehouses = 'Please select a warehouse.';
            isValid = false;
        } else {
            errors.warehouses = '';
        }

        if (!currentProducts) {
            errors.products = 'Please select a product.';
            isValid = false;
        } else {
            errors.products = '';
        }

        const quantity = parseInt(currentQuantityUpper, 10);
        if (!currentQuantityUpper || isNaN(quantity) || quantity <= 0) {
            errors.quantity = 'Please enter a valid quantity.';
            isValid = false;
        } else {
            errors.quantity = '';
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleAddProductUpper = () => {
        if (!isValidateForm()) {
            return;
        }
        const newProduct = {
            ...currentProducts,
            quantity: currentQuantityUpper
        };

        setSelectedProductsUpper([...selectedProductsUpper, newProduct]);
        setCurrentProducts(null);
        setClearTypeahead(!clearTypeahead);
        setCurrentQuantityUpper('');
        setValidationErrors({});
    }

    const handleDeleteProductUpper = (index) => {
        const updatedProducts = selectedProductsUpper.filter((_, i) => i !== index);
        setSelectedProductsUpper(updatedProducts);
    };;

    const validateForm = () => {
        const errors = {};
        let isValid = true;
        if (!selectedProducts?.length) {
            errors.product = 'Select at least one product.';
            isValid = false;
        } else {
            errors.product = '';
        }
        const hasValidProduct = selectedProducts.some(
            (product) => product.quantity > 0
        );

        if (!selectedSuppliers?.length) {
            errors.supplier = 'Select at least one supplier.';
            isValid = false;
        }

        if (!selectedWarehouse) {
            errors.warehouse = 'Select the warehouse.';
            isValid = false;
        } else {
            errors.warehouse = '';
        }

        if (!expectedDeliveryDate) {
            errors.deliveryDate = 'Enter delivery date.';
            isValid = false;
        } else {
            errors.deliveryDate = '';
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }
        setIsSaving(true);
        const payload = {
            groupId: parseInt(localStorage.getItem("selectedGroupId"), 10),
            products: selectedProducts.map(product => ({
                productcode: parseInt(product.productcode, 10),
                qty: parseInt(product.quantity, 10),
                unit: product.unit
            })),
            intentDate: moment(currentDateTime).format("YYYY-MM-DD"),
            expecteDeliveryDate: expectedDeliveryDate ? expectedDeliveryDate.toISOString("YYYY-MM-DDTHH:mm:ss.SSSZ") : null,
            warehouse: [
                selectedWarehouse ? selectedWarehouse.wareHouseId : null,
                selectedWarehouseUpper ? selectedWarehouseUpper.wareHouseId : null,
            ].filter(id => id !== null),
            suppliers: selectedSuppliers.map(supplier => parseInt(supplier.supplierId, 10))
        };
        try {
            let response;
            if (intentId) {
                response = await fetch(`${API.commerceAPI}/commerce/intent/group/${groupId}/intent/${intentId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            } else {
                response = await fetch(`${API.apiGateway}/commerce-gw/add/intent`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            }
            if (response.ok) {
                const actionType = intentId ? 'Updated' : 'Created';
                toast.success(`Intent ${actionType} Successfully!`, {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                });
                handleClose()
                fetchIntents();
            } else {
                throw new Error('Error in response.');
            }
        } catch (error) {
            console.error('Failed to save intent:', error);
            toast.error('Failed to save intent: ' + error.message);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (intent) {
            if (intent.products) {
                setSelectedProducts(
                    intent.products.map((product) => ({
                        ...product,
                        quantity: product.qty || 0,
                        unit: product?.unit || 'N/A',
                    }))
                );
            }

            if (intent.suppliers) {
                setSelectedSuppliers(intent.suppliers);
            }

            if (intent.warehouse && Array.isArray(intent.warehouse)) {
                const warehouses = intent.warehouse.map((wh) => ({
                    wareHouseId: wh.wareHouseId || wh.id,
                    name: wh.name || wh.WareHouseName,
                }));
                setSelectedWarehouse(warehouses[0] || null);
                setSelectedWarehouseUpper(warehouses[1] || null);
            }

            if (intent.expecteDeliveryDate) {
                setExpectedDeliveryDate(new Date(intent.expecteDeliveryDate));
            }
        }
    }, [intent]);

    return (
        <div className='Intent-sidebar'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <h5 className='Intent-create-title'>
                            {t(intentId ? "intentCreate.Update Intent" : "intentCreate.Add Intent")}
                        </h5>
                        <small className='intent-id'>{t(intentId ? `IntentId  ${intentId}` : "intentCreate.Create here new Intent")}</small>
                    </div>
                    <div className='col-lg-5'> </div>
                    <div className='col-lg-3'>
                        <p className="font16 date-Time mt-3 ml-2">
                            <strong> {t("newOrder.date-time")}</strong><br />
                            <small>{formattedDate}</small>{""}
                        </p>
                    </div>
                </div>

                <hr className='hr-line hrrr' />
                <div className='scroller-bar-intent'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <b className='head-theme'>{t("intent.warehouse name")}</b><span className='text-danger'>*</span><br />
                            <Typeahead
                                id="warehouse-typeahead-lower"
                                labelKey="name"
                                options={warehouse}
                                onChange={handleWarehouseSelect}
                                onFocus={handleFetchWarehouses}
                                placeholder={t("intentCreate.Select a warehouse")}
                                selected={selectedWarehouse ? [selectedWarehouse] : []}
                                className={`input-sto ${validationErrors.warehouse ? 'error-border' : ''}`}
                            />
                            {validationErrors.warehouse && (
                                <span className="error-message">{validationErrors.warehouse}</span>
                            )}
                        </div>
                        <div className='col-lg-3'> </div>
                        <div className='col-lg-5'>
                            <b className='head-theme'>{t("intentCreate.Delivery Date")}</b><span className='text-danger'>*</span><br />
                            <DatePicker
                                className={`intent-delivery-date ${validationErrors.deliveryDate ? "error-border" : ""}`}
                                onChange={handleDeliveryDateChange}
                                selected={expectedDeliveryDate}
                                placeholderText="dd-mm-yyyy"
                                dateFormat="dd-MM-yyyy"
                                popperPlacement="top-end"
                            />
                            <br />
                            {validationErrors.deliveryDate && (
                                <span className="error-message">{validationErrors.deliveryDate}</span>
                            )}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <table className='table mt-2'>
                                <thead>
                                    <tr className='head-theme'>
                                        <th>{t("intentCreate.Product Code")}</th>
                                        <th>{t("intentCreate.Product Name")}</th>
                                        <th>{t("intentCreate.Qty")}</th>
                                        <th>{t("intentCreate.Unit")}</th>
                                        <th>{t("intentCreate.Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.productcode}</td>
                                            <td>{product.name}</td>
                                            <td>{product.quantity}</td>
                                            <td>{product.unit}</td>
                                            <td>
                                                <i className="fa-solid fa-trash-can intent-trash"
                                                    onClick={() => handleDeleteProduct(index)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            <Typeahead
                                id="product-typeahead"
                                labelKey="name"
                                key={clearTypeahead ? 'reset1' : 'reset2'}
                                options={products}
                                filterBy={(option, props) => handleProductSearch(props.text, option)}
                                onChange={handleProductSelect}
                                placeholder={t("intentCreate.Enter product")}
                                multiple={false}
                                className={`input-sto  ${validationErrors.product ? "error-border" : ""}`}
                            />
                            {validationErrors.product && (
                                <span className="error-message pt-4">{validationErrors.product}</span>
                            )}
                        </div>

                        <div className='col-lg-4'>
                            <input
                                type='text'
                                placeholder={t('intentCreate.Qty')}
                                className='intent-qty'
                                value={currentQuantity}
                                onChange={(e) => {
                                    setCurrentQuantity(e.target.value);
                                    const quantity = parseInt(e.target.value, 10);
                                    if (isNaN(quantity) || quantity <= 0) {
                                        setValidationErrors({ quantity: 'Please enter a valid quantity.' });
                                    } else {
                                        setValidationErrors({ quantity: '' });
                                    }
                                }}
                            />
                            {validationErrors.quantity && (
                                <div className="error-message pl-2">{validationErrors.quantity}</div>
                            )}
                        </div>

                        <div className='col-lg-4'>
                            <button onClick={handleAddProduct}
                                className='quantity-add-intent'>
                                {t("intentCreate.Add")}
                            </button>
                        </div>
                    </div>
                    <hr className='hrrr' />
                    <div className='row'>
                        <div className='col-11'></div>
                        <div className='col-1'>
                            {isOpen ? (
                                // <img
                                //     src='minus.png'
                                //     onClick={handleOpen}
                                //     className='cursor-pointer'
                                //     height={25}
                                //     alt="Open"
                                // />
                                <i class="fa-sharp fa-solid fa-thin fa-circle-minus plusIcon cursor-pointer" onClick={handleOpen}></i>
                            ) : (

                                <i class="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleOpen}
                                ></i>
                            )}
                        </div>
                    </div>

                    {/*  second warehouse table  */}
                    {isOpen && (
                        <div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <b className='head-theme'>{t("intent.warehouse name")}</b><span className='text-danger'>*</span><br />
                                    <Typeahead
                                        id="warehouse-typeahead-upper"
                                        labelKey='name'
                                        options={warehouse}
                                        onChange={handleWarehouseSelectUpper}
                                        onFocus={handleFetchWarehouses}
                                        placeholder={t("intentCreate.Select a warehouse")}
                                        selected={selectedWarehouseUpper ? [selectedWarehouseUpper] : []}
                                        className={`input-sto ${validationErrors.warehouses ? "error-border" : ""}`}
                                    />
                                    {validationErrors.warehouses && (
                                        <span className="error-message pt-4">{validationErrors.warehouses}</span>
                                    )}
                                </div>
                                <div className='col-lg-3'> </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <table className='table mt-2'>
                                        <thead>
                                            <tr className='head-theme'>
                                                <th>{t("intentCreate.Product Code")}</th>
                                                <th>{t("intentCreate.Product Name")}</th>
                                                <th>{t("intentCreate.Qty")}</th>
                                                <th>{t("intentCreate.Unit")}</th>
                                                <th>{t("intentCreate.Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(selectedProductsUpper) && selectedProductsUpper.map((product, index) => (
                                                <tr key={index}>
                                                    <td>{product.productcode}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.quantity}</td>
                                                    <td>{product.unit}</td>
                                                    <td>
                                                        <i className="fa-solid fa-trash-can intent-trash" onClick={() => handleDeleteProductUpper(index)}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <Typeahead
                                        id="product-typeahead"
                                        labelKey="name"
                                        options={products}
                                        filterBy={(option, props) => handleProductSearchUpper(props.text, option)}
                                        onChange={handleProductSelectUpper}
                                        placeholder={t("intentCreate.Enter product")}
                                        selected={currentProducts ? [currentProducts] : []}
                                        multiple={false}
                                        className={`input-sto ${validationErrors.products ? "error-border" : ""}`}
                                        key={clearTypeahead ? 'reset1' : 'reset2'}
                                    />
                                    {validationErrors.products && (
                                        <span className="error-message pt-4">{validationErrors.products}</span>
                                    )}
                                </div>

                                <div className='col-lg-4'>
                                    <input
                                        type='text'
                                        placeholder={t('intentCreate.Qty')}
                                        className='intent-qty'
                                        value={currentQuantityUpper}
                                        onChange={(e) => {
                                            const quantity = parseInt(e.target.value, 10);
                                            setCurrentQuantityUpper(e.target.value);
                                            if (isNaN(quantity) || quantity <= 0) {
                                                setValidationErrors({ ...validationErrors, quantity: 'Please enter a valid quantity.' });
                                            } else {
                                                setValidationErrors({ ...validationErrors, quantity: '' });
                                            }
                                        }}
                                    />
                                    {validationErrors.quantity && (
                                        <div className="error-message pl-2">{validationErrors.quantity}</div>
                                    )}
                                </div>

                                <div className='col-lg-4'>
                                    <button onClick={handleAddProductUpper} className='quantity-add-intent'>
                                        {t("intentCreate.Add")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <hr className='hrrr' />
                    <div className='row '>
                        <div className='col-lg-12'>
                            <h5 className='head-theme'>{t("intentCreate.Supplier Details")}
                                <span className='text-danger'>*</span> </h5>
                            <table className='table'>
                                <thead className='head-theme'>
                                    <th>{t("intentCreate.Name")}</th>
                                    <th>{t("intentCreate.Contact")}</th>
                                    <th>{t("intentCreate.Email")}</th>
                                    <th>{t("intentCreate.Action")}</th>
                                </thead>
                                <tbody>
                                    {selectedSuppliers.map((supplier, index) => (
                                        <tr key={index}>
                                            <td>{supplier.name}</td>
                                            <td>{supplier.phoneNumber}</td>
                                            <td>{supplier.email}</td>
                                            <td><i className="fa-solid fa-trash-can intent-trash"
                                                onClick={() => handleRemoveSupplier(index)}></i></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-4'>
                            <Typeahead
                                id="supplier-typeahead"
                                labelKey="name"
                                options={suppliers}
                                onChange={handleSupplierSelect}
                                onFocus={fetchAndSetSuppliers}
                                key={clearTypeahead ? 'reset1' : 'reset2'}
                                placeholder={t("intentCreate.Select a supplier")}
                                className={`input-sto ${validationErrors.supplier ? "error-border" : ""} `}
                            />
                            {validationErrors.supplier && (
                                <span className="error-message">{validationErrors.supplier}</span>
                            )}
                        </div>
                        <div className='col-lg-4'>
                            <button className='quantity-add-intent ml-4'
                                onClick={handleAddSupplier}>{t("intentCreate.Add")}</button>
                        </div>

                        <div className='col-lg-4'></div>
                    </div>
                    <hr className='hrrr' />
                </div>
                <div className='mt-2'>
                    <button className="intent-save"
                        onClick={handleSave}
                        disabled={isSaving}>
                        {t(isSaving ? (intentId ? 'customer.Updating..' : 'Saving...') : intentId ? 'intentCreate.Update' : 'intentCreate.Save')}
                    </button>

                    <button className='intent-cancel ml-2'
                        onClick={handleClose}>
                        {t("intentCreate.Cancel")}
                    </button>
                </div>
            </div>
        </div>
    )
}