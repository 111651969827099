import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../services/Api";

const AddProductSidebar = () => {
  const [dataOpen, setDataOpen] = useState(true);
  const [name, setname] = useState("");
  const [igst, setIGST] = useState("");
  const [cgst, setCGST] = useState("");
  const [marketPrice, setMarketPrice] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [memberPrice, setMemberPrice] = useState("")
  const [sellingPrice, setSellingPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [producterrors] = useState({
    name: "",
    qty: "",
    tax: "",
    igst: "",
    cgst: "",
    marketPrice: "",
    sellingPrice: "",
    regularPrice: "",
    memberPrice: ""
  });

  const getinputClass = (fieldName) => {
    return producterrors[fieldName]
      ? "select-status pl-2 error-border"
      : "select-status pl-2";
  };

  const handleCancelClick = () => {
    setDataOpen(false);
  };

  const handleAddClick = () => {
    setIsLoading(true);

    const payload = {
      name: name,
      igst: parseFloat(igst),
      cgst: parseFloat(cgst),
      regularPrice: parseFloat(regularPrice),
      buyingPrice: parseFloat(sellingPrice),
      memberPrice: parseFloat(memberPrice),
      marketPrice: parseFloat(marketPrice),
      groupId: 1703228300417,
      categoryId: 1706770929321,
      isPaymentOffline: false,
      isPaymentOnline: false,
      igst: 0,
      sgst: 0,
    };

    fetch(`${API.commerceAPI}/commerce/products/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Product Added Successfully !", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });

        setDataOpen(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding product:", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ background: "red", color: "white" }}
      />
      
      {dataOpen ? (
        <div className="order-sidebar">
          <div className="side-padding">
            <div className="container-fluid ">
              <div className="row">
                <form className="">
                  <h5 className="fontw-600">Add Product</h5>
                  <hr />
                  <div className="row mt-2">
                    <div className="col-6">
                      <label className="font14">
                        Product name&nbsp;<span className="text-danger">*</span>
                      </label>{" "}
                      <br />
                      <input
                        className={getinputClass("name")}
                        placeholder="Name"
                        name="Name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.name}
                      </div>
                    </div>
                    <div className="col-6">
                      <label className="font14">
                        Regular Price&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <br />
                      <input
                        placeholder="Regular Price"
                        name="Regular Price"
                        value={regularPrice}
                        className={getinputClass("regularPrice")}
                        onChange={(e) => setRegularPrice(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.regularPrice}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 ">
                      <label className="font14">
                        Selling Price&nbsp;<span className="text-danger">*</span>
                      </label>{" "}
                      <br />
                      <input
                        placeholder="Selling Price"
                        name="Selling Price"
                        value={sellingPrice}
                        className={getinputClass("sellingPrice")}
                        onChange={(e) => setSellingPrice(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.sellingPrice}
                      </div>
                    </div>
                    <div className="col-6">
                      <label className="font14">
                        Member Price&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <br />
                      <input
                        placeholder="Member Price"
                        name="Member Price"
                        value={memberPrice}
                        className={getinputClass("memberPrice")}
                        onChange={(e) => setMemberPrice(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.memberPrice}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <label className="font14">
                        Market Price&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <br />
                      <input
                        placeholder="Market Price"
                        name="Market Price"
                        value={marketPrice}
                        className={getinputClass("marketPrice")}
                        onChange={(e) => setMarketPrice(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.marketPrice}
                      </div>
                    </div>
                    <div className="col-6 ">
                      <label className="font14">
                        IGST&nbsp;<span className="text-danger">*</span>
                      </label>{" "}
                      <br />
                      <input
                        placeholder="IGST"
                        name="IGST"
                        value={igst}
                        className={getinputClass("igst")}
                        onChange={(e) => setIGST(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.igst}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <label className="font14">
                        CGST&nbsp;<span className="text-danger">*</span>
                      </label>{" "}
                      <br />
                      <input
                        placeholder="CGST"
                        name="CGST"
                        value={cgst}
                        className={getinputClass("cgst")}
                        onChange={(e) => setCGST(e.target.value)}
                      />
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {producterrors.cgst}
                      </div>
                    </div>
                  </div>
                </form>
                <button
                  type="button"
                  className="verify-btn close-btn font14"
                  onClick={handleAddClick}
                  disabled={isLoading}>
                  {isLoading ? (
                    <span>
                      <i className="spinner-border spinner-border-sm text-dark spin"></i>
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  type="button"
                  className="verify-btn close-btn font14 cursor-pointer ml-2"
                  onClick={handleCancelClick}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AddProductSidebar;
