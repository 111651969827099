import React, { useState } from 'react';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import { toast } from 'react-toastify';

function PaymentInfo({ openPaymentinfoSidebar, setOpenPaymentinfoSidebar, fetchCustomerData, userId, customerData }) {
  const [bank, setBank] = useState(customerData?.bank || '');
  const [upi, setUpi] = useState(customerData?.upi || '');
  const [accountNo, setAccountNo] = useState(customerData?.accountNo || '');
  const [ifsc, setIfsc] = useState(customerData?.ifsc || '');
  const [errors, setErrors] = useState({});
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLanguage();

  const handleClose = () => {
    setOpenPaymentinfoSidebar(!openPaymentinfoSidebar);
  };

  const handleClick = () => {
    setBank('');
    setUpi('');
    setAccountNo('');
    setIfsc('');
  };

  const handleBankChange = (e) => {
    setBank(e.target.value);
    clearError('bank');
  };

  const handleUpiChange = (e) => {
    const value = e.target.value;
    setUpi(value);
    clearError('upi');
    // const regex = /^[a-zA-Z0-9@]+$/;
    // if (!regex.test(value)) {
    //   setErrors((prevErrors) => ({ ...prevErrors, upi: 'UPI should contain only alphanumeric characters' }));
    // }
  };

  const handleAccountNoChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,16}$/.test(value)) {
      setAccountNo(value);
      if (value?.length < 12) {
        setErrors((prevErrors) => ({ ...prevErrors, accountNo: 'Account number must be at least 12 digits' }));
      } else {
        clearError('accountNo');
      }
    }
  };

  const handleIfscNoChange = (e) => {
    const value = e.target.value.trim().toUpperCase();
    setIfsc(value);
    if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, ifsc: 'Invalid IFSC' }));
    } else {
      clearError('ifsc');
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!bank.trim()) {
      newErrors.bank = 'Bank name is required';
      isValid = false;
    }

    // if (!upi.trim()) {
    //   newErrors.upi = 'UPI is required';
    //   isValid = false;
    // } else if (errors.upi) {
    //   isValid = false;
    // }

    if (!accountNo.trim()) {
      newErrors.accountNo = 'Account number is required';
      isValid = false;
    } else if (!/^\d{9,18}$/.test(accountNo)) {
      newErrors.accountNo = 'Invalid Account number';
      isValid = false;
    }

    if (!ifsc.trim()) {
      newErrors.ifsc = 'IFSC is required';
      isValid = false;
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)) {
      newErrors.ifsc = 'Invalid IFSC';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const data = {
        userId: parseInt(userId),
        bank,
        upi,
        accountNo,
        ifsc,
      };

      fetch(`${API.commerceAPI}/commerce/customer/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          toast.success("Payment Information Updated Successfully", {
            style: { backgroundColor: '#328b17', color: 'white', height: '50px' }
          })
          if (!response.ok) {
            throw new Error('Failed to update customer information');
          }
          setOpenPaymentinfoSidebar(false);
          fetchCustomerData();
        })
        .catch((error) => {
          console.error('Error updating customer information:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  return (
    <>
      <div className="add-customerinfo-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0">
            <div className=" container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b>{t('Payment.Payment Info')}</b>
                </h6>
                <p className="font14  title-info pl-1">{t('Payment.details')}</p>
                <hr className="sr-hr-info pl-1  "></hr>
              </div>

              <div className="close-icon">
                <i className="fa-solid fa-circle-xmark" onClick={handleClose}></i>
              </div>
            </div>

            <div className='sidebar-wrapper pl-3'>
              <form className="font14 ml-1">
                <div>
                  <label className="font14">{t('Payment.Bank')}<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.bank ? 'error-border' : ''}`}
                    placeholder={t('Payment.Bank')}
                    value={bank}
                    onChange={handleBankChange}
                  ></input>
                  {errors.bank && <div className="text-danger mt-2 font14">{errors.bank}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">UPI</label><br />
                  <input
                    className='payment-input'
                    placeholder={t("SupplierBank.UPI")}
                    name="upi"
                    value={upi}
                    onChange={handleUpiChange}
                  />
                  {/* {errors.upi && <div className="text-danger mt-2 font14">{errors.upi}</div>} */}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">{t('Payment.Account No')}<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.accountNo ? 'error-border' : ''}`}
                    placeholder={t('Payment.Account No')}
                    value={accountNo}
                    onChange={handleAccountNoChange}
                  ></input>
                  {errors.accountNo && <div className="text-danger mt-2 font14">{errors.accountNo}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-2">IFSC<span className="text-danger mt-1">*</span></label><br />
                  <input
                    type="text"
                    className={`add-Address-inputs pl-2 ${errors.ifsc ? 'error-border' : ''}`}
                    placeholder="IFSC"
                    maxLength="11"
                    value={ifsc}
                    onChange={handleIfscNoChange}
                  />
                  {errors.ifsc && <div className="text-danger mt-2 font14">{errors.ifsc}</div>}
                </div>
              </form>
            </div>

            <div className="mt-3 pl-3 set-button">
              <button className="saveDraft-btn mt-4" onClick={handleUpdate}>
                {isLoading ? (
                  t("customer.Updating..")
                ) : (
                  t('Personal.Update'))}
              </button>

              <button className="cancel-info-btn ml-2" onClick={handleClick}>
                {t('Personal.Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentInfo;