import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./DeleteCustomer.scss";
import API from '../../services/Api'
import STORAGE_KEYS from '../../utils/storageKey';
import { useLanguage } from '../../LanguageContext';

const DeleteCust = ({ data, onClose, fetchData, checkedItems, setIsDeleteConfirmationVisible }) => {
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const {t}=useLanguage()
    const [deleteInitiated, setDeleteInitiated] = useState(false);
    let isToastVisible = false;

    const deleteCustomerRequests = () => {
        if (isToastVisible) {
            return;
        }

        if (checkedItems ?.length === 0) {
            alert("Please select customer requests to delete.");
            return;
        }

        const urlParts = window.location.pathname.split("/");
        const customerrequestId = urlParts[urlParts ?.length - 1];
        const customerRequestIdsToDelete = checkedItems.map(
            (index) => data[index]?.userId
        );

        if (customerRequestIdsToDelete && !deleteInitiated) {
            setDeleteInitiated(true);
            fetch(
                `${API.commerceAPI}/commerce/customer/groupId/${groupId}?userId=${customerRequestIdsToDelete}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ customerrequestId: customerRequestIdsToDelete }),
                }
            )
                .then((response) => {

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((responseJson) => {
                    isToastVisible = true;
                    setIsDeleteConfirmationVisible(false)
                    toast.success("Customer Deleted Successfully !", {
                        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                        icon: (
                            <i
                                className="fa-solid fa-trash float-right cis pr-2"
                                style={{ color: "white" }}
                            ></i>
                        ),
                        progressClassName: "custom-progress",
                    });
                    fetchData();
                })
                .catch((error) => {
                    console.error("Error deleting Customer requests:", error);
                    alert("Error deleting Customer requests. Please try again.");
                })
                .finally(() => {
                    setDeleteInitiated(false);
                });
        };
    }

    return (
        <div>
            <ToastContainer />
            <div className="popup-box">
                <div className="cust-box">
                    <p className="text-delete">{t("customer.Are")}</p>
                    <button onClick={deleteCustomerRequests} className="ware-delete" >
                        {t(deleteInitiated ? 'Yes...' : 'terms.Yes')}
                    </button>
                    <button onClick={onClose} className="ware-nodelete">{t("productdetails.No")}</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteCust