import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './PersonalInformation.scss';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';
import { toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function PersonalInformation({ openCustomerinfoSidebar, setOpenCustomerinfoSidebar, fetchCustomerData, userId, customerData }) {
  const [dateOfBirth, setDateOfBirth] = useState(customerData?.dateOfBirth ? new Date(customerData.dateOfBirth) : null);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [location, setLocation] = useState(customerData?.location || '');
  const [gender, setGender] = useState(customerData?.gender || null);
  const [email, setEmail] = useState(customerData?.email || '');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(customerData?.name || '');
  const [phoneNumber, setPhoneNumber] = useState(customerData?.phoneNumber || '');
  const { t } = useLanguage();
  const { values } = useState('')

  const handleClose = () => {
    setOpenCustomerinfoSidebar(!openCustomerinfoSidebar);
  };

  const handleSave = () => {
    setGender('');
    setLocation('');
    setEmail('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(sanitizedValue);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value.toLowerCase();
    setEmail(emailInput);
    clearError('email');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailInput)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Please enter a valid email address'
      }));
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setDateOfBirth(selectedDate);
    clearError('dateOfBirth');
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    clearError('gender');
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    clearError('location');
  };

  const validateForm = () => {
    const errors = {};
    if (!gender) {
      errors.gender = 'Gender is required';
    }

    if (!location.trim()) {
      errors.location = 'Location is required';
    }

    setErrors(errors);

    return Object.keys(errors)?.length === 0;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      let formattedDateOfBirth = '';
      if (dateOfBirth) {
        const day = String(dateOfBirth.getDate()).padStart(2, '0');
        const month = String(dateOfBirth.getMonth() + 1).padStart(2, '0');
        const year = dateOfBirth.getFullYear();
        formattedDateOfBirth = `${day}-${month}-${year}`;
      }
      const data = {
        userId: parseInt(userId),
        gender,
        location,
        email,
        dateOfBirth: formattedDateOfBirth,
        name,
        phoneNumber
      };
      fetch(`${API.commerceAPI}/commerce/customer/updateByUserId/${groupId}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('refresh-token')}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          toast.success('Customer Information Update Successfully', {
            style: { backgroundColor: '#328b17', color: 'white', height: '50px' }
          })
          if (!response.ok) {
            throw new Error('Failed to update customer information')
          }
          setOpenCustomerinfoSidebar();
          fetchCustomerData();
        })
        .catch((error) => {
          console.error('Error updating customer information:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: ''
      };
    });
  };

  return (
    <>
      <div className="add-customerinfo-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0 m-0">
            <div className="container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b className=''>{t('Personal.Personal Information')}</b>
                </h6>
                <p className="font14  title-info pl-1">{t('Personal.details')}</p>
                <hr className="sr-hr-info"></hr>
              </div>

              <div className="close-icon">
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={handleClose}
                ></i>
              </div>
            </div>

            <div className='sidebar-wrapper pl-3'>
              <form className="mt-2 font14 ml-1">
                <div>
                  <label className="font14">{t('sidebarcustomers.Personal name')}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs pl-2"
                    value={name}
                    onChange={handleNameChange}
                    placeholder={t('sidebarcustomers.Personal.Name')}
                  />
                </div>
                <div>
                  <label className="font14">{t("sidebarcustomers.Phone Number")}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs pl-2"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number"
                  />
                </div>
                <div className="font14 mt-0">
                  <label className='pl-1'>{t('Personal.Gender')}<span className="text-danger mt-1">*</span></label>
                  <input
                    className="ml-2 cursor-pointer"
                    type="radio"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-1">{t('Personal.Male')}</label>
                  <input
                    className="ml-2 cursor-pointer"
                    type="radio"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  <label className="ml-1">{t('Personal.Female')}</label>
                  {errors.gender && <div className="text-danger mt-2 font14">{errors.gender}</div>}
                </div>


                <div>
                  <label className="font14">{t('Personal.Location')}<span className="text-danger mt-2 font14">*</span></label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs pl-2"
                    value={location}
                    onChange={handleLocationChange}
                    placeholder={t('Personal.Location')}
                  />
                  {errors.location && <div className="text-danger mt-2 font14">{errors.location}</div>}
                </div>

                <div className="mt-2">
                  <label className="font14 pt-3">{t("Customer.Date of Birth")}</label>
                  <br />
                  <input type='date'
                    placeholder='Enter BirthDate'
                    className='add-Address-inputs line-height-add  pl-2 mt-2'
                    value={values.birthdate} onChange={handleDateChange}
                    name='birthdate'
                  />
                </div>

                <div className='mt-2'>
                  <label className="font14 pt-3">{t("Customer.Email")}</label>
                  <br />
                  <input
                    type="text"
                    className="add-Address-inputs line-height-add pl-2 mt-2"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={t("Customer.Email")}
                  />
                </div>
              </form>
            </div>
            <div className="mt-1 pl-3 set-button">
              <button className="saveDraft-btn mt-4" onClick={handleUpdate}>
                {isLoading ? (
                  t("customer.Updating..")
                ) : (
                  t('Personal.Update'))}
              </button>
              <button className="cancel-info-btn ml-2" onClick={handleSave}>
                {t('Personal.Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
