import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";

function WarehousePopup({ warehouseId, onClose }) {
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const { t } = useLanguage();

  const handleDelete = async () => {
    if (warehouseId && !deleteInitiated) {
      try {
        setDeleteInitiated(true);

        const response = await fetch(`${API.commerceAPI}/commerce/warehouse/${warehouseId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          toast.success('Warehouse Deleted Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                  className="fa-solid fa-trash float-right cis pr-2"
                  style={{ color: "white" }}
              ></i>
          ),
            progressClassName: "custom-progress",
          });
            onClose();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          console.error('Failed to delete warehouse');
          toast.error('Failed to delete warehouse');
        }
      } catch (error) {
        console.error('Error deleting warehouse:', error);
        toast.error('Error deleting warehouse: ' + error.message);
      }
    } else if (deleteInitiated) {
      toast.warn('Delete operation already initiated');
    } else {
      console.error('Invalid warehouse ID');
      toast.error('Invalid warehouse ID');
    }
  }
 
  return (
    <div>
      <div className="popup-box-warehouse">
        <div className="cust-box">
          <p className="text-delete">{t("productdetails.Are you sure you want to delete this warehouse?")}</p>
          <button onClick={handleDelete} className="ware-delete" disabled={deleteInitiated}>
            {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}
          </button>
          <button onClick={onClose} className="ware-nodelete">{t("productdetails.No")}</button>
        </div>
      </div>
    </div>
  );
}

export default WarehousePopup;