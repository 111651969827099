import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSekeletonLoader = () => {
  return (
    <div style={{ display: "flex" }}>
      <SkeletonTheme color="#007bff" highlightColor="#C0C2DE">
        {[...Array(5)].map((_, index) => (
          <div className="row ml-3 " key={index}>
            <div className="col-2 cardstyle mr-3 card" key={index}>
              <p className="cardname text-center mt-1">
                <Skeleton />
              </p>
              <p className="cardno text-center">
                <Skeleton />
              </p>
            </div>
          </div>
        ))}
      </SkeletonTheme>
    </div>
  );
};

export default CardSekeletonLoader;
