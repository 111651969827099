import React from 'react';
import './CardSkeletonLoader.scss'; 

const CardSkeletonLoader = () => {
  return (
    <div className="card-skeleton-loader">
      <div className="skeleton-title"></div>
      <div className="skeleton-text"></div>
    </div>
  );
};

export default CardSkeletonLoader;
