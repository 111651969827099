import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './SelectGroup.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';

const SelectGroup = () => {
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;
  const [companies, setCompanies] = useState([]);
  const defaultImg = `baaplogo.jpg`;
  const defaultCheck = `active.png`;
  const [loading, setLoading] = useState(true);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/')
    }
    return;
  })

  useEffect(() => {
    fetch(`${API.authAPI}/auth/usergroup/all/${phoneNumber}`, {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `${localStorage.token}`,
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then(data => {
        const companiesWithLoading = data.data.map(company => ({
          ...company,
          loading: false,
        }));
        setCompanies(companiesWithLoading);
      })
      .catch(error => console.error('API request error:', error))
      .finally(() => setLoading(false));
  }, [phoneNumber]);

  const handleSelectCompany = (company) => {
    if (!company.groupId || !company.groupId.name || !company.role || !company.user) {
      toast.error("Name Or Role Is Missing !", {
        style: {
          fontSize: "14px",
          color: "white",
          background: "#FF0000",
        },
        progressStyle: {
          background: "#F5F5FC",
        },
      });
      return;
    }
    setSelectedCompany(company);
    setPermissionDenied(false);

    localStorage.setItem('selectedGroupId', company.groupId.groupId);
    localStorage.setItem('selectedUser', company.user.userId);
    localStorage.setItem('selectedGroupName', company.groupId.name);
    localStorage.setItem('selectedRole', company.role.name);
    localStorage.setItem('selectedImage', company.groupId.logo_url);

    setCompanies(prevCompanies => prevCompanies.map(c =>
      c.groupId.groupId === company.groupId.groupId ? { ...c, loading: true } : c
    ));

    if (company.groupId && company.role && company.user) {
      const groupId = company.groupId.groupId;
      const roleId = company.role.roleId;
      const userId = company.user.userId;

      if (company.role.code === "CC") {
        setPermissionDenied(true);
      } else {
        const fetchData = async () => {
          const token = localStorage.getItem('token');
          try {
            const response = await fetch(`${API?.apiGateway}/auth/auth/refresh-token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token,
                groupId,
                roleId,
                userId,
              }),
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            localStorage.setItem('refresh-token', data.data);
            if (!permissionDenied) {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                navigate('/orders');
              }, 1000);
            }
          } catch (error) {
            console.error('Error:', error.message);
          }
        };
        fetchData();
      }
    } else {
      console.log('groupId, roleId, or userId is missing. Do not proceed with refresh token.');
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const filteredCompanies = companies.filter(company =>
    company?.groupId?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bg_container">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "white", backgroundColor: "#008000" }}
      />

      <div className='row'>
        <div className='col-2'></div>
        <div className='col-8'>
          <div className='row Select-main-group'>
            <div className='col-md-12 col-lg-12'>
              <div className='row mt-3'>
                <div className='col-md-2'>
                  <Link to='/'> <img src='./backward1.png' alt="" height="26px" width="26px" className="mt-4 ml-2" /></Link>
                </div>

                <div className='col-md-5'>
                  <h1 className='mt-1 '><b className='organization-group-color'>Group’s you are part of ...</b></h1>
                  <p className='discription'>All the groups in which you are member or invited to join ...</p>
                </div>

                <div className='col-md-5'>
                  <div className='text-right mt-2'>
                    <div className='search-container text-end'>
                      <input
                        type='text'
                        className='select-input'
                        placeholder='Search Organization'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='scroll mt-3 pb-3 group-select-card'>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12 mt-2'>
                    {filteredCompanies.map((company, index) => (
                      <div className='card select-card mt-3' onClick={() => handleSelectCompany(company)} key={index}>
                        <div className='row mt-2'>
                          <div className='col-md-2'>
                            <img className='group-image fixed-image' src={company.groupId.logo_url || defaultImg} alt='logo' />
                          </div>

                          <div className='col-md-6 pt-3'>
                            <b className='font18 organization-color'>{company.groupId.name || "--"}</b>
                            <p className='font16 text-color'>{company.groupId.shortName || "--"}</p>
                            <p className='font16 text-color'>{company.groupId.name || "--"}</p>
                          </div>

                          <div className='col-md-2 pt-4'>
                            <b className='font14 role-color'>Roles</b>
                            <p className='font16'>{capitalizeFirstLetter(company.role.name || '--')}</p>
                          </div>

                          <div className='col-md-2 pt-4 text-center'>
                            {company.loading ? (
                              <img src={defaultCheck} alt='' height="46px" width="46px" />
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 256" width="50px" height="50px" fill="#f0f1ff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                                strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
                                fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"
                                style={{ mixBlendMode: 'normal' }}>
                                <g transform="scale(10.66667,10.66667)">
                                  <path d="M12,0c-6.617,0 -12,5.383 -12,12c0,6.617 5.383,12 12,12c6.617,0 12,-5.383 12,-12c0,-6.617 -5.383,-12 -12,-12zM16.707,10.293l-5,5c-0.391,0.391 -1.023,0.391 -1.414,0l-3,-3c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.293,2.293l4.293,-4.293c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path>
                                </g>
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-2'></div>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <div className="loader">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectGroup;