import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./VerifyOTP.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import API from '../../services/Api';
import ForgotPasswordPopup from './forgotpasswordpopup/ForgotPasswordPopup';

const VerifyOTP = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/')
    }
    return;
  })

  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    if (inputRefs.current[0]?.current) {
      inputRefs.current[0].current.focus();
    }
  }, []);


  const showToast = (message, type, toastId, fontSize, backgroundColor) => {
    const toastConfig = {
      toastId: toastId,
      style: {
        fontSize: fontSize || "17px",
        color: "white",
        minHeight: "50px",
        backgroundColor: backgroundColor || "#51a351",
      },
      className: "custom-toast",
    };

    if (!toast.isActive(toastId)) {
      toast[type](message, toastConfig);
    }
  };

  const handleOtpChange = (index, value) => {
    const numericValue = value.replace(/\D/g, "");
    const newOtpValues = [...otpValues];
    newOtpValues[index] = numericValue;

    if (numericValue?.length === 1 && index < inputRefs.current?.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    setOtpValues(newOtpValues);
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && otpValues[index] === "" && index > 0) {
      e.preventDefault();
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleOtpSubmit();
    }
  };

  const handleOtpSubmit = async () => {
    setIsLoading(true);

    const phoneNumber = location.state?.phoneNumber;
    let payload;

    if (showPassword) {
      payload = {
        phoneNumber: parseInt(phoneNumber, 10),
        password: password,
      };
    } else {
      const otp = otpValues.join("");
      const otpAsNumber = parseInt(otp, 10);

      if (otpValues.some(value => !value.trim())) {
        setIsLoading(false);
        showToast("Fill the OTP!", "error", "otp-error-toast", "17px", "#FF4D4F");
        return;
      }

      if (isNaN(otpAsNumber)) {
        showToast(
          "Invalid OTP Format",
          "error",
          "otp-error-toast",
          "17px",
          "#FF0000"
        ); setIsLoading(false);
        return;
      }

      payload = {
        otp: otpAsNumber,
        phoneNumber: phoneNumber,
      };
    }

    try {
      const response = await fetch(`${API.authAPI}/auth/auth/validateOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setIsLoading(false);
        showToast(
          "User Login Successfully ! ",
          "success",
          "otp-success-toast",
          "",
          "#008000"
        );
        let resp = await response.json();

        localStorage.setItem("token", resp.token);
        Cookies.set("token", resp.token, {
          expires: 7,
          domain: "localhost",
          path: "/",
        });
        setTimeout(() => {
          navigate("/select-group", { state: { phoneNumber } });
        }, 3000);
      } else {
        setIsLoading(false);
        showToast(showPassword ? "Incorrect credentials! Please try again." : "Incorrect OTP! Please try again.", "error", "otp-error-toast", "", "#FF0000");
      }
    } catch (error) {
      showToast("An error occurred", "error", "otp-error-toast", "", "#FF4D4F");
      window.location.reload();
    }
  };

  const togglePasswordField = () => {
    setShowPassword(!showPassword);
  };

  const toggleForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(!showForgotPasswordPopup);
  };

  const handleResendClick = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${API.apiGateway}/authgw/sendotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
        }),
      });
      if (response.ok) {
        setIsLoading(false);
        showToast("OTP Resent Successfully !", "success", "otp-success-toast");
      } else {
        showToast("Failed to Resent OTP", "error", "otp-error-toast");
      }
    } catch (error) {
      showToast("An error occurred", "error", "otp-error-toast");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />

      <div className="registration-menu"></div>
      <div className='padding-color'>
        <div className="padding50">
          <div>
            <h6 className='baap-commerce '><b>Baap commerce</b></h6>
            <h6 className='commerce-line '>Baap commerce is a platform to manage e-commerce sites</h6>
          </div>

          <div className='registration-scroll'>
            {showPassword ? (
              <div className='input-otp'>
                <div className='font14 password'><b>VERIFY YOUR PASSWORD</b></div>
                <div className='d-inline-flex mt-2'>
                  <form action="">
                    <input
                      className="otp-inp ml-1 password-dot"
                      type="password"
                      maxLength="10"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />

                  </form>
                  <button className="chevron-btn" onClick={handleOtpSubmit} disabled={isLoading}>
                    {isLoading ? (
                      <i className="spinner-border spinner-border-sm text-white spin"></i>
                    ) : (
                      <i className="fa-solid fa-chevron-right text-white"></i>
                    )}
                  </button>
                </div>

                <div className="note-name kumbh-sans">
                  <b className=''>Receive the new password ?</b>{" "}
                  <span className="resend-otp-text" onClick={toggleForgotPasswordPopup}>
                    Forgot Password
                  </span>
                </div>
              </div>
            ) : (

              <div className='input-otp'>
                <div className='enter-text'><b>VERIFY YOUR OTP</b></div>
                <div className='d-inline-flex  otp-input mt-2'>
                  <form action="">
                    {otpValues.map((value, index) => (
                      <input
                        key={index}
                        className="otp ml-1 text-center otp-dot"
                        type='password'
                        maxLength="1"
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        value={value}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyPress={handleKeyPress}
                        pattern="[0-9]*"
                        autoFocus={index === 0}
                      />
                    ))}
                  </form>

                  <button className="chevron-btn" onClick={handleOtpSubmit} disabled={isLoading}>
                    {isLoading ? (
                      <i className="spinner-border spinner-border-sm text-white spin"></i>
                    ) : (
                      <i className="fa-solid fa-chevron-right text-white"></i>
                    )}
                  </button>
                </div>
                <div className="note-name ">
                  <b>Didn't receive the verification otp ?</b>{" "}
                  <span className="resend-otp-text" onClick={handleResendClick}>
                    Resend OTP
                  </span>
                </div>
              </div>
            )}
            <p className='login-for-password otp-password-text mt-1' onClick={togglePasswordField}>{showPassword ? "Login with OTP" : " Login With Password"}</p>

            <div className="input-text">
              <h6 className='name-baap'> <b>The Baap Company</b></h6>
              <h6 className='all-reserve1'>All rights reserved</h6>
            </div>
          </div>
        </div>

        {showForgotPasswordPopup && (
          <ForgotPasswordPopup onClose={toggleForgotPasswordPopup} />
        )}
      </div>
    </div>
  );
}

export default VerifyOTP;