import React, { useState } from 'react';
import './ForgotPasswordPopup.scss';
import { toast } from 'react-toastify';
import API from '../../../services/Api';
import { ToastContainer } from "react-toastify";

const ForgotPasswordPopup = ({ onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState('enterPhoneNumber');
    const [isLoading, setIsLoading] = useState(false);

    const handleSendOTP = async () => {
        if (!phoneNumber || phoneNumber?.length !== 10) {
            toast.error('Please enter a valid 10-digit phone number');
            return;
        }

        const apiUrl = `${API.apiGateway}/authgw/sendotp`;
        try {
            setIsLoading(true);
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                    groupId: 1703228300417,
                }),
            });

            if (response.ok) {
                setStep('enterOTP');
            } else {
                const errorData = await response.json();
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyOTP = async () => {
        const otpAsNumber = parseInt(otp, 10);

        if (isNaN(otpAsNumber)) {
            toast.error('Invalid OTP Format');
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetch(`${API.authAPI}/auth/auth/validateOtp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    otp: otpAsNumber,
                    phoneNumber: parseInt(phoneNumber, 10),
                }),
            });

            if (response.ok) {
                setStep('enterNewPassword');
            } else {
                toast.error('Incorrect OTP! Please try again.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = async () => {
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetch(`${API.authAPI}/auth//auth/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPassword: newPassword,
                    phoneNumber: parseInt(phoneNumber, 10),
                }),
            });

            if (response.ok) {
                toast.success('Password Reset Successfully!',{
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                });
                onClose();
            } else {
                toast.error('Failed to reset password. Please try again.');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            switch (step) {
                case 'enterPhoneNumber':
                    handleSendOTP();
                    break;
                case 'enterOTP':
                    handleVerifyOTP();
                    break;
                case 'enterNewPassword':
                    handleNext();
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ color: "white", background: "#FF0000" }}
            />

            <div className="popup-container">
                <div className="popup-content">
                    <i
                        className="bi bi-x-circle-fill cancel-reset-icon cursor-pointer"
                        data-tooltip-id="my-tooltip-40"
                        onClick={onClose}
                    ></i>
                    {step === 'enterPhoneNumber' && (
                        <>
                            <input
                                className='reset-style mt-3'
                                maxLength="10"
                                pattern="\d{10}"
                                type="tel"
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                onChange={(e) => {
                                    const inputPhoneNumber = e.target.value;
                                    const regex = /^\d*$/;
                                    if (regex.test(inputPhoneNumber) && inputPhoneNumber?.length <= 10) {
                                        setPhoneNumber(inputPhoneNumber);
                                    }
                                }}
                                onKeyPress={handleEnterKeyPress}
                            />

                            <button className='reset-submit-button' onClick={handleSendOTP} disabled={isLoading}>
                                {isLoading ? (
                                    <i className="spinner-border spinner-border-sm text-dark spin "></i>
                                ) : (
                                    <span> Send OTP</span>
                                )}
                            </button>
                        </>
                    )}
                    {step === 'enterOTP' && (
                        <>
                            <input
                                className='reset-style mt-3'
                                type="password"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                            <button className='reset-submit-button' onClick={handleVerifyOTP} disabled={isLoading}>
                                {isLoading ? (
                                    <i className="spinner-border spinner-border-sm text-dark spin"></i>
                                ) : (
                                    <span> Verify OTP</span>
                                )}
                            </button>
                        </>
                    )}
                    {step === 'enterNewPassword' && (
                        <>
                            <input
                                className='reset-style mt-3'
                                type="password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                            <input
                                className='reset-style'
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                            <button className='reset-submit-button' onClick={handleNext} disabled={isLoading}>
                                {isLoading ? (
                                    <i className="spinner-border spinner-border-sm text-dark spin"></i>
                                ) : (
                                    <span> Submit</span>
                                )}
                            </button>
                        </>
                    )}
                </div>
            </div></>
    );
};

export default ForgotPasswordPopup;