import React, { useState } from 'react';
import API from '../../services/Api';
import { ToastContainer, toast } from "react-toastify";
import STORAGE_KEYS from '../../utils/storageKey';
import { useLanguage } from '../../LanguageContext';

const OrderDelete = ({ onClose, groupId, fetchData, checkedItems, data }) => {
    const {t} = useLanguage()
    const [deleteInitiated, setDeleteInitiated] = useState(false);
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

    const orderIdDelete = checkedItems.map(index => {
        const orderId = data[index]?.orderId;
        return orderId;
    });

    const handleDeleteOrder = async () => {
        try {
            setDeleteInitiated(true);
            const payload = {
                groupId: parseInt(groupId),
                orderIds: orderIdDelete
            };

            const response = await fetch(`${API.commerceAPI}/commerce/order/delete/orders`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${refreshToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to delete order");
            }
            toast.success("Orders Deleted Successfully", {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                icon: (
                    <i
                        className="fa-solid fa-trash float-right cis pr-2"
                        style={{ color: "white" }}
                    ></i>
                ),
                progressClassName: "custom-progress",
            });
            fetchData();
            onClose();

        } catch (error) {
            console.error("Error deleting order:", error);
            alert("Error deleting order. Please try again.");
        } finally {
            setDeleteInitiated(false);
        }
    };

    return (
        <>
            <div>
                <ToastContainer />
                <div className="popup-box">
                    <div className="cust-box">
                        <p className="text-delete">
                           {t("order.Are")}
                        </p>
                        <button
                            onClick={handleDeleteOrder}
                            className="ware-delete"
                            disabled={deleteInitiated}>
                            {t(deleteInitiated ? "Yes..." : "terms.Yes")}
                        </button>

                        <button
                            onClick={onClose}
                            className="ware-nodelete">
                            {t("categorySidebar.No")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDelete