import React, { useState, useEffect } from 'react';
import "./Inventory.scss";
import InventorySidebar from '../../components/inventory-sidebar/InventorySidebar';
import UpdateInventory from './UpdateInventory';
import InventoryPopup from './InventoryPopup';
import { useLanguage } from '../../LanguageContext';
import API from '../../services/Api';
import ReactPaginate from 'react-paginate';
import { ToastContainer } from 'react-toastify';
import RestockPopup from '../../components/Stock alert Popup/stockInventory';
import InventorySkeletonLoader from '../../components/skeleton-loader/InventorySkeletonLoader';
import STORAGE_KEYS from '../../utils/storageKey';

const Inventory = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [productsNeedingRestock, setProductsNeedingRestock] = useState([]);
  const [inventory, setInventory] = useState(false);
  const [isRestockPopupVisible, setIsRestockPopupVisible] = useState(false);
  const [updateItemId, setUpdateItemId] = useState(null);
  const [inventoryData, setInventoryData] = useState({ items: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { t } = useLanguage();
  const [updateSidebar, setUpdateSidebar] = useState(false);
  const offset = currentPage * itemsPerPage;
  const currentItems = inventoryData.items?.slice(offset, offset + itemsPerPage);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deleteInventoryId, setDeleteInventoryId] = useState(null);

  const fetchData = () => {
    setIsSearching(true);
    const apiUrl = `${API.commerceAPI}/commerce/inventry/all/getByGroupId/${groupId}?search=${searchQuery}`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const items = data.data.items.reverse();
        setInventoryData({ items });
        setIsLoading(false);
        setIsSearching(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
        setIsLoading(false);
        setIsSearching(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, [groupId, searchQuery]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setCurrentPage(0);
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const handleCancelData = () => {
    setInventory(false);
    setUpdateSidebar(false)
  };

  const handleToggleSidebar = (_id) => {
    setUpdateSidebar(_id);
    setUpdateItemId(_id);
  };

  const openDeleteConfirmation = (InventoryId) => {
    setDeleteInventoryId(InventoryId);
    setIsDeleteConfirmationVisible(true);
  };

  const checkStockLevels = () => {
    const productsToRestock = inventoryData.items?.filter(item => item.quantity < 50);
    setProductsNeedingRestock(productsToRestock);
  };

  useEffect(() => {
    checkStockLevels();
  }, [inventoryData.items]);

  const handleReorderClick = (item) => {
    setProductsNeedingRestock([item]);
    setIsRestockPopupVisible(true);
  };


  const handleClosePopup = () => {
    setIsRestockPopupVisible(false);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "--";
    return text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <div className="main">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="#F5F5FC"
          toastStyle={{ color: "black" }}
        />

        <div className="body_container">
          <div className="main-padding1">
            <div className='mt-2'>
              <div className='row'>
                <div className='col-5 pl-3'>
                  <img src='../images/inventory.png' className='inventory-img'></img>
                  <b className='inventory-name'>{t('inventory.title')}</b>
                  <p className='inventory-text'>{t('inventory.subtitle')}</p>
                </div>

                <div className='col-4'></div>

                <div className='col-3 mt-2'>
                  <div className="d-flex align-items-center float-right">
                    <div className="me-2 search-role">
                      <i className="fa-solid fa-magnifying-glass searching pl-5"></i>
                      <input type="text"
                        placeholder={t('inventory.Search Product')}
                        className='product-search'
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearchInputChange} />
                    </div>

                    <div className="filter mx-2" onClick={() => setInventory(!inventory)}>
                      <i className="fa-solid fa-plus  plus-icon " ></i>
                    </div>
                    {inventory && (<InventorySidebar handleClose={handleCancelData} setInventory={setInventory} fetchData={fetchData} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr_height' />
            <div className='scrollmenu  mt-3 pl-2'>
              <div className='role-table'>
                <div className='sidebar-wrapper-i'>
                  <table className="table table-hover ">
                    <thead className='table-head'>
                      <tr>
                        <th scope='col' className="table-head" style={{ width: "60px", fontSize: "14px" }}>{t('inventory.product img')}</th>
                        <th scope="col" className="table-head" style={{ width: "140px", fontSize: "14px" }}>{t('inventory.product')}</th>
                        <th scope="col" className="table-head" style={{ width: "90px", fontSize: "14px" }}>{t('inventory.category')}</th>
                        <th scope="col" className="table-head" style={{ width: "80px", fontSize: "14px" }}>{t('inventory.sku')}</th>
                        <th scope="col" className="table-head" style={{ width: "58px", fontSize: "14px" }}>{t('inventory.price')}</th>
                        <th scope="col" className="table-head" style={{ width: "70px", fontSize: "14px" }}>{t('inventory.Qty')}</th>
                        <th scope="col" className="table-head" style={{ width: "90px", fontSize: "14px" }}>{t('inventory.Date')}</th>
                        <th scope="col" className="table-head" style={{ width: "60px", fontSize: "14px" }}>{t('inventory.actions')}</th>
                      </tr>
                    </thead>

                    {isLoading ? (
                      <InventorySkeletonLoader />
                    ) : (
                      <tbody className=''>
                        {(Array.isArray(currentItems) && currentItems?.length > 0 ? (
                          currentItems.map(items => (
                            <tr key={items._id} className='font14 inventory-row animate__animated'>
                              <td className='text-colors'>
                                <img className='w-50 img-zoom' src={items.productId ? items.productId.pictures || "--" : "--"} />
                              </td>
                              <td className='text-colors'>{items.productId ? items.productId.name || "--" : "--"}</td>
                              <td className='text-colors'>{items.productId ? items.productId.category || "--" : "--"}</td>
                              <td className='text-colors'>{items.productId ? items.productId.skuNumber || "--" : "--"}</td>
                              <td className='text-colors'>{items.productId ? items.productId.regularPrice || "--" : "--"}</td>
                              <td className='text-colors'>
                                {items.quantity === 0 ? (
                                  <span onClick={() => handleReorderClick(items)} style={{ cursor: 'pointer', color: 'red' }}>
                                    <small className='out_stock'>{t('inventory.stock')}</small>
                                  </span>
                                ) : (
                                  items.quantity
                                )}
                              </td>

                            
                              <td className='text-colors'>{formatTimestamp(items.updatedAt || "--")}</td>
                              <td className='text-colors'>
                                <i className="fa-solid fa-pencil inventory-pencil"
                                  onClick={() => handleToggleSidebar(items._id)}></i>
                                {updateSidebar === items._id && (
                                  <UpdateInventory
                                    updateItemId={updateItemId}
                                    handleClose={handleCancelData}
                                    setInventoryData={setInventoryData}
                                    setInventory={setInventory}
                                    fetchData={fetchData}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                  />
                                )}
                                <i className="fa-solid fa-trash inventory-pencil ml-2"
                                  onClick={() => openDeleteConfirmation(items._id)}></i>
                                {isDeleteConfirmationVisible && (
                                  <InventoryPopup InventoryId={deleteInventoryId}
                                    fetchData={fetchData}
                                    onClose={() => setIsDeleteConfirmationVisible(false)}
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              <img src="/no-data-image.png" alt="No data found" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              <div className="d-inline-flex pagination-container">
                <div className="col-12 p-0">
                  <ReactPaginate
                    className="pagination-bar"
                    previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                    nextLabel={<i className="fa-solid fa-angle-right"></i>}
                    breakLabel={'...'}
                    pageCount={Math.ceil(inventoryData.items?.length / itemsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={0}
                    onPageChange={onPageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName={'Pagination-products'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isRestockPopupVisible && <RestockPopup products={productsNeedingRestock} onClose={handleClosePopup} />}
    </>
  );
}

export default Inventory;
