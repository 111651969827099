import React from "react";
import { Link } from "react-router-dom";
import "./LogoutPopup.scss";
import { useLanguage } from "../../LanguageContext";

const LogoutPopup = ({ handleLogout, onClose }) => {
    const { t } = useLanguage();

    const handleLogoutClick = () => {
        handleLogout();
    };

    const handleDataClear = () => {
        localStorage.clear();
    }

    return (
        <div className="popup-container">
            <div className="logpopup-box">
                <div className="logout-box">
                    <p className="text-delete">{t("log.Are")}</p>
                    <Link to="/" onClick={handleDataClear}>
                        <button onClick={handleLogoutClick} className="logout-delete">
                            Yes
                        </button>
                    </Link>
                    <button onClick={onClose} className="logout-nodelete">
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LogoutPopup;
