import React, { useEffect } from 'react';
import './CustomerInfo.scss';
import { useState } from 'react';
import PersonalInformation from '../personal-information/PersonalInformation';
import PaymentInfo from '../payment-info/PaymentInfo';
import KYCDetails from '../kyc-details/KYCDetails';
import CustomerAddress from '../cust-addresses/CustomerAddress';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from '../../utils/storageKey';
import { ToastContainer } from 'react-bootstrap';

const CustomerInfo = ({ userId, custId, customer }) => {
  const [openCustomerinfoSidebar, setOpenCustomerinfoSidebar] = useState();
  const [openPaymentinfoSidebar, setOpenPaymentinfoSidebar] = useState();
  const [openKycinfoSidebar, setOpenKycinfoSidebar] = useState();
  const [openCustomerAddSidebar, setOpenCustomerAddSidebar] = useState();
  const [customerData, setCustomerData] = useState();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const { t } = useLanguage();

  const handleCloseAddress = () => {
    setOpenCustomerAddSidebar(!openCustomerAddSidebar)
  }

  const fetchCustomerData = () => {
    fetch(`${API.commerceAPI}/commerce/customer/all/getByGroupId/${groupId}?userId=${userId}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch customer data');
        }
      })
      .then(data => {

        setCustomerData(data?.data?.items[0])
      })
      .catch(error => {
        console.error('Error:', error);
        throw error;
      });
  }

  useEffect(() => {
    fetchCustomerData()
  }, [])

  return (
    <>
      <div className='row  font-14 lineHeight2'>
        <div className='scrollbar-customer-card'>
          <div className='row'>
            <div className='col-4'>
              <div className="card1 card-customer-info" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'>
                      <p className='font16 cust-title-info'>{t('Personal.Personal Information')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.MC3) && (
                      <div className='col-2'>
                        <i className="fa-solid fa-pencil info-icon cursor-pointer"
                          onClick={() => setOpenCustomerinfoSidebar(!openCustomerinfoSidebar)}></i>
                        {openCustomerinfoSidebar && (
                          <PersonalInformation userId={userId} customerData={customerData} openCustomerinfoSidebar={openCustomerinfoSidebar}
                            setOpenCustomerinfoSidebar={setOpenCustomerinfoSidebar} fetchCustomerData={fetchCustomerData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Personal.Gender')}</p>
                      <p className='font14 email-wrap'>{customerData?.gender || "--"}</p>
                      <p className='text-secondary font14 height-line'>{t('Personal.Date of birth')}</p>
                      <p className='font14 height-line email-wrap'>{customerData?.dateOfBirth || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line '>{t('Personal.Location')}</p>
                      <p className='font14 email-wrap'>{customerData?.location || "--"}</p>
                      <p className='text-secondary font14 height-line'> {t("Personal.Email")}</p>
                      <p className='font14 line-height-add height-line email-wrap'>{customerData?.email || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className="card-customer-info card1" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'><p className='font16 cust-title-info'>{t('Payment.Payment Info')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.MC3) && (
                      <div className='col-2'>  <i className="fa-solid fa-pencil info-icon cursor-pointer"
                        onClick={() => setOpenPaymentinfoSidebar(!openPaymentinfoSidebar)}></i>
                        {openPaymentinfoSidebar && (
                          <PaymentInfo userId={userId} customerData={customerData} openPaymentinfoSidebar={openPaymentinfoSidebar}
                            setOpenPaymentinfoSidebar={setOpenPaymentinfoSidebar} fetchCustomerData={fetchCustomerData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Bank')}</p>
                      <p className='font14 bank-label'>{customerData?.bank || "--"}</p>
                      <p className='text-secondary font14 height-line'>{t('Payment.Account No')}</p>
                      <p className='font14 height-line bank-label'>{customerData?.accountNo || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line email-wrap'>UPI</p>
                      <p className='font14 bank-label'>{customerData?.upi || "--"}</p>
                      <p className='text-secondary font14 height-line'>IFSC</p>
                      <p className='font14 height-line bank-label'>{customerData?.ifsc || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className="card-customer-info card1" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'><p className='font16 cust-title-info'>{t('Payment.KYC')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.MC3) && (
                      <div className='col-2' >  <i className="fa-solid fa-pencil  info-icon cursor-pointer"
                        onClick={() => setOpenKycinfoSidebar(!openKycinfoSidebar)}></i>
                        {openKycinfoSidebar && (
                          <KYCDetails userId={userId} customerData={customerData} openKycinfoSidebar={openKycinfoSidebar}
                            setOpenKycinfoSidebar={setOpenKycinfoSidebar} fetchCustomerData={fetchCustomerData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Aadhar Card')}</p>
                      <p className='font14 bank-label'>{customerData?.aadharCardNo || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Pan Card')}</p>
                      <p className='font14 bank-label'>{customerData?.panCardNo || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-1'>
            <div className='col-4'>
              <div className="card-customer-info card-address" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-10'>
                      <p className='font16 cust-title-info'>{t('cust.Customer Address')}</p>
                    </div>

                    <div className='col-2'>
                      {tokenPermissions && tokenPermissions.includes(Permissions.MC3) && (
                        <div className="ml-auto">
                          <i className="fa-solid fa-pencil info-icon cursor-pointer pt-3" onClick={() => setOpenCustomerAddSidebar(!openCustomerAddSidebar)}></i>
                          {openCustomerAddSidebar && (
                            <CustomerAddress customer={customer} custId={custId} userId={userId} customerData={customerData} handleCloseAddress={handleCloseAddress}
                              fetchCustomerData={fetchCustomerData} />
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                  <div className='row '>
                    <p className='font16'>{customerData?.addresses[0]?.address?.tag || "--"}</p>
                    <div className='col-6'>
                      <div>
                        <div className='line-height-add'>
                          <span className='font14 text-secondary height-line bank-label'>{customerData?.addresses[0]?.address?.line1 || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'> {customerData?.addresses[0]?.address?.line2 || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'> {customerData?.addresses[0]?.address?.city || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{customerData?.addresses[0]?.address?.state || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{customerData?.addresses[0]?.address?.country || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{customerData?.addresses[0]?.address?.zip || "--"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'></div>
          </div>

          <div className='mt-3'></div>

        </div>
      </div>
    </>
  )
}

export default CustomerInfo;