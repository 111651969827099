import React, { useEffect, useId } from 'react';
import './EmployeeInfo.scss';
import { useState } from 'react';
import EmployeeInformation from '../employee-information/EmployeeInformation'
import EmployeePaymentInfo from '../employee-paymentinfo/EmployeePaymentInfo';
import EmployeeKYCDetails from '../employee-kycdetails/EmployeeKYCDetails';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import EmployeeAddress from '../employee-update-address/EmployeeAddress';
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from '../../utils/storageKey';

const EmployeeInfo = ({ userId }) => {
  const [openEmployeeinfoSidebar, setOpenEmployeeinfoSidebar] = useState();
  const [openPaymentinfoSidebar, setOpenPaymentinfoSidebar] = useState();
  const [openKycinfoSidebar, setOpenKycinfoSidebar] = useState();
  const [openCustomerAddSidebar, setOpenCustomerAddSidebar] = useState();
  const [userData, setUserData] = useState(null);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const { t } = useLanguage();

  const handleCloseAddress = () => {
    setOpenCustomerAddSidebar(!openCustomerAddSidebar)
  }

  const fetchEmployeeData = () => {
    const url = `${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}?userId=${userId}`;
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch employee data');
        }
      })
      .then(data => {

        setUserData(data?.data?.items[0]);
      })
      .catch(error => {
        console.error('Error fetching employee data:', error);
      });
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  return (
    <>
      <div className='row  font-14 lineHeight2'>
        <div className='scrollbar-customer-card '>
          <div className='row'>
            <div className='col-4'>
              <div className="card2 card-customer-info" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'>
                      <p className='font16 cust-title-info'>{t('employee.Employee Information')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.ME3) && (
                      <div className='col-2'>
                        <i className="fa-solid fa-pencil info-icon cursor-pointer"
                          onClick={() => setOpenEmployeeinfoSidebar(!openEmployeeinfoSidebar)}></i>
                        {openEmployeeinfoSidebar && (
                          <EmployeeInformation userId={userId} userData={userData} openEmployeeinfoSidebar={openEmployeeinfoSidebar}
                            setOpenEmployeeinfoSidebar={setOpenEmployeeinfoSidebar} fetchEmployeeData={fetchEmployeeData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Personal.Gender')}</p>
                      <p className='font14 email-wrap'>{userData?.gender || "--"}</p>
                      <p className='text-secondary font14 height-line'>{t('Personal.Date of birth')}</p>
                      <p className='font14 height-line email-wrap'>{userData?.dateOfBirth || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line '>{t('Personal.Location')}</p>
                      <p className='font14 email-wrap'>{userData && typeof userData.locations === 'string' ? userData.locations : "--"}</p>
                      <p className='text-secondary font14 height-line'> {t("Personal.Email")}</p>
                      <p className='font14 line-height-add height-line email-wrap'>{userData?.email || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className="card-customer-info card2" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'><p className='font16 cust-title-info'>{t('Payment.Payment Info')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.ME3) && (
                      <div className='col-2'>  <i className="fa-solid fa-pencil info-icon cursor-pointer"
                        onClick={() => setOpenPaymentinfoSidebar(!openPaymentinfoSidebar)}></i>
                        {openPaymentinfoSidebar && (
                          <EmployeePaymentInfo userId={userId} userData={userData} openPaymentinfoSidebar={openPaymentinfoSidebar}
                            setOpenPaymentinfoSidebar={setOpenPaymentinfoSidebar} fetchEmployeeData={fetchEmployeeData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Bank')}</p>
                      <p className='font14 bank-label'>{userData?.bank || "--"}</p>
                      <p className='text-secondary font14 height-line'>{t('Payment.Account No')}</p>
                      <p className='font14 height-line bank-label'>{userData?.accountNo || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line email-wrap'>UPI</p>
                      <p className='font14 bank-label'>{userData?.upi || "--"}</p>
                      <p className='text-secondary font14 height-line'>IFSC</p>
                      <p className='font14 height-line bank-label'>{userData?.ifsc || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className="card-customer-info card2" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-8'><p className='font16 cust-title-info'>{t('Payment.EmployeeKYCDetails')}</p></div>
                    <div className='col-2'></div>

                    {tokenPermissions && tokenPermissions.includes(Permissions.ME3) && (
                      <div className='col-2' >  <i className="fa-solid fa-pencil  info-icon cursor-pointer"
                        onClick={() => setOpenKycinfoSidebar(!openKycinfoSidebar)}></i>
                        {openKycinfoSidebar && (
                          <EmployeeKYCDetails
                           userId={userId} 
                           userData={userData} 
                           openKycinfoSidebar={openKycinfoSidebar}
                            setOpenKycinfoSidebar={setOpenKycinfoSidebar} 
                            fetchEmployeeData={fetchEmployeeData} />
                        )}
                      </div>
                    )}
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Aadhar Card')}</p>
                      <p className='font14 bank-label'>{userData?.aadhar || "--"}</p>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Pan Card')}</p>
                      <p className='font14 bank-label'>{userData?.pan || "--"}</p>
                    </div>
                  </div>

                  <div className='row mt-2'>
                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Passport')}</p>
                      <p className='font14 bank-label'>{userData?.passportNo || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-4'>
              <div className="card-customer-info card2" >
                <div className="card-body">
                  <div className='row'>
                    <div className='col-10'>
                      <p className='font16 cust-title-info'>{t('employee.employeeAddress')}</p>
                    </div>

                    <div className='col-2'>
                      {tokenPermissions && tokenPermissions.includes(Permissions.ME3) && (
                        <div className="ml-auto">

                          {userData && (!userData.addresses || userData.addresses?.length === 0) || (
                            <i className="fa-solid fa-pencil info-icon cursor-pointer pt-2"
                              onClick={() => setOpenCustomerAddSidebar(!openCustomerAddSidebar)}></i>
                          )}

                          {openCustomerAddSidebar && (
                            <EmployeeAddress userId={userId} userData={userData} handleCloseAddress={handleCloseAddress}
                              fetchEmployeeData={fetchEmployeeData} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row '>
                    <p className='font16'>{userData?.addresses[0]?.address?.tag || "--"}</p>
                    <div className='col-6'>
                      <div>
                        <div className='line-height-add'>
                          <span className='font14 text-secondary height-line bank-label'>{userData?.addresses[0]?.address?.locality || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'> {userData?.addresses[0]?.address?.city || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{userData?.addresses[0]?.address?.state || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{userData?.addresses[0]?.address?.country || "--"}</span><br></br><br></br>
                          <span className='font14 text-secondary height-line bank-label'>{userData?.addresses[0]?.address?.zip || "--"}</span>
                        </div>
                      </div>
                    </div>

                    <div className='col-6'>
                      <p className='text-secondary font14 height-line'>{t('Payment.Type')}</p>
                      <p className=' font14'>{t('Payment.Billing')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4'></div>
          </div>

          <div className='mt-3'></div>
        </div>
      </div>
    </>
  )
}

export default EmployeeInfo