import React, { useState, useEffect } from 'react';
import { CreateIntent } from '../../components/intent-form/create-intent';
import API from '../../services/Api';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import './Intent.scss'
import IntentSkeletonLoader from '../../components/skeleton-loader/IntentSkeletonLoader';
import { useLanguage } from '../../LanguageContext';
import Quotation from "../../pages/quotation/Quotation"
import { Link } from 'react-router-dom';
import STORAGE_KEYS from '../../utils/storageKey';

const Intent = () => {
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [intents, setIntents] = useState([]);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoding, setIsLoding] = useState(true)
  const [showQuotationDetails, setShowQuotationDetails] = useState(false);
  const [currentIntentId, setCurrentIntentId] = useState(null);
  const [currentIntent, setCurrentIntent] = useState(null);
  const [checkedItems, setCheckedItems] = useState([])
  const [allCheck, setAllCheck] = useState(false)
  const { t } = useLanguage();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [setCurrentPage] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const handleClose = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  const toggleCreateIntent = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  const handleQuotationClick = (intentId) => {
    setCurrentIntentId(intentId);
    setShowQuotationDetails(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = intents.map((_, index) => index)
      setCheckedItems(checkedItems)
    } else {
      setCheckedItems([]);
    }
    setAllCheck(event.target.checked)
  }

  const handleSingelCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, index])
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index))
    }
  }

  const handleDeleteIntent = async () => {
    const intentIds = checkedItems.map(index => intents[index]?.intentId).filter(Boolean);
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/intent/delete/intent/`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          groupId: groupId,
          intentId: intentIds,
        })
      });

      if (!response.ok) {
        throw new Error("Failed to delete intents");
      }
      window.location.reload()
      toast.success("Intents Deleted Successfully", {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        icon: (
          <i
            className="fa-solid fa-trash float-right cis pr-2"
            style={{ color: "white" }}
          ></i>
        ),
        progressClassName: "custom-progress",
      });
      onclose();
      fetchIntents();
    } catch (error) {
      console.error('Error deleting intents:', error);
      setIsDeleteConfirmationOpen(false);
    }
  };

  const fetchIntents = async () => {
    try {
      setIsLoding(true)
      const response = await fetch(`${API.commerceAPI}/commerce/intent/group/${groupId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIntents(data?.data?.items.reverse());
      setIsLoding(false)
      setCheckedItems([])
      setAllCheck(false);
    } catch (error) {
      console.error('Failed to fetch intents:', error);
      setIsLoding(false)
    }
  };

  useEffect(() => {
    fetchIntents();
  }, [groupId, pageNumber, itemsPerPage]);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * itemsPerPage;
  const currentIntents = intents?.slice(offset, offset + itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const totalQuantity = currentIntents.reduce((total, intent) => {
    return total + intent.products.reduce((sum, product) => sum + product.qty, 0);
  }, 0);

  const handleOpenSidebar = (intentId, intent) => {
    setIsOpenSidebar(true);
    setCurrentIntentId(intentId);
    setCurrentIntent(intent);

    if (intent && intent.intentDate) {
      const intentDate = new Date(intent.intentDate);
      intentDate.setDate(intentDate.getDate() - 1);
      setCurrentIntent(prevIntent => ({
        ...prevIntent,
        expecteDeliveryDate: intentDate.toISOString(),
      }));
    }
  };

  const handleCloseSidebar = () => {
    setIsOpenSidebar(false);
    setCurrentIntentId(null);
    setCurrentIntent([])
  };

  const handleDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  return (
    <div className="main">
      <ToastContainer autoClose={1000} />

      <div className="body_container">
        <div className="main-padding1">

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
            <div>
              <h5 className='intent-title'><strong>{t("intent.Intents")}</strong></h5>
              <p className='intent-title'>{t("intent.List of all intents")}</p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                className={`btn_disable cursor-pointer mt-2 ${checkedItems?.length === 0 ? "light-button" : "light-dark"}`}
                onClick={handleDeleteConfirmation}
                disabled={checkedItems?.length === 0}
                style={{ width: "85px", height: "34px" }}
              >
                {t("button.delete")}
              </button>

              <button className='intent-button' onClick={toggleCreateIntent}>
                {t("intent.New")}
              </button>
            </div>
          </div>

          {isOpenSidebar && (
            <CreateIntent
              fetchIntents={fetchIntents}
              handleClose={handleCloseSidebar}
              intentId={currentIntentId}
              intent={currentIntent}
            />
          )}

          <div className='scrollmenu pl-2'>
            <div className='role-table'>
              <div className='sidebar-wrapper'>
                <div className=''>
                  <table className="table table-hover">
                    <thead className='table-head'>
                      <tr>
                        <th>
                          <input type='checkbox'
                            className='cursor-pointer'
                            onChange={handleAllChecked}
                            checked={allCheck}
                          />
                        </th>

                        <th>{t("intent.Intent No")}</th>
                        <th>{t("intent.warehouse name")}</th>
                        <th>{t("intent.Quantity")}</th>
                        <th>{t("intent.Date of intent")}</th>
                        <th>{t("intent.Quotation")}</th>
                        <th>{t("intent.Date of Delivery")}</th>
                      </tr>
                    </thead>

                    {
                      isLoding ? (
                        <IntentSkeletonLoader />
                      ) : (
                        <tbody className='table-scrol'>
                          {Array.isArray(currentIntents) && currentIntents?.length > 0 ? (
                            currentIntents.map((intent, index) => (
                              <tr key={index}>
                                <td>
                                  <input type='checkbox'
                                    className='cursor-pointer'
                                    onChange={(e) =>
                                      handleSingelCheckboxChange(e, index)}
                                    checked={checkedItems.includes(index)}
                                  />
                                </td>

                                <td className='text-colors-id'
                                  onClick={() => handleOpenSidebar(intent?.intentId, intent)}>
                                  {intent?.intentId}</td>
                                <td>
                                  {intent.warehouse && intent.warehouse.length > 0 ?
                                    intent.warehouse.map(wh => wh.name).join(', ') :
                                    'No warehouse'}
                                </td>
                                <td>{intent.products.reduce((sum, product) => sum + product.qty, 0)} </td>

                                <td className='text-colors'>{formatDate(intent?.intentDate?.split('T')[0])}</td>

                                <td className='text-color pl-4'>
                                  {intent.quotationCount > 0 ? (
                                    <Link
                                      to={`/quotation/${intent?.intentId}`}
                                      className="circle-button">
                                      {intent?.quotationCount}
                                    </Link>
                                  ) : (
                                    <span className="circle-button-disabled">
                                      {intent?.quotationCount}
                                    </span>
                                  )}
                                </td>

                                <td className='text-colors'>{formatDate(intent?.expecteDeliveryDate ? intent?.expecteDeliveryDate.split('T')[0] : '')}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="12" className="text-center">
                                <img src="/no-data-image.png" alt="No data found" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showQuotationDetails && (
          <Quotation
            intentId={currentIntentId}
          />
        )}
        <div className="d-inline-flex pagination-container">
          <div className="col-12 ml-2 mt-3">
            <ReactPaginate
              className="pagination-bar"
              previousLabel={<i className="fa-solid fa-chevron-left"></i>}
              nextLabel={<i className="fa-solid fa-angle-right"></i>}
              breakLabel={'...'}
              pageCount={Math.ceil(intents.length / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={0}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'Pagination-products'}
            />
          </div>
        </div>

        {isDeleteConfirmationOpen && (
          <div className="term-popup-box">
            <div className="popup_content">
              <p>{t('intent.confirmation')}</p>
              <div className="popup_buttons">
                <button className="terms-delete" onClick={handleDeleteIntent}>{t('terms.Yes')}</button>
                <button className="terms-nodelete" onClick={handleCloseDeleteConfirmation}>{t('terms.No')}</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Intent;