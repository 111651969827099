import React, { useState, useEffect } from 'react';
import './TermsConditionsSidebar.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../../LanguageContext';
import API from '../../services/Api';

const TermsConditionSidebar = ({ handleClose, setIsOpenSidebar, fetchTermsConditions }) => {
    const { t } = useLanguage();
    const [terms, setTerms] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedModule, setSelectedModule] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAddTerm = () => {
        if (inputValue.trim() !== '') {
            setTerms([...terms, inputValue]);
            setInputValue('');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddTerm();
        }
    };

    useEffect(() => {
        const inputElement = document.querySelector('.terms_add');
        inputElement.addEventListener('keydown', handleKeyDown);
        return () => {
            inputElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [inputValue]);

    const handleSave = async () => {
        if (!selectedModule) {
            toast.error('Please select a module.');
            return;
        }

        if (terms.length === 0) {
            toast.error('Please add at least one term and condition.');
            return;
        }

        const termsConditions = terms.map((term, index) => ({ index: index + 1, content: term }));
        const payload = {
            groupId: 1703228300417,
            propertieType: selectedModule,
            termsConditions
        };
        setLoading(true);

        try {
            const response = await fetch(`${API.commerceAPI}/commerce/termsconditions`, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setTerms(result.data.termsConditions);
            toast.success('Terms and Conditions Created Successfully!', {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            });
            setIsOpenSidebar(false);
            fetchTermsConditions();
        } catch (error) {
            console.error('Error saving Terms and Conditions:', error);
            toast.error('Error saving Terms and Conditions');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTerm = (index) => {
        setTerms(terms.filter((_, i) => i !== index));
    };

    return (
        <div className='terms_Sidebar'>
            <div className='side_padding'>
                <div className='sidebar_head'>
                    <div className='mt-4'>
                        <strong>{t('terms.Add Terms & Conditions')}</strong>
                    </div>

                    <div className="font14 lineHeight0 cursor-pointer mt-4">
                        <p className="dropdown-items drop-module drop-term">{t('terms.Add Module')}
                            <span className='text-danger'>*</span>
                        </p>
                        <select
                            className="cursor-pointer font14"
                            value={selectedModule}
                            onChange={(e) => setSelectedModule(e.target.value)}>
                            <option>{t('terms.Select Module')}</option>
                            <option>Order Invoice</option>
                            <option>PO Invoice</option>
                            <option>Credit Notes</option>
                        </select>
                    </div>

                    <div>
                        <i className="fa-solid fa-circle-xmark cursor-pointer close-term"
                            onClick={handleClose}
                        />
                    </div>
                </div><hr />

                <div className='terms_scroller-bar'>
                    <div>
                        <strong>{t('terms.Terms & Conditions')}</strong>
                        <span className='text-danger'>*</span>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder={t('terms.Add new terms and conditions')}
                            className="terms_add"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button type="button" className="add_terms" onClick={handleAddTerm}>
                            {t('terms.Add')}
                        </button>

                        <div className='mt-3 list_term'>
                            <ol>
                                {terms.map((term, index) => (
                                    <li key={index}>
                                        {`${index + 1}. ${term}`}
                                        <i className="fa-solid fa-trash cursor-pointer delete-icon" onClick={() => handleDeleteTerm(index)} />
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>

                <div className='terms-btn-save'>
                    <div>
                        <button
                            className='terms-save' onClick={handleSave} disabled={loading}>
                            {loading ? 'Saving...' : t('terms.Save')}
                        </button>
                    </div>

                    <div>
                        <button
                            className='terms-cancel'
                            onClick={handleClose}>
                            {t('terms.cancel')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsConditionSidebar;