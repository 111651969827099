import React, { useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Registration.scss";
import { useNavigate } from 'react-router-dom';
import API from '../../services/Api';

const Registration = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumber(newPhoneNumber);
    setPhoneNumberValid(/^\d{10}$/.test(newPhoneNumber));
    const numericValue = e.target.value.replace(/\D/g, "");
    setPhoneNumber(numericValue.slice(0, 10));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendOtp();
    }
  };

  const handleSendOtp = async () => {
    if (otpSent) {
      return;
    }
    setIsLoading(true);
    try {
      if (!phoneNumberValid) {
        setIsLoading(false);
        if (!toast.isActive("error-toast")) {
          toast.error("Please Enter A Valid 10-Digit Phone Number", {
            toastId: "error-toast",
          });
        }
        return;
      }

      const response = await fetch(`${API.apiGateway}/authgw/sendotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          groupId: 1703228300417,
        }),
      });

      if (response.ok) {
        setOtpSent(true);
        setIsLoading(false);
        toast.success("OTP Sent Successfully !", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          className: "custom-toast",
        });

        setTimeout(() => {
          navigate("/verify-otp", { state: { phoneNumber: phoneNumber } });
        }, 2000);
      } else {
        setIsLoading(false);
        toast.error("Failed To Send OTP. Please Try Again.");
      }
    } catch (error) {
      console.error("Error Sending OTP:", error);
      setIsLoading(false);
      toast.error("Error Sending OTP. Please Try Again.");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />

      <div className='registration-menu'></div>
      <div className='register'>
        <div className='registration-padding'>
          <div className='m-0 p-0'>
            <h6 className='baap-commerce'><b>Baap Commerce</b></h6>
            <p className='commerce-line'>Baap commerce is a platform to manage e-commerce sites</p>
          </div>

          <div className='input-tex'>
            <p className='enter-text'><b>ENTER YOUR MOBILE NUMBER</b></p>
            <div className='d-inline-flex'>
              <input
                type="text"
                placeholder=""
                name="text"
                className="input-number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onKeyPress={handleKeyPress}
                pattern="[0-9]*"
                maxLength="10"
              />
              <button className='chevron-btn-' onClick={handleSendOtp}>
                {isLoading ? (
                  <i className="spinner-border spinner-border-sm text-white spin"></i>
                ) : (
                  <i className="fas fa-chevron-right text-light text-white"></i>
                )}
              </button>
            </div>

            <p className='note-name'><b>Note</b></p>
            <h6 className='password-text'>Make sure that you are using your registered email ID for signing up with the baap</h6>
            <h6 className='password-text '>web app. We do not recommend you to share your password with anyone.</h6>
          </div>

          <div className='input-text'>
            <h6 className='name-baap '>The <b> Baap Company</b></h6>
            <h6 className='all-reserve1'>All rights reserved</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration;