import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";

function SuppliersDeletePopup({ SupplierId, onClose }) {
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const { t } = useLanguage();

  const handleDelete = async () => {
    if (SupplierId && !deleteInitiated) {
      try {
        setDeleteInitiated(true);
        const response = await fetch(`${API.commerceAPI}/commerce/supplier/${SupplierId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          toast.success("Supplier Deleted Successfully!", {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                  className="fa-solid fa-trash float-right cis pr-2"
                  style={{ color: "white" }}
              ></i>
          ),
            progressClassName: "custom-progress-leads",
          });
          onClose();
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          console.error('Failed to delete Suppliers');
          toast.error('Failed to delete Suppliers');
        }
      } catch (error) {
        console.error('Error deleting Suppliers:', error);
        toast.error('Error deleting Suppliers: ' + error.message);
      }
    } else if (deleteInitiated) {
      toast.warn('Delete operation already initiated')
    }
    else {
      console.error('Invalid Suppliers ID');
      toast.error('Invalid Suppliers ID');
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="popup-box-supplier">
        <div className="cust-box">
          <p className="text-delete">{t("productdetails.Are you sure you want to delete this Supplier?")}</p>
          <button onClick={handleDelete} className="ware-delete" disabled={deleteInitiated}>
            {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}
          </button>

          <button onClick={onClose} className="ware-nodelete">{t("productdetails.No")}</button>
        </div>
      </div>
    </div>
  );
}

export default SuppliersDeletePopup;