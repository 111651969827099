import React, { createContext, useReducer, useEffect } from 'react';

const initialState = { count: 500 };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return { ...state, count: state.count + 1 };
    case 'DECREMENT':
      return { ...state, count: state.count - 1 };
    case 'UPDATE_USER':
      return { ...state, user: action.data}
    default:
      return state;
  }
};

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
 
    const savedState = JSON.parse(localStorage.getItem('appState'));
    if (savedState) {
      dispatch({ type: 'SET_STATE', payload: savedState });
    }
  }, []);

  useEffect(() => {
   
    localStorage.setItem('appState', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };