import React, { useState, useEffect } from 'react';
import API from '../../services/Api';
import './UpdatePurchaseSidebar.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguage } from "../../LanguageContext";
import ConfirmationPopup from '../order-sidebar/invoice-popup/ConfirmationPopup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import STORAGE_KEYS from '../../utils/storageKey';
import { useParams } from 'react-router-dom';

const UpdatePurchaseSidebar = ({ purchaseOrder, setSelectedOption, selectedOption, fetchPurchaseOrders }) => {
    const [isLoading, setIsLoading] = useState(false);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [errors, setErrors] = useState({})
    const { t } = useLanguage();
    const { quotationId } = useParams('')

    const [formData, setFormData] = useState({
        grnDate: null,
        deliveryDate: null,
        lotBatchNo: '',
        paymentDate: null,
        expiryDate: null,
        contactPerson: '',
        contactEmail: '',
        contactPhoneNumber: '',
        pancard: ''
    });

    useEffect(() => {
        if (purchaseOrder && purchaseOrder.option) {
            setFormData({
                grnDate: purchaseOrder?.option?.value?.grnDate ? new Date(purchaseOrder.option.value.grnDate) : null,
                deliveryDate: purchaseOrder?.option?.value?.deliveryDate ? new Date(purchaseOrder.option.value.deliveryDate) : null,
                paymentDate: purchaseOrder?.option?.value?.paymentDate ? new Date(purchaseOrder.option.value.paymentDate) : null,
                expiryDate: purchaseOrder?.option?.value?.expiryDate ? new Date(purchaseOrder.option.value.expiryDate) : null,
                lotBatchNo: purchaseOrder?.option?.value?.lotBatchNo || '',
                contactPerson: purchaseOrder?.option?.value?.POContactPerson?.contactPerson || '',
                contactEmail: purchaseOrder?.option?.value?.POContactPerson?.email || '',
                contactPhoneNumber: purchaseOrder?.option?.value?.POContactPerson?.phoneNumber || '',
                pancard: purchaseOrder?.option?.value?.quotation?.supplier?.pancard || ''
            });
        }
    }, [purchaseOrder]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: date
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'contactPhoneNumber' && !/^\d*$/.test(value)) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));

        if (name === 'contactPhoneNumber') {
            const phoneRegex = /^\d{10}$/;
            if (!phoneRegex.test(value)) {
                setPhoneError('Invalid phone number format');
            } else {
                setPhoneError('');
            }
        }
    };

    const validateForm = () => {
        if (formData.contactEmail && !emailRegex.test(formData.contactEmail)) {
            setEmailError('Invalid Email Format');
            return false;
        }
        if (formData.contactPhoneNumber && !phoneRegex.test(formData.contactPhoneNumber)) {
            setPhoneError('Invalid PhoneNumber Format');
            return false;
        }
        return true;
    };

    const sendInvoice = async () => {
        console.log("Sending invoice...");
        try {
            const response = await fetch(
                `${API.sendInvoice}/whatsapp/send-message/${purchaseOrder?.option?.value?.quotation?.supplier?.phoneNumber}?messageType=document`,

                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        filename: 'invoiceBill',
                        document: `${API.pdfGenerator}/generate-pdf?groupId=${groupId}&quotationId=${purchaseOrder?.option?.value?.quotation?.quotationId}&type=purchesOrder`,
                        caption: `${purchaseOrder?.option?.value?.quotation?.supplier?.phoneNumber}`,
                        groupId: parseInt(groupId),
                    }),
                }
            );

            if (response.ok) {
                const responseData = await response.json();
                toast.success('Invoice Send Successfully !', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
            } else {
                throw new Error(`Failed to send invoice: ${response.statusText}`);
                toast.error('Failed to send invoice.');
            }
        } catch (error) {
            console.error('Error sending invoice:', error.message);
            toast.error('Error sending invoice.');
        }
    };

    const handleConfirm = () => {
        sendInvoice();
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const ValidForm = () => {
        let formIsValid = true;
        const newErrors = {}

        if (!formData.grnDate) {
            formIsValid = false
            newErrors.grnDate = 'GRN Date is required'
        }
        if (!formData.lotBatchNo) {
            formIsValid = false
            newErrors.lotBatchNo = 'Lot/Batch No is required'
        }
        if (!formData.paymentDate) {
            formIsValid = false
            newErrors.paymentDate = 'Payment Date is required'
        }
        if (!formData.deliveryDate) {
            formIsValid = false
            newErrors.deliveryDate = 'Delivery Date is required'
        }
        if (!formData.contactPerson) {
            formIsValid = false
            newErrors.contactPerson = 'Name is required'
        }
        if (!formData.contactPhoneNumber) {
            formIsValid = false
            newErrors.contactPhoneNumber = 'Phone No is required'
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleUpdate = async () => {
        if (!validateForm()) {
            return;
        }
        if (!ValidForm()) {
            return;
        }
        setIsLoading(true)
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/purchaseorder/group/${groupId}/purchaseOrder/${selectedOption?.option?.purchaseOrderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "POContactPerson": {
                        "contactPerson": formData.contactPerson,
                        "phoneNumber": formData.contactPhoneNumber,
                        "email": formData.contactEmail
                    },
                    ...formData
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update data');
            }
            setIsLoading(false)
            setSelectedOption(false)

            toast.success("Purchase Order Updated Successfully !", {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            });
            fetchPurchaseOrders()
        } catch (error) {
            setSelectedOption(false)
            toast.error("Error Updating Purchase Order", error)
        }
    };

    const handleCancelSidebar = () => {
        setSelectedOption(false);
    };

    useEffect(() => {
        const storedDisabledButtons = localStorage.getItem('disabledButtons');
        if (storedDisabledButtons) {
            setDisabledButtons(JSON.parse(storedDisabledButtons));
        }
    }, []);

    const [disabledButtons, setDisabledButtons] = useState({});
    const [loadingButtons, setLoadingButtons] = useState({});

    const handleAddQty = async (quotationId) => {
        if (loadingButtons[quotationId]) {
            return;
        }
        setLoadingButtons((prevState) => ({
            ...prevState,
            [quotationId]: true,
        }));

        try {
            const response = await fetch(`${API.commerceAPI}/commerce/purchaseorder/increase-inventory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: parseInt(groupId),
                    quotation: purchaseOrder?.option?.value?.quotation?.quotationId,
                })
            });

            if (response.ok) {
                const data = await response.json();
                toast.success("Actual Qty Added Successfully!", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
                const updatedDisabledButtons = {
                    ...disabledButtons,
                    [quotationId]: true,
                };
                setDisabledButtons(updatedDisabledButtons);
                localStorage.setItem('disabledButtons', JSON.stringify(updatedDisabledButtons));

            } else {
                toast.error('Failed to save data.', {
                    style: { backgroundColor: "red", color: "white", height: "50px" },
                });
            }
        } catch (error) {
            toast.error("Error Fetching Actual Qty.", {
                style: { backgroundColor: "red", color: "white", height: "40px" },
            });
        } finally {
            setLoadingButtons((prevState) => ({
                ...prevState,
                [quotationId]: false,
            }));
        }
    };



    return (
        <>
            <div className="quatation-sidebar-update">
                <div className="side-padding">
                    <div className=" pt-2">
                        <div className="row pur-sub">
                            <div className="col-6 text-left lineHeight">
                                <p className="font900 font18">{t("po.updatePo")}</p>
                                <p className="font14">{t("po.updatePo")}</p>
                            </div>

                            <div className="col-6 text-right lineHeight">
                                <p className="font900 font18 me-3">{t("purchase.date")}</p>
                                <p className="font14 me-1">
                                    {purchaseOrder?.option?.value?.purchaseOrderDate &&
                                        new Date(purchaseOrder?.option?.value?.purchaseOrderDate).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        })
                                    }
                                </p>
                            </div>
                            <hr />
                        </div>
                    </div>

                    <div className="sidebar-wrapper-quotation pb-4">
                        <div className="row paddingx10 pl-3">
                            <div className="col-6 p-0">
                                <div className="container_ mb-3">
                                    <p className="font14 black-color">{t("supplier.Supplier Name")}</p>
                                    <span className="font14 fontw-800"><b>{purchaseOrder?.option?.value?.quotation?.supplier?.name}</b></span>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("intentCreate.Email")}</p>
                                    <p className="font14 fontw-800"><b>{purchaseOrder?.option?.value?.quotation?.supplier?.email}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("AddSupplier.Phone Number")}</p>
                                    <p className="font14 fontw-800"><b>{purchaseOrder?.option?.value?.quotation?.supplier?.phoneNumber}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("AddSupplier.GST No")}</p>
                                    <p className="font14 fontw-800"><b>{purchaseOrder?.option?.value?.quotation?.supplier?.gstNo || "--"}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("po.accDetails")}</p>
                                    <p className="font14">
                                        {t("Payment.Bank")}: &nbsp;<b>{purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails?.length > 0 ? purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails[0].accountDetails.bank : "--"}</b><br /><br />
                                        {t("po.branch")}: &nbsp;<b>{purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails?.length > 0 ? purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails[0].accountDetails.branch : "--"}</b><br /><br />
                                        {t("po.AccNo")}: &nbsp;<b>{purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails?.length > 0 ? purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails[0].accountDetails.accountNo : "--"}</b><br /><br />
                                        {t("PAN No")}: &nbsp;<b>{purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails?.length > 0 ? purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails[0].accountDetails.pancard : "--"}</b><br /><br />
                                        IFSC: &nbsp;<b>{purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails?.length > 0 ? purchaseOrder?.option?.value?.quotation?.supplier?.accountDetails[0].accountDetails.ifsc : "--"}</b><br />
                                    </p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("AddSupplier.Address")}</p>
                                    {purchaseOrder?.option?.value?.quotation?.supplier?.addresses.map((address, index) => (
                                        <div key={index} className="font14 fontw-800">
                                            <span>{address.locality ? <p>&nbsp;{address.locality}</p> : null}</span>
                                            <p>&nbsp;{address.city || "--"}</p>
                                            <p>&nbsp;{address.stateName || "--"}</p>
                                            <p>&nbsp;{address.zip || "--"}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                                <tr className="font13 head-theme">
                                    <th style={{ width: "0px" }}>{t("neworder.Sr.No")}</th>
                                    <th style={{ width: "100px" }}>{t("intentCreate.Product Name")}</th>
                                    <th style={{ width: "10px" }}>{t("HSN No")}</th>
                                    <th style={{ width: "40px" }}>{t("neworder.qty")}</th>
                                    <th style={{ width: "80px" }}>{t("neworder.AQty")}</th>
                                    <th style={{ width: "85px" }}>{t("po.unitRate")}</th>
                                    <th style={{ width: "85px" }}>{t("neworder.total")}</th>
                                    <th style={{ width: "50px" }}>{t("neworder.tax")}</th>
                                    <th style={{ width: "125px" }}>{t("po.taxableValue")}</th>
                                    <th style={{ width: "0px" }}>{t("neworder.Add Inventory")}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {purchaseOrder?.option?.products.map((product, index) => (
                                    <tr key={index} className="font14">
                                        <td>{index + 1}</td>
                                        <td>{product.product.name || "--"}</td>
                                        <td>{product.product.hsnNo || "--"}</td>
                                        <td>{product.qty || "--"}</td>
                                        <td>{product.actualQty || '--'}</td>
                                        <td className='p-fixed'><i className="fa-solid fa-indian-rupee-sign"></i> {product.perUnit || "--"}</td>
                                        <td><i className="fa-solid fa-indian-rupee-sign"></i> {product.totalAmount}</td>
                                        <td>{product.tax}%</td>
                                        <td><i className="fa-solid fa-indian-rupee-sign"></i> {product.totalPriceExcludingTax}</td>
                                        <td>
                                            <button className='add-inve'
                                                onClick={() => handleAddQty(purchaseOrder?.option?.value?.quotation?.quotationId)}
                                                disabled={disabledButtons[purchaseOrder?.option?.value?.quotation?.quotationId] || loadingButtons[purchaseOrder?.option?.value?.quotation?.quotationId]}
                                            >
                                                {loadingButtons[purchaseOrder?.option?.value?.quotation?.quotationId] ? 'Added...' : t("neworder.add")}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="row total_a_sec mt-4">
                            <div className="col-6 col-md-6">
                                <div>
                                    <label>{t("po.totalPoAmt")}</label>
                                </div>
                                <div>
                                    <label>{t("po.totalTaxAmount")}</label>
                                </div>
                                <div>
                                    <label>{t("po.totalPriceET")}</label>
                                </div>
                                <div>
                                    <label>{t("po.Negotiated Price")}</label>
                                </div>
                            </div>
                            <div className="'col-6 col-md-6">
                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{purchaseOrder?.option?.value?.quotation?.totalProductAmount}</b>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{purchaseOrder?.option?.value?.quotation?.totalTaxAmount}</b>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{purchaseOrder?.option?.value?.quotation?.totalProductPriceExcludingTax}</b>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{purchaseOrder?.option?.value?.quotation?.negotiatedPrice}</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr></hr>

                        <div className='row'>
                            <div className='col-6'>
                                <label className='font14 pt-2'>{t("po.grn")}</label>
                                <span className='text-danger'>*</span><br />
                                <DatePicker
                                    selected={formData.grnDate}
                                    onChange={(date) => handleDateChange(date, 'grnDate')}
                                    placeholderText="dd-mm-yyyy"
                                    className={`select-status2 ${errors.grnDate ? 'is-invalid' : ''}`}
                                    dateFormat="dd-MM-yyyy"
                                /><br />
                                {errors.grnDate && <div className='validation-error'>{errors.grnDate}</div>}

                                <label className='font14 pt-2'>{t("po.lotBatchNo")}</label>
                                <span className='text-danger'>*</span><br />
                                <input
                                    name='lotBatchNo'
                                    placeholder={t("po.lotBatchNo")}
                                    type='number'
                                    className={`select-status3 ${errors.lotBatchNo ? 'error-border' : ''}`}
                                    value={formData.lotBatchNo || ''}
                                    onChange={handleChange}
                                /><br />
                                {errors.lotBatchNo && <div className='validation-error'>{errors.lotBatchNo}</div>}

                                <label className='font14 pt-2'>{t("po.expirydate")}</label><br />
                                <DatePicker
                                    selected={formData.expiryDate}
                                    onChange={(date) => handleDateChange(date, 'expiryDate')}
                                    className={`select-status2 ${errors.expiryDate ? 'is-invalid' : ''}`}
                                    placeholderText="dd-mm-yyyy"
                                    dateFormat="dd-MM-yyyy"
                                /><br />
                            </div>

                            <div className='col-6'>
                                <label className='font14 pt-2'>{t("po.paymentDate")}</label>
                                <span className='text-danger'>*</span><br />
                                <DatePicker
                                    selected={formData.paymentDate}
                                    onChange={(date) => handleDateChange(date, 'paymentDate')}
                                    placeholderText="dd-mm-yyyy"
                                    className={`select-status2 ${errors.paymentDate ? 'is-invalid' : ''}`}
                                    dateFormat="dd-MM-yyyy"
                                /><br />
                                {errors.paymentDate && <div className='validation-error'>{errors.paymentDate}</div>}

                                <label className='font14 pt-2'>{t("intentCreate.Delivery Date")}</label>
                                <span className='text-danger'>*</span><br />
                                <DatePicker
                                    selected={formData.deliveryDate}
                                    onChange={(date) => handleDateChange(date, 'deliveryDate')}
                                    placeholderText="dd-mm-yyyy"
                                    className={`select-status2 ${errors.deliveryDate ? 'is-invalid' : ''}`}
                                    dateFormat="dd-MM-yyyy"
                                /><br />
                                {errors.deliveryDate && <div className='validation-error'>{errors.deliveryDate}</div>}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='contact-person pt-3'>{t("po.contactPersonInfo")}</div>
                            <div className='col-6'>
                                <label className='font14 pt-2'>{t("intentCreate.Name")}</label>
                                <span className='text-danger'>*</span><br />

                                <input
                                    name='contactPerson'
                                    placeholder={t("intentCreate.Name")}
                                    type='text'
                                    className={`select-status ${errors.contactPerson ? 'is-invalid' : ''}`}
                                    value={formData?.contactPerson}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const regex = /^[A-Za-z\s]*$/;
                                        if (regex.test(value)) {
                                            handleChange(e);
                                        }
                                    }}
                                />
                                {errors.contactPerson && <div className='validation-error'>{errors.contactPerson}</div>}

                                <label className='font14 pt-2'>{t("AddSupplier.Phone Number")}</label>
                                <span className='text-danger'>*</span><br />

                                <input
                                    type="text"
                                    name="contactPhoneNumber"
                                    placeholder="phoneNumber"
                                    value={formData.contactPhoneNumber}
                                    onChange={handleChange}
                                    className={`select-status ${errors.contactPhoneNumber ? 'is-invalid' : ''}`}
                                    maxLength={10}
                                    pattern="\d*"
                                />
                                {errors.contactPhoneNumber && <p className="input-error">{errors.contactPhoneNumber}</p>}
                            </div>

                            <div className='col-6'>
                                <label className='font14 pt-2'>{t("intentCreate.Email")}</label><br />
                                <input name='contactEmail'
                                    placeholder={t("intentCreate.Email")}
                                    type='email'
                                    className='select-status'
                                    value={formData?.contactEmail}
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex mt-1 footer-btn-container">
                        <div className="footer-btn">
                            <button className="saveDraft-btn mx-0 mb-2" disabled={isLoading} onClick={handleUpdate}>
                                {isLoading ? (
                                    t("customer.Updating..")
                                ) : (
                                    t("product.Update")
                                )}
                            </button>

                            {formData.contactPerson && formData.contactPhoneNumber ? (
                                <>
                                    <button className="saveDraft-btn mx-2 mb-2">
                                        <a
                                            className="font14  print-button-page"
                                            href={`${API.pdfGenerator}/generate-pdf?groupId=${groupId}&quotationId=${purchaseOrder?.option?.value?.quotation?.quotationId}&type=purchesOrder`}
                                            rel="noopener noreferrer">
                                            {t("quotation.print")}
                                        </a>
                                    </button>
                                    <button
                                        className="saveDraft-btn mx-0 mb-2"
                                        onClick={() => setShowConfirmation(true)}>
                                        <span>{t("quotation.send")}</span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="saveDraft-btn mx-2 mb-2" disabled>
                                        <span className="font14">Print</span>
                                    </button>
                                    <button className="saveDraft-btn mx-0 mb-2"
                                        disabled onClick={() => setShowConfirmation(true)}>
                                        <span>{t("quotation.send")}</span>
                                    </button>
                                </>
                            )}
                            <button className="ml-2 cancel-btn mb-2" onClick={handleCancelSidebar}>{t("button.cancel")}</button>
                        </div>
                    </div>

                    {showConfirmation && (
                        <ConfirmationPopup onConfirm={handleConfirm} onCancel={handleCancel} />
                    )}
                </div>
            </div>
        </>
    )
}
export default UpdatePurchaseSidebar;