import React, { useState, useEffect, useRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./NewOrderSidebar.scss";
import moment from "moment-timezone";
import { useLanguage } from "../../LanguageContext";
import AddressSidebar from "../all-order/AddressSidebar";
import PaymentSidebar from "./PaymentSidebar";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import API from "../../services/Api"
import { Link } from "react-router-dom";
import AddProductSidebar from "./AddProductSidebar";
import STORAGE_KEYS from "../../utils/storageKey";
import { toast } from "react-toastify";

const NewOrderSidebar = ({ fetchData, setOpenNewSidebar }) => {
  const { t } = useLanguage();
  const [selectedDate, setSelectedDate] = useState(null);
  const [viewHistory, setViewhistory] = useState(false);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [openNewAddressSidebar, setOpenNewAddressSidebar] = useState(false);
  const [dataOpen, setDataOpen] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchProduct, setSearchProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [discount, setDiscount] = useState("");
  const [openPaymentSidebar, setOpenPaymentSidebar] = useState();
  const [openAddProductSidebar, setOpenAddProductSidebar] = useState();
  const [addresses, setAddresses] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [status, setstatus] = useState("new");
  const [driverName, setDriverName] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [driverNo, setDriverNo] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [trackingNo, setTrackingNo] = useState("");
  const [trackingURL, setTrackingURL] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [dispatchNo, setDispatchNo] = useState();
  const [eWayBillNo, setEWayBillNo] = useState("");
  const [refNo, setRefNo] = useState();
  const [refDate, setRefDate] = useState();
  const [selectedNewOrder, setSelectedNewOrder] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [transporter, settransporter] = useState();
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [assign, setAssign] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [setIsLoadingWarehouse] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState([]);
  const [setWarehouseOptions] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [discountType, setDiscountType] = useState('Discount Type');
  const [errorMessage, setErrorMessage] = useState('');
  const handleWarehouseChange = (e) => {
    const selectedId = e.target.value;
    setSelectedWarehouse(selectedId);
    setSelectedWarehouseId(selectedId);
  };

  const [errors, setErrors] = useState({
    status: "",
    driverName: "",
    driverNo: "",
    location: "",
    name: "",
    contactPerson: "",
    trackingNo: "",
    trackingURL: "",
    eWayBillNo: ""
  });

  const handleToggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleToggleDetail = () => {
    setShowDetail(!showDetail);
  };

  const openGoogleMap = () => {
    const latitude = selectedNewOrder?.latitude;
    const longitude = selectedNewOrder?.longitude;
    if (latitude !== undefined && longitude !== undefined && latitude !== null && longitude !== null) {
      const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(googleMapsURL);
    } else {
      console.error("Latitude or Longitude is missing or invalid:", { latitude, longitude });
      alert("Coordinates are not available for this request.");
    }
  };
  const chooseAddressSidebar = () => {
    setOpenNewAddressSidebar(!openNewAddressSidebar);
  };

  const filter = async () => {
    try {
      const selectedUserId = data?.userId;

      if (!selectedUserId) {
        console.error("No userId found in selected order data");
        return;
      }
      const response = await fetch(
        `${API.commerceAPI}/commerce/customer/address/${groupId}/${selectedUserId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setAddresses(responseData.data);
      setOpenNewAddressSidebar(!openNewAddressSidebar);
      setSelectedNewOrder({
        ...data,
        userId: selectedUserId,
      });
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleCancelClick = () => {
    setDataOpen(false);
  };

  let totalQuantity = 0;
  if (data && Array.isArray(data.products)) {
    totalQuantity = data.products.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
  };

  const handleSearch = async (query) => {
    try {
      const response = await fetch(
        `${API.commerceAPI}/commerce/customer/all/getByGroupId/${groupId}?search=${query}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const itemsArray = data.data?.items || [];
      setSearchResults(itemsArray);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setSearchResults([]);
    }
  };

  const fetchSearchCust = async () => {
    if (selectedNewOrder?.userId) {
      try {
        const response = await fetch(
          `${API.commerceAPI}/commerce/customer/address/${groupId}/${selectedNewOrder?.userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAddresses(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  const handleProductSearch = async (query) => {
    try {
      const response = await fetch(
        `${API.commerceAPI}/commerce/products/all/group/${groupId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const itemsArray = data.data?.items || [];

      const filteredItems = itemsArray.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()))
      );

      setSearchProduct(filteredItems);
      setIsInitialSearch(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setSearchProduct([]);
    }
  };

  const handleAddButtonClick = async () => {
    setErrorMessage('');

    if (!quantity) {
      return;
    } else {
      setErrors('');
    }

    const currentCartProducts = await fetchCartProducts();
    const productExistsInCart = currentCartProducts?.find(
      (product) => product.productcode === selectedProduct
    );
    if (productExistsInCart) {
      setErrorMessage("Product is already in the cart.");
      return;
    }


    if (selectedProduct && quantity !== "") {
      const postData = {
        groupId: parseInt(groupId),
        userId: selectedNewOrder?.userId,
        wareHouseId: parseInt(selectedWarehouseId),
        products: [
          {
            productcode: selectedProduct,
            quantity: parseFloat(quantity),
          },
        ],
      };

      if (discountType === 'percentage') {
        postData.products[0].percentage = parseFloat(discount) || 0;
      } else if (discountType === 'amount') {
        postData.products[0].amount = parseFloat(discount) || 0;
      }

      typeaheadRef.current.clear();

      try {
        const response = await fetch(
          `${API.commerceAPI}/commerce/cart`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );
  
        if (response.ok) {
          const responseData = await response.json();
  
          if (responseData?.status === "Success") {
            // Call to update cart products after successful addition
            fetchCartProducts();
  
            // Clear fields and reset states after successful addition
            setSelectedProduct(null);   // Reset product selection
            setQuantity("");            // Clear quantity input
            setDiscount("");            // Clear discount input
            setDiscountType("");        // Clear discount type selection
            setErrorMessage("");        // Clear any error messages
  
            // Display success message
            toast.success("Product Added Successfully!", {
              style: { backgroundColor: "#328b17", color: "white", height: "50px" },
            });
          } else if (responseData?.status === "Failed") {
            setErrorMessage(responseData.message || "Failed to add product.");
          }
        } else {
          setErrorMessage("Failed to add product to cart. Please try again.");
        }
      } catch (error) {
        console.error("An error occurred while adding the product to the cart.", error);
        setErrorMessage("An unexpected error occurred.");
      }
    } else {
      setErrorMessage("Please select a product and enter a quantity.");
    }
  };

  const fetchCartData = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchCartProducts = () => {
    const apiUrl = `${API.commerceAPI}/commerce/cart/getProductByUserId/${groupId}/${selectedNewOrder?.userId}`;
    fetchCartData(apiUrl)
      .then((result) => {
        setData(result?.data);

      })
      .catch((error) => { });
  };

  const handleDelete = async (groupId, cartId, productCode) => {
    try {
      await axios.delete(
        `${API.commerceAPI}/commerce/cart/${groupId}/${cartId}/${productCode}`
      );
      fetchCartProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const hasMembership = (customer) => {
    return customer?.hasMembership === true;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formattedDate = currentDateTime.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const typeaheadStyle = {
    borderRadius: "30px",
  };

  const handleDeductionChange = (newDeduction) => {
    setData((prevData) => ({
      ...prevData,
      deduction: parseFloat(newDeduction) || 0,
    }));
  };

  const handleDepartmentChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedDepartment(selectedValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      department: selectedValue ? '' : prevErrors.department,
    }));
  };

  const handleSaveClick = () => {
    const newErrors = {};
    if (!status) {
      newErrors.status = "Status is required !";
    }

    if (driverName.trim() && !isValidName(driverName.trim())) {
      newErrors.driverName = "Invalid Name!";
    }

    if (driverNo.trim() && !isValidDriverNo(driverNo.trim())) {
      newErrors.driverNo = "Contact Person must be 10 digits!";
    }

    if (location.trim() && !isValidName(location.trim())) {
      newErrors.location = "Invalid Name!";
    }

    if (name.trim() && !isValidName(name.trim())) {
      newErrors.name = "Invalid Name!";
    }

    if (contactPerson.trim() && !isValidContactPerson(contactPerson.trim())) {
      newErrors.contactPerson = "Contact Person must be 10 digits!";
    }

    if (trackingNo.trim() && trackingNo.trim()?.length < 5) {
      newErrors.trackingNo = "Tracking No must be at least 5 characters!";
    }

    if (trackingURL.trim() && !isValidURL(trackingURL.trim())) {
      newErrors.trackingURL = "Invalid Tracking URL!";
    }

    if (!selectedDepartment) {
      setErrors({ department: 'Select a Department' });
      return;
    }

    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      setIsSaveClicked(true);
    }

    setQuantity('');
    setSelectedProduct(null);
    setIsSaveButtonDisabled(true);
  };

  const isValidName = (name) => {
    return /^[a-zA-Z\s]+$/.test(name);
  };

  const isValidContactPerson = (contactPerson) => {
    return /^\d{10}$/.test(contactPerson.trim());
  };

  const isValidDriverNo = (driverNo) => {
    return /^\d{10}$/.test(driverNo.trim());
  };

  const isValidURL = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handlePaymentTermChange = (e) => {
    setSelectedPaymentTerm(e.target.value);
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setstatus(newStatus);
    setErrors((prevErrors) => ({ ...prevErrors, status: "" }));
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.toUpperCase();
    setVehicleNo(inputValue);
  };


  const typeaheadRef = useRef();

  const getInputClass = (fieldName) => {
    return errors[fieldName]
      ? "select-status pl-2 error-border"
      : "select-status pl-2";
  };

  const clearInputs = () => {
    setSelectedProduct(null);
    setQuantity('');
  };

  const handleTypeaheadInputChange = (query) => {
    if (!query) {
      setQuantity('');
    }
  };

  const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handleProductSelection = (selected) => {
    const productCode = selected[0]?.productcode;
    setSelectedProduct(productCode);
    setSelectedProductData(selected);
    setIsSaveButtonDisabled(!(productCode && quantity));
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);

    if (newQuantity >= 1 || e.target.value === '') {
      setQuantity(newQuantity);
      setErrors((prevErrors) => ({ ...prevErrors, quantity: '' }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        quantity: '',
      }));
    }
    setIsSaveButtonDisabled(!(selectedProduct && newQuantity >= 1));
  };

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const generateOrderNumber = () => {
      const timestamp = moment().format("YYMMDDHHmmss");
      const newOrderNumber = `Order # ${timestamp}`;
      setOrderNumber(newOrderNumber);
      setRefNo(timestamp);
      setDispatchNo(timestamp)
    };
    generateOrderNumber();
  }, [orderNumber]);

  useEffect(() => {
    fetch(
      `${API.peopleManagentService}/peopleManagement/deparment/groupId/${groupId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const departments = data?.data?.items.map((item) => ({
            departmentId: item.departmentId,
            name: item.name,
          }));
          setDepartmentOptions(departments);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedNewOrder?.userId) {
      fetchSearchCust();
    }
  }, [selectedNewOrder?.userId, groupId]);

  useEffect(() => {
    fetch(
      `${API.peopleManagentService}/peopleManagement/members/all/getByGroupId/${groupId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data?.data?.items)) {
          const employees = data?.data?.items?.map((employee) => ({
            value: employee,
          }));
          setEmployeeOptions(employees);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleWarehouseSearch = (query) => {
    setIsLoadingWarehouse(true);
    fetch(`${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}?WareHouseName=${query}`)
      .then((response) => response.json())
      .then((responseJson) => {
        const { data } = responseJson;
        if (data && Array.isArray(data.items)) {
          const warehouseOptions = data.items.map((warehouse) => ({
            label: warehouse.WareHouseName,
            value: warehouse.id,
          }));
          setSelectedWarehouse(false);
          setWarehouseOptions(warehouseOptions);
        } else {
          console.error('No warehouse options found in the API response:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching warehouse data:', error);
      })
      .finally(() => {
        setIsLoadingWarehouse(false);
      });
  };

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value?.length <= 10) {
      setter(value);
    }
  };

  const [isTableOpen, setIsTableOpen] = useState(true);
  const handleToggle = () => {
    setIsTableOpen(!isTableOpen);
  };

  const TruncateText = ({ text, maxLength }) => {
    if (text?.length <= maxLength) {
      return <span>{text}</span>;
    }
    return <span>{text.substring(0, maxLength)}...</span>;
  };

  const apiUrl = `${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}?search=%27`;
  useEffect(() => {
    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setWarehouses(data?.data?.items);
      })
      .catch(error => {
        console.error('Error fetching warehouses:', error);
      });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const handleToggleInformation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {dataOpen ? (
        <div className="order-sidebar">
          <div className="side-padding">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-4">
                  <div className="service-request ">
                    <p className="font18 fontw-800 ">{orderNumber}</p>
                    <div className="d-inline-flex">
                      <p className="font14">
                        {t("neworder.items")} :{totalQuantity},{" "}
                        {t("table.Amount")}: &nbsp;&nbsp;
                        <i className="fa-solid fa-indian-rupee-sign"> &nbsp;</i>
                        {`${data?.subtotal || 0}`}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="font14 lineHeight0 cursor-pointer col-4 mt-1 pl-5">
                  <p className="dropdown-items drop-item drop-list">
                    {t("select.Department")}
                  </p>
                  <select
                    className="cursor-pointer font14"
                    value={selectedDepartment?.categoryId}
                    onChange={handleDepartmentChange}>
                    <option value="" className="fontw-500 dropdown_head">
                      {t("sidebarcustomers.Select Department")}
                    </option>
                    {departmentOptions
                      ? departmentOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.departmentId}>
                          {option?.name}
                        </option>
                      ))
                      : null}
                  </select>
                  {errors.department && <div className="error-message">{errors.department}</div>}
                </div>
                <div className="col-3 ml-5">
                  <div className="date-time">
                    <p className="font16 date-Time">
                      {t("newOrder.date-time")}
                    </p>
                    <p className="font14 ">{formattedDate}</p>{" "}
                  </div>
                </div>
              </div>
              <hr className=" " />
              <div className="sidebar-wrapper1">
                <div className="row paddingx10  pl-3">
                  <div className="col-4 line-height p-0 ">
                    <p className="font14 black-color">
                      {t("table.customerName")}
                    </p>
                    <span className="font14 fontw-800">
                      {selectedNewOrder?.name || "--"}
                      {hasMembership(selectedNewOrder) && (
                        <button className="Premium">Premium</button>
                      )}
                    </span>
                  </div>
                  <div className="col-3 p-0">
                    <div className="line-height">
                      <p className="font14 black-color">{t("table.phoneNo")}</p>
                      <p className="font14 fontw-800">
                        {selectedNewOrder?.phoneNumber || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="col-4 p-0">
                    <Typeahead
                      style={typeaheadStyle}
                      id="customer-search"
                      className="search-cust"
                      labelKey={(option) =>
                        `${option.name}- ${option.phoneNumber}`
                      }
                      onChange={(selected) => {
                        if (selected && selected?.length > 0) {
                          const selectedCustomer = selected[0];
                          setSelectedNewOrder({
                            name: selectedCustomer?.name || "--",
                            phoneNumber: selectedCustomer?.phoneNumber || "--",
                            premium: selectedCustomer?.premium || false,
                            location: selectedCustomer?.location || "--",
                            userId: selectedCustomer?.userId || "--",
                            pinCode: selectedCustomer?.pinCode || "--",
                            latitude: selectedCustomer?.latitude || "--",
                            longitude: selectedCustomer?.longitude || "--",
                          });
                        }
                      }}
                      onInputChange={(query) => handleSearch(query)}
                      options={searchResults}
                      placeholder={t("newOrder.search-customer")}
                    />
                  </div>
                  <div className="col-lg-1">
                    <Link to="/customers" target="_blank">
                      <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon-plus pt-1 "></i>
                    </Link>
                  </div>
                </div>
                <div className="row paddingx10 pl-3">
                  <div className="col-4 line-height p-0">
                    <p className="font14">{t("table.location")}</p>
                    <p className="font14 fontw-600">
                      {selectedNewOrder?.location || "--"}
                    </p>
                  </div>
                  <div className="col-4 p-0">
                    <div className="line-height">
                      <p className="font14">{t("requestsidebar.pinCode")}</p>
                      <p className="font14 fontw-600">
                        {selectedNewOrder?.pinCode || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="col-4 p-0">
                    <div className="line-height">
                      <p className=" font14 locateMap ">
                        {t("requestsidebar.locateMap")}
                      </p>
                      <p
                        className="textBlue cursorPointer font14 fontw-600 Click"
                        onClick={openGoogleMap} >
                        {t("newOrder.click")}
                        <span className="font14 color-grey-light pl-2">
                          {t("newOrder.serving")}
                        </span>
                      </p>
                    </div>
                  </div>
                  <hr className="hr-dash" />
                </div>
                <div className="row">
                  <div className="col-lg-12 d-flex align-items-center">
                    <span className="flex-grow-1 OrderDetails text-orange1">{t("neworder.dispatchstatus")}</span>

                    <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggle}
                    ></i>
                  </div>
                  {isTableOpen && (
                    <div className="">
                      <div>
                        <select
                          className="select-warehouse-drop font14 mt-2"
                          value={selectedWarehouse || ""}
                          onChange={handleWarehouseChange}>
                          <option value="">Select Warehouse</option>
                          {Array.isArray(warehouses) && warehouses?.length > 0 ? (
                            warehouses.map(item => (
                              <option key={item.id} value={item.wareHouseId}>{item.WareHouseName || "--"}</option>
                            ))
                          ) : (
                            <option>No warehouses available</option>
                          )}
                        </select>
                      </div><br />
                      <table className="table">
                        <thead>
                          <tr className="">
                            <th className="Items">{t("intentCreate.Product Name")}</th>
                            <th className="Items">{t("SKU")}</th>
                            <th className="Qty-UI">{t("neworder.qty")}</th>
                            <th className="Rate-UI">{t("neworder.rates")}</th>
                            <th className="Tax-UI">{t("neworder.discount")}</th>
                            <th className="Tax-UI">{t("neworder.tax")}</th>
                            <th className="Total-UI">{t("neworder.total")}</th>
                            <th className="Total-UI">{t("intentCreate.Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.products?.map((product, index) => (
                            <tr key={index}>
                              <td className="ProductName font14">
                                <TruncateText text={`${product.productcode.name}`} maxLength={15} />
                              </td>
                              <td>{product?.productcode?.skuNumber}</td>
                              <td className=" text-dark">{product.quantity}</td>
                              <td className="text-dark">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                &nbsp;{`${product?.productcode?.regularPrice}`}
                              </td>
                              <td className="text-dark">
                                {product?.productcode?.discount}
                              </td>
                              <td className="text-dark">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                &nbsp;{`${product?.productcode?.tax || 0}`}
                              </td>
                              <td className="">
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                &nbsp;{`${product?.totalProductPrice}`}
                              </td>
                              <td className="CloseIcon">
                                <i
                                  className="fa-solid fa-trash float-right cis"
                                  onClick={() =>
                                    handleDelete(
                                      data.groupId,
                                      data.cartId,
                                      product.productcode.productcode
                                    )
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div>
                  {isTableOpen && (
                    <div className="display_order">
                      <div className="mt-3">
                        <Typeahead
                          ref={typeaheadRef}
                          id="product-search"
                          labelKey={(option) => `${option.name} (${option.tags.join(', ')})`}
                          onChange={handleProductSelection}
                          onInputChange={(query) => {
                            handleTypeaheadInputChange(query);
                            handleProductSearch(query);
                          }}
                          options={searchProduct}
                          placeholder={t("neworder.search-product")}
                          className="search-products font14"
                        />
                        {errorMessage && (
                          <div className="validation-error">
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="mt-3 ml-2">
                        <input type="number"
                          className="qty_input"
                          placeholder="Qty"
                          value={quantity || ''}
                          onChange={handleQuantityChange}
                          min="1"
                        />
                        {errors.quantity && <div className="validation-error">{errors.quantity}</div>}
                      </div>
                      <div className="mt-3 mx-4">
                        <div className="">
                          <select
                            className="disc_input"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            name="discountType">
                            <option value="">{t("neworder.select")}</option>
                            <option value="amount">{t("neworder.Amount (Rs)")}</option>
                            <option value="percentage">{t("neworder.Percentage")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-3 mx-3">
                        {discountType === 'Discount Type' ? (
                          <input
                            type="number"
                            className="qty_input_dis"
                            placeholder={discountType === 'amount' ? t("neworder.Amount") : t("neworder.discount")}
                            value={discount}
                            onChange={handleDiscountChange}
                            name="discount"
                          />
                        ) : (
                          <input
                            type="text"
                            className="qty_input"
                            placeholder={t("neworder.Amount")}
                            value={discount}
                            onChange={handleDiscountChange}
                            name="discount"
                          />
                        )}
                      </div>
                      <div className="mt-3">
                        {!isInitialSearch && searchProduct?.length > 0 && (
                          <button
                            className="qty_input1 font12 add-order-btn"
                            type="button"
                            onClick={handleAddButtonClick}>
                            {t("neworder.add")}
                          </button>
                        )}
                        <div className=" add-product">
                          {!isInitialSearch && searchProduct?.length === 0 && (
                            <i
                              className="fa fa-plus plus-icon cursor-pointer"
                              onClick={() => {
                                setOpenAddProductSidebar(!openAddProductSidebar);
                                clearInputs();
                              }}
                            ></i>
                          )}
                          {openAddProductSidebar && <AddProductSidebar />}
                        </div>
                      </div>
                    </div>
                  )}
                  {isTableOpen && (
                    <div>
                      <hr className=" mt-2" />
                      <div className="row total_a_sec">
                        <div className="col-6 col-md-6">
                          <div>
                            <label>{t("newOrder.total-amount")}</label>
                          </div>
                          <div>
                            <label>{t("neworder.saving")}</label>
                          </div>
                          <div>
                            <label>{t("neworder.tax")}</label>
                          </div>
                          <div>
                            <label>{t("neworder.payable")}</label>
                          </div>
                        </div>
                        <div className="'col-6 col-md-6">
                          <div>
                            <label>
                              &nbsp;&nbsp;
                              <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                              {`${data.subtotal || 0}`}
                            </label>
                          </div>
                          <div>
                            <label>
                              &nbsp;&nbsp;
                              <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                              {`${data?.saving || 0}`}
                            </label>
                          </div>
                          <div>
                            <label>
                              &nbsp;&nbsp;
                              <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                              {`${data.taxes || 0}`}
                            </label>
                          </div>
                          <div>
                            <label>
                              &nbsp;&nbsp;
                              <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                              {`${data?.totalCartPrice || 0}`}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr className=" mt-2" />
                <div className="row mt-3">
                  <div className="col-6 col-md-6">
                    <p className="pay_det mb-0 mt-2 text-orange1">
                      {t("sidebarcustomers.Shipping & Billing Addresses")}
                    </p>
                  </div>
                  <div className="col-6 col-md-6 text-end">
                    {selectedNewOrder && addresses && addresses?.length > 0 && (
                      <button
                        className="saveDraft-btn mx-1"
                        onClick={chooseAddressSidebar}>
                        {t("neworder.change")}
                      </button>
                    )}
                    {selectedNewOrder &&
                      (!addresses || addresses?.length === 0) && (
                        <button
                          className="saveDraft-btn mx-1"
                          onClick={() => setOpenNewAddressSidebar(true)}
                        >
                          {t("neworder.add")}
                        </button>
                      )}
                  </div>
                  {openNewAddressSidebar && (
                    <div>
                      <AddressSidebar
                        data={data}
                        selectedNewOrder={selectedNewOrder}
                        addresses={addresses}
                        fetchSearchCust={fetchSearchCust}
                        setOpenNewAddressSidebar={setOpenNewAddressSidebar}
                        handleClose={chooseAddressSidebar}
                      />
                    </div>
                  )}
                </div>
                <hr className="mt-1" />
                <div className="row">
                  <div className="col-6">
                    <p className="font16">
                      {t("AddressSidebar.Billing Address")}
                    </p>
                    {addresses.map((address, index) => (
                      <div key={index} className="">
                        <div>
                          {address?.address &&
                            address?.address?.billingAddress && (
                              <>
                                <p className="classfont_size mb-0 mt-1 color cls-lcs">
                                  {t("neworder.address-type")}{" "}
                                  {address?.address?.tag || ""}
                                </p>
                                <p className=" mb-0 cls-lcs font13">
                                  {address?.address?.street || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address?.address?.locality || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address?.address?.city || ""},{" "}
                                  {address?.address?.state || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address.address.zip || ""}
                                </p>
                              </>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-6">
                    <p className="foont16">
                      {t("AddressSidebar.Shipping Address")}
                    </p>
                    {addresses.map((address, index) => (
                      <div key={index}>
                        <div>
                          {address?.address &&
                            address?.address?.shippingAddress && (
                              <>
                                <p className="classfont_size mb-0 mt-1 color cls-lcs">
                                  {t("neworder.address-type")}{" "}
                                  {address?.address?.tag || ""}
                                </p>
                                <p className=" mb-0 cls-lcs font13">
                                  {address?.address?.street || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address?.address?.locality || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address?.address?.city || ""},{" "}
                                  {address?.address?.state || ""}
                                </p>
                                <p className="mb-0 cls-lcs font13">
                                  {address.address.zip || ""}
                                </p>
                              </>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr className=" mt-1" />
                {openPaymentSidebar && (
                  <PaymentSidebar
                    data={data}
                    orderNumber={orderNumber}
                    onChangeDeduction={handleDeductionChange}
                    selectedDate={selectedDate}
                    selectedPaymentTerm={selectedPaymentTerm}
                    status={status}
                    driverName={driverName}
                    vehicleNo={vehicleNo}
                    driverNo={driverNo}
                    location={location}
                    name={name}
                    contactPerson={contactPerson}
                    trackingNo={trackingNo}
                    trackingURL={trackingURL}
                    selectedDepartment={selectedDepartment}
                    addresses={addresses}
                    dispatchNo={dispatchNo}
                    refNo={refNo}
                    refDate={refDate}
                    transporter={transporter}
                    assign={assign}
                    setOpenPaymentSidebar={setOpenPaymentSidebar}
                    fetchData={fetchData}
                    setOpenNewSidebar={setOpenNewSidebar}
                    setIsSaveClicked={setIsSaveClicked}
                    eWayBillNo={eWayBillNo}
                    selectedWarehouseId={selectedWarehouseId}
                    latitude={selectedNewOrder?.latitude}
                    longitude={selectedNewOrder?.longitude} 
                  />
                )}
                <div className="row pt-2">
                  <div className="col-6">
                    <h6 className="font14">{t("neworder.delivery-date")}</h6>
                    <DatePicker
                      className="select-status p-2"
                      dateFormat="dd-MM-yyyy"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                      placeholderText="dd-mm-yyyy"
                    />
                  </div>
                  <div className="col-6">
                    <h6 className="font14">
                      {t("sidebarcustomers.Payment Terms")}
                    </h6>
                    <select
                      className="select-status pl-2"
                      value={selectedPaymentTerm}
                      onChange={handlePaymentTermChange}>
                      <option disabled>{t("neworder.terms")}</option>
                      <option value="Due end of the month">
                        {t("neworder.Due end of the month")}
                      </option>
                      <option value="Net 15">{t("neworder.Net 15")}</option>
                      <option value="Net 30">{t("neworder.Net 30")}</option>
                      <option value="Net 45">{t("neworder.Net 45")}</option>
                      <option value="Net 60">{t("neworder.Net 60")}</option>
                      <option value="Due on Receipt">{t("neworder.Due on Receipt")}</option>
                    </select>
                  </div>
                </div>
                <div>
                  <div className="row pt-2 font16">
                    <div className="order-status">
                      <p className="text-orange text-orange1">{t("neworder.Dispatch")}</p>
                      {!isOpen && (
                        <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleInformation}
                        ></i>
                      )}
                      {isOpen && (
                        <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleInformation}
                        ></i>
                      )}
                    </div>
                  </div>
                  {isOpen && (
                    <div className="row pt-2 font14">
                      <hr className=" " />
                      <div className="col-6">
                        <span>{t("table.status")}</span>
                        <span className="text-danger">*</span>
                        <br />
                        <select
                          className={getInputClass("status")}
                          onChange={handleStatusChange}
                          value={status}>
                          <option value="Select Status">
                            {t("order.status")}
                          </option>
                          <option value="new">New</option>
                          <option value="inprogress" disabled>In Progress</option>
                          <option value="dispatched" disabled>Dispatched</option>
                          <option value="cancelled" disabled>Cancelled</option>
                          <option value="completed" disabled>Completed</option>
                          <option className="intransit" disabled>In Transit</option>
                        </select>
                        <div style={{ color: "red" }}>{errors.status}</div>
                      </div>
                      <div className="col-6 mt-2">
                        <span className="">{t("neworder.assign-employee")}</span>
                        <br />
                        <select
                          className="input-style-assign font14 cursor-pointer mt-1"
                          value={assign}
                          onChange={(e) => {
                            setAssign(parseInt(e.target.value));
                          }}>
                          <option value="">{t("order.select-employee")}</option>
                          {employeeOptions.map((employee, index) => {
                            return (
                              <option
                                key={index}
                                value={employee.value.empId}>
                                {employee.value.name}
                              </option>
                            );
                          })}
                        </select>
                        <div style={{ color: "red" }}>{errors.assign}</div>
                      </div>
                      <div className="col-6 pt-2">
                        <p>
                          {t("neworder.driver-name")}
                          <input
                            className={getInputClass("driverName")}
                            placeholder={t("neworder.driver-name")}
                            value={driverName}
                            onChange={(e) => setDriverName(e.target.value)}
                          ></input>
                          <div style={{ color: "red" }}>{errors.driverName}</div>
                        </p>
                      </div>
                      <div className="col-6 pt-2">
                        <p>
                          {t("neworder.Transporter Name")}
                          <input
                            className={getInputClass("driverName")}
                            placeholder={t("neworder.Transporter Name")}
                            value={transporter}
                            onChange={(e) => settransporter(e.target.value)}
                          ></input>
                          <div style={{ color: "red" }}>{errors.driverName}</div>
                        </p>
                      </div>
                    </div>
                  )}
                  {isOpen && (
                    <div className="row font14 ">
                      <div className="col-6">
                        <span>{t("neworder.vehical-no")}</span>
                        <br />
                        <input
                          type="text"
                          className={getInputClass("vehicleNo")}
                          placeholder={t("neworder.vehical-no")}
                          value={vehicleNo}
                          onChange={handleChange}
                        />

                      </div>
                      <div className="col-6">
                        <span>{t("neworder.Driver Contact No")}</span>
                        <br />
                        <input
                          className={getInputClass("driverNo")}
                          placeholder={t("neworder.Driver Contact No")}
                          value={driverNo}
                          onChange={handleInputChange(setDriverNo)}
                          maxLength={10}
                        ></input>
                        {errors.driverNo && <div style={{ color: "red" }}>{errors.driverNo}</div>}
                      </div>
                    </div>
                  )}
                  {isOpen && (
                    <div className="row font14">
                      <div className="col-6 mt-2">
                        <span>{t("neworder.Dispatch Number")}</span>
                        <input
                          type="number"
                          className={getInputClass("DispatchNo")}
                          placeholder={t("neworder.Dispatch Number")}
                          value={dispatchNo}
                          onChange={(e) => setDispatchNo(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-6 mt-2">
                        <span>{t("neworder.E-waybill")}</span>
                        <input
                          type="number"
                          className={getInputClass("ewayBillNo")}
                          placeholder={t("neworder.E-waybill")}
                          value={eWayBillNo}
                          onChange={(e) => setEWayBillNo(e.target.value)}
                        ></input>
                      </div>
                    </div>
                  )}
                  <div className="row pt-2 font16">
                    <div className="order-status">
                      <p className="text-orange1">{t("neworder.status")}</p>
                      <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleInfo}
                      ></i>
                    </div>
                  </div>
                  {showInfo && (
                    <div className="row pt-2 font14">
                      <hr className=" " />
                      <div className="col-6">
                        <span>{t("neworder.Order ID")}</span>
                        <br />
                        <input
                          type="text"
                          className="select-status-p"
                          placeholder={t("neworder.Order ID")}
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                        />
                        <div style={{ color: "red" }}>{errors.orderId}</div>
                      </div>
                      <div className="col-6 ">
                        <span>{t("neworder.Reference Date")}</span>
                        <br />
                        <DatePicker
                          className={`${getInputClass("refDate")} customDatePicker`}
                          selected={refDate}
                          onChange={(date) => setRefDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                          placeholderText="dd-mm-yyyy"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                      <div className="row">
                        <div className="col-6 mt-2">
                          <span>{t("table.location")}</span>
                          <br />
                          <input
                            className={getInputClass("location")}
                            placeholder={t("table.location")}
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          ></input>
                          <div style={{ color: "red" }}>{errors.location}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row pt-2 font16">
                    <div className="order-status">
                      <span className="text-orange1">
                        {t("neworder.shipping")}
                      </span>
                      <i className="fa-sharp fa-light fa-solid fa-circle-plus plusIcon cursor-pointer" onClick={handleToggleDetail}
                      ></i>
                    </div>
                  </div>
                  {showDetail && (
                    <div className="row pt-2 font16">
                      <br />
                      <hr className="" />
                      <div className="col-6 pt-2">
                        <span>{t("neworder.name")}</span>
                        <br />
                        <input
                          className={getInputClass("name")}
                          placeholder={t("neworder.name")}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></input>
                        <div style={{ color: "red" }}>{errors.name}</div>
                      </div>
                      <div className="col-6 pt-2">
                        <span>{t("neworder.Contact Number")}</span>
                        <br />
                        <input
                          className={getInputClass("contactPerson")}
                          placeholder={t("neworder.Contact Person")}
                          value={contactPerson}
                          onChange={handleInputChange(setContactPerson)}
                          maxLength={10}
                        ></input>
                        {errors.contactPerson && <div style={{ color: "red" }}>{errors.contactPerson}</div>}
                      </div>
                    </div>
                  )}
                  {showDetail && (
                    <div className="row pt-2">
                      <div className="col-6 pt-2">
                        <span className="font14">
                          {t("neworder.tracking-no")}
                        </span>
                        <br />
                        <input
                          className={getInputClass("trackingNo")}
                          placeholder={t("neworder.tracking-no")}
                          value={trackingNo}
                          onChange={(e) => setTrackingNo(e.target.value)}
                        ></input>
                        <div style={{ color: "red" }}>{errors.trackingNo}</div>
                      </div>
                      <div className="col-6 pt-2">
                        <span className="font14">
                          {t("neworder.tracking-url")}
                        </span>
                        <br />
                        <input
                          className={getInputClass("trackingURL")}
                          placeholder={t("neworder.tracking-url")}
                          value={trackingURL}
                          onChange={(e) => setTrackingURL(e.target.value)}
                        ></input>
                        <div style={{ color: "red" }}>{errors.trackingURL}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!viewHistory ? (
                <div className="d-flex mt-2 order-sid-btn-container">
                  <div className="order-sid-btn">
                    <button
                      className="saveDraft-btn mx-0"
                      onClick={handleSaveClick}
                      disabled={isSaveButtonDisabled}>
                      {t("sidebarcustomersbtn.Save")}
                    </button>
                    <button
                      className="ml-2 cancel-btn"
                      onClick={handleCancelClick}>
                      {t("button.cancel")}
                    </button>

                    <div className="d-flex">
                      {isSaveClicked && (
                        <button
                          className="floating-getPayment-btn"
                          onClick={() =>
                            setOpenPaymentSidebar(!openPaymentSidebar)
                          }>
                          {t("neworder.getpayment")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default NewOrderSidebar;