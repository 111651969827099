import React, { useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../services/Api";
import { useLanguage } from "../../LanguageContext";

function CategoryDeletePopup({ categoryId, onClose }) {
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const { t } = useLanguage();

  const handleDelete = async () => {
    if (categoryId && !deleteInitiated) {
      try {
        setDeleteInitiated(true)
        const response = await fetch(`${API.commerceAPI}/commerce/category/${categoryId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          toast.success('Category Deleted Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                  className="fa-solid fa-trash float-right cis pr-2"
                  style={{ color: "white" }}
              ></i>
          ),
          });
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 4000);

        } else {
          console.error('Failed to delete category');
          toast.error('Failed to delete category');
        }
      } catch (error) {
        console.error('Error deleting category:', error);
        toast.error('Error deleting category: ' + error.message);
      }
    } else if (deleteInitiated) {
      toast.warn('Delete operation already initiated')
    }
    else {
      console.error('Invalid category ID');
      toast.error('Invalid category ID');
    }
  }

  return (
    <div>
      <div className="popup-box-category">
        <div className="cust-box">
          <p className="text-delete">{t("productdetails.Are you sure you want to delete this category?")}</p>
          <button onClick={handleDelete} className="ware-delete" disabled={deleteInitiated}>
            {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}</button>
          <button onClick={onClose} className="ware-nodelete">{t("productdetails.No")}</button>
        </div>
      </div>
    </div>
  );
}

export default CategoryDeletePopup;