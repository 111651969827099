import React, { useState, useEffect } from 'react';
import "./DeliveryCode.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const DeliverySidebar = ({ handleClose }) => {
    const [code, setCode] = useState('');
    const [locality, setLocality] = useState('');
    const [localities, setLocalities] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [apiCallMade, setApiCallMade] = useState(false);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();

    useEffect(() => {
        validateForm();
    }, [code]);

    const handleCodeChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setCode(value);
        }
    };

    const clearInputs = () => {
        setCode('');
        setLocality('');
        setLocalities([]);
        setErrors({});
    };

    const handleLocalityChange = (e) => {
        setLocality(e.target.value);
    };

    const handleAddLocality = () => {
        if (locality.trim() !== '') {
            setLocalities([...localities, { location: locality }]);
            setLocality('');
        }
    };

    const validateForm = () => {
        const newErrors = {};
    
        if (code.trim() === '') {
            newErrors.code = '';
        } else {
            const pinRegex = /^\d{6}$/;
            if (!pinRegex.test(code.trim())) {
                newErrors.code = 'PIN code must be a 6-digit number.';
            }
        }
    
        setErrors(newErrors);
    };
    
    const handleSave = async () => {
        if (apiCallMade) {
            return;
        }
        setIsSaving(true);

        const payload = {
            groupId: groupId,
            code: parseInt(code.trim(), 10),
            locality: localities,
        };

        try {
            const response = await fetch(`${API.commerceAPI}/commerce/deliverycode`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                toast.success('Delivery Created Successfully!', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
                setTimeout(() => {
                    handleClose();
                }, 1000);
                setApiCallMade(true);
            } else {
                throw new Error('Error saving');
            }
        } catch (error) {
            console.error(error);
            toast.error('Error saving');
        } finally {
            setIsSaving(false);
        };
    }

    const handleCancel = () => {
        clearInputs();
    };

    const handleRemoveLocality = (index) => {
        const updatedLocalities = [...localities];
        updatedLocalities.splice(index, 1);
        setLocalities(updatedLocalities);
    };

    return (
        <>
            <div className='setting-sidebar pt-2'>
                <ToastContainer position="top-right" autoClose={5000} />
                <div className='row'>
                    <div className=''>
                        <div className='ml-4'>
                            <h4 className='mt-3'>{t("Deliverycode.Delivery")}
                                <i className="fa-solid fa-circle-xmark float-right mr-4" onClick={handleClose}></i>
                            </h4>
                            <small>{t("Deliverycode.delivery codes")}</small>
                        </div>
                    </div>
                    <hr className='hr-set ' />
                </div>

                <div className=' mt-2 scrollbar-delivery'>
                    <div>
                        <label className='ml-4 mt-3'>{t("Deliverycode.PIN code")}</label>
                        <input
                            className='code-input'
                            type='text'
                            placeholder={t("Deliverycode.PIN code")}
                            value={code}
                            onChange={handleCodeChange}
                            onBlur={validateForm}
                            maxLength={6}
                        />
                        {errors.code && <p className="error-message pl-3">{errors.code}</p>}
                    </div>

                    <p className='ml-4 mt-3'>{t("Deliverycode.Village")}</p>
                    {localities.map((localityObj, index) => (
                        <>
                            <div className='d-inline-flex ml-4' key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.375" height="19.375" viewBox="0 0 19.375 19.375">
                                    <path id="Icon_awesome-arrow-alt-circle-right" data-name="Icon awesome-arrow-alt-circle-right" d="M10,.313A9.688,9.688,0,1,1,.313,10,9.686,9.686,0,0,1,10,.313ZM5.469,11.719H10v2.77a.469.469,0,0,0,.8.332l4.465-4.488a.464.464,0,0,0,0-.66L10.8,5.18a.469.469,0,0,0-.8.332v2.77H5.469A.47.47,0,0,0,5,8.75v2.5A.47.47,0,0,0,5.469,11.719Z" transform="translate(-0.313 -0.313)" />
                                </svg>
                                <p className='mx-3'><b className='village-name'>{localityObj.location}</b></p>
                                <i className='fa-solid fa-circle-xmark cross-mark' onClick={() => handleRemoveLocality(index)}></i>
                            </div>
                            <br />
                        </>
                    ))}

                    <div>
                        <input className='village-input'
                            type='text'
                            placeholder={t("Deliverycode.Village")}
                            value={locality}
                            onChange={handleLocalityChange} />
                        <button className='code-add-btn' onClick={handleAddLocality}>{t("Deliverycode.Add")}</button>
                    </div>
                </div>

                <div className='button-savedelivery1 mt-3'>
                    <button
                        className="save-delivery"
                        type="submit"
                        disabled={isSaving}
                        onClick={handleSave} >
                        {isSaving ? 'Saving...' : t("Deliverycode.Save")}
                    </button>
                   

                    <button type='cancel' onClick={handleCancel} className='cancel-delivery ml-2'>{t("Deliverycode.Cancel")}</button>
                   
                </div>
            </div>
        </>
    );
}

export default DeliverySidebar;