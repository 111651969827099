import { createContext, useContext, useState } from 'react';
import Tokens from './assets/Tokens.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('english'); 

  const changeLanguage = (l) => {
    setLanguage(l);
  };

  const t = (key) => {
    const translation = Tokens.find((e) => e.key === key);
    return translation ? translation[language] : key; // Return the translation if found, otherwise return the key itself
  }

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};