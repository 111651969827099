import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SupplierTable.scss';
import { useLanguage } from '../../LanguageContext';
import ReactPaginate from 'react-paginate';
import SuppliersDeletePopup from './SupplierDeletePopup';
import { Link } from 'react-router-dom';
import API from '../../services/Api';
import SupplierSkeletonLoader from '../../components/skeleton-loader/SupplierSkeletonLoader';
import STORAGE_KEYS from '../../utils/storageKey';
import TextTruncate from 'react-text-truncate';

const SupplierTable = () => {
  const [inputValue, setInputValue] = useState('');
  const [supplierData, setSupplierData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deleteSupplierId, setDeleteSupplierId] = useState(null);
  const { t } = useLanguage();
  const lowerSearchQuery = searchQuery.toLowerCase();
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setInputValue(newQuery);
    setSearchQuery(newQuery);
  };

  useEffect(() => {
    fetchSupplierData();
  }, []);

  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const fetchSupplierData = async () => {
    try {
      const response = await fetch(
        `${API.commerceAPI}/commerce/supplier/all/getByGroupId/${groupId}`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const filteredData = data.data.items.filter(
        (supplier) =>
          (supplier.name &&
            supplier.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (supplier.company &&
            supplier.company.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (supplier.phoneNumber &&
            supplier.phoneNumber.toString().toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setSupplierData(filteredData.reverse());
      setLoading(false);
    } catch (error) {
      console.error('Error fetching supplier data:', error);
    }
  };

  useEffect(() => {
    fetchSupplierData(searchQuery);
  }, [searchQuery]);

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const offset = currentPage * itemsPerPage;
  const currentData = supplierData.slice(offset, offset + itemsPerPage);

  const openDeleteConfirmation = (supplierId) => {
    setDeleteSupplierId(supplierId);
    setIsDeleteConfirmationVisible(true);
  };

  const onColumnClick = (supplierId) => { };
  const handleDeleteClick = () => {
    setIsDeleteConfirmationVisible(false);
    toast.success('Items Deleted Successfully!', {
      style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: 'black' }}
      />

      <div className="main">
        <div className="body_container">
          <div className="main-padding1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 p-0 main-customer">
                  <div className="d-inline-flex customer-main">
                    <small className="customers font18" href="#">
                      {t('supplier.title')}
                    </small>
                  </div>
                  <p className="signed-members-subtitle font14">{t('supplier.subtitle')}</p>
                </div>

                <div className="col-6  ">
                  <div className="d-inline-flex float-right mt-3">
                    <div className="search-member mt-1">
                      <i className="fa-solid fa-magnifying-glass  supplier-search mt-1"></i>
                      <input
                        className="search"
                        type="text"
                        placeholder={t('supplier.Search Suppliers')}
                        aria-label="Search"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="filter mx-2">
                      <span className="filter-icon">
                        <svg
                          ngcontent-jbm-c51=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="20px"
                          height="18px"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-funnel" >
                          <path
                            ngcontent-jbm-c51=""
                            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
                          ></path>
                        </svg>
                      </span>
                    </div>

                    <Link to={`/create-supplier/new`}>
                      <div className="filter mx-2">
                        <i className="fa-solid fa-plus plus-icon " title="Create Customer"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <hr className='supplier_hr' />

            <div className="scrollmenu">
              <div className="role-table">
                <div className='sidebar-wrapper'>
                  <table className="table table-hover">
                    <thead className='table-head font14'>
                      <tr>
                        <th scope="co1 table-head">{t('supplier.Supplier ID')}</th>
                        <th scope="co1 table-head">{t('supplier.Supplier Name')}</th>
                        <th scope="co1 table-head">{t('supplier.Company Name')}</th>
                        <th scope="co1 table-head">{t('supplier.Address')}</th>
                        <th scope="co1 table-head">{t('supplier.Contact')}</th>
                        <th scope="co1 table-head">{t('supplier.WhatsApp')}</th>
                        <th scope="co1 table-head">{t('supplier.Actions')}</th>
                      </tr>
                    </thead>

                    {loading ? (
                      <SupplierSkeletonLoader columns={8} />
                    ) : (
                      <tbody className='table-body-color font14'>
                        {currentData && currentData?.length > 0 ? (
                          currentData.map((supplier) => (
                            <tr key={supplier?._id}>
                              <td>{supplier?.supplierId}</td>
                              <td className=''>{supplier?.name}</td>
                              <td>{supplier?.company} </td>
                              <td>
                                <TextTruncate text={supplier?.address ? `${supplier?.address}` : 'Address here'} maxLength={15} />
                              </td>
                              <td>{supplier?.phoneNumber}</td>
                              <td>{supplier?.whatsapp}</td>

                              <td>
                                <Link to={`/create-supplier/${supplier?.supplierId}`}>
                                  <i className="fa-solid fa-pencil edit-icon" onClick={() => onColumnClick(supplier?.supplierId)}></i>
                                </Link>
                                <i className="fa-solid fa-trash supplier-delete-icon ml-2" onClick={() => openDeleteConfirmation(supplier._id)}></i>
                                {isDeleteConfirmationVisible && (
                                  <SuppliersDeletePopup
                                    SupplierId={deleteSupplierId}
                                    onClose={() => setIsDeleteConfirmationVisible(false)}
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <img src="/no-data-image.png" alt="No data found" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>

            <div className="d-inline-flex pagination-container">
              <div className="col-12 p-0 mt-3">
                <ReactPaginate
                  className="pagination-bar"
                  previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                  nextLabel={<i className="fa-solid fa-angle-right"></i>}
                  breakLabel={'...'}
                  pageCount={Math.ceil(supplierData?.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={onPageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'Pagination-products'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierTable;