import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Category.scss';
import { useLanguage } from '../../LanguageContext';
import { fetchParentCategories } from '../../utils/Helper';
import slugify from 'slugify';
import Avatar from '../../components/avatar/Avatar';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';

const CategorySidebar = ({ isOpen, handleSidebarClose, fetchCategory }) => {
  const [apiCallMade, setApiCallMade] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [areInputsFilled, setAreInputsFilled] = useState(false);
  const { t } = useLanguage();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const handleInputChange = (e) => {
    const { name, type, checked } = e.target;
    setInputErrors((prevErrors) =>
      prevErrors.map((prevError) =>
        prevError.field === name ? { ...prevError, message: '' } : prevError
      )
    );

    let updatedValue;
    if (type === 'checkbox') {
      if (name === 'isBusinessEnable' || name === 'isCommerceEnable' || name === 'subcategory') {

        updatedValue = !formData[name];
      } else {
        updatedValue = checked;
      }
    } else {
      updatedValue = e.target.value;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));

    const isNameFilled = name === 'name' ? updatedValue.trim() !== '' : formData.name.trim() !== '';
    const isSlugFilled = name === 'slug' ? updatedValue.trim() !== '' : formData.slug.trim() !== '';
    const isTagFilled = name === 'tag' ? updatedValue?.length > 0 : formData.tag?.length > 0;
    const isSubcategoryFilled =
      name === 'subcategory'
        ? (updatedValue !== undefined && updatedValue.toString().trim() !== '')
        : formData.subcategory && formData.subcategory.toString().trim() !== '';

    setAreInputsFilled(
      isNameFilled && isSlugFilled && isTagFilled && isSubcategoryFilled
    );
  };

  const [inputErrors, setInputErrors] = useState([
    { field: 'name', message: '' },
    { field: 'slug', message: '' },
    { field: 'tag', message: '' },
    { field: 'description', message: '' },
  ]);

  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    tag: [],
    description: '',
    groupId: groupId,
    categorySaved: false,
    imageUrl: '',
    selectedImage: [],
    selectedImages: [],
    isSaving: false,
    selectedFile: null,
    category: {},
    showUploadButton: false,
    profile_pic: '',
    showSuccessToast: false,
    isBusinessEnable: true,
    isCommerceEnable: true,
  });

  const clearInputs = () => {
    setFormData({
      ...formData,
      name: '',
      slug: '',
      tag: [],
      tags: '',
      description: '',
      selectedFile: null,

    });
  };

  useEffect(() => {
    const newSlug = generateSlug(formData.name);
    setFormData({ ...formData, slug: newSlug });
  }, [formData.name]);

  const generateSlug = (input) => {
    const options = {
      lower: true,
      strict: true,
    };
    const slug = slugify(input, options);
    return slug.replace(/-/g, '_');
  };

  const handleSave = async () => {
    let hasError = false;
    inputErrors.forEach((error) => {
      if (!formData[error.field]) {
        error.message = `${error.field.charAt(0).toUpperCase() + error.field.slice(1)} is required`;
        hasError = true;
      }
      if (!formData.tag?.length) {
        inputErrors.find((error) => error.field === 'tag').message = 'Tag is required';
        hasError = true;
      }
    })

    setInputErrors([...inputErrors]);
    if (hasError) {
      return;
    }

    setFormData({ ...formData, isSaving: true });
    const data = {
      name: formData.name,
      slug: formData.slug,
      tags: formData.tag.split(',').map((tag) => tag.trim()),
      description: formData.description,
      groupId: formData.groupId,
      imageUrl: formData.imageUrl,
      subcategory: formData.subcategory,
      isBusinessEnable: formData.isBusinessEnable || false,
      isCommerceEnable: formData.isCommerceEnable || false
    };

    if (apiCallMade) {
      return;
    }
    setIsSaving(true);
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/category`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      toast.success("Category Has Been Successfully Saved!", {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
      });
      clearInputs();
      handleSidebarClose();
      setApiCallMade(true);
      setFormData((prevData) => ({ ...prevData, showSuccessToast: true }));
      setTimeout(() => {
        fetchCategory('')
      }, 1000);
    } catch (error) {
      toast.error("Category is not added");
    } finally {
      setFormData({ ...formData, isSaving: false });
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchParentCategories();
        const items = data.data.items;
        setFormData({ ...formData, parentCategories: items });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formData.showSuccessToast) {
      setTimeout(() => {
        setFormData(prevData => ({ ...prevData, showSuccessToast: false }));
      }, 1000);
    }
  }, [formData.showSuccessToast]);
  const handleCancel = () => {
    clearInputs();
  };

  function changeProfileURL(url) {
    setFormData({ ...formData, imageUrl: url });
  }

  return (
    <div className={`feature-sidebar ${isOpen ? 'open' : ''}`}>
      <h4 className=' feature-titles'>{t('categorySidebar.Add Category')}</h4>
      <hr className='add-category-hr' />
      <div className='close-icon-category' onClick={handleSidebarClose}>
        <i className="fa-solid fa-circle-xmark"></i>
      </div>

      <div className=' mt-2 scrollbar-warehouse'>
        <div className="category-section">
          <div className='scrollbar-right'>
            {areInputsFilled && (
              <Avatar formData={formData} onUploadSuccess={changeProfileURL} />
            )}
          </div>

          <br />
          {inputErrors.map((error) => (
            <p key={error.field}>
              <label htmlFor={error.field} className="input-label pl-1">
                {error.field === "description"
                  ? "Category's Description"
                  : ` ${error.field.charAt(0).toUpperCase() + error.field.slice(1)}`}
              </label><small className='required'>*</small>
              <input
                type="text"
                className={`category-inputs ${error.message && 'error-border'}`}
                name={error.field} placeholder={
                  `Category's ${error.field.charAt(0).toUpperCase() + error.field.slice(1)} `} value={formData[error.field]} onChange={handleInputChange} /><br></br>
              {error.message && <span className="error-message pl-1">{error.message}</span>}
            </p>
          ))}

          <label className='input-label'>{t('categorySidebar.Sub category')}</label>
          <label className='input-label'>
            <input
              type="checkbox"
              className="subcategory-checkbox-"
              name="subcategory"
              checked={formData.subcategory === true}
              onChange={handleInputChange}
              value="true"
            />
          </label> {t('categorySidebar.yes')}

          <label className='input-label'>
            <input
              type="checkbox"
              className="subcategory-checkbox-c"
              name="subcategory"
              checked={formData.subcategory === false}
              onChange={handleInputChange}
              value="false" />
          </label> {t('categorySidebar.No')}
          <br />

          <label className='input-label'>{t('categorySidebar.Business enable')}</label>
          <input type='checkbox'
            checked={formData.isBusinessEnable}
            onChange={handleInputChange}
            name='isBusinessEnable'
            className='subcategory-checkbox ml-4'
          /> {t('categorySidebar.yes')}

          <input
            type='checkbox'
            checked={!formData.isBusinessEnable}
            onChange={handleInputChange}
            name='isBusinessEnable'
            className='subcategory-checkbox'
          />{t('categorySidebar.No')}
          <br />

          <label className='input-label'>{t('categorySidebar.Commerce enable')}</label>
          <input type='checkbox'
            checked={formData.isCommerceEnable}
            onChange={handleInputChange}
            name='isCommerceEnable'
            className='subcategory-checkbox-a'
          />{t('categorySidebar.yes')}

          <input type='checkbox'
            checked={!formData.isCommerceEnable}
            onChange={handleInputChange}
            name='isCommerceEnable'
            className='subcategory-checkbox'
          />{t('categorySidebar.No')}
        </div>
      </div>

      <p className='category-button'>
        <button className='features-saves-btn' onClick={handleSave} disabled={isSaving}>
          {isSaving ? t('intentCreate.Saving....') : t('product.Save')}
        </button>

        <button className='feature-cancel ml-2' onClick={handleCancel}>{t("product.Cancel")}</button>
      </p>
    </div>

  );
}
export default CategorySidebar;