import React, { useState } from 'react';
import './EmployeePopup.scss';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

const EmployeePopup = ({ onClose, userId, fetchEmployee, setIsDeleteConfirmationVisible }) => {
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const { t } = useLanguage();

  const handleDelete = async () => {
    if (userId && userId ?.length > 0 && !deleteInitiated) {
      try {
        setDeleteInitiated(true);

        const response = await fetch(`${API.apiGateway}/commerce-gw/member/${groupId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId }),
        });

        if (response.ok) {
          setDeleteInitiated(true)
          fetchEmployee()
          setIsDeleteConfirmationVisible()
          toast.success('Employees Deleted Successfully', {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            icon: (
              <i
                  className="fa-solid fa-trash float-right cis pr-2"
                  style={{ color: "white" }}
              ></i>
          ),
            progressClassName: "custom-progress",
          });
          onClose();
        } else {
          console.error('Failed to delete employees');
          toast.error('Failed to delete employees');
        }
      } catch (error) {
        console.error('Error deleting employees:', error);
        toast.error('Error deleting employees: ' + error.message);
      } finally {
        setDeleteInitiated(false);
      }
    } else if (deleteInitiated) {
      toast.warn('Delete operation already initiated');
    } else {
      console.error('Invalid employee userIds');
      toast.error('Invalid employee userIds');
    }
  };

  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className="popup-box-emp">
        <div className="emp-cust-box">
          <p className="txt-delete">{t("productdetails.Are you sure you want to delete these employees?")}</p>
          <button onClick={handleDelete} className="emp-delete" disabled={deleteInitiated}>
            {t(deleteInitiated ? 'Yes...' : 'productdetails.Yes')}
          </button>
          <button onClick={onClose} className="emp-nodelete">
            {t("productdetails.No")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeePopup;