import React, { useState } from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';

const Button = ({onClick}) => {
  const {t} = useLanguage()

  return (
    <div>
      <button className='view-button-3' onClick={onClick}><b>{t("quota.View")}</b></button>
    </div>
  )
}
export default Button;

export const ViewDetails = () => {
  return (
    <div>
      <Link to={`/member-details`} ><button className='btn-action'><b>View Details</b></button></Link>
    </div>
  )
}

export const PreviewButton = () => {
  return (
    <>
      <button className='feature-preview'>Full Preview</button>
    </>
  )
}

export const Remove = () => {
  return (
    <div>
      <button className='feature-remove-button'>Remove</button>
    </div>
  )
}
export const Filters = () => {
  return (
    <div>
      <button className='filter-button'>Filters</button>
    </div>
  )
}

export const Minterals = () => {
  return (
    <div>
      <button className='minteral-but-1 ml-4'>Minterals</button>
    </div>
  )
}
export const BuyNow = () => {
  return (
    <div>
      <button className='buy-button-1'><b>Buy Now</b></button>
    </div>
  )
}

export const ViewOrder = ({userId}) => {
  return (
    <div>
      <Link to={`/order/${userId}`}><button className='view-order-button mt-4'><b>View Order</b></button></Link>
    </div>
  )
}