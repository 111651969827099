import React, { useState, useEffect } from 'react';
import API from '../../services/Api';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguage } from "../../LanguageContext";
import ConfirmationPopup from '../order-sidebar/invoice-popup/ConfirmationPopup';
import STORAGE_KEYS from '../../utils/storageKey';
import "./UpdateQuotation.css"

const UpdateQuotation = ({ setSidebarOpen, fetchData, selectedQuotation, setSelectedQuotation }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [negotiatedPrice, setNegotioedPrice] = useState(null);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [actualQty, setActualQty] = useState('')
    const [pendingQty, setPendingQty] = useState('')
    const { t } = useLanguage();

    const handleCancelSidebar = () => {
        setSidebarOpen(false);
    };

    const updateQuotationData = async () => {
        if (!selectedQuotation || !selectedQuotation.products) {
            return;
        }

        const productsToUpdate = selectedQuotation.products.map(product => ({
            product: product?.product?.productcode,
            qty: product.qty,
            perUnit: product.perUnit,
            tax: parseInt(product.tax),
            image: imageUrl,
            negotiatedPrice: parseInt(product.negotiatedPrice),
            actualQty: product.actualQty
        }));
        try {
            setIsLoading(true);
            const response = await fetch(`${API.commerceAPI}/commerce/productsquotation/groupId/${groupId}/quotationId/${selectedQuotation?.quotationId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ products: productsToUpdate })
            });

            if (!response.ok) {
                throw new Error('Failed to update data');
            }

            fetchData();
            setIsLoading(false);
            toast.success("Quotation Updated Successfully!", {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
            });
        } catch (error) {
            setIsLoading(false);
            toast.error("Error Updating Quotation.");
        }
    };

    const sendInvoice = async () => {
        try {
            const response = await fetch(
                // `${API.sendInvoice}/whatsapp/send-message/${selectedQuotation?.supplier?.phoneNumber}?messageType=document`,
                `${API.sendInvoice}/whatsapp/send-message/${selectedQuotation?.supplier?.phoneNumber}?messageType=document`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        filename: 'invoiceBill',
                        document: `${API.pdfGenerator}/generate-pdf?groupId=${groupId}&quotationId=${selectedQuotation?.quotationId}&type=quotation`,
                        caption: `${selectedQuotation?.supplier?.phoneNumber}`,
                        groupId: parseInt(groupId),
                    }),
                }
            );
            if (response.ok) {
                toast.success('Invoice Sent Successfully!', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
            } else {
                throw new Error(`Failed to send invoice: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error sending invoice:', error.message);
            toast.error('Error sending invoice.');
        }
    };

    const handleConfirm = () => {
        sendInvoice();
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const handleUpdate = async () => {
        await updateQuotationData();
        setSidebarOpen(false);
    };

    const handleFieldChange = (index, field, value) => {
        const updatedProducts = [...selectedQuotation.products];
        updatedProducts[index][field] = value;

        setSelectedQuotation(prevData => ({
            ...prevData,
            products: updatedProducts
        }));
    };

    const handleQtyChange = (index, value) => {
        const updatedProducts = selectedQuotation.products.map((product, idx) => {
            if (idx === index) {
                return {
                    ...product,
                    qty: value
                };
            }
            return product;
        });
        setSelectedQuotation(prevData => ({
            ...prevData,
            products: updatedProducts
        }));
    };

    const handlePriceChange = (index, value) => {
        const updatedProducts = selectedQuotation.products.map((product, idx) => {
            if (idx === index) {
                return {
                    ...product,
                    negotiatedPrice: value
                };
            }
            return product;
        });
        setSelectedQuotation(prevData => ({
            ...prevData,
            products: updatedProducts
        }));
    };

    const handleTaxChange = (index, value) => handleFieldChange(index, 'tax', value);
    const handleUnitRateChange = (index, value) => handleFieldChange(index, 'perUnit', value);


    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const signedUrlResponse = await fetch(`${API.imageUpload}/doc-upload/signed-url?fileName=${file.name}&public=true&action=write`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fileName: file.name })
            });
            if (!signedUrlResponse.ok) {
                throw new Error("Failed to get signed URL");
            }
            const signedUrlData = await signedUrlResponse.json();
            const signedUrl = signedUrlData.url;
            const uploadResponse = await fetch(signedUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                },
            });
            if (uploadResponse.ok) {
                const uploadedUrl = signedUrl.split('?')[0];
                setImageUrl(uploadedUrl);
                toast.success("Image Uploaded Successfully!", {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                });
            } else {
                throw new Error("Failed to upload image.");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            toast.error("Error uploading image.");
        }
    };

    const handleViewImage = () => {
        if (imageUrl) {
            window.open(imageUrl, '_blank');
        }
    };

    useEffect(() => {
        if (selectedQuotation) {
            const image = selectedQuotation?.products?.[0]?.image;
            setImageUrl(image || null);
        }
    }, [selectedQuotation]);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ background: "#FF0000", color: "white" }}
            />

            <div className="quatation-sidebar-update">
                <div className="side-padding">
                    <div className=" pt-2">
                        <div className="row">
                            <div className="col-6 text-left lineHeight">
                                <p className="font900 font18">{t("quotation.qupdateQuotation")}</p>
                                <p className="font14">{t("quotation.qupdateQuotation")}</p>
                            </div>
                            <div className="col-6 text-right lineHeight">
                                <p className="font900 font18 me-3">{t("purchase.date")}</p>
                                <p className="font14 me-1">
                                    {selectedQuotation?.quotationDate &&
                                        new Date(selectedQuotation?.quotationDate).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })
                                    }
                                </p>
                            </div>
                            <hr />
                        </div>
                    </div>

                    <div className="sidebar-wrapper-quotation">
                        <div className="row paddingx10 pl-3">
                            <div className="col-6 p-0">
                                <div className="container_ mb-3">
                                    <p className="font14 black-color">{t("supplier.Supplier Name")}</p>
                                    <span className="font14 fontw-800"><b>{selectedQuotation?.supplier?.name}</b></span>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("Employee.Email")}</p>
                                    <p className="font14 fontw-800"><b>{selectedQuotation?.supplier?.email}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("sidebarcustomers.Phone Number")}</p>
                                    <p className="font14 fontw-800"><b>{selectedQuotation?.supplier?.phoneNumber}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("AddSupplier.GST No")}</p>
                                    <p className="font14 fontw-800"><b>{selectedQuotation?.supplier?.gstNo || "--"}</b></p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("po.accDetails")}</p>
                                    <p className="font14">
                                        {t("Payment.Bank")}: &nbsp;<b>{selectedQuotation?.supplier.accountDetails?.length > 0 ? selectedQuotation?.supplier.accountDetails[0].accountDetails.bank : "--"}</b><br /><br />
                                        {t("po.branch")}: &nbsp;<b>{selectedQuotation?.supplier.accountDetails?.length > 0 ? selectedQuotation?.supplier.accountDetails[0].accountDetails.branch : "--"}</b><br /><br />
                                        {t("po.AccNo")}: &nbsp;<b>{selectedQuotation?.supplier.accountDetails?.length > 0 ? selectedQuotation?.supplier.accountDetails[0].accountDetails.accountNo : "--"}</b><br /><br />
                                        IFSC: &nbsp;<b>{selectedQuotation?.supplier.accountDetails?.length > 0 ? selectedQuotation?.supplier.accountDetails[0].accountDetails.ifsc : "--"}</b><br />
                                    </p>
                                </div>
                            </div>

                            <div className="col-6 p-0">
                                <div className="line-height">
                                    <p className="font14 black-color">{t("supplier.Address")}</p>
                                    {selectedQuotation?.supplier.addresses.map((address, index) => (
                                        <div key={index} className="font14 fontw-800">
                                            <span>{address.locality ? <p>&nbsp;{address.locality}</p> : null}</span>
                                            <p>&nbsp;{address.city}</p>
                                            <p>&nbsp;{address.stateName}</p>
                                            <p>&nbsp;{address.zip}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowX: "4000px" }}>
                            <table className="table">
                                <thead>
                                    <tr className="font13">
                                        <th className='quotation-text' style={{ width: 80 }}>{t("neworder.Sr.No")}</th>
                                        <th className='quotation-text' style={{ width: 180 }}>{t("intentCreate.Product Name")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("HSN")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("intentCreate.Qty")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("neworder.AQty")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("neworder.PQty")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("po.unitRate")}</th>
                                        <th className='quotation-text' style={{ width: 180 }}>{t("po.Total Amount")}</th>
                                        <th className='quotation-text' style={{ width: 130 }}>{t("neworder.tax")}</th>
                                        <th className='quotation-text' style={{ width: 180 }}>{t("quotation.taxAmount")}</th>
                                        <th className='quotation-text' style={{ width: 180 }}>{t("neworder.total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedQuotation?.products.map((product, index) => (
                                        <tr key={index} className="font14">
                                            <td>{index + 1}</td>
                                            <td>{product?.product?.name || "--"}</td>
                                            <td>{product.product.hsnNo || "--"}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="table-input"
                                                    value={product.qty || ""}
                                                    onChange={(e) => handleQtyChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td >{product?.actualQty || "--"}</td>
                                            <td>{product?.pendingQty || "--"}</td>
                                            <td><i className="fa-solid fa-indian-rupee-sign"></i>{product.perUnit || ""}</td>
                                            <td><i className="fa-solid fa-indian-rupee-sign"></i>{product.totalAmount || "--"}</td>
                                            <td><i className="fa-solid fa-indian-rupee-sign"></i>{product.tax}</td>
                                            <td><i className="fa-solid fa-indian-rupee-sign"></i>{product?.taxAmount}</td>
                                            <td><i className="fa-solid fa-indian-rupee-sign"></i>{product.totalPriceExcludingTax || "--"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>


                        <div className="row total_a_sec mt-4">
                            <div className="col-6 col-md-6">
                                <div>
                                    <label>{t("po.totalPoAmt")}</label>
                                </div>
                                <div>
                                    <label>{t("po.totalTaxAmount")}</label>
                                </div>
                                <div>
                                    <label>{t("po.totalPriceET")}</label>
                                </div>
                                <div>
                                    <label>{t("po.Negotiated Price")}</label>
                                </div>
                            </div>

                            <div className="'col-6 col-md-6">
                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{selectedQuotation?.totalProductAmount}</b>
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{selectedQuotation?.totalTaxAmount}</b>
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        <i className="fa-solid fa-indian-rupee-sign"></i> <b>{selectedQuotation?.totalProductPriceExcludingTax}</b>
                                    </label>
                                </div>

                                <div>
                                    <input
                                        type="number"
                                        min="0"
                                        className='price-input'
                                        value={selectedQuotation?.products[0]?.negotiatedPrice || ""}
                                        onChange={(e) => handlePriceChange(0, e.target.value)}
                                    />
                                    <label htmlFor="imageUpload" className="upload-arrow">
                                        <i className="fa-solid fa-upload"></i>
                                    </label>
                                    <div>
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                        {imageUrl && <button className="view-button-q" onClick={handleViewImage}>{t('quota.View')}</button>}
                                    </div>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                    </div>

                    <div className="d-flex mt-1 footer-btn-container">
                        <div className="footer-btn">
                            <button className="saveDraft-btn mx-0 mb-2" disabled={isLoading} onClick={handleUpdate}>
                                {isLoading ? (
                                    t("customer.Updating..")
                                ) : (
                                    t("product.Update")
                                )}
                            </button>

                            <button className="saveDraft-btn mx-0 mx-2 mb-2" onClick={() => setShowConfirmation(true)}>{t("quotation.send")}</button>
                            <button className="cancel-qu" onClick={handleCancelSidebar}>{t("button.cancel")}</button>
                        </div>
                    </div>
                    {showConfirmation && (
                        <ConfirmationPopup onConfirm={handleConfirm} onCancel={handleCancel} />
                    )}
                </div>
            </div>
        </>
    )
}
export default UpdateQuotation;