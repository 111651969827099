import React, { useState, useEffect} from "react";
import "./PaymentSidebar.scss";
import API from "../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import STORAGE_KEYS from "../../utils/storageKey";
import { useLanguage } from "../../LanguageContext";

const PaymentSidebar = ({
  data, orderDetails, orderNumber, addresses, status, driverName, vehicleNo, driverNo, location, name,
  contactPerson, trackingNo, trackingURL, selectedDepartment: selectedDepartment, onClose, dispatchNo, eWayBillNo, refDate, refNo,
  assign, transporter, setOpenPaymentSidebar, setOpenNewSidebar, fetchData, setIsSaveClicked, selectedWarehouseId, latitude, longitude,
}) => {
  console.log(latitude, longitude, "jnehfiefneq");

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLanguage()
  const [upiId, setUpiId] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [dataOpen, setDataOpen] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const userId = localStorage.getItem(STORAGE_KEYS.USERID);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimer, setPopupTimer] = useState(10);
  const [updatedTransactionId, setUpdatedTransactionId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState();
  const [localDeduction, setLocalDeduction] = useState(data?.totalCartPrice || "");
  const [anotherApiIntervalId, setAnotherApiIntervalId] = useState();
  const [transactionId] = useState(null);
  const [paymentStatusError, setPaymentStatusError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null)
  const [bankName, setBankName] = useState();
  const [chequeNo, setChequeNo] = useState();
  const [chequeAmount, setChequeAmount] = useState();

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMode(event.target.value);
    setSelectedPaymentMethod(event.target.value);
    setPaymentMethodError("");
  };

  const remainingAmount = selectedPaymentMode === 'Pay by Credit' ? data.totalCartPrice : (selectedPaymentMode === "Pay by Cheque" ? localDeduction : data.totalCartPrice - localDeduction); const handleCancelClick = () => {
    setDataOpen(false);
    setIsSaveClicked(false)
  };

  let totalQuantity = 0;
  if (Array.isArray(data?.products)) {
    totalQuantity = data?.products.reduce(
      (sum, product) => sum + product?.quantity,
      0
    );
  } else {
    console.error('Data does not have a "products" array:', data);
  }

  let serialNumber = 1;
  const currentINDate = new Date();
  const year = currentINDate.getFullYear();
  const month = String(currentINDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentINDate.getDate()).padStart(2, "0");
  const formattedSerialNumber = String(serialNumber).padStart(4, "0");
  let invoiceNumber = `${day}${month}${year}`;
  let invoiceDate = `${day}/${month}/${year}`;
  const invoiceNo = `${invoiceNumber}-${formattedSerialNumber}`;
  serialNumber++;

  const generateTransportId = () => {
    return Math.floor(Math.random() * 900000) + 100000;
  }
  let transportId = generateTransportId();

  const handleCreateOrder = async () => {
    setIsLoading(true);
    let paymentModeError = "";
    let paymentStatusError = "";
    let dateError = "";
    let imageError = "";
    if (!selectedPaymentMode) {
      paymentModeError = "Please select a payment mode";
    }
    if (!paymentStatus) {
      paymentStatusError = "Please select a payment status";
    }
    if (selectedPaymentMethod === "Pay by Credit" && !selectedDate) {
      dateError = "Please select a due date";
    }
    if (selectedPaymentMethod === "Pay by Cheque" && !selectDate) {
      dateError = "Please select a cheque date";
    } if (selectedPaymentMethod === "Pay by Online" && !selectedImage) {
      imageError = "Please add a screen shot";
    }
    setPaymentModeError(paymentModeError);
    setPaymentStatusError(paymentStatusError);
    setDateError(dateError);
    setImageError(imageError);

    if (paymentModeError || paymentStatusError || dateError) {
      setIsLoading(false);
      return;
    }
    const tagsToCheck = ["इतर", "घर", "ऑफिस"];
    const getAddressByTag = (addresses, tagsToCheck) => {
      let matchingAddress = null;
      for (const tag of tagsToCheck) {
        matchingAddress = addresses.find(
          (address) =>
            address &&
            address.address &&
            address.address.tag &&
            address.address.tag.toLowerCase() === tag.toLowerCase()
        );
        if (matchingAddress) {
          break;
        }
      }
      return matchingAddress ? matchingAddress.address : null;
    };

    const shippingAddress = getAddressByTag(addresses, tagsToCheck);
    const billingAddress = getAddressByTag(addresses, tagsToCheck);
    const formattedDate = new Date(refDate).toLocaleDateString("en-GB");
    try {
      const payload = {
        groupId: parseInt(groupId),
        wareHouseId: parseInt(selectedWarehouseId),
        userId: data?.userId,
        latitude,
        longitude,
        orderDetails: data?.products.map((product) => ({
          product: {
            productcode: product?.productcode?.productcode,
            name: product?.productcode?.name,
            buyingPrice: product?.productcode?.buyingPrice,
            memberPrice: product?.productcode?.memberPrice,
            regularPrice: product?.productcode?.regularPrice,
            marketPrice: product?.productcode?.marketPrice,
            tax: product?.productcode?.tax,
            hsnNo: product?.productcode?.hsnNo,
            discount: product?.productcode?.discount,
            skuNumber: product?.productcode?.skuNumber
          },
          quantity: product?.quantity,
          totalProductPrice: product?.totalProductPrice,
        })),
        subtotal: data?.subtotal,
        taxes: data?.taxes,
        saving: data?.saving != null ? data.saving : 0,
        deduction: data?.deduction,
        totalProductQuantity: data?.products.reduce(
          (total, product) => total + (product?.quantity || 0),
          0
        ),
        totalCartPrice: data?.totalCartPrice,
        currency: data?.currency,
        delivery_info: {
          shipping_address: {
            tag: shippingAddress?.tag,
            locality: shippingAddress?.locality,
            city: shippingAddress?.city,
            state: shippingAddress?.state,
            zip: shippingAddress?.zip,
          },
          billing_address: {
            tag: billingAddress?.tag,
            locality: billingAddress?.locality,
            city: billingAddress?.city,
            state: billingAddress?.state,
            zip: billingAddress?.zip,
          },
        },

        paymentInfo: {
          mode: selectedPaymentMode,
          paymentStatus: paymentStatus,
          paymentDateTime: currentDate.toLocaleString(),
          loggedInUser: parseInt(userId),
        },

        shippingDetails: {
          driverName: driverName,
          vehicleNo: vehicleNo,
          driverNo: driverNo,
          location: location,
          name: name,
          dispatchNo: dispatchNo,
          eWayBillNo: eWayBillNo,
          contactPerson: contactPerson,
          trackingNo: trackingNo,
          trackingURL: trackingURL,
          transporter: transporter,
        },

        lat: data?.latitude,
        lon: data?.longitude,
        source: "Web",
        TotalCardPrice: data?.totalCartPrice,
        tokenAmount: selectedPaymentMode === "Pay by Cheque" ? 0 : (selectedPaymentMode === "Pay by Credit" ? 0 : parseInt(localDeduction)),
        RemainingAmount: remainingAmount,
        status: status,
        refDate: refDate,
        refNo: refNo,
        department: selectedDepartment,
        handledBy: assign,
        transportId: transportId,
      };

      switch (selectedPaymentMode) {
        case "Pay by Cash":
          break;
        case "Pay by Online":
          payload.paymentInfo.OnlineTxnId = selectedPaymentMethod === "Pay by Online" ? onlineTxnIds : null;
          payload.paymentInfo.pictures = picturesUrls;
          break;
        case "Pay by Credit":
          payload.paymentInfo.creditPaymentDate = selectedDate ? selectedDate.toLocaleDateString("en-GB") : null;
          break;
        case "Pay by Cheque":
          payload.paymentInfo.bankName = bankName;
          payload.paymentInfo.chequeNo = chequeNo;
          payload.paymentInfo.chequeAmount = chequeAmount;
          payload.paymentInfo.chequeDate = selectDate ? selectDate.toLocaleDateString("en-GB") : null;
          break;
        case "Pay using UPI":
          payload.paymentInfo.upi = upiId;
          payload.paymentInfo.txnId = updatedTransactionId;
          break;
        default:
          break;
      }
      







      const response = await fetch(
        `${API.apiGateway}/commerce-gw/products/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
        
      );

      if (response.ok) {
        toast.success("Order Created Successfully !", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });

        setOpenPaymentSidebar(false);
        setOpenNewSidebar(false)
        fetchData()
      } else {

        if (response.status === 400) {
          setOpenNewSidebar(false)

          try {
            const errorMessage = await response.json();
            toast.error(errorMessage.message, {
              style: {
                fontSize: "14px",
                color: "white",
                background: "#FF0000",
                height: "50px",
              },
              progressClassName: "custom-progress"
            });
          } catch (jsonError) {
            console.log("An error occurred while processing your request.");
          }
        } else {
          console.log("An error occurred while processing your request.");
        }
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectDate(date);
  };

  const handleViewImage = (imageUrl) => {
    window.open(imageUrl);
  };

  const handleQRCode = async () => {
    try {
      const transactionReference = "service-request-id1";
      const transactionNote = "Mahindra Tractor";
      const amountInput = document.getElementById("amountInput");
      const amount = amountInput.value;
      if (!amount) {
        alert("Please enter an amount.");
        return;
      }
      const apiUrl = `${API.paymentAPI
        }/payment/qrcode/upi?transactionReference=${transactionReference}&transactionNote=${encodeURIComponent()}&amount=${amount}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const qrCodeImageData = await response.blob();
        const qrCodeImageUrl = URL.createObjectURL(qrCodeImageData);
        document.getElementById("qrCodeImage").src = qrCodeImageUrl;
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const handleUPIChange = (e) => {
    setUpiId(e.target.value);
    setVerificationStatus(null);
  };

  const verifyUPI = async () => {
    try {
      const response = await fetch(
        `${API.paymentAPI}/payment/v1/api/vpa/check`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ vpaName: upiId }),
        }
      );

      if (response.ok) {
        setVerificationStatus("valid");
      } else {
        setVerificationStatus("invalid");
      }
    } catch (error) {
      setVerificationStatus("invalid");
    }
  };
  const handleRequestPayment = async () => {
    try {
      const response = await fetch(
        `${API.paymentAPI}/payment/v1/api/vpa/collect`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: orderDetails?.orderId,
            groupId: parseInt(groupId),
            payeeVpa: upiId,
            description: "Testing",
            amount: 2,
          }),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setShowPopup(true);
        const transactionId = responseData?.data?.id;
        const intervalId = setInterval(async () => {
          try {
            const statusResponse = await fetchTransactionStatus(transactionId);
            if (statusResponse?.data?.status === "success") {
              clearInterval(intervalId);
            } else {
              console.error("Transaction Status not success:", statusResponse);
            }
          } catch (error) {
            console.error("Error fetching transaction status:", error);
          } finally {
            clearInterval(intervalId);
          }
        }, 5000);
        setAnotherApiIntervalId(transactionId);
      } else {
        console.error("TransactionId not available in the response:");
      }
    } catch (error) {
      console.error("Error requesting payment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransactionStatus = async (transactionId) => {
    try {
      const response = await fetch(
        `${API.paymentAPI}/payment/v1/api/vpa/fetch/transaction/status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: transactionId }),
        }
      );

      if (response.ok) {
        const transactionSuccess = await response.json();
        const txnId = transactionSuccess?.data?.txnId;
        setUpdatedTransactionId(txnId);
      } else {
        console.error("Failed to fetch transaction status:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  };

  const handleInputChange = (e) => {
    const inputVal = e.target.value;
    if (selectedPaymentMethod === "Pay by Cash" && inputVal <= data.totalCartPrice) {
      setLocalDeduction(inputVal);
    } else {
      console.log("Invalid deduction amount!");
    } if (selectedPaymentMethod === "Pay by Online" && inputVal <= data.totalCartPrice) {
      setLocalDeduction(inputVal);
    } else {
      console.log("Invalid deduction amount!");
    }
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
    setPaymentStatusError("");
  };

  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = currentDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  useEffect(() => {
    let intervalId;
    const checkTransactionStatus = async () => {
      try {
        const statusResponse = await fetchTransactionStatus(transactionId);
        if (statusResponse?.data?.status === "success") {
          setShowPopup(false);
          setPopupTimer(10);
          clearInterval(intervalId);
        } else {
          console.error("Transaction Status not success:", statusResponse);
        }
      } catch (error) {
        console.error("Error fetching transaction status:", error);
      }
    };

    if (showPopup) {
      intervalId = setInterval(() => {
        setPopupTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          if (newTimer === 0) {
            setShowPopup(false);
            setPopupTimer(10);
            clearInterval(intervalId);
          } else if (newTimer % 5 === 0) {
            checkTransactionStatus();
          }
          return newTimer;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [showPopup]);

  useEffect(() => {
    return () => {
      clearInterval(anotherApiIntervalId);
    };
  }, [anotherApiIntervalId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleImageSelect = async (index, file) => {
    if (file.size > 1024 * 1024) {
      toast.error("File size exceeds 1MB. Please upload a smaller image.", {
        style: {
          background: 'red',
        },
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await fetch(`${API.imageUpload}/doc-upload/signed-url?fileName=${file.name}&public=true&action=write`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const responseData = await response.json();
        const signedUrl = responseData.url;
        const uploadResponse = await fetch(signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
        if (uploadResponse.ok) {
          const uploadedUrl = new URL(uploadResponse.url);
          const finalUrl = `${uploadedUrl.protocol}//${uploadedUrl.host}${uploadedUrl.pathname}`;
          const updatedUrls = [...picturesUrls];
          updatedUrls[index] = finalUrl;
          setPicturesUrls(updatedUrls);
          toast.success("Image Uploaded Successfully!", {
            style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
          });
        } else {
          throw new Error("Failed to upload image.");
        }
      } else {
        throw new Error("Failed to get signed URL");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image.", {
        style: {
          background: 'red',
        },
      });
    }
  };

  const handleAddButtonClick = () => {
    setOnlineTxnIds(prevTxnIds => [...prevTxnIds, '']);
    setPicturesUrls(prevUrls => [...prevUrls, '']);
    setOnlineTxnIds([...onlineTxnIds, '']);
  };
  const [onlineTxnIds, setOnlineTxnIds] = useState(['']);
  const [picturesUrls, setPicturesUrls] = useState([]);
  const handleTxnIdChange = (index, value) => {
    const updatedTxnIds = [...onlineTxnIds];
    updatedTxnIds[index] = value;
    setOnlineTxnIds(updatedTxnIds);
  };

  return (
    <>
      {dataOpen ? (
        <div className="payment-sidebar">
          <div className="row container-fluid">
            <div className="col-8 lineHeight10">
              <p className="fontw-800">{orderNumber}</p>
              <p className="font14">
              {t("neworder.items")}: {totalQuantity} {t("table.Amount")}:
                {`Rs. ${data?.totalCartPrice || 0}`}
              </p>
            </div>
            {showPopup && (
              <>
                <div className="payment-popup">
                  <div className="payment-box">
                    <p className="text-delete">
                      Transaction successful! Closing in {popupTimer} seconds...
                    </p>
                    <button onClick={onClose} className="ware-nodelete">
                      No
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="col-lg-4 col-md-4 col-sm-4 ">
              <div className="payment-date-time font14 lineHeight10">
                <p>{t("table.date & time")}</p>
                <p>
                  {formattedDate} - {formattedTime}
                </p>
              </div>
            </div>
            <hr />
            <div className="row pt-2">
              <p className="fontw-800 margin1 font14">{t("sidebarcustomers.Payment Method")} <span className="text-danger font14">*</span></p>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="payByCash"
                  name="payment-method"
                  value="Pay by Cash"
                  checked={selectedPaymentMethod === "Pay by Cash"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payByCash" className="ml-2 payment-details-option font14">
                  {t("order.Cash")}
                </label>
              </div>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="scanQRCode"
                  name="payment-method"
                  value="Scan QR Code"
                  checked={selectedPaymentMethod === "Scan QR Code"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="scanQRCode"
                  className="ml-2 payment-details-option font14">
                  {t("order.Scan QR")}
                </label>
              </div>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="payUsingUPI"
                  name="payment-method"
                  value="Pay using UPI"
                  checked={selectedPaymentMethod === "Pay using UPI"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payUsingUPI"
                  className="ml-1 payment-details-option font14 upi">
                  {t("UPI")}
                </label>
              </div>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="Paybycredit"
                  name="payment-method"
                  value="Pay by Credit"
                  checked={selectedPaymentMethod === "Pay by Credit"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="Paybycredit"
                  className="ml-2 payment-details-option font14">
                  {t('table.Credit')}
                </label>
              </div>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="paybyonline"
                  name="payment-method"
                  value="Pay by Online"
                  checked={selectedPaymentMethod === "Pay by Online"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="paybyonline"
                  className="ml-2 payment-details-option font14">
                  {t("order.Online")}
                </label>
              </div>
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="payByCheque"
                  name="payment-method"
                  value="Pay by Cheque"
                  checked={selectedPaymentMethod === "Pay by Cheque"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payByCheque"
                  className="ml-2 mt-1 payment-details-option font14">
                  {t("order.Cheque")}
                </label>
              </div>
            </div>
            {selectedPaymentMethod === "Pay by Cash" && (
              <>
                <input
                  type="text"
                  id="deduction"
                  placeholder={t("order.Dedu")}
                  value={localDeduction}
                  onChange={handleInputChange}
                  className="small-input-btn"
                  min="0"
                />
              </>
            )}
            {paymentModeError && <div className="error text-danger font14">{paymentModeError}</div>}
            {selectedPaymentMethod === "Pay using UPI" && (
              <div className="row font14 pt-3">
                <div className="col-lg-5 col-md-5 col-sm-5">
                  <input
                    type="text"
                    className={`pl-2 font14 add-customer-inputs1 ${verificationStatus === "invalid" ? "is-invalid" : ""
                      }`}
                    placeholder="Enter UPI"
                    onChange={handleUPIChange}
                  />
                  {verificationStatus === "invalid" && (
                    <div className="invalid-feedback">Invalid UPI</div>
                  )}
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="verify-button-pay "
                    onClick={verifyUPI}>
                    {t("order.Verify")}
                  </button>
                </div>
                {verificationStatus === "valid" && (
                  <div className="popup-message">
                    <p>Verification successful!</p>
                  </div>
                )}
                {verificationStatus === "valid" && (
                  <div className="col-lg-5 col-md-5 col-sm-5">
                    <button
                      type="button"
                      className="request"
                      onClick={handleRequestPayment}>
                      {t("order.Request Payment")}
                    </button>
                  </div>
                )}
              </div>
            )}
            {selectedPaymentMethod === "Scan QR Code" && (
              <>
                <div>
                  <input
                    type="text"
                    placeholder={data?.subtotal || 0}
                    value={localDeduction}
                    className="small-input-btn-qr "
                    id="amountInput"
                  />
                  <button
                    onClick={handleQRCode}
                    className="generate-qr font-14">
                    {t("order.Generate QR")}
                  </button>
                </div>
                <img id="qrCodeImage" className="QR-image mt-3" />
              </>
            )}
            {selectedPaymentMethod === "Pay by Credit" && (
              <>
                <div>
                  <label className="font14">{t("order.Due Date")}</label><br />
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => handleDateChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                    dateFormat="dd/MM/yyyy"
                    className="small-input-date"
                    placeholderText="dd-mm-yyyy"
                    minDate={new Date()}
                  />
                  {dateError && <div className="error text-danger font14">{dateError}</div>}
                </div>
              </>
            )}
            {selectedPaymentMethod === "Pay by Online" && (
              <div className="row pt-2">
                <div className="col-12 p-0">
                  <input
                    type="number"
                    id="deduction"
                    placeholder={t("order.Enter Amount")}
                    value={localDeduction}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const numericValue = enteredValue.replace(/[^0-9]/g, '');
                      setLocalDeduction(numericValue);
                    }}
                    className="small-input-btn"
                    min="0"
                  />
                  {onlineTxnIds.map((txnId, index) => (
                    <div key={index} className="mb-2">
                      <input
                        type="text"
                        className="small-input-btn"
                        placeholder={t("order.TrID")}
                        value={txnId}
                        onChange={(e) => handleTxnIdChange(index, e.target.value)}
                      />
                      <label>
                        <i className="fa-solid fa-upload cursor-pointer ml-2"></i>
                        <input
                          type="file"
                          onChange={(e) => handleImageSelect(index, e.target.files[0])}
                          accept="image/png, image/jpeg, image/jpg"
                          className="upload-doc "
                        />
                      </label>
                      {picturesUrls[index] && (
                        <button className="view-button ml-2" onClick={() => handleViewImage(picturesUrls[index])}>
                          {t("quota.View")}
                        </button>
                      )}
                      {index === onlineTxnIds?.length - 1 && picturesUrls[index] && (
                        <button className="view-button ml-2" onClick={handleAddButtonClick}>
                          {t("neworder.add")}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedPaymentMethod === "Pay by Cheque" && (
              <>
                <div className="row pt-2">
                  <div className="col-3 p-0">
                    <input
                      type="text"
                      className="small-input-btn"
                      placeholder={t("SupplierBank.Bank Name")}
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <input
                      type="text"
                      className="small-input-btn"
                      placeholder={t("order.Cheque Number")}
                      value={chequeNo}
                      onChange={(e) => setChequeNo(e.target.value)}
                    />
                  </div>
                  <div className="col-3 mt-1">
                    <input
                      type="number"
                      className="small-input-btn1"
                      placeholder={t("table.Amount")}
                      value={chequeAmount}
                      onChange={(e) => setChequeAmount(e.target.value)}
                      min="0"
                    />
                    <DatePicker
                      selected={selectDate}
                      onChange={(date) => handleDateChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                      dateFormat="dd/MM/yyyy"
                      className="small-input-date1 mt-1"
                      placeholderText="dd-mm-yyyy"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="col-6"></div>
                </div>
              </>
            )}
            <p className="font14 fontw-800 margin1  ">{t("table.Payment Status")} <span className="text-danger font14">*</span></p>
            <select
              className="small-input-btn font12"
              id="metric"
              name="metric"
              value={paymentStatus}
              onChange={handlePaymentStatusChange}>
              <option value="">{t("order.status")}</option>
              <option value="paid" disabled={selectedPaymentMethod === "Pay by Credit" || selectedPaymentMethod === "Pay by Cheque"}>
                Paid
              </option>
              <option
                value="partially paid"
                disabled={selectedPaymentMethod === "Pay by Credit" || selectedPaymentMethod === "Pay by Cheque"}
              >
                Partially Paid
              </option>
              <option value="credit" disabled={selectedPaymentMethod !== "Pay by Credit" && selectedPaymentMethod !== "Pay by Cheque"}>
                Credit
              </option>
            </select>
            {paymentStatusError && <div className="error text-danger font14">{paymentStatusError}</div>}
            <div>
              <span>
                <button
                  type="button"
                  className="Create-Order font14"
                  onClick={handleCreateOrder}
                  disabled={
                    !selectedPaymentMethod ||
                    !paymentStatus ||
                    !localDeduction ||
                    (
                      (selectedPaymentMethod === "Pay using UPI" && !verificationStatus) ||
                      (selectedPaymentMethod === "Pay by Online" && (onlineTxnIds.some(txnId => !txnId) || picturesUrls?.length === 0))
                    )
                  }>
                  {isLoading ? (
                    <span>
                      <i className="spinner-border spinner-border-sm text-dark spin"></i>
                    </span>
                  ) : (
                    t("product.Save")
                  )}
                </button>
                <button
                  type="button"
                  className="verify-btn close-btn font14"
                  onClick={handleCancelClick}>
                  {t("order.Close")}
                </button>
              </span>
            </div>
            <div>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ background: "#FF0000", color: "white" }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default PaymentSidebar;