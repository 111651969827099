import React, { useState, useEffect } from 'react';
import "./WarehouseSidebar.scss";
import { useLanguage } from '../../LanguageContext';
import Avatar from '../avatar/Avatar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';
import { fetchManager } from '../../utils/Helper';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';

const WarehouseSidebar = ({ handleClose, fetchWareHouse }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingManager, setIsLoadingManager] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const { t } = useLanguage();
  const { empId, phoneNumber } = useParams()
  const [loading, setLoading] = useState(false);
  const [areInputsFilled, setAreInputsFilled] = useState(false);
  const [pinCodeError, setPinCodeError] = useState('');
  const [apiCallMade, setApiCallMade] = useState(false);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [selectedManagers, setSelectedManagers] = useState(null);
  const [managerError, setManagerError] = useState('');
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
    if (name === 'latitude' || name === 'longitude') {
    }
    setWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      [name]: parsedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      locationName: '',
    }));

    const isWareHouseNamefilled = name === 'WareHouseName' ? value.trim() !== '' : warehouse?.WareHouseName?.trim() !== '';
    const isWareHouseManagerfilled = name === 'WareHouseManager' ? value.trim() !== '' : warehouse?.name?.trim() !== '';
    const isCapacityFilled = name === 'capacity' ? value.trim() !== '' : warehouse?.capacity?.trim() !== '';
    const isLocationFilled = name === 'locationName' ? value.trim() !== '' : warehouse?.locationName?.trim() !== '';
    const isPinCodeFilled = name === 'pinCode' ? value?.length > 0 : warehouse?.pinCode?.length > 0;
    setAreInputsFilled(isWareHouseNamefilled && isWareHouseManagerfilled && isCapacityFilled && isLocationFilled && isPinCodeFilled);

    if (name === 'locationName') {
      console.log("Fetching coordinates for locationName:", value);
    }

    if (name === 'pinCode') {
      if (/^\d{6}$/.test(value)) {
        setPinCodeError('');
      }
    }
  };

  const [errors, setErrors] = useState({
    name: '',
    WareHouseName: '',
    capacity: '',
    locationName: '',
    pinCode: '',
  });

  const [warehouse, setWarehouse] = useState({
    WareHouseName: "",
    capacity: "",
    locationName: "",
    groupId: groupId,
    pinCode: "",
    manager: [
      {
        name: "",
        id: empId || "",
        phoneNumber: phoneNumber || "",
      }
    ]
  });

  const clearInputs = () => {
    setWarehouse({
      name: '',
      WareHouseName: '',
      capacity: '',
      locationName: '',
      latitude: '',
      longitude: '',
      pinCode: '',
    });
    setErrors({
      name: '',
      WareHouseName: '',
      capacity: '',
      locationName: '',
      latitude: '',
      longitude: '',
      pinCode: '',
    });
  };

  const validatePinCode = (pinCode) => {
    return /^\d{6}$/.test(pinCode);
  };

  const showToast = (message) => {
    if (!toastDisplayed) {
      toast.success(message, {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        toastId: 'my-toast'
      });
      setToastDisplayed(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    const latitude = parseFloat(warehouse.latitude);
    const longitude = parseFloat(warehouse.longitude);
    const location = {
      coordinates: [latitude, longitude],
      type: "Point"
    };
    const manager = [
      {
        name: selectedManagers?.name || "",
        id: empId || selectedManagers?.empId,
        phoneNumber: phoneNumber || selectedManagers?.phoneNumber
      }
    ];

    if (!warehouse?.WareHouseName?.trim()) {
      newErrors.WareHouseName = 'Enter Warehouse Name*';
    }

    if (!selectedManagers?.name?.trim()) {
      newErrors.name = 'Please select the manager.';
    }

    if (!warehouse?.capacity?.trim()) {
      newErrors.capacity = 'Enter Warehouse capacity*';
    }

    if (!warehouse?.locationName?.trim()) {
      newErrors.locationName = 'Enter Warehouse location*';
    }

    if (!validatePinCode(warehouse.pinCode)) {
      newErrors.pinCode = 'PIN code must be 6 digits.';
    }
    setErrors(newErrors);

    if (!warehouse.latitude || !warehouse.latitude.toString().trim()) {
      newErrors.latitude = 'Enter Warehouse latitude*';
    } else if (!/^[-+]?(\d+(\.\d+)?|90(\.0+)?)$/.test(warehouse.latitude.toString().trim()) && warehouse.latitude !== "0") {
      newErrors.latitude = 'Invalid Latitude';
    }

    if (!warehouse.longitude || !warehouse.longitude.toString().trim()) {
      newErrors.longitude = 'Enter Warehouse Longitude*';
    } else if (!/^[-+]?(180(\.\d+)?|\d{1,2}(\.\d+)?)$/.test(warehouse.longitude.toString().trim()) && warehouse.longitude !== "0") {
      newErrors.longitude = 'Invalid Longitude';
    }

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    if (apiCallMade) {
      return;
    }
    setIsSaving(true);
    setLoading(true);

    try {
      const { latitude, longitude, ...warehouseWithoutLatLng } = warehouse;
      const response = await fetch(`${API.commerceAPI}/commerce/warehouse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...warehouseWithoutLatLng,
          manager,
          location: {
            coordinates: [latitude, longitude],
            type: "Point"
          }
        }),
      });

      if (response.ok) {
        setWarehouse({
          ...warehouse,
        })
        const responseData = await response.json();
        showToast('Warehouse created successfully!', 'success',)
        setTimeout(() => {
          fetchWareHouse('')
        }, 3000);
      } else {
        console.error("Failed to submit warehouse data:", response.statusText);
        showToast('Failed to create warehouse', {});
      }
    } catch (error) {
      console.error("Error submitting warehouse data:", error);
      showToast('An error occurred while saving customer', {});
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    clearInputs();
    setErrors({
      name: '',
      WareHouseName: '',
      capacity: '',
      locationName: '',
      latitude: '',
      longitude: '',
      pinCode: '',
    });
  };

  function changeProfileURL(url) {
    setWarehouse({ ...warehouse, pictures: url });
  }

  useEffect(() => {
    if (toastDisplayed) {
      handleClose();
    }
  }, [toastDisplayed, handleClose]);

  const handleManagerSelection = (selected) => {
    setSelectedManagers(selected[0] || {});
  };

  const handleManagerSearch = async (query) => {
    setIsLoadingManager(true);
    try {
      const data = await fetchManager(query);
      setManagerOptions(data?.data?.items || []);
    } catch (error) {
      console.error('Error fetching manager data:', error);
      setManagerOptions([]);
    }
    setIsLoadingManager(false);
    setManagerError('');
  };

  return (
    <>
      <div className='warehouse-padding'>
        <div className='warehouse-sidebar'>
          <form onSubmit={handleSubmit}>
            <div className="col-12">
              <div className="d-inline-flex pt-4">
                <h6 className="pl-3 ware-name">
                  <b>{t('warehouseSidebar.Add Warehouse')} </b>
                </h6>
                <div className='close-icon' onClick={handleClose}>
                  <i className="fa-solid fa-circle-xmark"></i>
                </div>
              </div>
              <hr className=" hr-wareside" />

              <div className=' mt-2 scrollbar-warehouse'>
                <div>
                  <p className="warehouse-line pl-3">
                  </p>

                  <div className="col-lg-2 ml-1">
                    <div className='avatar-image ml-5'>
                      {areInputsFilled && (
                        <Avatar warehouse={warehouse} onUploadSuccess={changeProfileURL}> </Avatar>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ware-info">
                  <p className="">
                    <h6 className='ware-text pt-2'>
                      {t("warehouseSidebar.Warehouse name")}
                      <span className='text-danger'>*</span>
                    </h6>
                    <input
                      className={`${errors.WareHouseName ? "is-invalid" : ""} Manager-input`}
                      type="text"
                      placeholder={t("warehouseSidebar.Warehouse name")}
                      name="WareHouseName"
                      value={warehouse.WareHouseName}
                      onChange={handleInputChange} />
                    {errors.WareHouseName && (
                      <div className="invalid-feedback">{errors.WareHouseName}</div>
                    )}

                    <h6 className='ware-text pt-3'>{t('inventorySidebar.Select warehouse manager')}<span className='text-danger'>*</span></h6>
                    <AsyncTypeahead
                      id="manager-search"
                      labelKey="name"
                      isLoading={isLoadingManager}
                      options={managerOptions}
                      onSearch={handleManagerSearch}
                      onChange={handleManagerSelection}
                      placeholder={t('inventorySidebar.Search Managers')}
                      className={`warehouse-manager ${errors.name ? 'error-border' : ''}`}
                    />
                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}

                    <h6 className='ware-text pt-3'>{t("warehouseSidebar.Capacity")}
                      <span className='text-danger'>*</span>
                    </h6>
                    <input
                      className={`${errors.capacity ? "is-invalid" : ""} Manager-input`}
                      type="text"
                      placeholder={t("warehouseSidebar.Capacity")}
                      name="capacity"
                      value={warehouse.capacity}
                      onChange={handleInputChange} />
                    {errors.capacity && (
                      <div className="invalid-feedback">{errors.capacity}</div>
                    )}

                    <h6 className='ware-text pt-3'>{t('warehouseSidebar.Location')}
                      <span className='text-danger'>*</span>
                    </h6>
                    <input
                      className={`${errors.locationName ? "is-invalid" : ""} Manager-input`}
                      type="text"
                      placeholder={t('warehouseSidebar.Location')}
                      name="locationName"
                      value={warehouse.locationName}
                      onChange={handleInputChange} />
                    {errors.locationName && (
                      <div className="invalid-feedback">{errors.locationName}</div>
                    )}

                    <h6 className='ware-text pt-3'>{t('serviceSidebar.Latitude')}
                      <span className='text-danger'>*</span> </h6>
                    <input type="number" className={`Manager-input ${errors.latitude ? "is-invalid" : ""}`}
                      name="latitude"
                      id="latitude"
                      value={warehouse.latitude}
                      onChange={handleInputChange}
                      placeholder={t('serviceSidebar.Latitude')} />
                    {errors.latitude && (
                      <div className="invalid-feedback">{errors.latitude}</div>
                    )}

                    <h6 className='ware-text pt-3'>{t('serviceSidebar.Longitude')}
                      <span className='text-danger'>*</span>
                    </h6>
                    <input type="number"
                      name="longitude"
                      className={`Manager-input ${errors.longitude ? "is-invalid" : ""}`}
                      value={warehouse.longitude}
                      onChange={handleInputChange}
                      id="longitude" placeholder={t('serviceSidebar.Longitude')} />
                    {errors.longitude && (
                      <span className="invalid-feedback">{errors.longitude}</span>
                    )}

                    <h6 className='ware-text pt-3'>{t('requestsidebar.pinCode')}
                      <span className='text-danger'>*</span> </h6>
                    <input
                      className={`${errors.pinCode ? "is-invalid" : ""} Manager-input`}
                      type='text'
                      name='pinCode'
                      placeholder={t('warehouseSidebar.Enter the pin code')}
                      value={warehouse.pinCode}
                      onChange={handleInputChange}
                      maxLength={6}
                    />
                    {errors.pinCode && (
                      <div className="invalid-feedback">{errors.pinCode}</div>
                    )}
                  </p>
                </div>
              </div>

              <button className="warehouse-button mt-3" type="submit" disabled={isSaving}>
                {t(isSaving ? 'intentCreate.Saving....' : 'intentCreate.Save')}
              </button>

              <button className="cancel-ware ml-2" type="button" onClick={handleCancel}>
                <b>{t("intentCreate.Cancel")}</b>
              </button>

            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default WarehouseSidebar;