import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./DeliveryCode.scss";
import DeliverySidebar from './DeliverySidebar';
import DeliveryUpdateSidebar from './DeliveryUpdateSidebar';
import { useLanguage } from '../../LanguageContext';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';

const DeliveryCodes = ({ villages }) => {
  const [deliveryCodes, setDeliveryCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUpdateSidebarOpen, setIsUpdateSidebarOpen] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const { t } = useLanguage();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const numberOfVillages = villages ? villages?.length : '';

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUpdateSidebar = (_id) => {
    const selected = deliveryCodes.find((code) => code._id === _id);
    setSelectedDelivery(selected);
    setIsUpdateSidebarOpen(!isUpdateSidebarOpen);
  };

  useEffect(() => {
    GetCall('')
  }, []);

  const GetCall = () => {
    axios.get(`${API.commerceAPI}/commerce/deliverycode/all/getByGroupId/${groupId}`)
      .then(response => {
        if (Array.isArray(response.data.data.items)) {
          setDeliveryCodes(response.data.data.items);
          setLoading(false);
        } else {
          console.error('API response items is not an array:', response.data.data.items);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <div>
      <div className='set-height'>
        <div className='setting d-inline-flex'>
          <p className='ml-2 delivery-title'><b>{t("code.deliveryCode")}</b></p>
        </div>
        <p className='update-setting ml-1'>{t("code.deliveryText")}</p>
      </div>
      {loading && (
        <div>
          <div className='spinner-border LODER'></div>
        </div>
      )}

      <div className=''>
        <button className="add-code" onClick={toggleSidebar}>{t("Deliverycode.Add Code")}</button>
        {isSidebarOpen && <DeliverySidebar villages={villages} handleClose={toggleSidebar} />}
      </div>
      <hr className='hr_height' />

      <div className=''>
        <div className='row pt-2'>
          <div className='col-5'>
            <p className='feature-delivery pl-3'><b>{t("code.deliveryCode")}</b></p>
          </div>
          <div className='col-2'></div>
        </div>
      </div>

      <div className='container-fluid '>
        <div className='row '>
          <div className='col-lg-8'>
            <div className='grid-container container-grid' >
              {deliveryCodes.map((code, index) => (
                <div key={index} className='grid-item '>
                  <small className='feature-tag  ' onClick={() => toggleUpdateSidebar(code._id)} >
                    {code.code}
                    {code.locality && <small className='circle-badge margin-right-10'>{code.locality?.length}</small>}
                  </small>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isUpdateSidebarOpen && selectedDelivery && (
        <DeliveryUpdateSidebar
          villages={villages}
          handleClose={toggleUpdateSidebar}
          _id={selectedDelivery._id}
          selectedDelivery={selectedDelivery}
          getCall={GetCall} />
      )}
    </div>
  );
}

export default DeliveryCodes;