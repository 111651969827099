import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TermSkeletonLoader = () => {
    return (
        <SkeletonTheme color="#007bff" highlightColor="#C0C2DE">
            <tbody>
                {[...Array(4)].map((_, index) => (
                    <tr className="font14" key={index}>
                        <td className="t-data">
                            <Skeleton />
                        </td>
                        <td className="t-data">
                            <Skeleton />
                        </td>
                        <td className="t-data">
                            <Skeleton />
                        </td>
                        <td className="t-data">
                            <Skeleton />
                        </td>
                    </tr>
                ))}
            </tbody>
        </SkeletonTheme>
    )
}

export default TermSkeletonLoader;