import React from 'react'
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SupplierAddress from '../../components/supplier-address/SupplierAddress';
import SuppliersBankDetails from '../supplier-bank-details/SupplierBankDetails';
import AddSupplierSidebar from './AddSupplierSidebar';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';
import STORAGE_KEYS from '../../utils/storageKey';

function AddSupplier({ accountDetails }) {
    const [supplierAddress, setSupplierAddress] = useState(false);
    const [supplierBankDetails] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const { _id } = useParams();
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const { t } = useLanguage();

    const handleCancelAddress = () => {
        setSupplierAddress({});
    };
    const { supplierId } = useParams();
    const [supplierInfo, setSupplierInfo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        name: '',
        company: '',
        phoneNumber: '',
        address: '',
        phoneNumber: '',
        whatsapp: '',
        email: '',
    });

    const [supplier, setSupplier] = useState({
        groupId: groupId,
        name: '',
        company: "",
        address: '',
        phoneNumber: '',
        whatsapp: '',
        email: '',
        aadharcard: '',
        pancard: '',
        gstNo: '',
        tanNo: '',
        cinNo: '',
        source: '',
        currency: '',
        paymentTerms: '',
        language: '',
        supplierId: ''
    });

    const fetchSupplier = () => {
        fetch(
            `${API.commerceAPI}/commerce/supplier/all/getByGroupId/${groupId}?supplierId=${supplierId}`
        ).then((result) => {
            result.json().then((resp) => {
                const firstItem = resp?.data?.items?.[0];
                if (firstItem) {
                    setSupplier({
                        ...supplier,
                        groupId: groupId,
                        ...firstItem,
                    });
                }
            });
        });
    };

    useEffect(() => {
    }, [supplier]);

    useEffect(() => {
        if (isMounted) {
            fetchSupplier();
        } else {
            setIsMounted(true);
        }
    }, [isMounted]);

    useEffect(() => {
        if (supplierId !== "new") {
            fetchSupplier();
        } else {
            setSupplier({
                groupId: groupId,
            });
        }
    }, []);

    const regexPhoneNumber = /^\d{10}$/;
    const regexLocation = /^[a-zA-Z0-9\s.,-]*$/;
    const regexAadhar = /^[0-9]{12}$/;
    const regexName = /^[a-zA-Z\s'-]+$/;
    const regexCompany = /^[a-zA-Z0-9\s&'-]+$/;

    const updateSupplierInfo = (event) => {
        setSupplier({ ...supplier, [event.target.name]: event.target.value });
        setErrors({ ...errors, [event.target.name]: '' });
    };

    const updateBankDetailsInfo = (event) => {
        setSupplier({ ...supplier, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};
        if (!supplier.name) {
            formIsValid = false;
            newErrors.name = 'Name is required';
        } else if (!regexName.test(supplier.name)) {
            formIsValid = false;
            newErrors.name = 'Invalid name format';
        } else {
            newErrors.name = '';
        }

        if (!supplier.company) {
            formIsValid = false;
            newErrors.company = 'Company is required';
        } else if (!regexCompany.test(supplier.company)) {
            formIsValid = false;
            newErrors.company = 'Invalid company format';
        } else {
            newErrors.company = '';
        }
        if (!supplier.address) {
            formIsValid = false;
            newErrors.address = 'Location is required';
        } else if (!regexLocation.test(supplier.address)) {
            formIsValid = false;
            newErrors.address = 'Invalid location format';
        } else {
            newErrors.address = '';
        }

        if (!supplier.phoneNumber) {
            formIsValid = false;
            newErrors.phoneNumber = 'Phone Number is required';
        } else if (supplier.phoneNumber?.length != 10) {
            formIsValid = false;
            newErrors.phoneNumber = 'Invalid phone number format';
        }

        if (!supplier.whatsapp) {
            formIsValid = false;
            newErrors.whatsapp = 'Whatsapp number is required';
        } else if (!regexPhoneNumber.test(supplier.whatsapp)) {
            formIsValid = false;
            newErrors.whatsapp = 'Invalid phone number format';
        }

        if (supplier.aadharcard) {
            if (!regexAadhar.test(supplier.aadharcard)) {
                formIsValid = false;
                newErrors.aadharcard = 'Invalid Aadhar format';
            }
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleSave = async () => {

        if (!validateForm()) {
            return;
        }
        const userWithGroupId = {
            ...supplier,
            groupId: supplier?.groupId || groupId,
        };

        setLoading(true);
        try {
            if (supplier?.supplierId) {
                const response = await fetch(
                    `${API.commerceAPI}/commerce/supplier/updateBySupplierId/${supplierId}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(userWithGroupId),
                    }
                );

                if (response.ok) {
                    setLoading(false);
                    toast.success('Supplier Updated Successfully!', {
                        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                    });
                    setSupplierInfo(false);
                } else {
                    console.error('Error updating supplier:', response.statusText);
                    setSupplierInfo(false);
                    toast.error('An error occurred while updating supplier', {});
                }
            } else {
                const response = await fetch(`${API.commerceAPI}/commerce/supplier`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(userWithGroupId),
                });

                const newSupplier = await response.json();
                if (response.ok) {
                    setSupplierInfo(false);
                    toast.success('Supplier Created Successfully!', {
                        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                    });
                    navigate(`/create-supplier/${newSupplier?.data?.supplierId}`);
                    window.location.reload()
                } else {
                    console.error('Unexpected response status:', response.status);
                    toast.error('Failed to create supplier', {});
                }
            }
        } catch (error) {
            console.error('Error saving/updating supplier:', error);
            setSupplierInfo(false);
            toast.error('An error occurred while saving supplier', {});
        }
        setLoading(false);
    };

    const cancel = () => {
        navigate(`/create-supplier/${supplierId}`);
    };

    const handleCancelData = () => {
        setSupplierInfo(false);
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false} closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="#F5F5FC"
                toastStyle={{ color: "black" }}
            />

            <div className='container-fluid'>
                <div className="row  mt-3">
                    <div className="col-10 p-0">
                        <div className="profile-page11 ">
                            <div className="profile-name">
                                <div>
                                    <b className='supplier-theme'>{supplier && supplier?.name ? supplier?.name : t("Supplier.Name goes here")}
                                    </b>
                                    <i onClick={() => setSupplierInfo(!supplierInfo)}
                                        className="fa-solid fa-pencil edit-profile ml-5 mb-3" />
                                </div>
                            </div>

                            {supplierInfo && <AddSupplierSidebar
                                handleClose={handleCancelData}
                                supplier={supplier}
                                setSupplier={setSupplier}
                                updateSupplierInfo={updateSupplierInfo}
                                saveDataInfo={handleSave}
                                supplierId={supplierId}
                                errors={errors}
                            />}
                            <button className='close-icon-2'>
                                <Link to={'/supplier'}><i className="fa-solid fa-circle-xmark"></i></Link>
                            </button>
                            <p className="font16">
                                {""}
                                {supplier?.company ? `${supplier?.company}` : t("Supplier.Company here")}
                            </p>

                            <p className=" font16">
                                {""}
                                {supplier?.address ? `${supplier?.address}` : t("Supplier.Address here")}
                            </p></div></div>
                    <hr className="profile-hr " />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 m-0">
                    <div className="customer-margin-left">
                        <p className="customer-title">{t("Supplier.CONTACT DETAILS")}</p>
                        <div className="profile-line-height ">
                            <p className="text-color ">{t("Supplier.Phone no")} </p>
                            <p className=" font16">
                                {" "}
                                {supplier?.phoneNumber ? supplier?.phoneNumber : "--"}
                            </p>
                        </div>

                        <div className="profile-line-height mt-3">
                            <p className="text-color ">{t("Supplier.WhatsApp")}</p>
                            <p className=" font16">
                                {" "}
                                {supplier?.whatsapp ? supplier?.whatsapp : "--"}
                            </p>
                        </div>

                        <div className="profile-line-height  mt-3">
                            <p className="text-color ">{t("Supplier.Email")} </p>
                            <p className=" font16">
                                {" "}
                                {supplier?.email ? supplier?.email : "--"}
                            </p>
                        </div>
                    </div>

                    <div className="customer-margin-left">
                        <p className="customer-title mt-5 ">{t("Supplier.KYC")}</p>
                        <div className="profile-line-height">
                            <p className="text-color ">{t("Supplier.Aadhar Card")} </p>
                            <p className=" font16">
                                {" "}
                                {supplier?.aadharcard ? supplier?.aadharcard : "--"}
                            </p>
                        </div>

                        <div className="profile-line-height mt-4">
                            <p className="text-color ">{t("Supplier.Pan Card")} </p>
                            <p className=" font16">
                                {" "}
                                {supplier?.pancard ? supplier?.pancard : "--"}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-12 col-md-6">
                    <SupplierAddress
                        supplier={supplier}
                        supplierId={supplierId}
                        cancel={cancel}
                        supplierAddress={supplierAddress}
                        updateSupplierAddress={updateSupplierInfo}
                    />
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <SuppliersBankDetails
                        accountDetails={accountDetails}
                        supplierId={supplierId}
                        supplier={supplier}
                        cancel={cancel}
                        supplierBankDetails={supplierBankDetails}
                        updateBankDetails={updateBankDetailsInfo}
                    />
                </div>
            </div>
        </>
    )
}

export default AddSupplier
