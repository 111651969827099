import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./CustomerDetails.scss";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageContext";
import CustomerInfo from "../../components/customer-info/CustomerInfo";
import AllOrders from "../../components/all-order/AllOrder";
import AllServiceRequests from "../../components/all-service-request/AllServiceRequest";
import API from "../../services/Api";
import STORAGE_KEYS from "../../utils/storageKey";
import { ToastContainer } from "react-toastify";

const CustomerDetails = () => {
  const { t } = useLanguage();
  const [activeComponent, setActiveComponent] = useState("component1");
  const { userId } = useParams();
  const { custId } = useParams();
  const defaultImageUrl = "/images/default images.jpg";
  const [customer, setCustomer] = useState("");
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem("activeComponent");
    if (storedActiveComponent) {
      setActiveComponent(storedActiveComponent);
    }
    fetch(
      `${API.commerceAPI}/commerce/customer/all/getByGroupId/${groupId}?userId=${userId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCustomer(data?.data?.items[0]);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]);

  const renderComponent = () => {
    switch (activeComponent) {
      case "component1":
        return (
          <CustomerInfo
            userId={userId}
            customer={customer}
            custId={custId}
            setCustomer={setCustomer}
          />
        );
      case "component2":
        return <AllOrders userId={userId} />;
      case "component3":
        return <AllServiceRequests userId={userId} />;
      default:
        return null;
    }
  };

  return (
    <div className="main">
      <ToastContainer autoClose={1500} />
      <div className="body_container">
        <div className="main-padding-customer">
          <div className="profile-section">
            <img
              className="profile"
              src={customer?.imageUrl || defaultImageUrl}
              alt="User Profile Image"
            />

            <div className="info  ">
              <p className="name">
                {customer?.name ? customer.name.charAt(0).toUpperCase() + customer.name.slice(1) : "--"}
              </p>

              <i className="fa-solid fa-circle-xmark customer_xmark cursor-pointer" onClick={handleClose}></i>
              <p className="customer_address color-text">
                {customer?.location ? customer.location.charAt(0).toUpperCase() + customer.location.slice(1) : "--"}
              </p>

              {customer &&
                customer.memberMembership &&
                customer.memberMembership?.length > 0 &&
                customer.memberMembership.map((membershipItem, index) => {

                  if (
                    membershipItem.membershipPremium.toLowerCase() === "premium"
                  ) {
                    return (
                      <button key={index} className="premium-btn">
                        {t('cust.Premium')}
                      </button>
                    );
                  } else {
                    console.log(
                      "Not rendering Premium Button for Membership Item:",
                      membershipItem
                    );

                    return null;
                  }
                })}
              <div className="phone-email">
                <div className="color-text ">
                  <i className="fa-solid fa-phone"></i>
                  <span className="pl-2">{customer?.phoneNumber || " --"}</span>
                </div>

                <div className="customer-email">
                  <i className="fa-solid fa-envelope customer_email pt-2">
                    <span className="pl-2">{customer?.email || " --"}</span>
                  </i>
                </div>
              </div>
            </div>
          </div>

          <hr className="hr-line-customer " />
          <div className="buttons-section">
            <button
              onClick={() => setActiveComponent("component1")}
              className={`cust-info-btn p-1 ${activeComponent === "component1" ? "selected" : ""}`}>
              {t('cust.Customer Info')}
            </button>

            <button
              className={`cust-info-btn p-1 ml-2 ${activeComponent === "component2" ? "selected" : ""}`}
              onClick={() => setActiveComponent("component2")}>
              {t('cust.Orders')}
            </button>

            <button className={`cust-info-btn p-1 ml-2 ${activeComponent === "component3" ? "selected" : ""}`}
              onClick={() => setActiveComponent("component3")} > {t('cust.Service Request')}</button>
          </div>

          <div>{renderComponent()}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;


