import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Const from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import API from '../../services/Api';
import { useLanguage } from '../../LanguageContext';

const SuppliersBankDetailsSidebar = ({ supplier, handleCancel }) => {
  const [accountNo, setAccountNo] = useState('');
  const [bankName, setBankName] = useState('');
  const [pancard, setPanCard] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [branchName, setBranchName] = useState('');
  const [upi, setUpi] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [accountNoError, setAccountNoError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [ifscCodeError, setIfscCodeError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [upiError, setUpiError] = useState('');
  const { t } = useLanguage();
  const [errors, setErrors] = useState({});

  const handleAccountNoChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,16}$/.test(value)) {
      setAccountNo(value);
      if (value?.length < 12) {
        setErrors({ ...errors, accountNo: 'Invalid Account No' });
      } else {
        clearError('accountNo');
      }
    }
  }

  const handleBankNameChange = (e) => {
    setBankName(e.target.value);
    setBankNameError('');
  };

  const handlePanCardChange = (e) => {
    const inputValue = e.target.value.toUpperCase();
    setPanCard(e.target.value)
  }

  const handleIfscCodeChange = (e) => {
    const inputValue = e.target.value.toUpperCase();
    setIfscCode(inputValue);

    if (inputValue.trim() === '') {
      setIfscCodeError('IFSC Code is required');
    } else {
      setIfscCodeError('');
    }
  };

  const handleBranchNameChange = (e) => {
    setBranchName(e.target.value);
    setBranchNameError('');
  };

  const handleUpiChange = (e) => {
    setUpi(e.target.value);
    setUpiError('');

    const regex = /^[a-zA-Z0-9@]+$/;
    const onlyNumbers = /^\d+$/;
    const onlyCharacters = /^[a-zA-Z]+$/;

    if (!regex.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    }
    else if (onlyNumbers.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    } else if (onlyCharacters.test(e.target.value)) {
      setUpiError('UPI should contain only alphanumeric characters');
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!accountNo) {
      setAccountNoError('Account No is required');

      isValid = false;
    } else if (accountNoError) {
      isValid = false;
    }
    if (!bankName) {
      setBankNameError('Bank Name is required');
      isValid = false;
    }

    if (!ifscCode) {
      setIfscCodeError('IFSC Code is required');
      isValid = false;
    }

    if (!branchName) {
      setBranchNameError('Branch Name is required');
      isValid = false;
    }

    if (!upi) {
      setUpiError('UPI is required');
      isValid = false;
    } else if (upiError) {
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formattedPayment = {
        accountDetails: [
          {
            accountNo: accountNo || 0,
            bank: bankName || '',
            ifsc: ifscCode || '',
            branch: branchName || '',
            pancard: pancard || '',

            upi: {
              upi: upi || '',
            },
          },
        ],
      };

      try {
        setLoading(true);
        await saveBankDetails(formattedPayment);
        toast.success('Payment Details Added', {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
        });
        navigate(`/create-supplier/${supplier?.supplierId}`);
      } catch (error) {
        console.error('Error saving payment:', error);
        toast.error('An error occurred while saving payment');
      } finally {
        setLoading(false);
      }
    }
  };

  const saveBankDetails = async (bankDetails) => {
    try {
      const response = await fetch(`${API.commerceAPI}/commerce/supplier/accountDetails/${supplier.supplierId}`, {
        method: 'PUT',
        body: JSON.stringify(bankDetails),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to save payment. Server returned ${response.status} ${response.statusText}`);
      }
      const saveBankDetails = data.data;

      window.location.reload();
    } catch (error) {

    }
  };

  const handleClearFields = () => {
    setAccountNo('');
    setBankName('');
    setIfscCode('');
    setBranchName('');
    setUpi('');
    setAccountNoError('');
    setBankNameError('');
    setIfscCodeError('');
    setBranchNameError('');
    setUpiError('');
  };
  const clearError = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: ''
      };
    });
  };

  return (
    <>

      <div className='cust-profile-sidebar'>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="#F5F5FC"
          toastStyle={{ color: 'black' }}
        />
        <div className='pl-4'>
          <div className="d-inline-flex">
            <p className="suppliers-style1 pt-3">{t("SupplierBank.Supplier Bank Details")}</p>
            <div className="close-icon">
              <i
                className="fa-solid fa-circle-xmark mr-4"
                onClick={handleCancel}
              ></i>
            </div>
          </div>

          <p className="text-color2 ">Bank Details: {supplier.supplierId}</p>
          <hr className="suppliers-hr" />

          <div className='supplier-scrollar1'>
            <div className='mt-4'>
              <p className='text-color2 '>{t("SupplierBank.Bank Name")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.bankName ? 'error-border' : ''}`}
                  placeholder={t("SupplierBank.Bank Name")}
                  name="bankName"
                  value={bankName}
                  onChange={handleBankNameChange}
                /><br />
                <span className='err-alert'>{bankNameError}</span>
              </p>

              <p className='text-color2 '>{t("PAN Card")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.bankName ? 'error-border' : ''}`}
                  placeholder={t("PAN Card")}
                  name="pancard"
                  value={pancard}
                  onChange={handlePanCardChange}
                /><br />
                <span className='err-alert'>{bankNameError}</span>
              </p>
              
              <p className='accNo text-color2 '>{t("SupplierBank.Account No")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.accountNo ? 'error-border' : ''}`}
                  placeholder={t("SupplierBank.Account No")}
                  name="accountNo"
                  value={accountNo}
                  onChange={handleAccountNoChange}
                /><br />
                <span className='err-alert'>{accountNoError}</span>
              </p>

              <p className='text-color2 '>{t("SupplierBank.IFSC Code")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.ifscCode ? 'error-border' : ''}`}
                  placeholder={t("SupplierBank.IFSC Code")}
                  name="ifscCode"
                  value={ifscCode}
                  onChange={handleIfscCodeChange}
                  maxLength={10}
                /><br />
                <span className='err-alert'>{ifscCodeError}</span>
              </p>

              <p className='text-color2 '>{t("SupplierBank.Branch Name")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.branchName ? 'error-border' : ''}`}
                  placeholder={t("SupplierBank.Branch Name")}
                  name="branchName"
                  value={branchName}
                  onChange={handleBranchNameChange}
                /><br />
                <span className='err-alert'>{branchNameError}</span>
              </p>

              <p className='text-color2 '>{t("SupplierBank.UPI")} <span className='text-danger'>*</span><br />
                <input
                  className={`payment-input  ${errors.upi ? 'error-border' : ''}`}
                  placeholder={t("SupplierBank.UPI")}
                  name="upi"
                  value={upi}
                  onChange={handleUpiChange}
                /><br />
                <span className='err-alert'>{upiError}</span>
              </p>
            </div>
          </div>

          <div className='payment-btns mt-5'>
            <button className='save-info' onClick={handleSubmit}>
              {t(loading ? 'intentCreate.Saving....' : 'intentCreate.Save')}
            </button>
            <button className='cancel-info ml-2' onClick={handleClearFields}>
              {t("intentCreate.Cancel")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuppliersBankDetailsSidebar;
