import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import STORAGE_KEYS from '../../utils/storageKey';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

  useEffect(() => {
    setLoading(false);
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;