import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Magnifier from "react-magnifier";
import '../popups/ProductDetails.scss'
import { useLanguage } from '../../LanguageContext';
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from '../../utils/storageKey';

const ProductDetailPopup = ({ product, onClose, onDeleteProduct }) => {
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [expandedTooltips, setExpandedTooltips] = useState({});
  const defaultImageUrl = "/images/productdefault.jpg";
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);
  const { t } = useLanguage();

  const handleUpdateProduct = (productId) => {
    navigate(`/product-form/${productId}`);
  };

  const renderTags = (tags) => {
    if (!tags || tags?.length === 0) {
      return <p>{t("productdetails.No tags available")}</p>;
    }
    return tags.map((tag, index) => (
      <button key={index} className="btn tag-btn">{tag}</button>
    ));
  };

  const renderHTML = (html) => {
    return { __html: html };
  };

  const commonPricesHeader = (
    <>
      <th>{t("productdetails.Member Price")}</th>
      <th>{t("productdetails.Market Price")}</th>
      <th>{t("productdetails.Regular Price")}</th>

    </>
  );

  const renderDynamicCells = (priceListItem) => {
    const dynamicHeaders = Object.keys(priceListItem).filter(
      (header) =>
        !['memberPrice', 'marketPrice', 'regularPrice', 'buyingPrice'].includes(
          header
        )
    );

    return dynamicHeaders.map((header, index) => (
      <td key={index}>{priceListItem[header]}</td>
    ));
  };

  const renderTableHeaders = () => {
    if (!product.priceList || product.priceList?.length === 0) {
      return null;
    }
    const headers = Object.keys(product.priceList[0]);
    const dynamicHeaders = headers.filter(
      (header) =>
        !['memberPrice', 'marketPrice', 'regularPrice', 'buyingPrice'].includes(
          header
        )
    );

    return (
      <tr>
        {dynamicHeaders.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
        {commonPricesHeader}
      </tr>
    );
  }

  const renderVariants = () => {
    if (!product.variants || product.variants?.length === 0) {
      return null;
    }

    return (
      <div className="variant-section">
        {product.variants.map((variant, index) => (
          <div key={index} >
            <b className='property'>{Object.keys(variant)[0]}</b>
            {variant[Object.keys(variant)[0]].map((value, valueIndex) => (
              <label key={valueIndex} className='ml-1'>
                <button className='variants-btns'>{value}</button>
              </label>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const handleDeleteProduct = (productId) => {
    setProductIdToDelete(productId);
    setShowDeleteConfirmation(true);

  };

  const toggleDescription = (productId) => {
    setExpandedTooltips((prevExpandedTooltips) => ({
      ...prevExpandedTooltips,
      [productId]: !prevExpandedTooltips[productId],
    }));
  };

  const DeleteConfirmationModal = () => {
    const confirmDelete = () => {
      setShowDeleteConfirmation(false);
      onDeleteProduct(product._id);
      onClose();
    };
    const cancelDelete = () => {
      setShowDeleteConfirmation(false);
    };

    return showDeleteConfirmation ? (
      <div className='popup-box-product'>
        <div className='cust-box-product'>
          <p className='popup-priceTag-new'>{t("productdetails.Are you sure you want to delete this product?")}</p>
          <button onClick={confirmDelete} className='ware-delete'>{t("productdetails.Yes")}</button>
          <button onClick={cancelDelete} className='ware-nodelete'>{t("productdetails.No")}</button>
        </div>
      </div>
    ) : null;
  };

  return (

    <div className="product-detail-popup">
      <div className="popup-contents">
        <i className="fa-solid fa-circle-xmark close" onClick={onClose}></i>
        <div className="product-details">
          <div className="product-image">
            {product.pictures && product.pictures?.length > 0 ? (
              <Magnifier
                key={0}
                src={product.pictures[0]}
                alt={product.name}
                className='popup-image'
              />
            ) : (
              <img
                src={defaultImageUrl}
                alt="Default Product"
                className='popup-image'
              />
            )}
            <br />
          </div>

          <div className="product-info ">
            <div className='scroler'>
              <strong key={product._id} className='product-title-name'>
                {product.name?.length > 15 ? (
                  expandedTooltips[product._id] ? (
                    <>
                      {product.name}
                      <button className='more-option' onClick={() => toggleDescription(product._id)}>
                        Read Less
                      </button>
                    </>
                  ) : (
                    <>
                      {product.name.slice(0, 15)}...
                      <button className='more-option' onClick={() => toggleDescription(product._id)}>
                        Read More
                      </button>
                    </>
                  )
                ) : (
                  product.name
                )}
              </strong>

              <div className="d-flex star mt-1">
                {Array.from({ length: Number(product.rating) }).map((_, index) => (
                  <i key={`filled-${index}`} className="fa-solid fa-star feature-stars" />
                ))}
                {Array.from({ length: 5 - Number(product.rating) }).map((_, index) => (
                  <i key={`empty-${index}`} className="fa-regular fa-star feature-stars" />
                ))}
              </div>
              <div className='mt-2'>
                <b className='buy-price'>
                  <i className="fa-sharp fa-solid fa-indian-rupee-sign mr-1"></i>
                  {product.marketPrice}
                </b>
                <b className='sp-price pl-3'>
                  <i className="fa-sharp fa-solid fa-indian-rupee-sign mr-1"></i>
                  {product.regularPrice}
                </b>
              </div>

              {product.variants && product.variants?.length > 0 && (
                <div className="variant-section">
                  {product.variants.map((variant, index) => (
                    <div key={index}>
                      <b className='property'>{Object.keys(variant)[0]}</b>
                      {variant[Object.keys(variant)[0]].map((value, valueIndex) => (
                        <label key={valueIndex} className='ml-1'>
                          <button className='variants-btns'>{value}</button>
                        </label>
                      ))}
                    </div>
                  ))}
                </div>
              )}

              <div className='mt-2'>
                <h5>{t("productdetails.Description")}:</h5>
                <p className='popup-description' dangerouslySetInnerHTML={renderHTML(product.value?.description)} />
              </div>
            </div>
            <DeleteConfirmationModal />
            {tokenPermissions && tokenPermissions.includes(Permissions.MP3) && (
              <button className="delete-button" onClick={() => handleUpdateProduct(product._id)}>
                {t("productdetails.Edit")}
              </button>
            )}

            {tokenPermissions && tokenPermissions.includes(Permissions.MP4) && (
              <button className="delete-button-d ml-4" onClick={() => handleDeleteProduct(product._id)}>
                {t("productdetails.Delete")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPopup;