import React from 'react';
import './stockInventory.scss';

const RestockPopup = ({ products, onClose }) => {

  if (!Array.isArray(products) || products?.length === 0) {
    return null;
  }

  const openWhatsApp = () => {
    const phoneNumber = '8799958549';
    const message = encodeURIComponent('Please restock the following products:\n' +
      products.map(product => `${product.productId.name} (${product.warehouse}): ${product.quantity}`).join('\n'));
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-header">
          <strong><h5> Stock of Product <i className="fa-solid fa-circle-xmark stock-popup-icon cursor-pointer " onClick={onClose}></i></h5></strong>
         
        </div>
        <div className="popup-body">
          {products.map(product => (
            <div key={product._id} className="product-item">
              <div className="s">
            <h6>{product.productId.name}</h6>
              <h6>Quantity: {product.quantity}</h6>
              </div>
              <div className="product-actions">
                <i className="fa-brands fa-whatsapp text-success cursor-pointer" onClick={openWhatsApp}></i>
                <span className="send-alert-text cursor-pointer" onClick={openWhatsApp}><strong className='ml-2'>Send Stock Alert</strong></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RestockPopup;
