import { React, useState, useEffect } from 'react'
import moment from 'moment';
import './CreateStock.scss';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useLanguage } from '../../LanguageContext'
import { fetchWarehouse } from '../../utils/Helper'
import { fetchProducts } from '../../utils/Helper';
import { toast } from 'react-toastify';
import STORAGE_KEYS from '../../utils/storageKey';
import API from '../../services/Api';

const CreateStock = ({ handleClose, items, fetchStockData }) => {
    const { t } = useLanguage();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [products, setProducts] = useState([]);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [clearTypeahead, setClearTypeahead] = useState(false);
    const [currentQuantity, setCurrentQuantity] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [wareHouseId, setWareHouseId] = useState('')
    const [selectedSourceWarehouse, setSelectedSourceWarehouse] = useState(null);
    const [selectedDestinationWarehouse, setSelectedDestinationWarehouse] = useState(null);
    const [selectedProductForDescription, setSelectedProductForDescription] = useState(null);
    const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [transactionType, setTransactionType] = useState("Out");

    const formattedDate = currentDateTime.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleProductSearch = (query, product) => {
        return product.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()));
    };

    useEffect(() => {
        const loadProducts = async () => {
            try {
                const productData = await fetchProducts();
                if (productData && productData.data && productData.data.items) {
                    setProducts(productData.data.items);
                }
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        };
        loadProducts();
    }, []);

    useEffect(() => {
        const loadWarehouses = async () => {
            try {
                const response = await fetchWarehouse();
                if (response.data && response.data.items) {
                    const formattedWarehouses = response.data.items.map((item) => ({
                        name: item.WareHouseName,
                        wareHouseId: item.wareHouseId,
                    }));
                    setWarehouses(formattedWarehouses);
                }
            } catch (error) {
                console.error('Failed to fetch warehouses:', error);
            }
        };
        loadWarehouses();
    }, []);

    const handleProductSelect = (selected) => {
        if (selected?.length) {
            setSelectedProduct(selected[0]);
        }
    };

    const handleAddProduct = () => {
        let errors = {};
        if (!selectedProduct || !selectedProduct.name) {
            errors.product = 'Please select a valid product.';
        }
        const quantity = parseInt(currentQuantity, 10);
        if (isNaN(quantity) || quantity <= 0) {
            errors.quantity = 'Enter the quantity.';
        }
        if (Object.keys(errors)?.length > 0) {
            setValidationErrors(errors);
            return;
        }
        setValidationErrors({});
        setClearTypeahead(!clearTypeahead);

        const updatedProducts = [...selectedProducts];
        const existingProduct = updatedProducts.find(
            (p) => p.productcode === selectedProduct.productcode
        );

        if (existingProduct) {
            existingProduct.quantity += quantity;
        } else {
            updatedProducts.push({
                ...selectedProduct,
                quantity,
            });
        }
        setSelectedProducts(updatedProducts);
        setSelectedProduct(null);
        setCurrentQuantity('');
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        setCurrentQuantity(value);
        if (isNaN(value) || parseInt(value, 10) <= 0) {
            setValidationErrors({ quantity: 'enter quantity.' });
        } else {
            setValidationErrors({});
        }
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts.splice(index, 1);
        setSelectedProducts(updatedProducts);
    };

    useEffect(() => {
        handleFetchWarehouses();
    }, []);

    const handleFetchWarehouses = async () => {
        try {
            const response = await fetchWarehouse();
            if (response.data && response.data.items) {
                const formattedWarehouses = response.data.items.map((item) => ({
                    name: item.WareHouseName,
                    wareHouseId: item.wareHouseId,
                }));
                setWarehouses(formattedWarehouses);
            }
        } catch (error) {
            console.error("Failed to fetch warehouses:", error);
        }
    };

    const handleSourceWarehouseSelect = (selected) => {
        if (selected?.length > 0) {
            setSelectedSourceWarehouse(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                sourceWarehouse: '',
            }));
        } else {
            setSelectedSourceWarehouse(null);
        }
    };

    const handleDestinationWarehouseSelect = (selected) => {
        if (selected?.length > 0) {
            setSelectedDestinationWarehouse(selected[0]);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                destinationWarehouse: '',
            }));
        } else {
            setSelectedDestinationWarehouse(null);
        }
    };

    const handleOpenDescriptionPopup = (product) => {
        setSelectedProductForDescription(product);
        setShowDescriptionPopup(true);
    };

    const handleCloseDescriptionPopup = () => {
        setShowDescriptionPopup(false);
    };

    const handleDeliveryDateChange = (date) => {
        setExpectedDeliveryDate(date);
        if (date) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                deliveryDate: '',
            }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;
        if (!selectedProducts?.length) {
            errors.product = 'Select at least one product.';
            isValid = false;
        } else {
            errors.product = '';
        }
        const hasValidProduct = selectedProducts.some(
            (product) => product.quantity > 0
        );

        if (!selectedSourceWarehouse) {
            errors.sourceWarehouse = 'Select the warehouse.';
            isValid = false;
        } else {
            errors.sourceWarehouse = ''
        }

        if (!selectedDestinationWarehouse) {
            errors.destinationWarehouse = 'Select the warehouse.';
            isValid = false;
        } else {
            errors.destinationWarehouse = '';
        }

        if (!expectedDeliveryDate) {
            errors.deliveryDate = 'Enter delivery date.';
            isValid = false;
        } else {
            errors.deliveryDate = '';
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }
        setIsSaving(true);

        try {
            const payload = {
                groupId: parseInt(groupId, 10),
                from: selectedSourceWarehouse.wareHouseId,
                destination: selectedDestinationWarehouse.wareHouseId,
                type: transactionType,
                Qty: selectedProducts.reduce((total, product) => total + parseInt(product.quantity, 10), 0),
                price: calculateTotalPrice(selectedProducts),
                warehouseId: warehouses ? warehouses.wareHouseId : null,
                status: "new",
                STOdate: moment(currentDateTime).format("YYYY-MM-DD"),
                expectedDeliveryDate: moment(expectedDeliveryDate).format("YYYY-MM-DD"),
                products: selectedProducts.map(product => ({
                    id: product.id,
                    productcode: parseInt(product.productcode, 10),
                    Qty: parseInt(product.quantity, 10),
                }))
            };

            const response = await fetch(`${API.commerceAPI}/commerce/sto`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to save stock');
            }

            toast.success('Stock Created Successfully...!', {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
            });
            handleClose();
            fetchStockData();
        } catch (error) {
            console.error('Failed to save stock:', error);
            if (error.message) {
                toast.error('Failed to save stock: ' + error.message, { autoClose: 2000 });
            } else {
                toast.error('Failed to save stock: An unknown error occurred.', { autoClose: 2000 });
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleTransactionTypeChange = (event) => {
        setTransactionType(event.target.value);
    };

    const calculateTotalPrice = (products) => {
        return products.reduce((total, product) => total + (parseInt(product.regularPrice, 10) * parseInt(product.quantity, 10)), 0);
    };

    useEffect(() => {
        if (items) {
            if (items.products) {
                setSelectedProducts(
                    items.products.map((product) => ({
                        ...product,
                        quantity: product.Qty || 0,
                        unit: product.unit || 'N/A',
                        price: product.regularPrice * product.Qty,
                    }))
                );
            }

            if (items.warehouse) {
                setSelectedSourceWarehouse(items.warehouse);
            }

            if (items.expectedDeliveryDate) {
                setExpectedDeliveryDate(new Date(items.expectedDeliveryDate));
            }
        }
    }, [items]);

    return (
        <div className='Sto-sidebar'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <h5 className='Intent-create-title'>
                            {t('STO.Create Stock')}
                        </h5>
                        <small className='ware-color'>{t('STO.Create here new Stock')}
                        </small>
                    </div>

                    <div className='col-lg-5'></div>

                    <div className='col-lg-3'>
                        <p className="font16 mt-3 ml-2">
                            <strong> {t("newOrder.date-time")}</strong><br />
                            <small>{formattedDate}</small>{""}
                        </p>
                    </div>
                </div>

                <hr className='hr-line hrrr' />
                <div className='scroller-bar'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <table className='table'>
                                <thead>
                                    <tr className='head-theme'>
                                        <th>{t("STO.Product Code")}</th>
                                        <th>{t("STO.Product Name")}</th>
                                        <th>{t("STO.Description")}</th>
                                        <th>{t("STO.Qty")}</th>
                                        <th>{t("STO.Unit")}</th>
                                        <th>{t("STO.Action")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {selectedProducts.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.productcode}</td>
                                            <td>{product.name}</td>
                                            <td>
                                                <i className="fa-solid fa-ellipsis cursor-pointer"
                                                    onClick={() => handleOpenDescriptionPopup(product)}></i>
                                            </td>
                                            <td>{product.quantity}</td>
                                            <td>{product.unit}</td>
                                            <td>
                                                <i className="fa-solid fa-trash-can intent-trash"
                                                    onClick={() => handleDeleteProduct(index)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                {showDescriptionPopup && (
                                    <div className="products-descriptions">
                                        <div className="popup-content">
                                            <strong>Product Description
                                                <i className="fa-solid fa-circle-xmark close-icon"
                                                    onClick={handleCloseDescriptionPopup}></i></strong>
                                            <hr />
                                            <div dangerouslySetInnerHTML={{ __html: selectedProductForDescription.value?.description }}></div>
                                        </div>
                                    </div>
                                )}
                            </table>
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className='col-lg-4'>
                            <Typeahead
                                id="product-typeahead"
                                labelKey="name"
                                key={clearTypeahead ? 'reset1' : 'reset2'}
                                options={products}
                                filterBy={(option, props) => handleProductSearch(props.text, option)}
                                onChange={handleProductSelect}
                                placeholder={t("intentCreate.Enter product")}
                                multiple={false}
                                className={`input-sto ${validationErrors.product ? 'error-border' : ''}`}
                            />
                            {validationErrors.product && (
                                <span className="error-message">{validationErrors.product}</span>
                            )}
                        </div>

                        <div className='col-lg-4'>
                            <input
                                type='text'
                                placeholder={t('intentCreate.Qty')}
                                className={`intent-qty ware-color ${validationErrors.quantity ? 'error-border' : ''}`}
                                value={currentQuantity}
                                onChange={(e) => {
                                    setCurrentQuantity(e.target.value);
                                    const quantity = parseInt(e.target.value, 10);
                                    if (isNaN(quantity) || quantity <= 0) {
                                        setValidationErrors({ quantity: 'Please enter a valid quantity.' });
                                    } else {
                                        setValidationErrors({ quantity: '' });
                                    }
                                }}
                            />
                            {validationErrors.quantity && (
                                <div className="error-message pl-2">{validationErrors.quantity}</div>
                            )}
                        </div>

                        <div className='col-lg-4'>
                            <button onClick={handleAddProduct} className='quantity-add-sto'>{t("intentCreate.Add")}</button>
                        </div>
                    </div>
                    <hr className='mt-3' />

                    <div className='row mt-4'>
                        <div className='col-lg-5'>
                            <b className='ware-color'>{t("STO.Source Warehouse")}</b>
                            <span className='text-danger'>*</span><br />
                            <Typeahead
                                id="sourceWarehouseTypeahead"
                                labelKey="name"
                                options={warehouses}
                                selected={selectedSourceWarehouse ? [selectedSourceWarehouse] : []}
                                onChange={handleSourceWarehouseSelect}
                                placeholder={t("STO.Source Warehouse")}
                                className={`type-head_sto ${validationErrors.sourceWarehouse ? 'error-border' : ''}`}
                            />
                            {validationErrors.sourceWarehouse && (
                                <span className="error-message">{validationErrors.sourceWarehouse}</span>
                            )}
                        </div>

                        <div className='col-lg-1'></div>

                        <div className='col-lg-5'>
                            <b className='ware-color'>{t("STO.Destination Warehouse")}</b>
                            <span className='text-danger'>*</span><br />
                            <Typeahead
                                id="destinationWarehouseTypeahead"
                                labelKey="name"
                                options={warehouses}
                                selected={selectedDestinationWarehouse ? [selectedDestinationWarehouse] : []}
                                onChange={handleDestinationWarehouseSelect}
                                placeholder={t("STO.Destination Warehouse")}
                                className={`type-head_sto ${validationErrors.destinationWarehouse ? 'error-border' : ''}`}
                            />
                            {validationErrors.destinationWarehouse && (
                                <span className="error-message">{validationErrors.destinationWarehouse}</span>
                            )}
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-lg-5 mt-2'>
                            <b className='ware-color'>{t("intentCreate.Delivery Date")}</b>
                            <span className='text-danger'>*</span><br />
                            <input
                                type='date'
                                className={`STO-date ${validationErrors.deliveryDate ? 'error-border' : ''}`}
                                placeholder='selected date'
                                onChange={handleDeliveryDateChange}
                                selected={expectedDeliveryDate}
                            />
                            <br />
                            {validationErrors.deliveryDate && (
                                <span className="error-message">{validationErrors.deliveryDate}</span>
                            )}
                        </div>

                        <div className='col-lg-1'></div>

                        <div className="col-lg-5 mt-2 mr-2">
                            <b className='ware-color'>{t("STO.Type")}</b>
                            <span className="text-danger">*</span>
                            <br />
                            <select
                                className="sto-type ware-color"
                                value={transactionType}
                                onChange={handleTransactionTypeChange}>
                                <option value="In" disabled>{t("STO.In")}</option>
                                <option value="Out">{t("STO.Out")}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <button
                    className="stock-save"
                    onClick={handleSave}
                    disabled={isSaving}>
                    {isSaving ? 'Saving...' : t("STO.Save")}
                </button>

                <button
                    className="sto_challan1 mx-2 mb-2">
                    <a
                        rel="noopener noreferrer"
                        target="_blank">
                        {t("STO.delivery challan")}
                    </a>
                </button>

                <button
                    className='stock-cancel'
                    onClick={handleClose}>
                    {t("intentCreate.Cancel")}
                </button>
            </div>
        </div>
    )
}

export default CreateStock;