import React, { useState, useEffect } from "react";
import { CategoryHeader } from '../../components/header/Header';
import CategoryDeletePopup from "./CategoryDeletePopup";
import CategoryUpdateSidebar from "./CategoryUpdateSidebar";
import { Tooltip as ReactTooltip } from "react-tooltip";
import API from "../../services/Api";
import { useLanguage } from '../../LanguageContext';
import ReactPaginate from 'react-paginate';
import './Category.scss';
import STORAGE_KEYS from "../../utils/storageKey";
import 'react-toastify/dist/ReactToastify.css';

const Category = () => {
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useLanguage();
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [expandedTooltips, setExpandedTooltips] = useState({});
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCategoryData = (query) => {
    fetch(`${API.commerceAPI}/commerce/category/all/getByGroupId/${groupId}?search=${query}`)
      .then((result) => result.json())
      .then((resp) => {
        const items = resp?.data?.items || [];
        setCategory(items.reverse());
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchCategoryData(searchQuery);
  }, [searchQuery]);

  const filteredCategories = category.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const offset = currentPage * itemsPerPage;
  const currentItems = Array.isArray(filteredCategories) ? filteredCategories.slice(offset, offset + itemsPerPage) : [];

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  useEffect(() => {
    const getZoomLevel = () => {
      return Math.round((window.outerWidth / window.innerWidth) * 100);
    };
    const updateItemsPerPage = () => {
      const zoomLevel = getZoomLevel();
      if (zoomLevel === 100) {
        setItemsPerPage(10);
      } else if (zoomLevel <= 90) {
        setItemsPerPage(12);
      }
    };
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const truncateText = (text, maxLength) => {
    if (text) {
      if (text?.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + "...";
    }
    return "";
  };

  const openDeleteConfirmation = (categoryId) => {
    setDeleteCategoryId(categoryId);
    setIsDeleteConfirmationVisible(true);
  };

  const openSidebar = (categoryId) => {
    const selected = category.find((item) => item._id === categoryId);
    setSelectedCategory(selected);
  };

  const handleClose = () => {
    setSelectedCategory(null);
  };

  const handleImageError = (e, placeholderImagePath) => {
    e.target.src = placeholderImagePath;
  };

  const toggleDescription = (categoryId) => {
    setExpandedTooltips({
      ...expandedTooltips,
      [categoryId]: !expandedTooltips[categoryId]
    });
  };

  return (
    <div className="main">
      <div className="body_container">
        {selectedCategory && (
          <div>
            <CategoryUpdateSidebar handleClose={handleClose} selectedCategory={selectedCategory} _id={selectedCategory._id} fetchCategory={fetchCategoryData} />
          </div>
        )}

        <div className="main-padding1">
          <CategoryHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} fetchCategory={fetchCategoryData} />
          <hr className='hr_height' />

          <div className="grid-container category-list-scroll">
            {loading ? (
              <div>
                <div className='spinner-border LODER'></div>
              </div>
            ) : (
              currentItems?.length > 0 ? (
                currentItems.map((category, index) => (
                  <div key={category._id} className="grid-item ml-2">
                    <img
                      src={category.imageUrl}
                      className='category-image'
                      alt={`category ${category._id}`}
                      onError={(e) => handleImageError(e, 'https://icon-library.com/images/category-icon-png/category-icon-png-14.jpg')}
                    />
                    <b className='category-title' data-tooltip-id={`tooltip-${category._id}`}>{category.name}</b>
                    <ReactTooltip id={`tooltip-${category._id}`} clickable={true} >
                      <div className="tooltip-content">
                        {expandedTooltips[category._id]
                          ? category.description
                          : truncateText(category.description, 100)}
                        <button className="read-more-btn" onClick={() => toggleDescription(category._id)}>
                          {expandedTooltips[category._id] ? 'Read Less' : 'Read More'}
                        </button>
                      </div>
                    </ReactTooltip>

                    <div className="d-inline-flex">
                      <p className="category-title" data-tooltip-id={`tooltip-${category._id}`}>
                        {truncateText(category.description, 14)}
                      </p>
                      <div className="ml-2">
                        <i
                          className="fa-solid fa-pencil category-edit"
                          onClick={() => openSidebar(category._id)}
                          alt={`category ${category._id}`}
                        />
                      </div>

                      <div className="">
                        <i className="fa-solid fa-trash ware-trash ml-2" onClick={() => openDeleteConfirmation(category._id)} />
                      </div>
                    </div>

                    {isDeleteConfirmationVisible && (
                      <CategoryDeletePopup
                        categoryId={deleteCategoryId}
                        onClose={() => setIsDeleteConfirmationVisible(false)}
                      />
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <img src="/no-data-image.png" alt="No data found" className="no-data" />
                </div>
              )
            )}
          </div>

          <div className="d-inline-flex pagination-container">
            <div className="col-12 p-0 mt-2">
              <ReactPaginate
                className="pagination-bar"
                previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                nextLabel={<i className="fa-solid fa-angle-right"></i>}
                breakLabel={'...'}
                pageCount={Math.ceil(filteredCategories?.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={0}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'Pagination-products'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;