import React from 'react'
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import { useLanguage } from "../../LanguageContext";
import API from '../../services/Api';
import './AllServiceRequest.scss';
import { Link } from 'react-router-dom';
import STORAGE_KEYS from '../../utils/storageKey';

const AllServiceRequests = ({ userId }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [allServiceRequest, setAllServiceRequest] = useState([]);
  const { t } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [noRecordsMessage, setNoRecordsMessage] = useState("");
  const [setData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [setCurrentPage] = useState(0);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  const [perPage] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openNewSidebar, setNewOpenSidebar] = useState(false);

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let checkedItems = allServiceRequest.map((item, index) => index);
      setCheckedItems(checkedItems);
    } else {
      setCheckedItems([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleOpenSidebar = (request) => {
    setSelectedRequest(request);
    setOpenSidebar(true);
  };

  const handleSingleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, index]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const [setShowDropdown] = useState(false);
  const [selectedItem] = useState(null);

  const handleOptionClick = (option, e) => {
    e.stopPropagation();
    if (option === "WhatsApp" && selectedItem?.userId?.phoneNumber) {
      const whatsappUrl = `https://web.whatsapp.com/send?phone=${selectedItem.userId.phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    } else if (option === "Email" && selectedItem?.email) {
      const emailUrl = `mailto:${selectedItem.email}`;
      window.location.href = emailUrl;
    } else {
      console.log(`Selected option "${option}" for item:`, selectedItem);
    }
    setShowDropdown(false);
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const headers = {
    Authorization: `${refreshToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetch(`${API.commerceAPI}/commerce/servicerequest/group/${groupId}?userId=${userId}`, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setAllServiceRequest(data?.data)
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setNoRecordsMessage("No Data Found .");
          setData([]);
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    const totalItems = allServiceRequest?.length;
    const pages = Math.ceil(totalItems / perPage);
    setPageCount(pages);
  }, [perPage, userId]);

  function addSpaceToStatus(status) {
    if (status === "Inprogress" || status === "Inprocess") {
      return status
        .replace("Inprogress", "In Progress")
        .replace("Inprocess", "In Process");
    } else {
      return status;
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'new':
        return '#A29797';
      case 'inprogress':
        return '#1BCB4B';
      case 'open':
        return 'blue';
      case 'blocked':
        return '#F81B1B';
      case 'completed':
        return '#0BB7CE';
      case 'rejected':
        return '#DBBC11';
      default:
        return 'black';
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className="mt-3 table-scroll-sr service p-0">
            <table className="table table-hover  ">
              <thead className="font14">
                <tr className="dark-text-theme table-head">
                  <th className="checkbox-input">
                    <input
                      type="checkbox"
                      className="cursor-pointer "
                      name="check"
                      checked={allChecked}
                      onChange={handleAllChecked}
                    ></input>
                  </th>

                  <th
                    scope="col"
                    className="table-head">
                    {t("table.title")}
                  </th>

                  <th
                    scope="col"
                    className="table-head "
                    style={{ width: "140px" }}>
                    {t("table.customerName")}
                  </th>

                  <th
                    scope="col"
                    className="table-head ">
                    {t("table.phoneNo")}
                  </th>

                  <th
                    scope="col"
                    className="table-head ">
                    {t("table.assignTo")}
                  </th>

                  <th
                    scope="col"
                    className="table-head ">
                    {t("table.location")}
                  </th>

                  <th
                    scope="col"
                    className="table-head ">
                    {t("table.Date")}
                  </th>

                  <th
                    scope="col"
                    className="table-head">
                    {t("table.status")}
                  </th>

                  <th
                    scope="col"
                    className="table-head font14">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <div className="spinner-border  table-loader" role="status"></div>
                ) :
                  (Array.isArray(allServiceRequest) && allServiceRequest?.length > 0) || (allServiceRequest?.e || false) ? (
                    allServiceRequest?.map((item, index) => (
                      <tr key={index}>
                        <td className="t-data font13">
                          <input
                            type="checkbox"
                            className="cursor-pointer "
                            name="check"
                            checked={checkedItems.includes(index)}
                            onChange={(e) =>
                              handleSingleCheckboxChange(e, index)
                            }
                          ></input>
                        </td>

                        <td
                          className="t-data font13"
                        >
                          {typeof item?.title === "object"
                            ? "-"
                            : item?.title || "--"}
                        </td>

                        <td className="t-data font13">
                          {typeof item?.name === "object"
                            ? "-"
                            : item?.name || "--"}
                        </td>

                        <td
                          className="t-data font13 ">
                          {typeof item?.phoneNumber === "object"
                            ? "-"
                            : item?.phoneNumber || "--"}
                        </td>

                        <td
                          className="t-data font13">
                          {typeof item?.assignedTo === "object"
                            ? "-"
                            : item?.assignedTo || "--"}
                        </td>

                        <td
                          className="t-data font13">
                          {typeof item?.locationName === "object"
                            ? "-"
                            : item?.locationName || "--"}
                        </td>

                        <td
                          className="t-data font13 locate-color">
                          {typeof item?.DateTime === "object"
                            ? "--"
                            : (
                              <span>
                                {new Date(item?.DateTime).toLocaleDateString("en-US", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}{" "}
                                {new Date(item?.DateTime).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </span>
                            )
                          }
                        </td>

                        <td
                          className="t-data font13 status-color">
                          {item?.status ? (
                            <span style={{ color: getStatusColor(item?.status), fontWeight: "bold" }}>
                              {addSpaceToStatus(
                                item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                              )}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>

                        <td className="t-data font13">
                          <div className="dropdown">
                            <i
                              className="fas fa-ellipsis-h dot-icon"
                              id={`dropdownMenuButton${index}`}
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                            </i>

                            <div
                              className="dropdown-menu dropdown-style"
                              aria-labelledby={`dropdownMenuButton${index}`}>
                              <button
                                onClick={(e) => handleOptionClick("WhatsApp", e)}
                                className={`p-2 dropdown-item text-primary font13 ${!selectedItem?.phoneNumber ? "disabled" : ""}`}>
                                WhatsApp
                              </button>

                              <button
                                onClick={(e) => handleOptionClick("Email", e)}
                                className={`p-2 dropdown-item font13 ${!selectedItem?.email ? "disabled" : ""}`}>
                                Email
                              </button>

                              <Link to={`/customer-details/${item?.userId}`}>
                                <button
                                  onClick={(e) => handleOptionClick("View Customer", e)}
                                  className="p-2 dropdown-item font13 text-primary">
                                  View Customer
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <td colSpan="9" className="text-center">
                        {noRecordsMessage ? (
                          <img
                            src="./../no-data-image.png"
                            className="data-image"
                            alt="No Data Found"
                          />
                        ) : (
                          <img
                            src="./../no-data-image.png"
                            className="no-order"
                            alt="No Data Found"
                          />
                        )}
                      </td>
                    </>
                  )}
              </tbody>
            </table>
          </div>
        </div>

        <div className='d-inline-flex pagination-container'>
          <ReactPaginate
            className="pagination-bar"
            previousLabel={<i className="fa-solid fa-chevron-left"></i>}
            nextLabel={<i className="fa-solid fa-angle-right"></i>}
            breakLabel={"..."}
            pageCount={Math.ceil(allServiceRequest.length / perPage)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={0}
            onPageChange={onPageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={'Pagination-products'}
          />
        </div>
      </div>
    </>
  )
}
export default AllServiceRequests