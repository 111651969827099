import React, { useState } from 'react';
import "./header.scss";
import WarehouseSidebar from '../warehouse-sidebar/WarehouseSidebar';
import { Link } from 'react-router-dom';
import CategorySidebar from "../../pages/category/CategorySidebar"
import FilterComponent from '../Filter/filterSidebar';
import { useLanguage } from '../../LanguageContext';
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from '../../utils/storageKey';
import { ToastContainer } from 'react-toastify';

const Header = () => {
  const { t } = useLanguage();
  return (
    <div> </div>
  )
}
export default Header;

export function EmployeeHeader({ searchQuery, setSearchQuery }) {
  const { t } = useLanguage();
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION)

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6  p-0 m-0 main-customer">
            <div className="d-inline-flex  customer-main-1">
              <small className="customers pt-2" href="#">
                <b>{t('employee.title')}</b>
              </small>
            </div>

            <p className="signed-members mx-2">
              {t('employee.subtitle')}
            </p>
          </div>

          <div className="col-6  ">
            <div className="d-inline-flex float-right mt-3">
              {tokenPermissions && tokenPermissions.includes(Permissions.MES) && (
                <div className="search-member mt-1">
                  <i className="fa-solid fa-magnifying-glass search-icon mt-2"></i>
                  <input
                    className='search '
                    type="text"
                    placeholder={t('employee.Search Employees')}
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              )}

              {tokenPermissions && tokenPermissions.includes(Permissions.MEAE1) && (
                <Link to={`/create-employee/new`} >
                  <div className="filter mx-2">
                    <i className="fa-solid fa-plus plus-icon "
                      title="Add Customer">
                    </i>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProductHeader = ({ onSearch, searchQuery, onApplyFilter }) => {
  const [inputValue, setInputValue] = useState(searchQuery);
  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const tokenPermissions = localStorage.getItem('permissions');
  const { t } = useLanguage();

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setInputValue(newQuery);
    onSearch(newQuery);
  };

  const toggleFilterComponent = () => {
    setShowFilterComponent(!showFilterComponent);
  };
  
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <h3 className='p-0 '>
          <strong className=' product-title1'>{t("product.title")}</strong></h3>
        <p className='sub-titles'>{t("product.productText")}</p>
      </div>

      <div className="d-flex align-items-center mr-1">
        {tokenPermissions && tokenPermissions.includes(Permissions.MPS) && (
          <div className="me-3">
            <i className="fa-solid fa-magnifying-glass searching-1"></i>
            <input type="search"
              placeholder={t("product.Search Product")}
              className='product-search'
              value={inputValue}
              onChange={handleInputChange} />
          </div>
        )}

        <div className="me-3">
          <button className="plus" onClick={toggleFilterComponent}>
            <i className="fa-solid fa-filter mt-2"></i>
          </button>
        </div>

        {showFilterComponent && (
          <FilterComponent onClose={() => setShowFilterComponent(false)} onApplyFilter={onApplyFilter} />
        )}

        <div>
          {tokenPermissions && tokenPermissions.includes(Permissions.MPAP1) && (
            <button className="plus">
              <Link to="/product-form/new"><i className="fa-solid fa-plus text-dark mt-2"></i></Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export function WarehouseHeader({ searchQuery, setSearchQuery, fetchWareHouse }) {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 main-customer">
            <div className="m-0 p-0">
              <h4 className="ware-title"><strong>{t('warehouse.title')}</strong></h4>
            </div>
            <p className="ware-subline">{t('warehouse.subtitle')}</p>
          </div>

          <div className="col-6">
            <div className="d-flex align-items-center float-right">

              <div className="me-1 mt-2 search-ware">
                <i className="fa-solid fa-magnifying-glass searching w-search-icon"></i>
                <input type="text" placeholder={t("warehouse.Search name/location")}
                  className='warehouse-search' aria-label="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <div className="filter mx-2 mt-2" onClick={handleOpen}>
                <i className="fa-solid fa-plus plus-icon "></i>
              </div>

              {open && <WarehouseSidebar handleClose={handleOpen} fetchWareHouse={fetchWareHouse} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const CategoryHeader = ({ searchQuery, setSearchQuery, fetchCategory }) => {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
      <ToastContainer autoClose={2000}/>
        <h3 className='pt-2 ml-2 category-title'><strong>{t('catogory.title')}</strong></h3>
        <b className='sub-title ml-2'>{t('catogory.subtitle')}</b>
      </div>

      <div className="d-flex align-items-center mr-4">
        <div className="me-3">
          <i className="fa-solid fa-magnifying-glass searching-category"></i>
          <input type="search" placeholder={t('catogory.Search Category')} className='product-search' value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div>
          <button className="plus" onClick={handleOpen}>
            <i className="fa-solid fa-plus text-dark pt-2"></i>
          </button>
        </div>
        {open && <CategorySidebar handleClose={handleOpen} isOpen={open} handleSidebarClose={handleClose} fetchCategory={fetchCategory} />}
      </div>
    </div>
  );
};

export const IntentHeader = ({ toggleCreateIntent }) => {
  const { t } = useLanguage();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '7px' }}>
      <div>
        <h5 className='intent-title'><strong>{t("intent.Intents")}</strong></h5>
        <p className='intent-title'>{t("intent.List of all intents")}</p>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button className='intent-button' onClick={toggleCreateIntent}>
          {t("intent.New")}
        </button>
      </div>
    </div>
  );
}

