import React, { useState } from "react";
import "./AddCustomer.scss";
import { useLanguage } from "../../LanguageContext";
import API from "../../services/Api";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import Permissions from "../../components/jwttoken-decode-service/Permission";
import STORAGE_KEYS from "../../utils/storageKey";

function AddCustomer({ handleClose, fetchData, setOpenCustomerSidebar }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [panCardError, setPanCardError] = useState('');
  const { t } = useLanguage();
  const [upiId, setUpiId] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const billingAddressCheckbox = document.getElementById("vehicle1");
  const shippingAddressCheckbox = document.getElementById("vehicle2");
  const defaultCheckbox = document.getElementById("vehicle3");
  const [customerType, setCustomerType] = useState("individual");
  const [selectedState, setSelectedState] = useState([]);
  const tokenPermissions = localStorage.getItem(STORAGE_KEYS.PERMISSION);

  const [customerData, setCustomerData] = useState({
    name: "",
    phoneNumber: parseInt(),
    location: "",
    pinCode: "",
    panCard: "",
    aadharNumber: "",
    email: "",
    gstDetails: {},
    groupId: parseInt(localStorage.getItem(STORAGE_KEYS.GROUPID)),
    roleId: 1701427961185,
    password: "123456",
    source: "web",
  });

  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    location: "",
    pinCode: "",
    panCard: "",
    aadharNumber: "",
    email: "",
    openingBalance: "",
    line1: "",
    locality: "",
    city: "",
    country: "",
    state: "",
    zip: "",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleViewFile = () => {
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      window.open(fileUrl, '_blank');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    const letterAndSpaceRegex = /^[A-Za-z\s]*$/;
    if (name === "name" || name === "lastName") {
      if (letterAndSpaceRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid Name",
        }));
      }
    }

    setCustomerData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValidEmail ? "" : "Invalid email address",
      }));
    }

    if (name === "phoneNumber") {
      if (value?.length !== 10 || isNaN(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be 10 digits",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }

    if (name === "pinCode") {
      if (value?.length !== 6 || isNaN(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "PIN code must be exactly 6 digits",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
    setError('');

    if (name === 'aadharNumber') {
      if (value?.length !== 12 || isNaN(value)) {
        setError('Aadhar Number must be exactly 12 digits');
      } else {
        setError('');
      }
    }
    setPanCardError('');

    if (name === 'panCard') {
      if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(value)) {
        setPanCardError('Invalid PAN Card format. Please use a valid format.');
      } else {
        setPanCardError('');
      }
    }
  };

  const [addressData, setAddressData] = useState({
    line1: "",
    locality: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (["line1", "line2", "city", "country", "state", "locality"].includes(name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "" ? `${name} is required` : "",
      }));
    }

    if (name === 'zip') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: /^\d{6}$/.test(value) ? '' : 'Zip should be a 6-digit number.',
      }));
    }

    if (['country', 'state', 'city', 'locality'].includes(name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: /\d/.test(value) ? `${name} should only contain letters.` : '',
      }));
    }

    if (name === 'cars') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value ? '' : 'Please select an address.',
      }));
    }
  };

  const handleCancel = () => {
    setAddressData({
      line1: "",
      locality: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });

    setCustomerData({
      name: "",
      phoneNumber: "",
      location: "",
      pinCode: "",
      email: "",
    });
    setErrors({});
  };

  const handleSave = () => {
    setIsLoading(true);
    const validationErrors = {};
    const addressErrors = {};
    if (!customerData || !customerData.name || !customerData.name.trim()) {
      validationErrors.name = 'Name is required';
    }

    if (
      !customerData ||
      typeof customerData.phoneNumber !== 'string' ||
      !customerData.phoneNumber ||
      !customerData.phoneNumber.trim() ||
      customerData.phoneNumber.trim()?.length !== 10 ||
      isNaN(customerData.phoneNumber.trim())
    ) {
      validationErrors.phoneNumber =
        'Phone Number is required and must be 10 digits';
    }

    if (!customerData || !customerData.location || !customerData.location.trim()) {
      validationErrors.location = 'Location is required';
    }

    if (
      !customerData ||
      !customerData.pinCode ||
      !customerData.pinCode.trim() ||
      customerData.pinCode.trim()?.length !== 6 ||
      isNaN(customerData.pinCode.trim())
    ) {
      validationErrors.pinCode = 'PIN Code is required and must be 6 digits';
    }

    if (!addressData || !addressData.cars || !addressData.cars.trim()) {
      addressErrors.cars = 'Please select an address';
    }

    if (!addressData || !addressData.line1 || !addressData.line1.trim()) {
      addressErrors.line1 = 'Line1 is required';
    }

    if (!addressData || !addressData.locality || !addressData.locality.trim()) {
      addressErrors.locality = 'Line2 is required';
    }

    if (!addressData || !addressData.city || !addressData.city.trim()) {
      addressErrors.city = 'City is required';
    }

    if (!addressData || !addressData.state || !addressData.state.trim()) {
      addressErrors.state = 'State is required';
    }

    if (!addressData || !addressData.country || !addressData.country.trim()) {
      addressErrors.country = 'Country is required';
    }

    if (!addressData || !addressData.zip || !addressData.zip.trim()) {
      addressErrors.zip = 'Zip is required';
    } else if (!/^\d{6}$/.test(addressData.zip.trim())) {
      addressErrors.zip = 'Zip should be a 6-digit number';
    }

    const allValidationErrors = { ...validationErrors, ...addressErrors };
    if (Object.keys(allValidationErrors)?.length > 0) {
      setErrors(allValidationErrors);
      setIsLoading(false);
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const accountDetailsPayload = {
            accountDetails: [
              {
                accountDetails: {
                  upi: {
                    upi: upiId,
                  },
                },
              },
            ],
          };

          const addressPayload = {
            line1: addressData.line1,
            line2: addressData.locality,
            tag: addressData.cars,
            locality: addressData.locality,
            state: addressData.state,
            country: addressData.country,
            city: addressData.city,
            zip: addressData.zip,
            billingAddress: billingAddressCheckbox
              ? billingAddressCheckbox.checked
              : false,
            shippingAddress: shippingAddressCheckbox
              ? shippingAddressCheckbox.checked
              : false,
            default: defaultCheckbox ? defaultCheckbox.checked : false,
          };
          const flattenedAddresses = [
            {
              ...addressPayload,
            },
          ];

          const apiUrl = `${API.apiGateway}/commerce-gw/customer/save`;
          const { panCard, aadharNumber, openingBalance, ...rest } = customerData;

          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              panCard,
              aadharNumber,
              openingBalance,
              ...rest,
              ...accountDetailsPayload,
              addresses: flattenedAddresses,
              source: 'web',
              latitude,
              longitude,
            }),
          };

          fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((savedData) => {
              toast.success('Customer Created Successfully !', {
                style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
              });
              setOpenCustomerSidebar(false);
              fetchData();
              setIsLoading(false);
            })
            .catch((error) => {
              console.error('Error saving customer:', error);
              setIsLoading(false);
              toast.error('Failed to save customer', {
                style: {
                  backgroundColor: '#d9534f',
                  color: 'white',
                  height: '50px',
                },
              });
            });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleCustomerTypeChange = (type) => {
    setCustomerType(type);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ background: "red", color: "white" }}
      />

      <div className="add-customer-sidebar">
        <div className="side-padding">
          <div className="container-fluid p-0">
            <div className="row container-fluid add-customer-title">
              <div>
                <h6 className="add-customer-title-1">
                  <b>{t("sidebarcustomers.title")}</b>
                </h6>
                <p className="font14 add-customer-title-2">
                  {t("sidebarcustomers.subtitle")}
                </p>
                <hr className="sr-hr hr-line-address "></hr>
              </div>
              <i
                className="fa-solid fa-circle-xmark close-icon-xmark"
                onClick={handleClose}
              ></i>
            </div>

            <div className="sidebar-wrapper-customer ">
              <div className="container-fluid d-inline-flex  font14 ">
                <div className=" col- d-flex radio_btn_business_individual">
                  <span className="orange-color2 mb-1">{t("sidebarcustomers.Customer Type")}</span>
                  <div className="d-flex pl-5">
                    <input
                      type="radio"
                      name="userType"
                      checked={customerType === "individual"}
                      onChange={() => handleCustomerTypeChange("individual")}
                      className=" "
                    />
                    <div className="ml-2 radio_button">
                      {t("addcustomer.individual")}
                    </div>
                  </div>
                </div>

                <div className="d-flex ml-5">
                  <input
                    type="radio"
                    name="userType"
                    checked={customerType === "business"}
                    onChange={() => handleCustomerTypeChange("business")}
                    className="cust-radio"
                  />
                  <div className="ml-2 radio_button">
                    {t("addcustomer.business")}
                  </div>
                </div>
              </div>

              <p className="orange-color1 font14 pt-2 px-4 set-proper-title">
                {t("sidebarcustomers.Personal Details")}
              </p>

              <div className="container-fluid row set-inputs">
                <div className="col-4">
                  <label className="font14">
                    {customerType === "business"
                      ? t("table.companyName")
                      : t("table.customerName")}
                    <span className="star_color">*</span>
                  </label>
                  <input
                    type="text"
                    className={`pl-2 font14 add-customer-inputs ${errors.name ? "is-invalid" : ""
                      }`}
                    placeholder={t("sidebarcustomers.Enter Name")}
                    onChange={handleInputChange}
                    value={customerData?.name}
                    name="name"
                  />
                  {errors.name && (
                    <div
                      className="invalid-feedback mt-2 mr-3"
                      style={{ marginLeft: "0px" }}>
                      {errors.name}
                    </div>
                  )}
                </div>

                <div className="col-4">
                  <label className="font14 mr-2">
                    {t("sidebarcustomers.Mobile Number")}
                    <span className="star_color">*</span>
                  </label>
                  <br />
                  <input
                    type="tel"
                    maxLength="10"
                    pattern="\d{10}"
                    className={`pl-2 font14 add-customer-inputs ${errors.phoneNumber ? "is-invalid" : ""}`}
                    placeholder={t("sidebarcustomers.Mobile Number")}
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                    value={customerData?.phoneNumber || ""}
                    name="phoneNumber"
                  />
                  {errors.phoneNumber && (
                    <div
                      className="invalid-feedback"
                      style={{ marginLeft: "0px" }}>
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>

                <div className="col-4 Location ">
                  <label className="font14 ">
                    {t("sidebarcustomers.Location")}
                    <span className="star_color">*</span>
                  </label>
                  <br></br>
                  <input
                    type="text"
                    className={`pl-2 font14 add-customer-inputs ${errors.location ? "is-invalid" : ""
                      }`}
                    placeholder={t("sidebarcustomers.Location")}
                    onChange={handleInputChange}
                    value={customerData?.location}
                    name="location"
                  />
                  {errors.location && (
                    <div
                      className="invalid-feedback"
                      style={{ marginLeft: "0px" }}>
                      {errors.location}
                    </div>
                  )}
                </div>
              </div>

              <div className="container-fluid row set-inputs">
                <div className="col-4">
                  <label className="font14 pt-2">
                    {t("requestsidebar.pinCode")}
                    <span className="star_color">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className={`pl-2 font14 add-customer-inputs work_phone ${errors.pinCode ? "is-invalid" : ""}`}
                    placeholder={t("requestsidebar.pinCode")}
                    onChange={handleInputChange}
                    value={customerData?.pinCode}
                    name="pinCode"
                    maxLength="6"
                    pattern="\d{6}"
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.pinCode && (
                    <div
                      className="invalid-feedback phone_validation"
                      style={{ marginLeft: "0px" }}>
                      {errors.pinCode}
                    </div>
                  )}
                </div>

                <div className="col-4">
                  <label className="font14 pt-2">
                    {t("sidebarcustomers.Customer Email")}
                  </label>
                  <br></br>
                  <input
                    type="email"
                    className={`pl-2 font14 add-customer-inputs ${errors.email ? "is-invalid" : ""
                      }`}
                    onChange={handleInputChange}
                    value={customerData?.email}
                    name="email"
                    placeholder={t("sidebarcustomers.Customer Email")}
                  />
                </div>
                <div className="col-4"></div>
              </div>

              <hr className="mt-4 sr-hr hr-line-address1"></hr>
              <p className="orange-color1 font16  pl-4 set-proper-title">
                {t("sidebarcustomers.Other Details")}
              </p>

              <div className="container-fluid row set-inputs  mt-3">
                <div className="col-3">
                  <label className="font14 GstTreatment pl-1">
                    {t("sidebarcustomers.GST Treatment")}
                  </label>
                </div>

                <div className="col-4  GSTTreatment">
                  <select className="pl-3 font14 add-customer-inputs">
                    <option>{t("sidebarcustomers.Select A GST Treatment")}</option>
                    <option>{t("sidebarcustomers.Registrated Business -Regular")}</option>
                    <option>{t("sidebarcustomers.Registrated Business -Composition")}</option>
                    <option>{t("sidebarcustomers.Unregistrated Business")}</option>
                    <option>{t("sidebarcustomers.Consumer")}</option>
                    <option>{t("sidebarcustomers.Overseas")}</option>
                    <option>{t("sidebarcustomers.Special Economic Zone")}</option>
                  </select>
                </div>
              </div>

              <div className="container-fluid row set-row pt-4">
                <div className="col-4">
                  <label className="font14 ">
                    {t("sidebarcustomers.Place Of Supply")}
                  </label>
                  <br></br>
                  <div>
                    <Typeahead
                      className="select-Payment-Terms"
                      id="indianStatesTypeahead"
                      labelKey="state"
                      options={indianStates}
                      placeholder={t("sidebarcustomers.Search Indian States...")}
                      selected={selectedState}
                      onChange={(selected) => setSelectedState(selected)}
                    />
                  </div>
                </div>

                <div className="col-8 txaradio set-tax">
                  <p className=" font14 pt-1 orange-color1">
                    {t("sidebarcustomers.Tax Preference")}
                  </p>

                  <div className="d-flex ml-2">
                    <div className="d-flex ">
                      <input
                        type="radio"
                        className="cursor-pointer"
                        name="userType"
                      ></input>

                      <div className="ml-2 radio_btn ">
                        {t("addcustomer.taxable")}
                      </div>
                    </div>

                    <div className="ml-4 d-flex">
                      <input
                        type="radio"
                        className="cursor-pointer"
                        name="userType"
                      ></input>

                      <div className="ml-2 radio_btn ">
                        {t("addcustomer.tax-exempt")}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>

              <br></br>
              <div className="container-fluid row set-row">
                <div className="col-4">
                  <label className="font14 pt-2 pl-2">
                    {t("sidebarcustomers.Currency")}
                  </label>
                  <br></br>
                  <select
                    id="currencySelect"
                    className="pl-2 ml-2 font14 add-customer-inputs currency_input ">
                    <option value="INR" className="font14">
                      INR
                    </option>

                    <option value="USD" className="font14">
                      USD
                    </option>

                    <option value="EUR" className="font14">
                      EUR
                    </option>

                    <option value="GBP" className="font14">
                      GBP
                    </option>
                  </select>
                </div>

                <div className="col-4">
                  <label className="font14 pt-2 pl-2">
                    {t("sidebarcustomers.Opening Balance")}
                  </label>
                  <br></br>
                  <input
                    type="text"
                    className="pl-2 ml-2 font14 add-customer-inputs "
                    onChange={handleInputChange}
                    value={customerData?.openingBalance}
                    name="openingBalance"
                    placeholder={t("sidebarcustomers.Opening Balance")}
                  />
                </div>

                <div className="col-4">
                  <label className="font14 pt-2">
                    {t("sidebarcustomers.Payment Terms")}
                  </label>
                  <br></br>
                  <select
                    className="select-status pl-2">
                    <option disabled>{t("sidebarcustomers.Select Terms")}</option>
                    <option value="Due end of the month">{t("sidebarcustomers. Due end of the month")}</option>
                    <option value="Net 15">{t("sidebarcustomers.Net 15")}</option>
                    <option value="Net 30">{t("sidebarcustomers.Net 30")}</option>
                    <option value="Net 45">{t("sidebarcustomers.Net 45")}</option>
                    <option value="Net 60">{t("sidebarcustomers.Net 60")}</option>
                    <option value="Due on Receipt">{t("sidebarcustomers.Due on Receipt")}</option>
                  </select>
                </div>
              </div>

              <div className="container-fluid row set-row">
                <div className="col-4">
                  <label className="font14 pt-2  mt-2 pl-2">
                    {t("sidebarcustomers.PAN Card")}
                  </label>
                  <br></br>
                  <input
                    type="text"
                    className={`pl-2 ml-2 font14 add-customer-inputs panCard_input ${panCardError ? "is-invalid" : ''}`}
                    placeholder={t("sidebarcustomers.PAN Card")}
                    onChange={handleInputChange}
                    value={customerData?.panCard}
                    name="panCard"
                  />
                  {panCardError && <div className="error-message">{panCardError}</div>}
                </div>

                <div className="col-4">
                  <label className="font14 pt-2 fix-label mt-2 pl-2">
                    {t("sidebarcustomers.Aadhar Number")}
                  </label>
                  <br />
                  <input
                    type="tel"
                    className={`pl-2 ml-2 font14 add-customer-inputs ${error ? "is-invalid" : ''}`}
                    placeholder={t("sidebarcustomers.Aadhar Number")}
                    onChange={handleInputChange}
                    value={customerData.aadharNumber}
                    name="aadharNumber"
                    maxLength={12}
                  />
                  {error && <div className="error-message">{error}</div>}
                </div>

                <div className="col-4 Document">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="hiddenFileInput"
                  />
                  <label className="font14 pt-2 mt-2 ml-1">
                    {t("sidebarcustomers.Documents")}
                  </label>
                  <br />
                  <label htmlFor="hiddenFileInput">
                    <i className="fa-solid fa-upload cursor-pointer ml-2"></i>
                  </label>
                  {selectedFile && (
                    <button
                      type="button"
                      className="viewDocument ml-2 mt-1"
                      onClick={handleViewFile}
                    >
                      View
                    </button>
                  )}
                </div>

              </div>

              <hr className="srhr2"></hr>
              <div className="container-fluid row set-upi-input ">
                <div className="col-6 ">
                  <p className="orange-color2 font14 set-proper-title">
                    {t("sidebarcustomers.Shipping & Billing Addresses")}
                  </p>
                </div>

                <div className="col-6 text-right"> </div>
                <div className="container-fluid row set-upi-input">

                  <hr className="srhr1"></hr>
                  <div className="container-fluid row">
                    <div>
                      <div className="row mt-2">
                        <div className=" font14 Tag">
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                            className="checkbox-cursor"
                          />
                          <label htmlFor="vehicle1" className="address_checkbox">
                            {t("Customer.Billing Address")}
                          </label>
                          <input
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value="Car"
                            className="ml-4 checkbox-cursor"
                          />

                          <label htmlFor="vehicle2" className="address_checkbox">{t("Customer.Shipping Address")}</label>
                          <input
                            type="checkbox"
                            id="vehicle3"
                            name="vehicle3"
                            value="Boat"
                            className="ml-4  checkbox-cursor"
                          />

                          <label htmlFor="vehicle3" className="address_checkbox">
                            {t("Customer.Default")}
                          </label>
                          <br></br>

                          <div className="">
                            <div className="d-flex tag-addition">
                              <label htmlFor="cars" className="mt-2"> {t("Customer.tag")}: </label>
                              &nbsp;
                              <select
                                name="cars"
                                id="cars"
                                className="add-address-cursor"
                                onChange={handleAddressInputChange}>
                                <option value="" >{t("Customer.Select Address")}</option>
                                <option value="इतर">इतर</option>
                                <option value="घर">घर</option>
                                <option value="ऑफिस">ऑफिस</option>
                              </select>
                            </div>

                            <div>
                              {errors.cars && <div className="error-message ml-2">{errors.cars}</div>}
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2">
                            {t("Customer.line1")}<span className="star_color">*</span>
                          </label>{" "}
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.line1 && "is-invalid"}`}
                            placeholder={t("Customer.line1")}
                            name="line1"
                            value={addressData.line1}
                            onChange={handleAddressInputChange}
                          />
                          {errors.line1 && <div className="error-message">{errors.line1}</div>} {/* Show error message */}
                        </div>

                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2">
                            {t("Customer.line2")}<span className="star_color">*</span>
                          </label>
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.locality && "is-invalid"}`}
                            placeholder={t("Customer.line2")}
                            name="locality"
                            value={addressData.locality}
                            onChange={handleAddressInputChange}
                          />
                          {errors.locality && <div className="error-message">{errors.locality}</div>} {/* Show error message */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2 mt-2">
                            {t("Customer.city")}<span className="star_color">*</span>
                          </label>
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.city && "is-invalid"}`}
                            placeholder={t("Customer.city")}
                            name="city"
                            value={addressData.city}
                            onChange={handleAddressInputChange}
                          />
                          {errors.city && <div className="error-message">{errors.city}</div>} {/* Show error message */}
                        </div>

                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2 mt-2">
                            {t("Customer.State")}<span className="star_color">*</span>
                          </label>{" "}
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.state && "is-invalid"}`}
                            placeholder={t("Customer.State")}
                            name="state"
                            value={addressData.state}
                            onChange={handleAddressInputChange}
                          />
                          {errors.state && <div className="error-message">{errors.state}</div>} {/* Show error message */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2 mt-2">
                            {t("Customer.country")}<span className="star_color">*</span>
                          </label>{" "}
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.country && "is-invalid"}`}
                            placeholder={t("Customer.country")}
                            name="country"
                            value={addressData.country}
                            onChange={handleAddressInputChange}
                          />
                          {errors.country && <div className="error-message">{errors.country}</div>} {/* Show error message */}
                        </div>

                        <div className="col-6">
                          <label className="kumbh-sans font14 fix-label mr-2 mt-2">
                            {t("Customer.Zip code")}<span className="star_color">*</span>{" "}
                          </label>
                          <br />
                          <input
                            className={`add-customer-inputs input_width pl-2 font14 ${errors.zip && "is-invalid"}`}
                            placeholder={t("Customer.Zip code")}
                            name="zip"
                            maxLength="6"
                            pattern="\d{6}"
                            value={addressData.zip}
                            onChange={handleAddressInputChange}
                            onKeyPress={(event) => {
                              const keyCode = event.keyCode || event.which;
                              const keyValue = String.fromCharCode(keyCode);
                              if (!/^\d+$/.test(keyValue)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.zip && <div className="error-message">{errors.zip}</div>} {/* Show error message */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="save-customer-container">
              {tokenPermissions && tokenPermissions.includes(Permissions.MCACS) && (
                <button
                  className="save-customer-btn font14 mt-2"
                  onClick={handleSave}
                  disabled={isLoading}>
                  {isLoading ? (
                    t("intentCreate.Saving....")
                  ) : (
                    t("sidebarcustomersbtn.Save")
                  )}
                </button>
              )}

              {tokenPermissions && tokenPermissions.includes(Permissions.MCACC) && (
                <button
                  type="button"
                  className="cancel-btn font14 ml-2  "
                  onClick={handleCancel}>
                  {t("sidebarcustomersbtn.Cancel")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCustomer;