import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PaymentDetailsSidebar.scss";
import API from "../../services/Api";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";
import STORAGE_KEYS from "../../utils/storageKey";
import { useLanguage } from "../../LanguageContext";

const PaymentDetailsSidebar = ({
  data, orderId,
  orderDetails,
  totalQuantity, onClose,
  totalProductPrice, driverName,
  status, vehicleNo, driverNo, location, name, contactPerson, trackingNo, trackingURL, selectedDepartment, RemainingAmount, dispatchNo,
  refNo, refDate, transporter, assign, setOpenPaymentSidebar, fetchData, setOpenSidebar,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(); { }
  const [isLoading, setIsLoading] = useState(false);
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
  const [upiId, setUpiId] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [dataOpen, setDataOpen] = useState(true);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const userId = localStorage.getItem(STORAGE_KEYS.USERID);
  const [updateOrder, setUpdateOrder] = useState(false);
  const [allPictures, setAllPictures] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimer, setPopupTimer] = useState(10);
  const { t } = useLanguage()
  const [updatedTransactionId, setUpdatedTransactionId] = useState(null);
  const [paymentStatusError, setPaymentStatusError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");
  const [localDeduction, setLocalDeduction] = useState(RemainingAmount || 0);
  const remainingAmount = RemainingAmount - localDeduction;
  const [currentDate] = useState(new Date());
  const [paymentStatus, setPaymentStatus] = useState();
  const [popupClosed, setPopupClosed] = useState(false);
  const [anotherApiIntervalId, setAnotherApiIntervalId] = useState();
  const [transactionId] = useState(null);
  const [onlineTxnIds, setOnlineTxnIds] = useState(['']);
  const [bankName, setBankName] = useState()
  const [chequeNo, setChequeNo] = useState();
  const [bankNameError, setBankNameError] = useState("");
  const [chequeNoError, setChequeNoError] = useState("");
  const [localDeductionError, setLocalDeductionError] = useState("");
  const [selectDateError, setSelectDateError] = useState("");
  const [chequeAmount] = useState();

  const parseDate = (dateString) => {
    if (!dateString) {
      return null;
    }

    const parts = dateString.split('/');
    if (parts?.length !== 3) {
      return null;
    }
    const [day, month, year] = parts.map(Number);

    if (
      isNaN(day) || isNaN(month) || isNaN(year) ||
      day < 1 || day > 31 ||
      month < 1 || month > 12 ||
      year < 1
    ) {
      return null;
    }
    return new Date(year, month - 1, day);
  };

  const [selectedDate, setSelectedDate] = useState(() => {
    const initialDate = orderDetails?.paymentInfo?.creditPaymentDate;
    const parsedDate = initialDate ? parseDate(initialDate) : null;
    return parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null;
  });

  const [selectDate, setSelectDate] = useState(() => {
    try {
      const initialDates = orderDetails?.paymentInfo?.chequeDate;
      return initialDates ? parseDate(initialDates) : null;
    } catch (error) {
      console.error("Error parsing initial date:", error.message);
      return null;
    }
  });
  const handleViewImage = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const handlePaymentMethodChange = (event) => {
    const selectedMethod = event.target.value;
    setSelectedPaymentMethod(selectedMethod);
    let mode;
    switch (selectedMethod) {
      case "Pay by Cash":
        mode = "Pay by Cash";
        break;
      case "Scan QR Code":
        mode = "Scan QR Code";
        break;
      case "Pay using UPI":
        mode = "Pay using UPI";
        break;
      case "Pay by Credit":
        mode = "Pay by Credit";
        break;
      case "Pay by Online":
        mode = "Pay by Online";
        break;
      case "Pay by Credit":
        mode = "Pay by Credit";
        break;
      case "Pay by Online":
        mode = "Pay by Online";
        break;
      case "Pay by Cheque":
        mode = "Pay by Cheque";
        break;
      default:
        mode = "";
    }
    setSelectedPaymentMode(mode);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectDate(date);
  };

  const handleCancelClick = () => {
    setDataOpen(false);
  };

  const handleUpdateOrder = async (txnId) => {
    if (isLoading || updateOrder) {
      return;
    }
    setIsLoading(true);
    setUpdateOrder(true);

    let paymentModeError = "";
    let paymentStatusError = "";
    let isValid = true;

    if (!bankName || !bankName.trim()) {
      setBankNameError("Bank Name is required");
      isValid = false;
    } else {
      setBankNameError("");
    }

    if (!chequeNo || !chequeNo.trim()) {
      setChequeNoError("Cheque Number is required");
      isValid = false;
    } else {
      setChequeNoError("");
    }

    if (!localDeduction || !localDeduction.toString().trim()) {
      setLocalDeductionError("Amount is required");
      isValid = false;
    } else {
      setLocalDeductionError("");
    }

    if (!selectDate) {
      setSelectDateError("Date is required");
      isValid = false;
    } else {
      setSelectDateError("");
    }

    if (!selectedPaymentMode) {
      paymentModeError = "Please select a payment mode";
    }
    if (!paymentStatus) {
      paymentStatusError = "Please select a payment status";
    }
    setPaymentModeError(paymentModeError);
    setPaymentStatusError(paymentStatusError);
    if (paymentModeError || paymentStatusError) {
      setIsLoading(false);
      setUpdateOrder(false);
      return;
    }

    const getAddressByTag = (addresses, tag) => {
      if (addresses) {
        const foundAddress = addresses.find(
          (address) => address?.address?.tag?.toLowerCase() === tag.toLowerCase()
        );
        return foundAddress?.address;
      }
      return null;
    };

    const pictureUrlsPayload = picturesUrls.map((url) => ({ url }));
    try {
      const payload = {
        groupId: parseInt(groupId),
        orderId: orderDetails?.orderId,
        paymentInfo: {
          mode: selectedPaymentMode,
          paymentStatus,
          loggedInUser: parseInt(userId),
        },
        shippingDetails: {
          driverName: driverName,
          vehicleNo: vehicleNo,
          driverNo: driverNo,
          location: location,
          name: name,
          contactPerson: contactPerson,
          trackingNo: trackingNo,
          trackingURL: trackingURL,
          dispatchNo: dispatchNo,
          transporter: transporter
        },
        lat: data?.lat,
        lon: data?.lon,
        source: "Web",
        tokenAmount: parseInt(localDeduction),
        refNo: refNo,
        refDate: refDate,
        status: status,
        department: selectedDepartment,
        handedBy: assign
      };

      switch (selectedPaymentMode) {
        case "Pay by Cash":
          break;
        case "Pay by Online":
          payload.paymentInfo.OnlineTxnId = onlineTxnIds;
          payload.paymentInfo.pictures = picturesUrls;
          break;
        case "Pay by Credit":
          payload.paymentInfo.creditPaymentDate = selectedDate ? selectedDate.toLocaleDateString("en-GB") : null;
          break;
        case "Pay by Cheque":
          payload.paymentInfo.bankName = bankName;
          payload.paymentInfo.chequeNo = chequeNo;
          payload.paymentInfo.chequeAmount = chequeAmount;
          payload.paymentInfo.chequeDate = selectDate ? selectDate.toLocaleDateString("en-GB") : null;
          break;
        case "Pay using UPI":
          payload.paymentInfo.upi = upiId;
          payload.paymentInfo.txnId = updatedTransactionId;
          break;
        default:
          break;
      }

      if (paymentStatus !== "partially paid") {
        payload.RemainingAmount = parseInt(remainingAmount);
      }

      const response = await fetch(`${API.apiGateway}/commerce-gw/assign/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${refreshToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Order Updated Successfully!", {
          style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
        });
        setUpdateOrder(false);
        setOpenPaymentSidebar(false);
        setOpenSidebar(false);
        fetchData();
      } else {
        console.error("Failed to update order:", response.statusText);
        toast.error("Error in updating order. Please try again.", {
          style: { backgroundColor: 'red', color: 'white', height: '50px' },
        });
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Error in updating order. Please try again.", {
        style: { backgroundColor: 'red', color: 'white', height: '50px' },
      });
    } finally {
      setIsLoading(false);
      setUpdateOrder(false);
    }
  };

  const handleQRCode = async () => {
    try {
      const transactionReference = "service-request-id1";
      const transactionNote = "Mahindra Tractor";
      const amountInput = document.getElementById("amountInput");
      const amount = amountInput.value;
      if (!amount) {
        alert("Please enter an amount.");
        return;
      }
      const apiUrl = `${API.paymentAPI}/payment/qrcode/upi?transactionReference=${transactionReference}&transactionNote=${encodeURIComponent(
        transactionNote
      )}&amount=${amount}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const qrCodeImageData = await response.blob();
        const qrCodeImageUrl = URL.createObjectURL(qrCodeImageData);
        document.getElementById("qrCodeImage").src = qrCodeImageUrl;
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const handleUPIChange = (e) => {
    setUpiId(e.target.value);
    setVerificationStatus(null);
  };

  const handleRequestPayment = async () => {
    try {
      const response = await fetch(`${API.paymentAPI}/payment/v1/api/vpa/collect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: orderDetails?.orderId,
          groupId: parseInt(groupId),
          payeeVpa: upiId,
          description: "Testing",
          amount: 2,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setShowPopup(true);
        const transactionId = responseData?.data?.id;
        const intervalId = setInterval(async () => {
          try {
            const statusResponse = await fetchTransactionStatus(transactionId);
            if (statusResponse?.data?.status === "success") {
              clearInterval(intervalId);
            } else {
              console.error("Transaction Status not success:", statusResponse);
            }
          } catch (error) {
            console.error("Error fetching transaction status:", error);
          } finally {
            clearInterval(intervalId);
          }
        }, 5000);
        setAnotherApiIntervalId(transactionId);
      } else {
        console.error("TransactionId not available in the response:");
      }
    } catch (error) {
      console.error("Error requesting payment:", error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleInputChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    if (enteredAmount > RemainingAmount) {
      setLocalDeduction(RemainingAmount);
    } else {
      setLocalDeduction(enteredAmount);
    }
  };

  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handleClosePopup = async () => {
    try {
      const statusResponse = await fetchTransactionStatus(transactionId);
      if (statusResponse?.data?.status === "success") {
        setShowPopup(false);
        setPopupTimer(180);
        setPopupClosed(true);
      } else {
        console.error("Transaction Status not success:", statusResponse);
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  };

  const verifyUPI = async () => {
    try {
      const response = await fetch(
        `${API.paymentAPI}/payment/v1/api/vpa/check`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ vpaName: upiId }),
        }
      );

      if (response.ok) {
        setVerificationStatus("valid");
      } else {
        setVerificationStatus("invalid");
      }
    } catch (error) {
      console.error("Error verifying UPI:", error);
      setVerificationStatus("invalid");
    }
  };

  const fetchTransactionStatus = async (transactionId) => {
    try {
      const response = await fetch(
        `${API.paymentAPI}/payment/v1/api/vpa/fetch/transaction/status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: transactionId }),
        }
      );
      if (response.ok) {
        const transactionSuccess = await response.json();
        const txnId = transactionSuccess?.data?.txnId;
        setUpdatedTransactionId(txnId);
      } else {
        console.error(
          "Failed to fetch transaction status:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  };

  const renderPaymentButton = () => {
    const paymentStatus = orderDetails?.paymentInfo?.paymentStatus;
    switch (paymentStatus) {
      case 'paid':
        return (
          <button type="button"
            className="btn-paid">
            Paid
          </button>
        );

      case 'partially paid':
        return (
          <button type="button"
            className="btn-partialy-paid ml-2">
            Partially Paid
          </button>
        );
      case 'credit':
        return (
          <button type="button"
            className="btn-Credit-paid ml-2">
            Credit
          </button>
        );
      case 'Online':
        return (
          <button type="button"
            className="btn-online-paid ml-2">
            Online
          </button>
        );
      default:
        return (
          <></>
        );
    }
  };

  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedTime = currentDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  useEffect(() => {
    return () => {
      clearInterval(anotherApiIntervalId);
    };
  }, [anotherApiIntervalId]);

  useEffect(() => {
    let intervalId;

    const checkTransactionStatus = async () => {
      try {
        const statusResponse = await fetchTransactionStatus(transactionId);
        if (statusResponse?.data?.status === "success") {
          setShowPopup(false);
          handleClosePopup()
          clearInterval(intervalId);
        } else {
          console.error("Transaction Status not success:", statusResponse);
        }
      } catch (error) {
        console.error("Error fetching transaction status:", error);
      }
    };

    if (showPopup) {
      intervalId = setInterval(() => {
        setPopupTimer((prevTimer) => {
          const newTimer = prevTimer - 1;

          if (newTimer === 0) {
            setShowPopup(false);
            setPopupTimer(10);
            clearInterval(intervalId);
          } else if (newTimer % 5 === 0) {
            checkTransactionStatus();
          }
          return newTimer;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);

  }, [showPopup]);

  const [picturesUrl, setPicturesUrl] = useState(null);
  const handleImageSelect = async (index, file) => {
    try {
      if (!file) {
        throw new Error("No file selected.");
      }

      const formData = new FormData();
      formData.append("image", file);

      const response = await fetch(`${API.imageUpload}/doc-upload/signed-url?fileName=${file.name}&public=true&action=write`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to get signed URL.");
      }

      const responseData = await response.json();
      const signedUrl = responseData.url;

      const uploadResponse = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload image.");
      }

      const uploadedUrl = new URL(signedUrl);
      const finalUrl = `${uploadedUrl.protocol}//${uploadedUrl.host}${uploadedUrl.pathname}`;

      setAllPictures(prevPictures => [...prevPictures, finalUrl]);
      if (finalUrl.trim() !== '') {
        setPicturesUrls(prevUrls => [...prevUrls, finalUrl]);
      }

      toast.success("Image Uploaded Successfully!", {
        style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image.");
    }
  };
  const handleAddButtonClick = () => {
    setOnlineTxnIds(prevTxnIds => [...prevTxnIds, '']);
    setPicturesUrls(prevUrls => [...prevUrls, '']);
    setOnlineTxnIds([...onlineTxnIds, '']);

  };
  const [picturesUrls, setPicturesUrls] = useState([]);
  const handleTxnIdChange = (index, value) => {
    const updatedTxnIds = [...onlineTxnIds];
    updatedTxnIds[index] = value;
    setOnlineTxnIds(updatedTxnIds);
  };

  const handleAddTxnId = () => {
    setOnlineTxnIds(prevTxnIds => [...prevTxnIds, '']);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="#F5F5FC"
        toastStyle={{ color: "black" }}
      />
      {dataOpen ? (

        <div className="update-payment-sidebar">
          <div className="row container-fluid p-0">
            <div className="col-lg-8 col-md-8 col-sm-8">
              <p className="fontw-800 mb-0">Order # {orderDetails?.orderId}</p>
              <p className="font14 pr-4 ">
                {t("neworder.items")}: {totalQuantity} {t("table.Amount")}:{`Rs. ${totalProductPrice || 0}`}
                {renderPaymentButton()}
              </p>
            </div>

            {showPopup && (
              <>
                <div className="payment-popup">
                  <div className="payment-box">
                    <p className="text-delete ">
                      Transaction successful! Closing in {popupTimer} seconds...
                    </p>

                    <button onClick={onClose}
                      className="ware-nodelete">No</button>
                  </div>
                </div>
              </>
            )}
            <div className="col-lg-4 col-md-4 col-sm-4 p-0">
              <div className="payment-date-time font16">
                <p className="text-right mb-0 font14">{t("table.date & time")}</p>
                <p className="font14">{formattedDate} - {formattedTime}</p>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <span className="fontw-800 margin1 font14">{t("sidebarcustomers.Payment Method")}<span className="text-danger font14">*</span></span><br />
              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="payByCash"
                  name="payment-method"
                  value="Pay by Cash"
                  checked={selectedPaymentMethod === "Pay by Cash"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payByCash"
                  className="ml-2 payment-details-option font14">
                  {t("order.Cash")}
                </label>
              </div>

              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="scanQRCode"
                  name="payment-method"
                  value="Scan QR Code"
                  checked={selectedPaymentMethod === "Scan QR Code"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="scanQRCode"
                  className="ml-2 payment-details-option font14">
                  {t("order.Scan QR")}
                </label>
              </div>

              <div className="col-2">
                <input
                  type="radio"
                  className=" mt-1 cursor-pointer"
                  id="payUsingUPI"
                  name="payment-method"
                  value="Pay using UPI"
                  checked={selectedPaymentMethod === "Pay using UPI"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payUsingUPI"
                  className="ml-1 font14 payment-details-option cursor-pointer upi">
                  UPI
                </label>
              </div>

              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="Paybycredit"
                  name="payment-method"
                  value="Pay by Credit"
                  checked={selectedPaymentMethod === "Pay by Credit"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="Paybycredit"
                  className="ml-2 payment-details-option font14">
                  {t('table.Credit')}
                </label>
              </div>

              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="paybyonline"
                  name="payment-method"
                  value="Pay by Online"
                  checked={selectedPaymentMethod === "Pay by Online"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="paybyonline"
                  className="ml-2 payment-details-option font14">
                  {t("order.Online")}
                </label>
              </div>

              <div className="col-2">
                <input
                  type="radio"
                  className=""
                  id="payByCheque"
                  name="payment-method"
                  value="Pay by Cheque"
                  checked={selectedPaymentMethod === "Pay by Cheque"}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor="payByCheque"
                  className="ml-2 payment-details-option font14">
                  {t("order.Cheque")}
                </label>
              </div>
            </div>

            <div className="p-0">
              {selectedPaymentMethod === "Pay by Cash" && (
                <>
                  <input
                    type="number"
                    id="deduction"
                    placeholder={t("order.Enter Amount")}
                    value={localDeduction}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const numericValue = enteredValue.replace(/[^0-9]/g, '');
                      setLocalDeduction(numericValue);
                    }}
                    className="small-input-btn"
                    min="0"
                  />
                </>
              )}
            </div>

            <div className="container-fluid">
              {selectedPaymentMethod === "Pay using UPI" && (
                <div className="row font14">
                  <div className="col-6  ">
                    <input
                      type="text"
                      className={`small-input-btn font14 ml-1 ${verificationStatus === "invalid" ? "is-invalid" : ""
                        }`}
                      placeholder="Enter UPI"
                      value={upiId}
                      onChange={(e) => handleUPIChange(e)}
                    />
                    <button
                      type="button"
                      className="verify-button font14"

                      onClick={verifyUPI}>
                      {t("order.Verify")}
                    </button>
                    {verificationStatus === "invalid" && (
                      <div className="invalid-feedback">Invalid UPI</div>
                    )}
                    {verificationStatus === "valid" && (
                      <div className="popup-message">
                        <p>Verification successful!</p>
                      </div>
                    )}
                  </div>

                  <div className="col-1">
                    {verificationStatus === "valid" && (
                      <div className="popup-message">
                        <button
                          type="button"
                          className="request-btn mt-1 p-1"
                          onClick={handleRequestPayment}>
                          {t("order.Request Payment")}
                        </button>
                      </div>
                    )}
                    {popupClosed && (
                      <div className="success-message">
                        <p>Transaction successful!</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {paymentModeError && <div className="error text-danger font14">{paymentModeError}</div>}
            {selectedPaymentMethod === "Scan QR Code" && (
              <>
                <div>
                  <input
                    type="number"
                    placeholder={t("order.Enter Amount")}
                    className="small-input-btn ml-1"
                    id="amountInput"
                    value={localDeduction}
                    onChange={handleInputChange}
                  />
                  <button onClick={handleQRCode}
                    className="generate-qr font-14">
                    {t("order.Generate QR")}
                  </button>
                </div>
                <img id="qrCodeImage"
                  className="QR-image mt-1" />
              </>
            )}

            {selectedPaymentMethod === "Pay by Credit" && (
              <>
                <div>
                  <label className="font14 pl-1">{t("order.Due Date")}</label><br />
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => handleDateChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                    placeholderText="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    className="small-input-date"
                    minDate={new Date()}
                  />

                </div>
              </>
            )}

            {selectedPaymentMethod === "Pay by Online" && (
              <div className="row pt-2">
                <div className="col-12 p-0">
                  <input
                    type="number"
                    className="small-input-btn"
                    placeholder={t("table.Amount")}
                    value={localDeduction}
                    onChange={(e) => setLocalDeduction(e.target.value)}
                    min="0"
                  />
                  {onlineTxnIds.map((txnId, index) => (
                    <div key={index} className="mb-2">
                      <input
                        type="text"
                        className="small-input-btn"
                        placeholder={t("order.TrID")}
                        value={txnId}
                        onChange={(e) => handleTxnIdChange(index, e.target.value)}
                      />
                      <label>
                        <i className="fa-solid fa-upload cursor-pointer ml-2"></i>
                        <input
                          type="file"
                          onChange={(e) => handleImageSelect(index, e.target.files[0])}
                          accept="image/png, image/jpeg, image/jpg"
                          className="upload-doc ml-2"
                        />
                      </label>

                      {picturesUrls[index] && (
                        <button className="view-button ml-2" onClick={() => handleViewImage(picturesUrls[index])}>
                          {t("quota.View")}
                        </button>
                      )}

                      {index === onlineTxnIds?.length - 1 && picturesUrls[index] && (
                        <button className="view-button ml-2" onClick={handleAddButtonClick}>
                          {t("neworder.add")}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedPaymentMethod === "Pay by Cheque" && (
              <>
                <div className="row pt-2">
                  <div className="col-3 p-0">
                    <input
                      type="text"
                      className="small-input-btn"
                      placeholder={t("SupplierBank.Bank Name")}
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="error">{bankNameError}</div>
                    <input
                      type="text"
                      className="small-input-btn"
                      placeholder={t("order.Cheque Number")}
                      value={chequeNo}
                      onChange={(e) => setChequeNo(e.target.value)}
                    />
                    <div className="error">{chequeNoError}</div>
                  </div>

                  <div className="col-3">
                    <input
                      type="number"
                      className="small-input-btn1 mt-1"
                      placeholder={t("table.Amount")}
                      value={localDeduction}
                      onChange={handleInputChange}
                      min="0"
                    />

                    <div className="error">{localDeductionError}</div>
                    <DatePicker
                      selected={selectDate}
                      onChange={(date) => handleDateChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                      placeholderText="dd-mm-yyyy"
                      dateFormat="dd-MM-yyyy"
                      className="small-input-date1 mt-1"
                      minDate={new Date()}
                    />
                    <div className="error">{selectDateError}</div>
                  </div>

                  <div className="col-6"></div>
                </div>
              </>
            )}

            <p className="font14 fontw-800 margin1 pr-3">{t("table.Payment Status")}<span className="text-danger font14">*</span></p>
            <select
              className="small-input-btn font12"
              id="metric"
              name="metric"
              value={paymentStatus}
              onChange={handlePaymentStatusChange}>
              <option value="">{t("order.status")}</option>
              <option value="paid" disabled={selectedPaymentMethod === "Pay by Credit" || selectedPaymentMethod === "Pay by Cheque"}>
                Paid
              </option>

              <option
                value="partially paid"
                disabled={selectedPaymentMethod === "Pay by Credit" || selectedPaymentMethod === "Pay by Cheque"}>
                Partially Paid
              </option>

              <option value="credit" disabled={selectedPaymentMethod !== "Pay by Credit" && selectedPaymentMethod !== "Pay by Cheque"}>
                Credit
              </option>
            </select>

            {paymentStatusError && <div className="error text-danger font14">{paymentStatusError}</div>}
            <div>
              <span>
                <button
                  type="button"
                  className="Create-Order"
                  onClick={handleUpdateOrder}
                  disabled={
                    !selectedPaymentMethod ||
                    !paymentStatus ||
                    !localDeduction ||
                    (
                      (selectedPaymentMethod === "Pay using UPI" && !verificationStatus) ||
                      (selectedPaymentMethod === "Pay by Online" && (onlineTxnIds.some(txnId => !txnId) || picturesUrls?.length === 0))
                    )
                  }>
                  {updateOrder ? (
                    <div className="spinner-border spinner-border-sm payment-spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    t("inventoryUpdate.Update")
                  )}
                </button>

                <button
                  type="button"
                  className="verify-btn text-dark cancel-btn ml-2 font14"
                  onClick={handleCancelClick}>
                  {t("sidebarcustomersbtn.Cancel")}
                </button>
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default PaymentDetailsSidebar;