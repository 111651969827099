import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ChromePicker } from 'react-color'
import Select from 'react-select';
import { useLanguage } from '../../LanguageContext';
import { fetchParentCategories } from '../../utils/Helper';
import { Icons } from '../../components/avatar/Avatar';
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';
import { toast } from 'react-toastify';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent', border: "1px solid #C0C2DE",
    }),
};

const ServiceUpdateSidebar = ({ closeSidebar, isOpen, _id, setSelectedServiceRequest, selectedServiceRequest, fetchService }) => {
    const [serviceData, setServiceData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { t } = useLanguage();
    const [categories, setCategories] = useState([]);
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');
    const [webURLError, setWebURLError] = useState('');
    const [templateError, setTemplateError] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [longitudeError, setLongitudeError] = useState('');
    const [latitudeError, setLatitudeError] = useState('');

    const [data, setData] = useState({
        name: selectedServiceRequest.name || '',
        desc: selectedServiceRequest.desc || '',
        groupId: groupId,
        icon: selectedServiceRequest.icon || '',
        action: selectedServiceRequest.action || '',
        webURL: selectedServiceRequest.webURL || '',
        listener: selectedServiceRequest.listener || [],
        tags: selectedServiceRequest.tags || [],
        longitude: selectedServiceRequest.longitude || '',
        latitude: selectedServiceRequest.latitude || '',
        featured: selectedServiceRequest.featured || false,
        messageCustomers: selectedServiceRequest.messageCustomers || false,
        paymentEnabled: selectedServiceRequest.paymentEnabled || false,
        needCloseConfirmation: selectedServiceRequest.paymentEnabled || false,
        template: selectedServiceRequest.template || '',
        categoryId: selectedServiceRequest.categoryId || '',
        theme: {
            background: selectedServiceRequest.theme.background || '#B9B91A',
            color: "FFFFFF"
        },
        showServices: selectedServiceRequest.showServices || false,
        hideServices: selectedServiceRequest.hideServices || false,
    });

    const handleCloseSidebar = () => {
        setSelectedServiceRequest(false)
    }

    const [color, setColor] = useState(data.theme.background || '#B9B91A');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const navigate = useNavigate();

    const handleColorChange = (newColor) => {
        const hexColor = newColor.hex;
        const colorWithoutHash = hexColor.replace('#', '');
        setData((prevFormData) => ({
            ...prevFormData,
            theme: { background: colorWithoutHash },
        }));
        setColor(hexColor);
    };

    useEffect(() => {
        const fetchDataForUpdate = async () => {
            try {
                const response = await fetch(`${API.commerceAPI}/commerce/services/${_id}`);
                if (!response.ok) {
                    console.error('Failed to fetch service data for update');
                    return;
                }
                const { data } = await response.json();
                const updatedFormData = {
                    ...data,
                    name: data.name || '',
                    desc: data.desc || '',
                    groupId: data.groupId || '',
                    icon: data.icon || '',
                    action: data.action || '',
                    productSearchTags: data.productSearchTags ? data.productSearchTags.join(', ') : '',
                    webURL: data.webURL || '',
                    longitude: data.longitude || '',
                    latitude: data.latitude || '',
                    tags: data.tags ? data.tags.join(', ') : '',
                    listener: data.listener ? data.listener.join(', ') : '',
                    theme: { background: data.theme.background },
                    template: data.template || '',
                };

                setServiceData(data);
                setData(updatedFormData);
                const checkboxes = [
                    'featured',
                    'messageCustomers',
                    'paymentEnabled',
                    'needCloseConfirmation',
                    'showServices',
                    'hideServices',
                ];

                checkboxes.forEach((checkbox) => {
                    setData((prevFormData) => ({
                        ...prevFormData,
                        [checkbox]: data[checkbox] || false,
                    }));
                });

                setSelectedCategory({
                    label: data.categoryName,
                    value: data.categoryId,
                });
            } catch (error) {
                console.error('Error fetching data for update:', error);
            }
        };

        if (_id) {
            fetchDataForUpdate();
        }
    }, [_id]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetchParentCategories();
                const categoriesData = response.data.items;
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleInputChange = (fieldName, value) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const handleCheckboxChange = (field) => {
        setData((prevFormData) => ({
            ...prevFormData,
            [field]: !prevFormData[field],
        }));
    };

    const validateForm = () => {
        let isValid = true;

        if (!selectedCategory) {
            setCategoryError("Please select a category")
            isValid = false
        } else {
            setCategoryError('')
        }

        if (!data.name) {
            setNameError("Name is required");
            isValid = false
        } else {
            setNameError('')
        }
        if (!data.desc) {
            setDescError("Description is required");
            isValid = false
        } else {
            setDescError('')
        }
        if (!data.tags) {
            setTagsError("Tags is required");
            isValid = false
        } else {
            setTagsError('')
        }
        if (!data.longitude) {
            setLongitudeError("Longitude is required");
            isValid = false
        } else {
            setLongitudeError('')
        }
        if (!data.latitude) {
            setLatitudeError("Latitude is required");
            isValid = false
        } else {
            setLatitudeError('')
        }
        if (!data.webURL) {
            setWebURLError("Web URL is required");
            isValid = false
        } else {
            setWebURLError('')
        }
        if (!data.template) {
            setTemplateError("Template is required");
            isValid = false
        } else {
            setTemplateError('')
        }
        return isValid;
    }

    const handleUpdate = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const updateUrl = `${API.commerceAPI}/commerce/services/${_id}`;
            const response = await fetch(updateUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                toast.success('Service Update Successfully', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" },
                })
                setServiceData(false);
                setSelectedServiceRequest(false);
                fetchService()
                closeSidebar()
            } else {
                console.error('Failed to update service');
            }
        } catch (error) {
            console.error('Error during update:', error);
        }
    };

    const handleIconsUploadSuccess = (uploadedIcon) => {
        setData((prevFormData) => ({
            ...prevFormData,
            icon: uploadedIcon,
        }));
    };

    const handleToggleColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    return (
        <div className={`feature-sidebar p-3 ${isOpen ? 'open' : ''}`}>
            <div className='service-header'>
                <div className=''>
                    <b>{t('serviceSidebar.Update Service')}</b>
                </div>
                <div className=''>
                    <i className="fa-solid fa-circle-xmark  service-sidebar-close1" onClick={handleCloseSidebar}></i>
                </div>
            </div>
            <hr className='service-hrs'></hr>
            <div className="mt-2 ml-4">
                <div className='row'>
                    <div className='col-12 p-0'>
                        <div className='scrollbar-service'>
                            <p className='request-title mt-2'>{t('serviceSidebar.Parent category')} <span className='text-danger'>*</span>
                                <Select styles={customStyles}
                                    className='service-category-select'
                                    value={selectedCategory}
                                    onChange={(selectedOption) => {
                                        setSelectedCategory(selectedOption);
                                        handleInputChange('categoryId', selectedOption.value);
                                    }}
                                    options={categories.map((category) => ({
                                        label: category.name, value: category.categoryId,
                                    }))} />
                                {categoryError && <span className='validation-error'>{categoryError}</span>}
                            </p>
                            <p className='request-title '>
                                {t('serviceSidebar.Service request')}<span className='text-danger'>*</span><br />
                                <input type='text' className='service-input'
                                    value={data.name || ''}
                                    name='name'
                                    placeholder={t('serviceSidebar.Name')}
                                    onChange={(e) => handleInputChange('name', e.target.value)}></input>
                            </p>
                            {nameError && <span className='validation-error'>{nameError}</span>}
                            <p className='request-title mt-2'>
                                {t('serviceSidebar.Description')}<span className='text-danger'>*</span><br />
                                <textarea
                                    className='service-input-1'
                                    name='desc'
                                    placeholder={t('serviceSidebar.Description')}
                                    value={data.desc || ''}
                                    onChange={(e) => handleInputChange('desc', e.target.value)}
                                ></textarea>
                                {descError && <span className='validation-error'>{descError}</span>}
                            </p>
                            <p className='request-title mt-2'>{t('serviceSidebar.Icon')}</p>
                            <Icons data={{ profile_pic: data.icon }} onUploadSuccess={handleIconsUploadSuccess} />
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <p className='request-title'>{t('serviceSidebar.Action')}</p>
                                    <select styles={customStyles}
                                        className='service-select1'
                                        name='action'
                                        value={data.action || ''}
                                        onChange={(e) => handleInputChange('action', e.target.value)}>
                                        <option className='service-option'>whatsapp</option>
                                        <option className='service-option'>list</option>
                                    </select>
                                </div>
                                <div className='col-lg-8'>
                                    <p className='request-title'>{t('serviceSidebar.tags')}<span className='text-danger'>*</span></p>
                                    <input type='text' className='service-tag'
                                        name='tags'
                                        value={data.tags || ''}
                                        onChange={(e) => handleInputChange('tags', e.target.value)}
                                        placeholder={t('serviceSidebar.Enter tags separated by commas')}></input>
                                    {tagsError && <span className='validation-error'>{tagsError}</span>}
                                </div>
                            </div>
                            <p className='request-title mt-3'>{t('serviceSidebar.Longitude')}<span className='text-danger'>*</span>
                                <input
                                    type='text'
                                    className='text service-input'
                                    name='longitude'
                                    placeholder={t('serviceSidebar.Longitude')}
                                    value={data.longitude || ''}
                                    onChange={(e) => handleInputChange('longitude', e.target.value)}
                                />
                                {longitudeError && <span className='validation-error'>{longitudeError}</span>}
                            </p>
                            <p className='request-title mt-2'>{t('serviceSidebar.Latitude')}<span className='text-danger'>*</span>
                                <input
                                    type='text'
                                    className='text service-input'
                                    name='latitude'
                                    placeholder={t('serviceSidebar.Latitude')}
                                    value={data.latitude || ''}
                                    onChange={(e) => handleInputChange('latitude', e.target.value)}
                                />
                                {latitudeError && <span className='validation-error'>{latitudeError}</span>}
                            </p>
                            <p className='request-title mt-2'>
                                {t('serviceSidebar.Web URL')}<span className='text-danger'>*</span><br />
                                <input type='text'
                                    className='text service-url'
                                    name='webURL'
                                    placeholder={t('serviceSidebar.Web URL')}
                                    value={data.webURL || ''}
                                    onChange={(e) => handleInputChange('webURL', e.target.value)}
                                />
                                {webURLError && <span className='validation-error'>{webURLError}</span>}
                            </p>
                            <p className='request-title mt-2'>
                                {t('serviceSidebar. Notification Mobile Number')}
                                <input type='text' className='text service-url '
                                    name='listener'
                                    value={data.listener || ''}
                                    onChange={(e) => handleInputChange('listener', e.target.value)}
                                    placeholder={t('serviceSidebar.Enter numbers separated by commas')}
                                />
                            </p>
                            <p className='request-title'>{t('serviceSidebar.Selected Color')}: {color}</p>
                            <button onClick={handleToggleColorPicker} className="color-pick">{t('serviceSidebar.Toggle Color Picker')}</button>
                            {showColorPicker && (
                                <ChromePicker
                                    color={color}
                                    onChange={handleColorChange}
                                    className="chrome-picker-small"
                                />
                            )}<br /><br />
                            <input
                                type='checkbox'
                                name='featured'
                                checked={data.featured}
                                onChange={() => handleCheckboxChange('featured')}
                            />
                            <span className='checkbox-feature'>{t('serviceSidebar.is featured ?')}</span><br />
                            <input
                                type='checkbox'
                                name='messageCustomers'
                                checked={data.messageCustomers}
                                onChange={() => handleCheckboxChange('messageCustomers')}
                            />
                            <span className='checkbox-feature'>  {t('serviceSidebar.Message Customers?')}</span><br />
                            <input
                                type='checkbox'
                                name='paymentEnabled'
                                checked={data.paymentEnabled}
                                onChange={() => handleCheckboxChange('paymentEnabled')}
                            />
                            <span className='checkbox-feature'> {t('serviceSidebar.Payment enabled?')}</span><br />
                            <input
                                type='checkbox'
                                name='needCloseConfirmation'
                                checked={data.needCloseConfirmation}
                                onChange={() => handleCheckboxChange('needCloseConfirmation')}
                            />
                            <span className='checkbox-feature'> {t('serviceSidebar. Need close confirmation from customers?')}</span><br />
                            <input
                                type='checkbox'
                                name='showServices'
                                checked={data.showServices}
                                onChange={() => handleCheckboxChange('showServices')}
                            />
                            <span className='checkbox-feature'> {t('serviceSidebar.Show Services on App?')}</span>
                            <br />
                            <input
                                type='checkbox'
                                name='hideServices'
                                checked={data.hideServices}
                                onChange={() => handleCheckboxChange('hideServices')}
                            />
                            <span className='checkbox-feature'> {t('serviceSidebar.Hide Services on App?')}</span>
                            <p className='request-title mt-2'>{t('serviceSidebar.WhatsApp Message Template(customer)')}<span className='text-danger'>*</span>
                                <input type='text' className='service-input-3'
                                    name='template'
                                    placeholder={t('serviceSidebar.WhatsApp Message')}
                                    value={data ? data.template : ''}
                                    onChange={(e) => handleInputChange('template', e.target.value)}>
                                </input>
                                {templateError && <span className='validation-error'>{templateError}</span>}
                            </p>
                        </div>
                        <div className='mt-4'>
                            <button className='service-save' onClick={handleUpdate}>{t('serviceSidebar.Update')}</button>

                            <button className='service-cancel' onClick={handleCloseSidebar}>{t('serviceSidebar.Cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceUpdateSidebar;