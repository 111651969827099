import React from 'react';

const BankDetails = ({ supplier }) => {
  const { accountDetails } = supplier;
  const firstAccount = accountDetails && accountDetails?.length > 0 ? accountDetails[0].accountDetails : null;

  return (
    <>
      {firstAccount ? (
        <div>

          {firstAccount.bank && (
            <small className='font16'>Bank Name: {firstAccount.bank}</small>
          )}<br />

          {firstAccount.accountNo && (
            <small className='font16'>Account Number: {firstAccount.accountNo}</small>
          )}<br />

          {firstAccount.branch && (
            <small className='font16'>Branch: {firstAccount.branch}</small>
          )}<br />

          {firstAccount.ifsc && (
            <small className='font16'>IFSC code: {firstAccount.ifsc}</small>
          )}<br/>

          {firstAccount.upi?.upi && (
            <small className='font16'>GooglePay/PhonePay UPI: {firstAccount.upi.upi}</small>
          )}
        </div>
      ) : (
        <p>------</p>
      )}
    </>
  );
};

export default BankDetails;