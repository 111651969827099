import React, { useState, useEffect } from 'react';
import "./UpdateTermsCondition.scss";
import { useLanguage } from '../../LanguageContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/Api';
import STORAGE_KEYS from "../../utils/storageKey";

const UpdateTermsCondition = ({ handleClose, editTerms, fetchTermsConditions }) => {
    const { t } = useLanguage();
    const [terms, setTerms] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedModule, setSelectedModule] = useState(editTerms?.propertieType || '');
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);

    const [allTerms, setAllTerms] = useState(
        editTerms?.termsConditions.map((term, index) => ({ ...term, is_active: !!term.is_active, index: index + 1 })) || []
    );
    const handleAddTerm = () => {
        if (inputValue.trim() !== '') {
            const isDuplicate = allTerms.some(term => term.content === inputValue) ||
                terms.some(term => term.content === inputValue);

            if (!isDuplicate) {
                setTerms([...terms, { content: inputValue, is_active: false, index: allTerms.length + terms.length + 1 }]);
                setInputValue('');
            } else {
                toast.error('This term already exists.');
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddTerm();
        }
    };

    useEffect(() => {
        const inputElement = document.querySelector('.terms_add');
        inputElement.addEventListener('keydown', handleKeyDown);

        return () => {
            inputElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [inputValue]);

    const handleUpdateTerms = async () => {
        try {
            const combinedTerms = [...allTerms, ...terms];
            const uniqueTerms = Array.from(new Map(combinedTerms.map(term => [term.content, term])).values());

            const updatedTerms = uniqueTerms.map((term, i) => ({ ...term, index: i + 1 }));

            if (updatedTerms.length === 0) {
                toast.error('Please select at least one term to save.');
                return;
            }

            const response = await fetch(`${API.commerceAPI}/commerce/termsconditions/updateTermsConditions/1703228300417/${editTerms?.termsConditionsId}`, {
                method: 'PUT',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    termsConditionsId: editTerms?.termsConditionsId,
                    termsConditions: updatedTerms,
                    propertieType: selectedModule,
                }),
            });

            if (response.ok) {
                setAllTerms(updatedTerms);
                setTerms([]);
                toast.success('Terms and conditions updated successfully!', {
                    style: { backgroundColor: "#328b17", color: "white", height: "50px" }
                });
                fetchTermsConditions();
                handleClose();
            } else {
                toast.error('Failed to update terms and conditions');
            }
        } catch (error) {
            console.error('An error occurred while updating terms and conditions', error);
            toast.error('An error occurred while updating terms and conditions');
        }
    };

    const handleModuleChange = (e) => {
        setSelectedModule(e.target.value);
    };

    const handleCheckboxChange = (index, fromAllTerms = false) => {
        if (fromAllTerms) {
            setAllTerms(prevTerms =>
                prevTerms.map((term, i) =>
                    term.index === index ? { ...term, is_active: !term.is_active } : term
                )
            );
        } else {
            setTerms(prevTerms =>
                prevTerms.map((term, i) =>
                    term.index === index ? { ...term, is_active: !term.is_active } : term
                )
            );
        }
    };

    const handleDeleteTerm = async (index, fromAllTerms = false) => {
        const termToDelete = fromAllTerms ? allTerms.find(term => term.index === index) : terms.find(term => term.index === index);
        const termsConditionsId = editTerms?.termsConditionsId;
        const termIdToDelete = termToDelete?._id;
        console.log(termIdToDelete, "termIdToDelete");
        try {
            const response = await fetch(`${API.commerceAPI}/commerce/termsconditions/deleteTermCondition/${groupId}/${termsConditionsId}/${termIdToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                if (fromAllTerms) {
                    const updatedAllTerms = allTerms.filter(term => term.index !== index).map((term, i) => ({
                        ...term,
                        index: i + 1,
                    }));
                    setAllTerms(updatedAllTerms);
                } else {
                    const updatedTerms = terms.filter(term => term.index !== index);
                    setTerms(updatedTerms);
                }
                toast.success('Term deleted successfully', {
                    style: { backgroundColor: "#328b17 ", color: "white", height: "50px" }
                });
            } else {
                const errorData = await response.json();
                console.error('Error deleting term:', errorData);
                toast.error('Failed to delete term');
            }
        } catch (error) {
            console.error('Error deleting term:', error);
            toast.error('An error occurred while deleting term');
        }
    };

    return (
        <div className='terms_Sidebar'>
            <div className='container'>
                <div className='sidebar_head'>
                    <div className='mt-4'>
                        <strong>{t('terms.Update Terms & Conditions')}</strong>
                    </div>

                    <div className="font14 lineHeight0 cursor-pointer mt-4">
                        <p className="dropdown-items drop-module drop-term">{t('terms.Add Module')}</p>
                        <select
                            className="cursor-pointer font14"
                            value={selectedModule}
                            onChange={handleModuleChange}>
                            <option>{t('terms.Select Module')}</option>
                            <option>Order Invoice</option>
                            <option>PO Invoice</option>
                            <option>STO Invoice</option>
                        </select>
                    </div>

                    <div>
                        <i className="fa-solid fa-circle-xmark cursor-pointer close-term" onClick={handleClose} />
                    </div>
                </div>
                <hr />

                <div className='terms_scroller-bar'>
                    <div>
                        <strong>{t('terms.Terms & Conditions')}</strong>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder={t('terms.Add new terms and conditions')}
                            className="terms_add"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button type="button" className="add_terms" onClick={handleAddTerm}>
                            {t('terms.Add')}
                        </button>

                        <div className='mt-3 list_term'>
                            <ol>
                                {allTerms.map((term) => (
                                    <div className="term-item" key={term.index}>
                                        <input
                                            type="checkbox"
                                            checked={term.is_active}
                                            onChange={() => handleCheckboxChange(term.index, true)}
                                            className='ml-2'
                                        />
                                        <span className="term-content">{term.content}</span>
                                        <i
                                            className="fa-solid fa-trash cursor-pointer delete-icon terms-delete-icon"
                                            onClick={() => handleDeleteTerm(term.index, true)}
                                        />
                                    </div>
                                ))}
                                {terms.map((term) => (
                                    <div className="term-item" key={term.index}>
                                        <input
                                            type="checkbox"
                                            checked={term.is_active}
                                            onChange={() => handleCheckboxChange(term.index)}
                                            className='ml-2'
                                        />
                                        <span className="term-content">{term.content}</span>
                                        <i
                                            className="fa-solid fa-trash cursor-pointer delete-icon terms-delete-icon"
                                            onClick={() => handleDeleteTerm(term.index)}
                                        />
                                    </div>
                                ))}
                            </ol>
                        </div>

                    </div>
                </div>

                <div className='terms-btn-save'>
                    <div>
                        <button className='terms-save' onClick={handleUpdateTerms}>
                            {t('terms.Update')}
                        </button>
                    </div>

                    <div>
                        <button className='terms-cancel' onClick={handleClose}>
                            {t('terms.cancel')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateTermsCondition;
