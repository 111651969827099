import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchManager } from '../../utils/Helper';
import { useLanguage } from '../../LanguageContext';
import 'react-toastify/dist/ReactToastify.css';
import "./InventorySidebar.scss"
import API from '../../services/Api';
import STORAGE_KEYS from '../../utils/storageKey';
import { useParams } from 'react-router-dom';

const HTMLContentRenderer = ({ content }) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

const InventorySidebar = ({ handleClose, fetchData }) => {
    const [minStock, setMinStock] = useState('');
    const [isLoadingProduct, setIsLoadingProduct] = useState(false);
    const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
    const [isLoadingWarehouse, setIsLoadingWarehouse] = useState(false);
    const { t } = useLanguage();
    const { empId, phoneNumber } = useParams()
    console.log("Extracted empId:", empId);
    const [options, setOptions] = useState([]);
    const [expiryDate, setExpiryDate] = useState(null);
    const [grnDate, setGrnDate] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [GRNNo, setGRNNo] = useState('')
    const [batchNo, setBatchNo] = useState('');
    const [lotNo, setLotNo] = useState('')
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [selectedVariantPrices, setSelectedVariantPrices] = useState({});
    const [buyingPrice, setBuyingPrice] = useState('');
    const [memberPrice, setMemberPrice] = useState('');
    const [regularPrice, setRegularPrice] = useState('');
    const [marketPrice, setMarketPrice] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [productcode, setProductcode] = useState('');
    const [gst, setGst] = useState('');
    const [cgst, setCgst] = useState('');
    const [sgst, setSgst] = useState('');
    const [igst, setIgst] = useState('')
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [apiCallMade, setApiCallMade] = useState(false);
    const [selectedManagers, setSelectedManagers] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [selectedManagerName, setSelectedManagerName] = useState('');
    const groupId = localStorage.getItem(STORAGE_KEYS.GROUPID);
    const [selectedWareHouseId, setSelectedWareHouseId] = useState(null);
    const [productError, setProductError] = useState('');
    const [supplierError, setSupplierError] = useState('');
    const [warehouseError, setWarehouseError] = useState('');
    const [minStockError, setMinStockError] = useState('');
    const [quantityError, setQuantityError] = useState('');

    const handleMinStockChange = (event) => {
        setMinStock(event.target.value);
        setMinStockError('')
    };

    const handleGRNNoChange = (e) => {
        setGRNNo(e.target.value)
    };

    const handleBatchNoChange = (e) => {
        setBatchNo(e.target.value);
    };

    const handleLotNoChange = (e) => {
        setLotNo(e.target.value);
    };

    const handleGstChange = (e) => {
        setGst(e.target.value);
    };

    const handleCgstChange = (e) => {
        setCgst(e.target.value);
    };

    const handleIgstChange = (e) => {
        setIgst(e.target.value);
    };

    const handleSgstChange = (e) => {
        setSgst(e.target.value);
    };

    const handleBuyPriceChange = (e) => {
        setBuyingPrice(e.target.value)
    };

    const handleRegularPriceChange = (e) => {
        setRegularPrice(e.target.value)
    };

    const handleMarketPriceChange = (e) => {
        setMarketPrice(e.target.value)
    };

    const handleMemberPriceChange = (e) => {
        setMemberPrice(e.target.value)
    };
    const handleSearch = (query) => {
        setIsLoadingProduct(true);
        fetch(`${API.commerceAPI}/commerce/products/all/group/${groupId}?search=${query}`)
            .then((response) => response.json())
            .then((responseJson) => {
                const { data } = responseJson;
                if (data && Array.isArray(data?.items)) {
                    const productOptions = data?.items.map((product) => ({
                        label: product.name,
                        value: product._id,
                        description: product?.value?.description,
                        category: product.category,
                        variants: product.variants,
                        subCategory: product.subCategory,
                        supplier: product.supplier,
                        productcode: product.productcode,
                        minStock: parseInt(minStock),
                        buyingPrice: product.buyingPrice,
                        marketPrice: product.marketPrice,
                        regularPrice: product.regularPrice,
                        memberPrice: product.memberPrice
                    }));
                    setOptions(productOptions);
                    setSelectedProduct(null);
                    if (productOptions?.length > 0) {
                        setProductcode(productOptions[0].productcode);
                    }
                } else {
                    console.error('No products found in the API response:', data);
                }
                setIsLoadingProduct(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoadingProduct(false);
            });
    };

    const handleInputChange = (input) => {
    };

    const handleProductSelection = (selected) => {
        setSelectedProduct(selected[0]);
        setProductError('');
    };
    const handleWarehouseSearch = (query) => {
        setIsLoadingWarehouse(true);
        fetch(`${API.commerceAPI}/commerce/warehouse/all/getByGroupId/${groupId}?WareHouseName=${query}`)
            .then((response) => response.json())
            .then((responseJson) => {
                const { data } = responseJson;

                if (data && Array.isArray(data.items)) {
                    const warehouseOptions = data.items.map((warehouse) => {
                        const firstManager = warehouse.manager?.[0];
                        return {
                            label: warehouse.WareHouseName,
                            value: warehouse.wareHouseId,
                            wareHouseId: warehouse.wareHouseId,
                            managerName: firstManager?.name || '',
                            managerId: firstManager?.id || '',
                            phoneNo: firstManager?.phoneNumber || '',
                        };
                    });;
                    setWarehouseOptions(warehouseOptions);
                } else {
                    console.error('No warehouse options found in the API response:', data);
                }
            })
            .catch((error) => {
                console.error('Error fetching warehouse data:', error);
            })
            .finally(() => {
                setIsLoadingWarehouse(false);
            });
    };

    const handleWarehouseSelection = (selected) => {
        const selectedWarehouse = selected[0];
        setSelectedWarehouse(selectedWarehouse);
        setWarehouseError('');

        if (selectedWarehouse) {
            setSelectedWareHouseId(selectedWarehouse.wareHouseId);
            setSelectedManagerName(selectedWarehouse.managerName);
            setSelectedManagers(selectedWarehouse.managerId);
            setPhoneNumbers(selectedWarehouse.phoneNo);
        } else {
            setSelectedWarehouse(null);
            setSelectedWareHouseId(null);
            setSelectedManagerName('');
            setPhoneNumbers('')
        }
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
        setQuantityError('');
    };

    const handleSaveInventory = () => {
        let isValid = true;

        setProductError('');
        setSupplierError('');
        setWarehouseError('');
        setQuantityError('');
        setMinStockError('');

        if (!selectedProduct) {
            setProductError('Please select a product.');
            isValid = false;
        }

        if (!selectedSupplier) {
            setSupplierError('Please select a supplier.');
            isValid = false;
        }

        if (!selectedWareHouseId) {
            setWarehouseError('Please select a warehouse.');
            isValid = false;
        }

        if (!quantity) {
            setQuantityError('Please enter quantity.');
            isValid = false;
        }

        if (!minStock) {
            setMinStockError('Please fill minimum reorder level.');
            isValid = false;
        }

        if (!isValid) {
            setIsSaving(false);
            return;
        }

        setIsSaving(true);
        const data = {
            GRNNo: parseFloat(GRNNo),
            batchNo: parseFloat(batchNo),
            lotNo: parseFloat(lotNo),
            expiryDate: expiryDate,
            buyingPrice: parseFloat(buyingPrice || selectedProduct?.buyingPrice),
            memberPrice: parseFloat(memberPrice || selectedProduct?.memberPrice),
            regularPrice: parseFloat(regularPrice || selectedProduct?.regularPrice),
            marketPrice: parseFloat(marketPrice || selectedProduct?.marketPrice),
            groupId: groupId,
            productcode: selectedProduct.productcode,
            productId: selectedProduct.value,
            quantity: parseInt(quantity),
            minStock: parseInt(minStock),
            WareHouseName: selectedWarehouse.label,
            wareHouseId: selectedWarehouse.value,
            selectedVariants: selectedVariants,
            gst: parseInt(gst),
            igst: parseInt(igst),
            cgst: parseInt(cgst),
            sgst: parseInt(sgst),
            selectedVariantPrices: selectedVariants.map(variant => ({
                variant,
                ...selectedVariantPrices[variant],
            })),
            selectedSupplier: {
                supplierId: selectedSupplier.value,
                name: selectedSupplier.label,
                company: selectedSupplier.company,
                address: selectedSupplier.address,
                phoneNumber: selectedSupplier.phoneNumber,
            },
            managers: [
                {
                    name: selectedManagerName || "",
                    id: selectedManagers,
                    phoneNumber: phoneNumber || phoneNumbers
                }
            ],
        };

        fetch(`${API.commerceAPI}/commerce/inventry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    setIsSaving(false);
                    toast.success('Inventory Created Successfully!', {
                        style: { backgroundColor: "#328b17", color: "white", height: "50px" },
                    });
                    setApiCallMade(true);
                    fetchData();
                    handleClose();
                }
            })
            .catch((error) => {
                console.error('Error saving inventory data:', error);
                toast.error('An error occurred while saving inventory data. Please try again.');
                setIsSaving(false);
            });
    };

    const handleSearchSupplier = (query) => {
        setIsLoadingSupplier(true);
        fetch(`${API.commerceAPI}/commerce/supplier/all/getByGroupId/${groupId}?name=${query}`)
            .then((response) => response.json())
            .then((data) => {
                if (data && Array.isArray(data?.data?.items)) {
                    const supplierOptions = data?.data?.items?.map((supplier) => ({
                        label: supplier.name,
                        value: supplier.id,
                        address: supplier?.address,
                        phoneNumber: supplier.phoneNumber
                    }));

                    setOptions(supplierOptions);
                } else {
                    toast.error('No suppliers found in the API response:', data);
                }
                setIsLoadingSupplier(false);
            })
            .catch((error) => {
                console.error('Error fetching supplier data:', error);
                setIsLoadingSupplier(false);
            });
    };

    const handleSupplierSelection = (selected) => {
        setSelectedSupplier(selected[0]);
        setSupplierError('');
    };

    return (
        <>
            <div className='invertery-sidebar'>
                <div className='inventery-box pt-2'>
                    <div className='d-inline-flex'>
                        <b className='inventery-text'>{t('inventorySidebar.Add Product to Inventory')}</b>
                    </div>
                    <i
                        className="fa-solid fa-circle-xmark cancle-icon-inventery "
                        onClick={handleClose}>
                    </i>
                    <p className='inventery-text-sub'>{t('inventorySidebar.Search & add products')} </p>
                    <hr className='inventery-hr' />

                    <div className='scrollbar-enventary'>
                        <div className='product-id'>
                            <span>{t('inventorySidebar.Name of Product')}<span className='text-danger'>*</span></span>
                            <AsyncTypeahead
                                id={`product-search`}
                                labelKey="label"
                                isLoading={isLoadingProduct}
                                options={options}
                                onSearch={handleSearch}
                                onChange={handleProductSelection}
                                onInputChange={handleInputChange}
                                className={`inventory-products ${productError ? 'error-border' : ''}`}
                                placeholder={t('inventorySidebar.Enter Product ID / SKU / Name')} />
                            {productError && <p className="inventory-error">{productError}</p>}
                        </div>

                        <div className='pt-1'>
                            <HTMLContentRenderer content={selectedProduct?.description} />
                        </div>

                        <div className='row'>
                            <div className='col-4'>
                                <h6 className=''>{t('inventorySidebar.Category')} </h6>
                                <p className='color-color'>{selectedProduct?.category ? selectedProduct?.category : "--"}</p>
                            </div>

                            <div className='col-4'>
                                <h6 className='color-color'>{t('inventorySidebar.Sub Category')}</h6>
                                <p className='color-color'>{selectedProduct?.subCategory ? selectedProduct?.subCategory : "--"}</p>
                            </div>
                        </div>

                        <h6 className='mt-1'>{t("inventorySidebar.Buying Price")}</h6>
                        <input
                            type='text'
                            placeholder={t("inventorySidebar.Buying Price")}
                            className='enventery-input'
                            name='buyingPrice'
                            value={buyingPrice || selectedProduct?.buyingPrice}
                            onChange={handleBuyPriceChange}
                        />

                        <h6 className='mt-1'>{t("inventorySidebar.Member Price")}</h6>
                        <input
                            type='text'
                            placeholder={t("inventorySidebar.Member Price")}
                            className='enventery-input'
                            value={memberPrice || selectedProduct?.memberPrice}
                            onChange={handleMemberPriceChange}
                        />

                        <h6 className='mt-1'>{t("invenSidebar.Regular Price")}</h6>
                        <input
                            type='text'
                            placeholder={t("invenSidebar.Regular Price")}
                            className='enventery-input'
                            value={regularPrice || selectedProduct?.regularPrice}
                            onChange={handleRegularPriceChange}
                        />

                        <h6 className='mt-1'>{t("inventorySidebar.Market Price")}</h6>
                        <input
                            type='text'
                            placeholder={t("inventorySidebar.Market Price")}
                            className='enventery-input'
                            value={marketPrice || selectedProduct?.marketPrice}
                            onChange={handleMarketPriceChange}
                        />

                        <h6 className='mt-1'>{t('inventorySidebar.Total GST')}</h6>
                        <input
                            type='text'
                            placeholder={t('inventorySidebar.Total GST')}
                            className='enventery-input'
                            value={gst}
                            onChange={handleGstChange}>
                        </input>

                        <div className='row mt-2'>
                            <div className='col-lg-4'>
                                <label>{t('inventorySidebar.CGST')}</label>
                                <input
                                    type='text'
                                    placeholder={t('inventorySidebar.CGST')}
                                    className='cgst-input'
                                    value={cgst}
                                    onChange={handleCgstChange}
                                ></input>
                            </div>

                            <div className='col-lg-4'>
                                <label>{t('inventorySidebar.SGST')}</label>
                                <input
                                    type='text'
                                    placeholder={t('inventorySidebar.SGST')}
                                    className='sgst-input'
                                    value={sgst}
                                    onChange={handleSgstChange}>
                                </input>
                            </div>

                            <div className='col-lg-4'>
                                <label>{t('inventorySidebar.IGST')}</label><br></br>
                                <input
                                    type='text'
                                    placeholder={t('inventorySidebar.IGST')}
                                    className='igst-input'
                                    value={igst}
                                    onChange={handleIgstChange}>
                                </input>
                            </div>
                        </div>

                        <h6 className='color-color pt-4'>{t('inventorySidebar.Supplier')}
                            <span className='text-danger'>*</span></h6>
                        <AsyncTypeahead
                            id="supplier-search"
                            labelKey="label"
                            isLoading={isLoadingSupplier}
                            options={options}
                            onSearch={handleSearchSupplier}
                            onChange={handleSupplierSelection}
                            onInputChange={handleInputChange}
                            placeholder={t('inventorySidebar.Search Suppliers by Name')}
                            className={`inventory-products ${supplierError ? 'error-border' : ''}`}
                        />

                        {supplierError && <p className="inventory-error">{supplierError}</p>}
                        {selectedSupplier && (
                            <div className='pt-2'>
                                <b>{selectedSupplier?.company}</b>
                                <p className='color-color'>{selectedSupplier?.address},</p>
                                <span>{t('inventorySidebar.Phone No')} : {selectedSupplier?.phoneNumber}</span>
                            </div>
                        )}

                        <div className='mt-3'>
                            <b className='color-color'>{t('inventorySidebar.Reorder level')}</b>
                            <span className='text-danger'>*</span><br />
                            <input
                                className={`enventery-input ${minStockError ? 'error-border' : ''}`}
                                type="number"
                                value={minStock}
                                onChange={handleMinStockChange}
                                placeholder={t('inventorySidebar.Reorder level')}
                            />
                            {minStockError && <p className="inventory-error">{minStockError}</p>}
                        </div>

                        <div className='mt-3'>
                            <b className='color-color'>{t('inventorySidebar.Enter the Quantity')} </b>
                            <span className='text-danger'>*</span><br></br>
                            <input
                                className={`enventery-input mt-2 ${quantityError ? 'error-border' : ''}`}
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                placeholder={t('inventorySidebar.Enter Quantity')} />
                            {quantityError && <p className="inventory-error">{quantityError}</p>}
                        </div>

                        <div className='mt-3'>
                            <b className='color-color'>{t('inventorySidebar.Enter GRN Number')}</b>
                            <input type='number'
                                className='enventery-input'
                                placeholder={t('inventorySidebar.Enter GRN Number')}
                                onChange={handleGRNNoChange}
                            />
                        </div>

                        <div className='mt-3'>
                            <b className='color-color'>{t('inventorySidebar.GRN Date')}</b><br />
                            <DatePicker
                                className='enventery-input-2 pl-1'
                                selected={grnDate}
                                onChange={(date) => setGrnDate(date)}
                                placeholderText="dd-mm-yyyy"
                                dateFormat="dd-MM-yyyy"
                            />
                        </div>

                        <div className='mt-2'>
                            <b className='color-color'>{t('inventorySidebar.Batch Number/Enter Lot')}</b><br />
                            <input
                                className='enventery-input'
                                type='text'
                                value={batchNo}
                                onChange={handleBatchNoChange}
                                placeholder={t('inventorySidebar.Batch Number/Enter Lot')}
                            />
                        </div>

                        <div className='mt-2'>
                            <b className='color-color'>{t('inventorySidebar.Expiry Date')}</b><br />
                            <DatePicker
                                selected={expiryDate}
                                onChange={date => setExpiryDate(date)}
                                placeholderText="dd-mm-yyyy"
                                dateFormat="dd-MM-yyyy"
                                className='enventery-input-2'
                            />
                        </div>

                        <div className='mt-2'>
                            <b className='color-color'>{t('inventorySidebar.Select Warehouse')}</b>
                            <span className='text-danger'>*</span><br></br>
                            <AsyncTypeahead
                                id="warehouse-search"
                                labelKey="label"
                                valueKey="wareHouseId"
                                isLoading={isLoadingWarehouse}
                                options={warehouseOptions}
                                onSearch={handleWarehouseSearch}
                                onChange={handleWarehouseSelection}
                                onInputChange={handleInputChange}
                                className={`inventory-supplier-input-Add ${warehouseError ? 'error-border' : ''}`}
                                placeholder={t('inventorySidebar.Enter Warehouse Name')}
                            />
                            {warehouseError && <span className="inventory-error">{warehouseError}</span>}
                            <ul className='mt-3 manager-name'>
                                <div key={selectedManagers?.value}>
                                    <h6>Manager: {selectedManagerName} : {phoneNumbers}</h6>
                                </div>
                            </ul>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <button className='update-inventery-btn' onClick={handleSaveInventory} disabled={isSaving}>
                            {isSaving ? 'Saving...' : t('inventorySidebar.Save')}
                        </button>
                        <button className=' cancel-inventery-btn ml-2'>{t('inventorySidebar.Cancel')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventorySidebar;